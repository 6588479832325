import styled from '@emotion/styled';
import Container from '../../../../components/Container';

export const Content = styled(Container)`
  padding: 0;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    
    a {
      position: relative;
      font-size: 16px;
      text-decoration: none;
      color: ${ ({ theme }) => theme.colors?.black };
      
      img {
        margin-left: 10px;
      }
    }
  }
  
  ${({ theme }) => theme.functions?.minTablet(`
    .title {
      flex-direction: row;
    }
  `)}  
`;

export const Competition = styled.div`
  background: #FFFFFF;
  border: 1px solid ${ ({ theme }) => theme.colors?.lightBlue };
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(16, 36, 44, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;

  ${props => props.theme.functions?.mediaQueryMax('650px', `
    flex-direction: column;
  `)}
  
  ${props => props.theme.functions?.mediaQueryMin('1002px', `
    justify-content: space-between;
    max-height: 150px;
  `)}

  .date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 120px;
    padding: 20px 25px;
    background: ${ ({ theme }) => theme.colors?.lightBlue };
    border-radius: 4px;
    margin-right: 30px;
    min-width: 90px;
  ${props => props.theme.functions?.mediaQueryMax('650px', `
    width: 100%;
    margin-right: unset;
  `)}

    > * {
      line-height: 1;
    }
  }

  a {
    text-decoration: none;
  }
  
  .description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    min-height: 130px;

    ${props => props.theme.functions?.mediaQueryMax('650px', `
      text-align: left;
    `)}

    > a {
      display: flex;
      align-items: center;
      text-decoration: none;
    }

    .status {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px 8px;
      width: max-content;
      height: 25px;
      background: #C1E6E5;
      border-radius: 4px;
      margin-left: 10px;


      ${props => props.theme.functions?.mediaQueryMax('1000px', `
        margin-left: 0;
        margin-bottom: 5px;
      `)}

      ${props => props.theme.functions?.mediaQueryMax('650px', `
        margin-bottom: 5px;
      `)}

      > p {
        font-family: DM Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        width: max-content;
      }
    }

    .playing {
      background: #10242C;
      > p {
        color: #fff;
      }
    }

    ul {
      list-style: none;
      padding-left: 0;
      margin-top: 10px;
  
      li {
        font-size: ${ ({ theme }) => theme?.functions?.toEm(12) };
        display: inline-block;
        margin-right: 20px;
        text-transform: capitalize;
        
        &:after {
          content: "•";
          position: absolute;
          padding: 0 5px;
        }
          
        &:last-child {
            margin-right: 0;
        }
        
        &:last-child:after {
          display: none;
        }
      }
      
      &.event-details {
          li {
              font-size: ${({ theme }) => theme?.functions?.toEm(16)};
          }

          ${props => props.theme.functions?.mediaQueryMax('650px', `
            margin-bottom: 10px;
          `)}          
      }
    }
  }
  
  .go-to-event {
    position: absolute;
    right: 10px;
    bottom: 60px;
    ${props => props.theme.functions?.mediaQueryMax('650px', `
      right: 20px;
      bottom: 20px;
    `)}

    a {
      padding: 20px;
    }
  }
`;
