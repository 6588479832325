import styled from '@emotion/styled';
import { Link, Button } from '../Collection';

export const BadgeImage = styled.img({
  maxHeight: '17px',
  margin: 'auto 3px auto 0',
});

export const GoToEvent = styled(Button)({
  position: 'absolute',
  right: '40px',
  top: '50%',
  transform: 'translateY(-50%)',
  '@media (max-width: 875px)': {
    display: 'none',
  },
});

export const EventName = styled(Link)(props => ({
  color: props.theme.colors.primary,
  fontFamily: 'DM Sans',
  fontSize: props.theme?.functions?.toEm(24),
  lineHeight: '31px',
  fontWeight: '700',
  '@media (max-width: 1024px)': {
    fontSize: props.theme?.functions?.toEm(24),
  },
  textDecoration: 'none !important',
}));

export const GridContainer = styled.div({
  '.box': {
    width: '100%',
  },
});

export const TabContainer = styled.div({
  margin: 'auto 0 10px 0',
  '.react-tabs__tab-list': {
    borderBottom: 'unset',
  },
  '.react-tabs__tab': {
    border: 'unset',
    bottom: 'unset',
    position: 'unset',
    listStyle: 'unset',
    padding: 'unset',
    marginRight: '10px',
    height: '18px',
    userSelect: 'none',
    '&:focus': {
      boxShadow: 'unset',
    },
    '&:hover': {
      p: {
        color: '#7CC9C6 !important',
      },
    },
    '&:nth-of-type(2)': {
      borderLeft: '1px solid #EAF6F6',
      padding: '0 0 0 10px',
    },
  },
});

export const EventContainer = styled.div({
  display: 'flex',
  width: '100%',
  height: '220px',
  background: '#FFFFFF',
  border: '1px solid #EAF6F6',
  boxShadow: '0px 2px 4px rgba(16, 36, 44, 0.1)',
  borderRadius: '10px',
  marginBottom: '20px',

  '@media (max-width: 1270px)': {
    maxWidth: 'unset',
  },
  '@media (max-width: 875px)': {
    padding: '0 10px 10px',
    '.mobile': {
      display: 'unset',
    },
    '.desktop': {
      display: 'none',
    },
    flexDirection: 'column',
    height: 'unset',
    minHeihgt: '350px',
  },
  '@media (min-width: 876px)': {
    '.mobile': {
      display: 'none',
    },
    '.desktop': {
      display: 'unset',
    },
  },
});

export const Thumbnail = styled.img({
  width: '207px',
  minWidth: '207px',
  objectFit: 'cover',
  objectPosition: 'center',
  borderTopLeftRadius: '10px',
  borderBottomLeftRadius: '10px',
  '@media (max-width: 875px)': {
    borderRadius: '10px',
    margin: '10px auto',
    width: '100%',
    height: '315px',
    objectPosition: 'center center',
  },
});

export const Details = styled.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  margin: '20px',
  width: '100%',
  '@media (max-width: 1275px)': {
    maxWidth: 'unset',
    h4: {
      fontSize: '20px',
    },
  },
  '@media (max-width: 875px)': {
    margin: 'unset',
    border: 'unset',
  },
});

export const AboutEvent = styled.ul(props => ({
  listStyle: 'none',
  width: '100%',
  ...props.margin && {
    margin: props.margin,
  },
}));

export const EventData = styled.li({
  display: 'inline-block',
  marginRight: '15px',

  '&:after': {
    content: '"•"',
    position: 'absolute',
    padding: '0 5px',
  },
  '&:last-of-type': {
    marginRight: 'unset',
  },
  '&:last-of-type:after': {
    display: 'none',
    padding: 'unset',
  },
});

export const Badge = styled.div(props => ({
  display: 'flex',
  marginRight: '5px',
  background: props.bg,
  padding: `0 ${props.theme?.functions?.toEm(8)}`,
  borderRadius: '55px',
  height: '24px',
  img: {
    marginRight: '5px',
  },
  p: {
    minWidth: 'max-content',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '12px',
    lineHeight: '16px',
  },
}));

export const Competitions = styled.div(props => ({
  display: 'flex',
  ...props.direction && {
    flexDirection: props.direction,
  },

  margin: props.margin ? props.margin : '25px 0 0 0',
  '@media (max-width: 875px)': {
    flexDirection: 'column',
    ...!props.isGrid && { margin: '25px 0 25px' },
  },
  '@media (max-width: 650px)': {
    position: 'relative',
  },
}));

export const List = styled.ul({
  display: 'flex',
  flexWrap: 'wrap',
  '.availableMl': {
    background: '#65C763',
  },
  '.availableOs': {
    background: '#7CC9C6',
  },
  '.availableWl': {
    background: '#FFA800',
  },
  '.closed': {
    background: '#FC878E',
  },
  '.ended': {
    background: '#C8C8C8',
  },
  '@media (max-width: 650px)': {
    position: 'relative',
  },
});

export const Competition = styled.li({});

export const RadiusIcon = styled.img({
  marginRight: 'unset !important',
  marginBottom: '-2px',
});

export const OnMobile = styled.div`
display: none;
border-radius: 10px;

.eventRow__content--right
{
  margin: 2px;
  > div
  {
    padding: 10px 20px !important;
  }
}

${props => props.theme.functions?.mediaQueryMax('990px', `
  display: block;
`)}
`;

export const OnDesktop = styled.div`
display: block;
width: 100%;

${props => props.theme.functions?.mediaQueryMax('990px', `
  display: none;
`)}
`;
