import styled from '@emotion/styled';

export const Container = styled.div(() => ({
  display: "flex",
  justifyContent: "space-between",
  // marginTop: 5,
  marginBottom: 20,

  ".form-group": {
    margin: 0,
  },

  '@media (min-width: 992px) and (max-width: 1350px)': {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 20,
  },

  '@media (max-width: 991px)': {
    justifyContent: "center",
  },

  '@media (max-width: 767px)': {
    position: "relative",
    justifyContent: "start",
    left: -15,
    width: "calc(100% + 30px)",
    padding: "0px 10px 15px",
    boxShadow: "0px 2px 4px 0px rgba(16, 36, 44, 0.10)",
    marginTop: 10,
    marginBottom: 10,
  },
}));

export const FiltersContainer = styled.div(() => ({
  display: "flex",
  gap: 10,

  ".bookings-filters-list": {
    display: "flex",
    gap: 10,
  },

  '[class*="-singleValue"]': {
    maxWidth: "calc(100% - 2px)",

    ".my-location-img": {
      position: "relative",
      top: 2,
    },
  },

  ".form-group--select": {
    '[class*="-container"]': {
      marginBottom: 0,
    },
  },

  '@media (min-width: 991px) and (max-width: 1500px)': {
    '.select-time, .select-date, .proximity-radius': {
      width: 150,
    },
  },

  '@media (min-width: 768px) and (max-width: 991px)': {
    '.select-time, .proximity-radius': {
      width: 100,
    },
    '.select-date': {
      width: 130,
    },
  },

  '@media (max-width: 767px)': {
    display: "none",
  },
}));

export const TogglesContainer = styled.div(() => ({
  display: "flex",
  columnGap: 10,

  '@media (max-width: 991px)': {
    display: 'none',
  },
}));

export const DatePickerContainer = styled.div(() => ({
  position: "absolute",
  left: "50%",
  transform: "translateX(-50%)",
  zIndex: 9,
  background: "#FFFFFF",
  border: "1px solid #C1E6E5",
  boxSizing: "border-box",
  boxShadow: "0px 8px 34px rgba(0, 0, 0, 0.15)",
  borderRadius: 10,
}));

export const AdvancedFiltersContainer = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  gap: 5,
  cursor: "pointer",
  marginLeft: 10,
  marginRight: 10,

  img: {
    marginRight: 10,
  },

  ".total-filters": {
    fontSize: 12,
    fontWeight: 700,
    color: "#fff",
    width: 21,
    height: 21,
    flexShrink: 0,
    background: "#000",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  ".map-icon": {
    display: "none",
  },

  '@media (max-width: 991px)': {
    p: {
      display: 'none',
    },
  },

  '@media (max-width: 767px)': {
    ".map-icon": {
      display: "block",
    },
  },
}));

export const MobileFiltersContainer = styled.div(() => ({
  width: "100%",
  display: "flex",
  borderRadius: 4,
  border: "1px solid #C1E6E5",
  boxShadow: "0px 2px 4px 0px rgba(16, 36, 44, 0.10)",
  padding: 10,
  position: "relative",
  cursor: "pointer",

  ul: {
    display: "-webkit-box",
    listStyle: "none",
    overflowX: "scroll",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    maxWidth: "calc(100% - 35px)",

    "::-webkit-scrollbar": {
      display: "none",
    },

    li: {
      marginRight: 10,
      paddingRight: 10,
      borderRight: "1px solid #C1E6E5",
      "&:last-of-type": {
        borderRight: "none",
      },
    },
  },

  ".btn-container": {
    background: "linear-gradient(270deg, #FFF 69.12%, rgba(255, 255, 255, 0.00) 97.79%)",
    position: "absolute",
    right: 5,
    top: 0,
    height: "100%",
    width: "75px",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",

    ".search-btn": {
      width: 30,
      height: 30,
      img: {
        padding: 5,
      },
    },
  },

  '@media (min-width: 768px)': {
    display: "none",
  },
}));

export const MobileAdvancedFiltersContainer = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  gap: 20,
  position: "absolute",
  right: 15,
  bottom: -40,

  "> div": {
    position: "relative",
    cursor: "pointer",
    display: "flex",
    gap: 5,

    ".total-filters": {
      fontSize: 12,
      fontWeight: 700,
      color: "#fff",
      width: 18,
      height: 18,
      flexShrink: 0,
      background: "#000",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },

  '@media (min-width: 768px)': {
    display: "none",
  },
}));
