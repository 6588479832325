const getNextStep = (currentStep, steps = []) => {
  if (!steps.length) return;

  const { step, path } = steps.find(({ completed }) => !completed) || {};

  if (step === currentStep) return;
  if (path) return path;
  return '/dashboard?congrats';
};

export default getNextStep;
