import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import LoadImage from '../../../common/LoadImage';

import { Button as OSButton } from '../../../SecondButton';

import {
  Container,
  First,
  MediaContainer,
  MediaLink,
  MenuContainer,
  Second,
  CallToAction,
  Third,
  Capsule,
  Policies,
  Content,
  MediaIcon,
  MPGLogo,
  OSBanner,
} from './styles';

import {
  P, Contain, Link, H2, Button,
} from '../../../Collection';
import LangSelect from '../../../Form/langSelect';

const Footer = ({ LoggedIn, custom, blur }) => {
  const { t } = useTranslation('footer');
  return LoggedIn ? (
    <Container LoggedIn transparent={custom} blur={blur}>
      <Content LoggedIn>
        <First LoggedIn>
          <Contain direction="column" width="max-content" className="media">
            <MediaContainer>
              <MediaLink to="https://www.facebook.com/sportyaromania" external="yes">
                <MediaIcon src={LoadImage('facebook-icon.svg')} LoggedIn white={custom} />
                <P small margin="0 0 0 10px">Sportya Tenis Romania</P>
              </MediaLink>
              <MediaLink to="https://www.facebook.com/sportyapadel" external="yes">
                <MediaIcon src={LoadImage('facebook-icon.svg')} LoggedIn white={custom} />
                <P small margin="0 0 0 10px">Sportya Padel Romania</P>
              </MediaLink>
              <MediaLink to="https://www.youtube.com/@sportya6038" external="yes">
                <MediaIcon src={LoadImage('youtube-icon.svg')} LoggedIn white={custom} />
                <P small margin="0 0 0 10px">Sportya Global</P>
              </MediaLink>
            </MediaContainer>

            <Contain align="center" className="contact">
              <Link to="mailto:contact@sportya.net" external="yes">
                <P margin="0 40px 0 0">contact@sportya.net</P>
              </Link>
              {/* <Link to="tel:+4 031 805 4042" external="yes"> */}
              {/*  <P>+4 031 805 4042</P> */}
              {/* </Link> */}
            </Contain>
          </Contain>
          <MenuContainer LoggedIn>
            <Contain direction="column" className="menu">
              <Link to="/events">
                <P small bold>{t('events')}</P>
              </Link>
              <Link to="/friendly-matches">
                <P small bold>
                  <Trans ns="friendlyMatches" i18nKey="friendlyMatches">
                    Friendly Matches
                  </Trans>
                </P>
              </Link>
              <Link to="/find-player">
                <P small bold>{t('findPlayers')}</P>
              </Link>
            </Contain>
            <Contain direction="column" className="menu">
              <Link to="/leaderboards">
                <P small bold>{t('rankings')}</P>
              </Link>
              <Link to="/for-players">
                <P small bold>{t('forPlayers')}</P>
              </Link>
              <Link to="/plans">
                <P small bold>{t('membership')}</P>
              </Link>
              {/* <Link to="/coming-soon">
                <P small bold>{t('comingSoon')}</P>
              </Link>
              <Link to="/coming-soon">
                <P small bold>{t('blog')}</P>
              </Link> */}
            </Contain>
            <Contain direction="column" className="menu">
              <Link to="/rules-regulations">
                <P small bold>{t('rules')}</P>
              </Link>
              <Link to="/groups-playing-formats">
                <P small bold>{t('groupsPlaying')}</P>
              </Link>
              {/* <Link to="/local-leagues-rules"> */}
              {/*  <P small bold>{t('localLeaguesRules')}</P> */}
              {/* </Link> */}
            </Contain>
          </MenuContainer>
        </First>
        <Third LoggedIn>
          <P xSmall opacity={0.5}>
            MPG &copy; 2008 -
            {new Date().getFullYear()}
            .
            {t('copyrightFooter')}
          </P>
          <Policies LoggedIn white={custom}>
            <Link to="/terms-conditions">
              <P xSmall>{t('termsAndConditions')}</P>
            </Link>
            <Link to="/confidentiality">
              <P xSmall>{t('privacy')}</P>
            </Link>
            <Link to="/return-policy">
              <P xSmall>{t('returnPolicy')}</P>
            </Link>
            {!blur && <LangSelect classes="language-selector" NoLabel LoggedIn />}
          </Policies>
        </Third>
      </Content>
    </Container>
  ) : (
    <Container>
      <Content>
        <First>
          <Contain direction="column" width="max-content" className="media">
            <MediaContainer>
              <MediaLink to="https://www.facebook.com/sportyaromania" external="yes">
                <MediaIcon src={LoadImage('facebook-icon.svg')} />
                <P small margin="0 0 0 10px">Sportya Tenis Romania</P>
              </MediaLink>
              <MediaLink to="https://www.facebook.com/sportyapadel" external="yes">
                <MediaIcon src={LoadImage('facebook-icon.svg')} />
                <P small margin="0 0 0 10px">Sportya Padel Romania</P>
              </MediaLink>
              <MediaLink to="https://www.youtube.com/@sportya6038" external="yes">
                <MediaIcon src={LoadImage('youtube-icon.svg')} />
                <P small margin="0 0 0 10px">Sportya Global</P>
              </MediaLink>
            </MediaContainer>
            <P small maxWidth="445px" margin="43px 0" opacity={0.5}>
              <Trans ns="footer" i18nKey="sportyaDesc">
                We have set out to connect and inspire athletes of the world by creating competitive platforms,
                events and specific tools that help them make the most out of their sport life.
              </Trans>
            </P>
            <Contain align="center" className="contact">
              <Link to="mailto:contact@sportya.net" external="yes">
                <P margin="0 40px 0 0">contact@sportya.net</P>
              </Link>
              {/* <Link to="tel:+4 031 805 4042" external="yes"> */}
              {/*  <P>+4 031 805 4042</P> */}
              {/* </Link> */}
            </Contain>
          </Contain>
          <MenuContainer>
            <Contain direction="column" className="menu">
              <Link to="/events">
                <P small bold>{t('events')}</P>
              </Link>
              <Link to="/friendly-matches">
                <P small bold>
                  <Trans ns="friendlyMatches" i18nKey="friendlyMatches">
                    Friendly Matches
                  </Trans>
                </P>
              </Link>
              <Link to="/for-players">
                <P small bold>{t('forPlayers')}</P>
              </Link>
            </Contain>
            <Contain direction="column" className="menu">
              {/* <Link to="/coming-soon">
                <P small bold>{t('comingSoon')}</P>
              </Link>
              <Link to="/coming-soon">
                <P small bold>{t('blog')}</P>
              </Link> */}
              <Link to="/for-clubs">
                <P small bold>{t('forClubs')}</P>
              </Link>
              <Link to="/plans">
                <P small bold>{t('membership')}</P>
              </Link>
            </Contain>
            <Contain direction="column" className="menu">
              <Link to="/local-leagues-rules">
                <P small bold>{t('localLeaguesRules')}</P>
              </Link>
              <Link to="/groups-playing-formats">
                <P small bold>{t('groupsPlaying')}</P>
              </Link>
              <Link to="/rules-regulations">
                <P small bold>{t('rules')}</P>
              </Link>
            </Contain>
          </MenuContainer>
        </First>
        <CallToAction>
          <H2>{t('motto')}</H2>
          <Button outline large minWidth="200px" margin="0 0 0 23px">
            <Link to="/register">
              {t('joinNow')}
              <img className="ml10" src={LoadImage('right-arrow.svg')} alt="Join Now!" />
            </Link>
          </Button>
        </CallToAction>
        <OSBanner>
          <P bold><Trans ns="clubs" i18nKey="sportyaOSCTA">Are you a Club or Organizer?</Trans></P>
          <OSButton
            noImgMargin
            outline
            height="40px"
            margin="0 0 0 20px"
            padding="0 10px"
          >
            <a href={process.env.REACT_APP_OS_URL} target="_blank" rel="noreferrer">
              <Trans ns="clubs" i18nKey="sportyaOSButton">Go to SportyaOS</Trans>
              <img src={LoadImage('external-link.svg')} className="ml5" alt="Go to SportyaOS" />
            </a>
          </OSButton>
        </OSBanner>
        <Second justify="space-between" align="center">
          <Link to="https://mpg.com.ro" external>
            <MPGLogo src={LoadImage('mpg-logo.svg')} />
          </Link>

          <Contain maxWidth="520px" width="100%" justify="space-between" align="center">
            <Link to="https://mpg.com.ro/#comp-jok341tn" external>
              <P small opacity={0.5}>MPG Featured Projects</P>
            </Link>
            <Link to="https://carpathianmtb.ro" external>
              <Capsule>
                <P xSmall>Carpathian MTB Epic</P>
              </Capsule>
            </Link>
            <Link to="https://en.maratondhl.ro" external>
              <Capsule>
                <P xSmall>DHL Carpathian Marathon</P>
              </Capsule>
            </Link>
          </Contain>
        </Second>
        <Third>
          <P xSmall opacity={0.5}>
            MPG &copy; 2008 -
            {new Date().getFullYear()}
            .
            {t('copyrightFooter')}
          </P>
          <Policies>
            <Link to="/terms-conditions">
              <P xSmall>{t('termsAndConditions')}</P>
            </Link>
            <Link to="/confidentiality">
              <P xSmall>{t('privacy')}</P>
            </Link>
            <Link to="/return-policy">
              <P xSmall>{t('returnPolicy')}</P>
            </Link>
            <LangSelect classes="language-selector" NoLabel />
          </Policies>
        </Third>
      </Content>
    </Container>
  );
};

export default Footer;
