import styled from '@emotion/styled';
import { P } from '../../../../components/Collection';
import LoadImage from '../../../../components/common/LoadImage';

export const Filters = styled.div(props => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  gap: 10,

  ".form-group": {
    marginBottom: 0,

    "&--select": {
      "[class*='-container']": {
        marginBottom: 0,
      },
    },
  },

  ".form-group--select": {
    '[class*="-container"]': {
      marginBottom: 0,
    },
  },

  ...props.fromModal && {
    flexDirection: "column",
  },
}));

export const ClearSearch = styled(P)(() => ({
  position: 'absolute',
  top: 'auto',
  bottom: '-20px',
  margin: 0,
  padding: 0,
  width: '100%',
  height: 'auto',
  opacity: 0.5,
  color: "#000 !important",
  cursor: 'pointer',
  textAlign: 'center',

  '@media (max-width: 880px)': {
    width: 'auto',
    position: 'relative',
  },
}));

export const Calendar = styled.div`
${props => props.theme.functions?.mediaQueryMax('560px', `
    padding: 30px 0 0 0;
`)}
`;

export const Container = styled.div`
position: absolute;
left: 50%;
transform: translateX(-50%);
max-width: 754px;
width: 100%;
z-index: 1;


${props => props.theme.functions?.mediaQueryMin('881px', `
    ${props.pinned ? 'top: 130px;' : ''}
    ${!!props.displaySingleCalendar && 'top: 50px;'}
`)}


${props => props.theme.functions?.mediaQueryMax('880px', `
    top: 200px;
    ${!!props.isOnFriendlyMatchesCreateMatches && 'top: 50px;'}
`)}

margin: 5px auto;

background: #FFFFFF;
border: 1px solid #C1E6E5;
box-sizing: border-box;
box-shadow: 0px 8px 34px rgba(0, 0, 0, 0.15);
border-radius: 10px;
`;

export const Options = styled.div`
position: relative;
display: flex;
justify-content: space-around;

padding: 5px;

width: 185px;
height: 40px;

margin: 20px auto;

background: #EAF6F6;
border-radius: 4px;

cursor: pointer;
    .o  {
        &__active
        {
            width: 91px;
            height: 30px;
            color: white;
            background: #10242C;
        }
    }

    .yearSelector {
        position: absolute;
        left: -260px;
        top: 0px;

        ${props => props.theme.functions?.mediaQueryMax('881px', `
            left: -160px;
        `)}

        ${props => props.theme.functions?.mediaQueryMax('560px', `
            left: 30px;
            top: 50px;
        `)}

        [class*="-control"] {
            height: 30px !important;
            padding: 0 10px;
        }

        [class*="-singleValue"] {
            margin: unset;
        }
    }

    label {
        display: none;
    }
`;

export const Option = styled.div`
padding: 6px 16px;
width: 74px;
height: 30px;

background: #EAF6F6;
border-radius: 4px;

text-align: center;
`;

export const Months = styled.div`
display: flex;
justify-content: space-around;
${props => props.theme.functions?.mediaQueryMax('760px', `
flex-direction: column;
align-items: center;
`)}
height: max-content;
padding: 10px;

* {     
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.calendar
{
    width: 100%;
    max-width: 337px;
    height: max-content;

    &__header
    {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
    }

    &__navigate
    {
        width: 31px;
        height: 31px;
        cursor: pointer;
    }

    .back
    {
        background: url(${LoadImage('pagination-left-arrow.svg')}) no-repeat center;
    }

    .forth
    {
        background: url(${LoadImage('pagination-right-arrow.svg')}) no-repeat center;
    }

    &__month
    {
        margin: auto;
        -webkit-user-select: none;  
        -moz-user-select: none;
        -ms-user-select: none;  
        -o-user-select: none;
        user-select: none;
    }
}

.week
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    > span
    {
        width: 36px;
        height: 36px;
        text-align: center;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #78ADAB;
    }
}

.month
{
    display: grid;
    grid-template-columns: repeat(7, 36px);
    justify-content: space-between;
    overflow: hidden;

    .today
    {
        color: #13131387;
    }



    .selected
    {
        position: relative;
        color: #fff;
        background: #10242C;
        border-radius: 94px;
        font-weight: bold;
        font-size: 14px;

        &__left:before {
            position: absolute;
            content: '';
            width: 40px;
            height: 36px;
            top: 0px;
            left: -25px;
            background: #EAF6F6;
            z-index: -4;
        }

        &__right:after {
            position: absolute;
            content: '';
            width: 40px;
            height: 36px;
            top: 0px;
            background: #EAF6F6;
            z-index: -4;
        }
    }

    > span
    {
        padding: 10px;
        width: 36px;
        height: 36px;
        text-align: center;

        &:not(.padded):hover
        {
            color: #fff;
            background: #10242C;
            border-radius: 94px;
            font-weight: bold;
            font-size: 14px;
            cursor: pointer;
        }
    }

    .special {
        background: #c1e6e557;
        border-radius: 50%;
        opacity: 50%;
    }

    .gap {
        position: relative;
        background: #EAF6F6;

        &__left:before {
            position: absolute;
            content: '';
            width: 40px;
            height: 36px;
            top: 0px;
            left: -25px;
            background: #EAF6F6;
            z-index: -4;
        }

        &__right:after {
            position: absolute;
            content: '';
            width: 40px;
            height: 36px;
            top: 0px;
            background: #EAF6F6;
            z-index: -4;
        }
    }
}
`;

export const OnMobile = styled.div`
display: none;
${props => props.theme.functions?.mediaQueryMax('760px', `
    display: block;
`)}
`;

export const OnDesktop = styled.div`
display: block;
${props => props.theme.functions?.mediaQueryMax('760px', `
    display: none;
`)}
`;

export const MobileFiltersContainer = styled.div(() => ({
  width: "100%",
  display: "flex",
  borderRadius: 4,
  border: "1px solid #C1E6E5",
  boxShadow: "0px 2px 4px 0px rgba(16, 36, 44, 0.10)",
  padding: 10,
  position: "relative",
  cursor: "pointer",
  marginBottom: 10,

  ul: {
    display: "-webkit-box",
    listStyle: "none",
    overflowX: "scroll",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    maxWidth: "calc(100% - 35px)",

    "::-webkit-scrollbar": {
      display: "none",
    },

    li: {
      marginRight: 10,
      paddingRight: 10,
      borderRight: "1px solid #C1E6E5",
      "&:last-of-type": {
        borderRight: "none",
      },
    },
  },

  ".btn-container": {
    background: "linear-gradient(270deg, #FFF 69.12%, rgba(255, 255, 255, 0.00) 97.79%)",
    position: "absolute",
    right: 5,
    top: 0,
    height: "100%",
    width: "75px",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",

    ".search-btn": {
      width: 30,
      height: 30,
      img: {
        padding: 5,
      },
    },
  },

  '@media (min-width: 881px)': {
    display: "none",
  },
}));
