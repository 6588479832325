import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import dateFormat from 'dateformat';
import { isEmpty } from 'lodash';

import { getLeaderboard } from '../../../../components/Layout/reducers/session';
import calculationDate from '../../../../components/common/getCalculationDate';
import {
  fetchTabData,
  getEventsWithPenalties,
  setActiveFilters,
} from '../../reducers';
import { Contain, H3, P } from '../../../../components/Collection';
import GameType from '../components/GameType';
import Statistics from './components/Stats';
import {
  CalculationDate, CalculationDateContainer, Container, H3Title,
} from './styles';
import { UserContext } from '../..';
import {
  Column,
  Event,
  EventBody,
  EventFooter,
  EventHeader,
  EventName,
  EventsContainer,
  EventTotalWonPoints,
  FiltersContainer, TabOptionsContainer,
} from '../Activity/styles';
import EventMeta from '../Activity/components/eventMeta';
import Match from '../Activity/components/matches';
import rankBeforeEvent from '../../../../components/common/rankBeforeEvent';
import PenaltyEvent from './components/Penalties';
import Loader from '../../../../components/ContentLoader';
import Select from '../../../../components/Form/Select';
import { TabPlaceholder } from '../components/styles';
import LoadImage from '../../../../components/common/LoadImage';

const sportTypeSelectorEnabled = false;

const PointsTab = ({ activeSports, masterSport }) => {
  const user = useContext(UserContext);
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const [gameType, setGameType] = useState('singles');
  const [filters, setFilters] = useState({
    sportType: masterSport,
    gameLevel: user.gameLevel[gameType],
  });
  const { userId, penalties: userPenalties, profile } = user || {};

  const { generalStatus } = useSelector(state => state.accounts.info.tabs);
  const { leaderboard } = useSelector(state => state.session);
  const {
    data,
    penalties,
    status,
    activeFilters,
  } = useSelector(
    state => state.accounts.info.tabs[profile].points[gameType],
  );
  const isLoading = generalStatus === 'loading';
  const eventPenalties = penalties?.filter(({ sportType }) => sportType === filters.sportType);

  useEffect(() => {
    if (masterSport !== filters.sportType && !sportTypeSelectorEnabled) {
      setFilters({ ...filters, sportType: masterSport });
    }
  }, [masterSport, filters]);

  useEffect(() => {
    if ((isEmpty(data) && status === 'idle') || (JSON.stringify(filters) !== JSON.stringify(activeFilters))) {
      dispatch(fetchTabData({
        userId,
        tab: 'points',
        gameType,
        filters: {
          ...filters,
          gameLevel: user.sports?.[filters.sportType]?.[gameType]?.gameLevel,
        },
        options: {},
        profile,
      }));
      dispatch(setActiveFilters({
        gameType,
        profile,
        tab: 'points',
        filters: {
          ...filters,
          gameLevel: user.sports?.[filters.sportType]?.[gameType]?.gameLevel,
        },
      }));
    }
  }, [status, dispatch, gameType, filters]);

  useEffect(() => {
    if (isEmpty(leaderboard)) {
      dispatch(getLeaderboard());
    }
  }, []);

  const startDate = dateFormat(calculationDate(leaderboard).start, 'd mmm yyyy');
  const endDate = dateFormat(calculationDate(leaderboard).end, 'd mmm yyyy');

  useEffect(() => {
    if (userPenalties?.length) {
      const isAlreadyFetched = [];
      userPenalties?.forEach(penalty => {
        if (!isAlreadyFetched.includes(penalty?.event?.eventId)) {
          dispatch(getEventsWithPenalties({ id: penalty?.event?.eventId, gameType, profile }));
          isAlreadyFetched.push(penalty?.event?.eventId);
        }
      });
    }
  }, [user]);

  return (
    <Container>
      <TabOptionsContainer>
        <GameType gameType={gameType} setGameType={setGameType} />
        {sportTypeSelectorEnabled && (
          <FiltersContainer>
            <Select
              name="sportType"
              placeholder={t('sports.all')}
              onChange={(e) => setFilters({ ...filters, sportType: e.value })}
              options={activeSports.map((sport) => ({
                label: t(`sports.${sport}`),
                value: sport,
              }))}
              label={t('form.sport', { ns: 'clubs' })}
              value={filters.sportType}
              lowInput
              width="150px"
              {...filters.sportType && {
                labelOn: true,
                value: ['all', ...activeSports]
                  .filter(sport => sport === filters.sportType)
                  .map((sport) => ({
                    label: t(`sports.${sport}`),
                    value: sport,
                  })),
              }}
            />
          </FiltersContainer>
        )}
      </TabOptionsContainer>
      <CalculationDateContainer>
        <H3>
          <Trans ns="player" i18nKey="pointsSummary">Points Summary</Trans>
        </H3>
        {calculationDate().last && (
          <CalculationDate xSmall>
            <Trans ns="leaderboards" i18nKey="timeframe">
              Leaderboard calculation timeframe
            </Trans>
            {`: ${startDate} - ${endDate}`}
          </CalculationDate>
        )}
      </CalculationDateContainer>

      <Statistics
        user={user}
        points={data}
        gameType={gameType}
        sportType={filters.sportType}
      />

      {isLoading && (
        <Container hasLoader>
          <Loader />
        </Container>
      )}

      <H3Title>
        <Trans ns="player" i18nKey="bestResults">Best Competition Results</Trans>
      </H3Title>

      {!isLoading && !isEmpty(data) && (
        <>
          <EventsContainer>
            {(data || [])?.map((event, key) => {
              const { date, level, category } = event || { };
              const withGroups = event?.matches?.filter(a => a.group);
              const withStages = event?.matches?.filter(a => a.stage >= 0)
                .sort((a, b) => (a.stage > b.stage ? -1 : 1));
              const matches = [...withStages || [], ...withGroups || []];
              return (
                <Event key={key}>
                  <EventHeader>
                    <EventName to={`/events/${event.eventId}/competitions/${event.competitionId}`}>
                      {event.eventName || t('notAvailable')}
                    </EventName>
                    <EventMeta
                      {...{
                        category,
                        date,
                        level,
                        gameType: event.gameType,
                        clubId: event?.clubInfo?.id,
                        location: `${event?.clubInfo?.clubName}, ${event?.clubInfo?.location?.city}`,
                        restriction: event?.genderRestrictions,
                      }}
                    />
                  </EventHeader>
                  <EventBody>
                    <Contain>
                      <Column maxWidth={140}>
                        <P xSmall>
                          <Trans ns="player" i18nKey="round">
                            Round
                          </Trans>
                        </P>
                      </Column>
                      <Column maxWidth={300}>
                        <P xSmall>
                          <Trans ns="player" i18nKey="opponent">
                            Opponent
                          </Trans>
                        </P>
                      </Column>
                      <Column maxWidth={250} flex justify="center">
                        <P xSmall>
                          <Trans ns="player" i18nKey="leaderboard">
                            Rankings
                          </Trans>
                        </P>
                      </Column>
                      <Column maxWidth={300} flex justify="center">
                        <P xSmall>
                          <Trans ns="player" i18nKey="score">
                            Score
                          </Trans>
                        </P>
                      </Column>
                      <Column maxWidth={20} flex justify="center" xMargin="0 0 0 auto">
                        <P xSmall>
                          <Trans ns="player" i18nKey="wL">
                            W/L
                          </Trans>
                        </P>
                      </Column>
                    </Contain>
                    {matches?.map((match, matchKey) => (
                      <Match key={matchKey} match={match} gameType={gameType} />
                    ))}
                  </EventBody>
                  <EventFooter>
                    {rankBeforeEvent(event, userId, user, t)}
                    <EventTotalWonPoints xSmall bold>
                      +
                      {' '}
                      {event.totalWonPoints}
                        &nbsp;pts.
                    </EventTotalWonPoints>
                  </EventFooter>
                </Event>
              );
            })}
          </EventsContainer>
        </>
      )}

      {!isEmpty(eventPenalties) && (
        eventPenalties?.map((event, key) => (<PenaltyEvent key={key} event={event} />))
      )}

      {isEmpty(data) && isEmpty(eventPenalties) && status === "succeeded" && (
        <Contain
          width="fit-content"
          align="center"
          direction="column"
          margin="40px auto"
        >
          <TabPlaceholder src={LoadImage('tab-placeholder.png')} alt="No results found" />
          <P color="#78ADAB">
            <Trans ns="common" i18nKey="noResultsFound">
              No results found
            </Trans>
          </P>
        </Contain>
      )}
    </Container>
  );
};

export default PointsTab;
