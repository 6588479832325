import React from 'react';
import styled from '@emotion/styled';
import { withRouter } from 'react-router-dom';
import LoadImage from './common/LoadImage';

const GoBack = styled.span`
  display: flex;
  cursor: pointer;
  transition: all 0.3s ease;
  align-items: center;
  justify-content: center;
  height: 46px;
  width: 46px;
  min-width: 46px;
  border-radius: 4px;

  ${props => props.bold && `
    p {
      font-weight: 700;
    }
  `}

  &:hover {
    opacity: 0.6;
  }

  ${props => props.theme.functions?.mediaQueryMax('479px', `
    // display: none;
      width: auto;
      min-width: auto;
      justify-content: start;
  `)}
`;

const GoBackButton = ({
  history, bold, to, className, noAbsolute,
}) => {
  const goBack = () => history[to ? 'push' : 'goBack'](to);

  return (
    <GoBack onClick={goBack} bold={bold} noAbsolute={noAbsolute} {...className && { className }}>
      <img className="rotate_arrow" src={LoadImage('back-arrow-2.svg')} alt="Go Back" />
    </GoBack>
  );
};

export default withRouter(GoBackButton);
