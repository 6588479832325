export default {
  title: 'Clasamente',
  title_tennis: 'Clasament Tenis',
  title_padel: 'Clasament Padel',
  seoTitle: 'Clasamente | Sportya',
  lastUpdate: 'Ultima Actualizare',
  timeframe: 'Perioada de calcul a clasamentului',
  searchBar: 'Caută printre {{results}} de jucători',
  filters: {
    title: 'Filtre (Toate)',
    modalTitle: 'Filtre',
    singles: 'Simplu',
    doubles: 'Dublu',
    gender: 'Sex',
    age: 'Vârstă',
    reset: 'Resetează filtrele',
    apply: 'Aplică',
  },
  level: {
    4: 'Nivel 4',
    5: 'Nivel 5',
    6: 'Nivel 6',
    7: 'Nivel 7',
    8: 'Nivel 8',
    9: 'Nivel 9',
  },
  playerTableCard: {
    rank: 'Loc',
    player: 'Jucător',
    points: 'Puncte',
    participations: 'Evenimente',
    elo: 'Rating ELO',
    friendlyMatches: 'Meciuri Amicale',
    friend: 'Prieten',
    rankedFriendlyMatches: 'Meciuri Amicale Competitive',
    generalRank: 'Clasament General',
    rankedMatches: 'Meciuri Competitive',
    unrankedMatches: 'Meciuri de Antrenament',
    totalMatches: 'Total Meciuri',
  },
  noPlayersFound: 'Nu a fost găsit nici un jucător.',
  generalRankings_tennis: 'Clasament General de Tenis',
  generalRankings_padel: 'Clasament General de Padel',
  friendsRankings_tennis: 'Clasament de Tenis Prieteni',
  friendsRankings_padel: 'Clasament de Padel Prieteni',
};
