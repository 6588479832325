import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '../../components/common/http';

// Actions
export const fetchLeaderboards = createAsyncThunk('leaderboards/fetchLeaderboards', async (params, { getState }) => {
  const sportType = getState()?.session?.masterSport;
  const activeParams = Object.fromEntries(Object.entries(params).filter(([, v]) => !!v));
  const filterParams = new URLSearchParams({ sportType, ...activeParams }).toString();
  const { data: { data } } = await get(`/leaderboards?${filterParams}`);

  return { data, sportType };
});

export const fetchSearchLeaderboards = createAsyncThunk(
  'leaderboards/fetchSearchLeaderboards',
  async (params, { getState }) => {
    const sportType = getState()?.session?.masterSport;
    const activeParams = Object.fromEntries(Object.entries(params).filter(([, v]) => !!v));
    const filterParams = new URLSearchParams({ sportType, ...activeParams }).toString();

    const { data: { data } } = await get(`/leaderboards/search?${filterParams}`);
    return data;
  },
);

// Slice
const leaderboards = createSlice({
  name: 'leaderboards',
  initialState: {
    data: [],
    initial: [],
    hasSearched: false,
    status: 'idle',
    error: null,
    sportType: null,
  },
  reducers: {
    setHasSearched: (state, action) => {
      state.hasSearched = action.payload;
    },
    setLeaderboards: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload?.data;
      state.hasSearched = true;
    },
    resetLeaderboards: (state) => {
      state.data = state.initial;
      state.hasSearched = false;
    },
  },
  extraReducers: {
    [fetchLeaderboards.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchLeaderboards.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload?.data;
      state.sportType = action.payload?.sportType;
      state.initial = action.payload?.data;
    },
    [fetchLeaderboards.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

const searchLeaderboards = createSlice({
  name: 'searchLeaderboards',
  initialState: {
    data: [],
    searchStatus: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchSearchLeaderboards.pending]: (state) => {
      state.searchStatus = 'loading';
    },
    [fetchSearchLeaderboards.fulfilled]: (state, action) => {
      state.searchStatus = 'succeeded';
      state.data = action.payload;
    },
    [fetchSearchLeaderboards.rejected]: (state, action) => {
      state.searchStatus = 'failed';
      state.error = action.error.message;
    },
  },
});

const activeFilters = createSlice({
  name: 'activeFiltersRankings',
  initialState: {
    data: null,
  },
  reducers: {
    setActiveFilters: (state, action) => {
      state.data = { ...action.payload };
    },
  },
});

export const { setActiveFilters } = activeFilters.actions;
export const { setLeaderboards, resetLeaderboards, setHasSearched } = leaderboards.actions;

export default {
  list: leaderboards.reducer,
  search: searchLeaderboards.reducer,
  activeFilters: activeFilters.reducer,
};
