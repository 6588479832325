import styled from '@emotion/styled';
import 'react-day-picker/dist/style.css';

export const Layout = styled.div(props => ({
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'flex-start',

  background:
    props.black && props.custom ? `#000 url(${props.custom}) no-repeat scroll center bottom`
      : props.black && !props.custom ? props.black
        : props.custom && !props.black && `url(${props.custom})`,
  backgroundSize: props.black && props.custom ? 'contain' : 'auto',

  '.under-maintenance': {
    background: props.theme.colors.error,
    textAlign: 'center',
    color: 'white',
    margin: 0,
    padding: 5,
    position: 'fixed',
    bottom: 0,
    width: '100%',
    zIndex: 9,
    opacity: 0.8,
  },

  '.cookieConsent': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '635px',
    width: '100%',
    height: '115px',
    background: props.theme.colors.primary,
    position: 'fixed',
    left: '50%',
    padding: '30px',
    borderRadius: '10px',
    transform: 'translateX(-50%)',
    marginBottom: '20px',
    zIndex: 99,
    '@media (max-width: 650px)': {
      flexDirection: 'column',
      height: '200px',
      textAlign: 'center',
      marginBottom: 'unset',
      borderBottomLeftRadius: '0',
      borderBottomRightRadius: '0',
    },
  },
  '.cookieContent': {
    maxWidth: '380px',
    a: {
      textDecoration: 'underline',
    },
  },
  '.cookieButton': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '162px',
    height: '50px',
    cursor: 'pointer',
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '21px',
  },
}));

export const Main = styled.main(props => ({
  ...props.smoothBlur && { filter: 'blur(2px)' },
  ...props.blur && { filter: 'blur(35px)' },
  ...!props.headless && {
    paddingTop: '80px',
    '@media (max-width: 1024px)': {
      paddingTop: '50px',
    },
    ...props.bannedPaddingTop && {
      paddingTop: props.bannedPaddingTop,
      '@media (max-width: 1024px)': {
        paddingTop: '130px',
      },
      '@media (max-width: 534px)': {
        paddingTop: '170px',
      },
    },
  },
  ...props.newsBannerPaddingTop && {
    paddingTop: !props.headless ? props.newsBannerPaddingTop : 60,
    '@media (max-width: 1024px)': {
      paddingTop: !props.headless ? `calc(${props.newsBannerPaddingTop}px / 1.2)` : 60,
    },
    '@media (max-width: 479px)': {
      paddingTop: !props.headless ? 185 : 135,
    },
  },
}));

export const InstallPWA = styled.div({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '20px',
  background: '#fff',
});
