export default {
  title: 'Get a Premium Account',
  subtitle: 'To benefit from all the functionalities Sportya has to offer and save on tournament entry fees, choose the <1>Premium</1> accounts',
  seoTitle: 'Get a Premium Account | Sportya',

  benefits: {
    title: 'Benefits included in all accounts',
    1: 'Personal player profile, detailing match results, rankings, and statistics',
    2: 'National rankings for amateur players in all available sports',
    3: '10% discount TENNIS WAREHOUSE EUROPE, the largest specialized retailer in Europe, for all purchases',
    4: 'Standard of play for all events, including new balls (as indicated on each tournament page)',
    5: 'Friendly matches with any registered Sportya member',
    6: 'Personal messaging, notification system',
    7: 'Access to match based statistics, event results and comparisons for all Sportya members',
    8: 'Technical support (by e-mail)',
  },

  info: {
    feeReduction: '1. Discounted entries for Premium Accounts are event and country dependent and range from 3 to 5 EUR per event registration (applied automatically). Additional discounts may apply for certain calendar events, such as special format events, Platinum category tournaments, international tournaments, etc',
  },

  expirationDate: '{{value}} Months Validity',
  activePlan: 'Your <1>Premium</1> plan is active until <3>{{expDate}}</3>',

  planConfirmation: {
    bestValue: 'Best Value',
    noExpiration: 'No Expiration',
    currentPlan: 'Current Plan',
    included: 'Included',
    extend: 'Extend',
    upgrade: 'Upgrade',
    learnMore: 'Learn More',
    confirmFinalPayment: 'Please confirm your final payment for <1>Premium {{period}}</1>',
    validity: 'Validity',
    holder: 'Holder',
    totalOrder: 'Total Order',
    confirmPay: 'Confirm & Pay',
    availableBalance: 'Available Balance',
    redirected: 'You will be redirected to',
    redirecting: 'Redirecting to Stripe...',
    redirectingClean: 'Redirecting to',
  },

  bottomSection: {
    findMore: 'Find Out More',
    remindMe: 'Remind Me Later',
  },

  plans: {
    friendly: {
    },

    free: {
      title: 'Free Account',
      1: 'FULL Access to Friendly Matches with friends or other players',
      2: 'FULL price for tournament entries',
      3: 'Access to Platinum, Champions, Masters, International and Special Tournaments',
      expDate: 'No Expiration',
      price: 'Free',
    },

    summer: {
      title: 'Summer Account',
      1: 'FULL Access to Friendly Matches with friends or other players',
      2: 'Access to Platinum, Champions, Masters, International and Special Tournaments',
      3: 'DISCOUNTED price for tournament entries',
      info: 'The Summer Account is dedicated to the player very involved in the circuit who wants to improve his game and ranking constantly and to participate in as many tournaments as possible during the summer , both PLATINUM and International tournaments and special tournaments, such as Champions Tournaments and Masters Tournaments. The Summer Account offers you access to all the facilities and benefits of the online platform. The registration for the tournaments implies the payment of the online reservation fee, at a reduced price, at the moment of the tournament registration as well as of the participation fee, to the organizer, at the place of the competition.',
      expDate: '6 Months Validity',
      timePeriod: '1 Apr 2021 - 31 Oct 2021',
    },

    premium: {
        title: 'Premium Account',
        1: 'FULL Access to Friendly Matches with friends or other players',
        2: 'DISCOUNTED price for tournament entries',
        3: 'Access to Platinum, Champions, Masters, International and Special Tournaments',
        info: 'The Premium Account is dedicated to the player who wants to participate in as many tournaments as possible throughout the year , both the PLATINUM category and International tournaments and special tournaments, such as Champions Tournaments and Masters Tournaments. The Premium account gives you access to all the facilities and benefits of the online platform. The registration for the tournaments implies the payment of the online reservation fee, at a reduced price, at the moment of the tournament registration as well as of the participation fee, to the organizer, at the place of the competition.',
        expDate: '12 Months Validity',
        timePeriod: '1 Jan 2021 - 31 Dec 2021',
    },

    overlay: {
      free: {
        1: 'Access to Platinum, Champions, Masters, International and Special Tournaments',
        2: 'FULL price for tournament entries',
      },

      summer: {
        1: 'Access to Platinum, Champions, Masters, International and Special Tournaments',
        2: 'REDUCED price for tournament entries',
      },

      premium: {
        1: 'Access to Platinum, Champions, Masters, International and Special Tournaments',
        2: 'DISCOUNTS for online registration fees  + tournament participation fees, paid to the organizer',
      },
    },
  },
};
