import styled from '@emotion/styled';
import { Link } from '../../../../components/Collection';

export const Player = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  border: '1px solid #EAF6F6',
  borderRadius: '10px',
  boxShadow: '0px 2px 4px rgba(16, 36, 44, 0.1)',
  width: '100%',
  padding: '15px 0',
  a: {
    textDecoration: 'none !important',
  },
  '> p': {
    margin: 'unset !important',
  },
  '.ellipsis': {
    overflow: 'hidden',
    width: '250px',
    textOverflow: 'ellipsis',
    textAlign: 'center',
  },
  '.friends-icon': {
    verticalAlign: 'sub',
    marginRight: '4px',
    width: '18px',
    height: '18px',
  },
});

export const FriendsIcon = styled.img({
  verticalAlign: 'sub',
  marginRight: '4px',
  width: '18px',
  height: '18px',
});

export const ID = styled.span(props => ({
  color: props.theme.colors.darkTurquoise,
  fontWeight: 400,
  marginLeft: '5px',
}));

export const JoinedDate = styled.div(props => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 20px',
  borderRadius: '80px',
  height: '36px',
  background: props.theme.colors.lightBlue2,
}));

export const ContainAvatar = styled(Link)({
  position: 'relative',
  margin: '10px 0 15px',
  '.badge': {
    position: 'absolute',
    left: '50%',
    bottom: '-8px',
    transform: 'translateX(-50%)',
  },
});

export const Avatar = styled.img({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  objectFit: 'cover',
  borderRadius: '50%',
  height: '80px',
  width: '80px',
});

export const Initials = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '80px',
  width: '80px',
  border: '1px solid rgb(234, 246, 246)',
  borderRadius: '50%',
});
