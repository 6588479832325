import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { get } from '../../components/common/http';

export const fetchClubs = createAsyncThunk('clubs/fetchClubs', async ({ filters, options }, { getState }) => {
  const sportType = getState()?.session?.masterSport;
  const filterParams = new URLSearchParams({ sportType, ...filters, ...options }).toString();
  const { data: { data } } = await get(`/clubs/courts?${filterParams}`);

  return { data, sportType };
});

const clubs = createSlice({
  name: 'clubs',
  initialState: {
    data: [],
    status: 'idle',
    error: null,
    sportType: null,
  },
  extraReducers: {
    [fetchClubs.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchClubs.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload?.data;
      state.sportType = action.payload?.sportType;
    },
    [fetchClubs.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export const fetchClubsLocations = createAsyncThunk('clubs/fetchClubsLocations', async (filters, { getState }) => {
  const sportType = getState()?.session?.masterSport;
  const filterParams = new URLSearchParams({ sportType, ...filters }).toString();
  const { data: { data } } = await get(`/clubs/courts/locations?${filterParams}`);
  return data;
});

const locations = createSlice({
  name: 'locations',
  initialState: {
    data: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    resetClubsLocations: (state) => {
      state.data = [];
      state.status = 'idle';
    },
  },
  extraReducers: {
    [fetchClubsLocations.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchClubsLocations.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchClubsLocations.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export const fetchClub = createAsyncThunk('clubs/fetchClub', async (id) => {
  const { data: { data } } = await get(`/clubs/${id}`);

  return data;
});

export const fetchClubEvents = createAsyncThunk('clubs/fetchClubEvents', async (payload) => {
  const { clubId, ...filters } = payload;

  const filterParams = new URLSearchParams(filters).toString();
  const { data: { data } } = await get(`/clubs/${clubId}/events?${filterParams}`);

  return data;
});

export const fetchClubBookings = createAsyncThunk('clubs/fetchClubBookings', async (payload) => {
  const { clubId, ...filters } = payload;
  const filterParams = new URLSearchParams(filters).toString();
  const { data: { data } } = await get(`/clubs/${clubId}/bookings?${filterParams}`);

  return data;
});

const initialClubInfoState = {
  data: null,
  status: 'idle',
  error: null,

  owner: null,
  ownerStatus: 'idle',

  events: {
    data: null,
    status: 'idle',
    error: null,
  },

  bookings: {
    data: null,
    status: 'idle',
    error: null,
  },
};

const clubInfo = createSlice({
  name: 'clubInfo',
  initialState: initialClubInfoState,
  reducers: {
    clearClubInfoData: (state) => {
      Object.assign(state, initialClubInfoState);
    },
  },
  extraReducers: {
    // club info
    [fetchClub.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchClub.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'succeeded';
    },
    [fetchClub.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },

    // club bookings
    [fetchClubBookings.pending]: (state) => {
      state.bookings.status = 'loading';
    },
    [fetchClubBookings.fulfilled]: (state, action) => {
      state.bookings.status = 'succeeded';
      state.bookings.data = action.payload;
    },
    [fetchClubBookings.rejected]: (state, action) => {
      state.bookings.status = 'failed';
      state.bookings.error = action.error.message;
    },

    // club events
    [fetchClubEvents.pending]: (state) => {
      state.events.status = 'loading';
    },
    [fetchClubEvents.fulfilled]: (state, action) => {
      state.events.status = 'succeeded';
      state.events.data = action.payload;
    },
    [fetchClubEvents.rejected]: (state, action) => {
      state.events.status = 'failed';
      state.events.error = action.error.message;
    },
  },
});

export const { clearClubInfoData } = clubInfo.actions;
export const { resetClubsLocations } = locations.actions;

const reducer = combineReducers({
  listing: clubs.reducer,
  info: clubInfo.reducer,
  locations: locations.reducer,
});

export default reducer;
