export default {
  home: 'Domovská stránka',
  login: 'Pøihlásit se',
  register: 'Zaregistrovat se',
  dashboard: 'Øídicí panel',
  findEvent: 'Najít událost',
  friendlyMatches: 'Přátelské Zápasy',
  bookCourt: 'Rezervovat kurt',
  forPlayers: 'Pro hráèe',
  forClubs: 'Pro kluby',
  blog: 'Blog',
  help: 'Nápovìda',
  findAPlayer: 'Najít hráèe',
  community: 'Community',
  leaderboards: 'Hodnocení',
  lookForAnything: 'Hledat cokoli...',
  notifications: 'Upozornìní',
  membershipType: "<0>{{t(prop.membership) || 'Free'}}</0> Úèet",
  gameLevel: 'Úroveò hry',
  myProfile: 'Mùj profil',
  membership: 'Èlenství',
  comingSoon: 'Již brzy',
  free: 'Úèet zdarma',
  summer: 'Letní sezónní úèet',
  premium: 'Prémiový úèet',

  menu: {
    friends: 'Přátelé',
    myEvents: 'Události',
    bookings: 'Rezervace',
    mySettings: 'Nastavení',
    myOrders: 'Objednávky',
    virtualWallet: 'Virtuální penìženka',
    plans: 'Sportya předplatné',
    logout: 'Odhlásit se',
  },
};
