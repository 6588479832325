import { Trans, useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { omit, pick, pull } from 'lodash';
import { useSelector } from 'react-redux';

import Paragraph from '../../../../../components/Paragraph';
import Button from '../../../../../components/Button';
import Modal from '../../../../../components/Modal';
import { ModalContainer } from './styles';
import Checkbox from '../../../../../components/Form/VanillaCheckbox';
import ToggleButton from '../../../../../components/Form/ToggleButton';

const fields = ['surface', 'courtType', 'courtSize', 'selfService', 'availableOnly'];
const courtTypes = ['outdoor', 'indoor'];
const courtSizes = ['singles', 'doubles'];

const AdvancedFiltersModal = ({
  advancedFiltersModal,
  onRequestClose,
  activeFilters,
  setActiveFilters,
  setSubmitData,
}) => {
  const { masterSport } = useSelector(state => state.session);
  const { data: { courtSurfaces } = {} } = useSelector(state => state.settings);
  const { t } = useTranslation(['clubs', 'common']);
  const [activeAdvancedFilters, setActiveAdvancedFilters] = useState({
    ...pick(activeFilters, fields),
  });

  const surfaces = (courtSurfaces || [])
    .filter(({ sports }) => sports.includes(masterSport))
    .map(({ key }) => key);

  const clearFilters = () => {
    setActiveAdvancedFilters({});
  };

  const applyFilters = () => {
    setActiveFilters({
      ...omit(activeFilters, fields),
      ...Object.fromEntries(
        Object.entries(activeAdvancedFilters).filter(([, v]) => !!v),
      ),
    });

    setSubmitData(true);
    onRequestClose();
  };

  const setValue = (field, value) => {
    let filters = activeAdvancedFilters;
    if (field !== 'surface') {
      filters = omit(activeAdvancedFilters, [field]);
    }

    const appendToField = (a, b) => {
      let newData = filters[a].split(",");
      if (newData.includes(b)) {
        newData = pull(newData, b);
      } else {
        newData.push(b);
      }

      return newData.join(",") ?? null;
    };

    switch (field) {
    case 'selfService':
      filters = {
        ...filters,
        ...value === 'yes' && { selfService: value },
      };
      break;
    case 'surface':
      filters = {
        ...filters,
        ...value && { [field]: !filters[field] ? value : appendToField(field, value) },
      };
      break;
    default:
      filters = {
        ...filters,
        ...(value && value !== activeAdvancedFilters[field]) && { [field]: value },
      };
    }

    setActiveAdvancedFilters(filters);
  };

  const getValue = (field, value) => {
    let data;

    switch (field) {
    case 'selfService':
      data = (
        (value === 'yes' && activeAdvancedFilters?.selfService === value)
        || (value === undefined && !activeAdvancedFilters?.selfService)
      );
      break;
    case 'surface':
      data = (activeAdvancedFilters?.[field] || '').split(",").includes(value);
      break;
    default:
      data = activeAdvancedFilters?.[field] === value;
    }

    return data;
  };

  return (
    <Modal
      isOpen={advancedFiltersModal}
      onRequestClose={onRequestClose}
      style={{
        padding: "20px 30px 10px",
        borderRadius: 10,
      }}
      mobileFullDisplay
    >
      <ModalContainer>
        <div className="modal-header">
          <Trans ns="clubs" i18nKey="filters">Filters</Trans>
        </div>

        <div className="modal-content">
          <div className="mb20 hidden-desktop d-flex justify-content-center">
            <ToggleButton
              className="toggle"
              active={!!activeAdvancedFilters?.availableOnly}
              handler={() => setValue('availableOnly', !activeAdvancedFilters?.availableOnly)}
            >
              <Trans ns="clubs" i18nKey="onlyAvailableCourts">
                Only available courts
              </Trans>
            </ToggleButton>
          </div>

          {/* Surface */}
          <div>
            <Paragraph large bold className="mb20 mt0">
              <Trans ns="clubs" i18nKey="surface">Surface</Trans>
            </Paragraph>
            <div className="row mb20 bd-bottom">
              {surfaces.map((value, id) => (
                <div key={id} className="col-sm-4">
                  <Checkbox
                    onChange={() => setValue('surface', value)}
                    checked={getValue('surface', value)}
                    label={t(`surfaceType.${value}`)}
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Court Type */}
          <div>
            <Paragraph large bold className="mb20 mt20">
              <Trans ns="clubs" i18nKey="courtType">Court Type</Trans>
            </Paragraph>
            <div className="row mb20 bd-bottom">
              {courtTypes.map((value, id) => (
                <div key={id} className="col-sm-4">
                  <Checkbox
                    onChange={() => setValue('courtType', value)}
                    checked={getValue('courtType', value)}
                    label={t(`courtTypes.${value}`)}
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Court Size */}
          <div>
            <Paragraph large bold className="mb20 mt20">
              <Trans ns="clubs" i18nKey="courtSize">Court Size</Trans>
            </Paragraph>
            <div className="row mb20 bd-bottom">
              {courtSizes.map((value, key) => (
                <div key={key} className="col-sm-4">
                  <Checkbox
                    onChange={() => setValue('courtSize', value)}
                    checked={getValue('courtSize', value)}
                    label={t(`courtSizes.${value}`)}
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Self-Service Option */}
          <div className="form-group">
            <Paragraph large bold className="mb20 mt20">
              <Trans ns="clubs" i18nKey="selfService">Self-Service</Trans>
            </Paragraph>
            <div className="row">
              <div className="col-sm-4">
                <Checkbox
                  onChange={() => setValue('selfService', undefined)}
                  checked={getValue('selfService', undefined)}
                  label={t('no', { ns: 'common' })}
                />
              </div>
              <div className="col-sm-4">
                <Checkbox
                  onChange={() => setValue('selfService', 'yes')}
                  checked={getValue('selfService', 'yes')}
                  label={t('yes', { ns: 'common' })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <div onClick={clearFilters} className="cursor-pointer">
            <Paragraph bold><Trans ns="clubs" i18nKey="clearAll">Clear All</Trans></Paragraph>
          </div>
          <Button onClick={applyFilters} wider>
            <span><Trans ns="clubs" i18nKey="apply">Apply</Trans></span>
          </Button>
        </div>
      </ModalContainer>
    </Modal>
  );
};

export default AdvancedFiltersModal;
