import styled from '@emotion/styled';
import LoadImage from '../common/LoadImage';

export const ModalWrapper = styled.div(({
  contentScrollable,
  mobileFullDisplay,
  maxContentHeight,
  maxContainerHeight,
}) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: 'inherit',
  // overflow: 'auto',

  ...maxContainerHeight && {
    maxHeight: maxContainerHeight,
  },

  '&.report-match-modal': {
    maxWidth: 400,
    margin: 'auto',
  },

  '&.rate-player-modal': {
    input: {
      display: "none",
    },

    fieldset: {
      border: "none",
    },

    ".rating": {
      display: "flex",
      justifyContent: "center",
      flexDirection: "row-reverse",
    },

    "label:before": {
      margin: "5px",
      display: "inline-block",
      content: '""',
      width: 43,
      height: 41,
      background: `url(${LoadImage('rating-icon-outline.svg')}) no-repeat scroll center center`,
    },

    "input:checked ~ label, .rating:not(:checked) > label:hover, .rating:not(:checked) > label:hover ~ label": {
      background: `url(${LoadImage('rating-icon.svg')}) no-repeat scroll center 40%`,
    },

    "input:checked + label:hover, input:checked ~ label:hover, label:hover ~ input:checked ~ label": {
      opacity: 0.8,
    },

    "input:checked ~ label:hover ~ label": {
      opacity: 0.8,
    },

    "input, label": {
      cursor: "pointer",
    },
  },

  "&.bookings-filters": {
    justifyContent: "space-between",
    height: "100%",

    ".form-group": {
      '[class*="-container"]': {
        width: "100%",
      },
    },
  },

  '.modal-header': {
    position: 'relative',
    marginBottom: 30,
    padding: "0px 15px",
    textAlign: 'center',
    lineHeight: 1.3,
    fontWeight: 'bold',
    fontSize: 20,

    '@media and (maxWidth: 767px)': {
      marginBottom: 15,

      p: {
        fontSize: 20,
      },
    },
  },

  '.modal-content': {
    position: 'relative',
    flex: 'auto',

    ...contentScrollable && {
      overflowY: 'scroll',
      maxHeight: maxContentHeight,
    },

    '&__has-scroll': {
      "&::-webkit-scrollbar": {
        appearance: "none",
      },

      "&::-webkit-scrollbar:vertical": {
        width: 11,
      },

      "&::-webkit-scrollbar-thumb": {
        borderRadius: 8,
        border: "2px solid white",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      },

      // "&:after": {
      //   position: 'fixed',
      //   content: '""',
      //   width: "calc(100% + 40px)",
      //   height: 75,
      //   bottom: 80,
      //   left: -20,
      //   background: 'linear-gradient(180deg, #81969d 0%, rgba(255, 255, 255, 0) 100%)',
      //   opacity: 0.2,
      //   transform: 'matrix(1, 0, 0, -1, 0, 0)',
      // },
    },

    ".actions": {
      display: "grid",
      gridGap: 14,
      listStyleType: "none",
      marginTop: 40,

      li: {
        display: "flex",
        alignItems: "center",
        fontSize: 16,

        img: {
          marginRight: 10,
        },
      },
    },

    ".terms": {
      p: {
        opacity: 0.5,
      },
    },

    ".cancellation-text": {
      strong: {
        "&.early": {
          color: "#39D377",
        },

        "&.late": {
          color: "#F42B03",
        },
      },
    },
  },

  ".gap-10": {
    gap: 10,
  },

  '.modal-footer': {
    marginTop: 30,
    marginBottom: 0,
    display: 'flex',
    justifyContent: 'space-between',

    '&.justify-content-center': {
      justifyContent: 'center',
    },
  },

  '@media all and (maxWidth: 767px)': {
    ...mobileFullDisplay && {
      maxHeight: "100%",
    },

    ".modal-footer": {
      marginTop: 15,
    },
  },

  '@media all and (max-width: 479px)': {
    br: {
      display: "none",
    },

    ".modal-header": {
      marginBottom: 20,
    },

    ".modal-content": {
      ...mobileFullDisplay && maxContentHeight && {
        overflowY: 'auto',
        minHeight: "60vh",
        height: `${maxContentHeight}px`,
        ...maxContentHeight < 100 && {
          maxHeight: '65vh',
        },
      },
    },

    ".modal-footer": {
      marginTop: 20,
    },
  },
}));

export const Header = styled.div(({
  position: 'relative',
  textAlign: 'center',
  lineHeight: 1,
  marginBottom: 20,
}));

export const Close = styled.img({
  position: 'absolute',
  cursor: 'pointer',
  right: 10,
  top: 15,
  padding: 15,
  zIndex: 2,
});

export const OverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 20,
  width: '100vw',
  height: '100vh',
  maxWidth: 'unset !important',
  touchAction: 'none',
  background: 'rgba(0,0,0, 0.7)',
};

export const ContentStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  maxWidth: 'calc(100% - 20px)',
  border: 'unset',
  boxShadow: '4px 4px 0px #fff00',
  height: 'fit-content',
  width: '600px',
  transform: 'translate(-50%,-50%)',
  borderRadius: '20px',
  background: '#fff',
  zIndex: 21,
  padding: '30px 40px 30px',
  overflow: 'unset',
  pointerEvents: 'all',
};

export const MobileContentStyle = {
  borderRadius: "none",
  maxWidth: "100% !important",
  height: "100%",
};
