import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Paragraph,
  H4,
  Link,
  Button,
} from '../../../../components';

import LoadImage from '../../../../components/common/LoadImage';
import {
  Container,
  Content,
  CongratsBar,
  TermsLink,
} from './style';
import { firstTaskRewardCompleted } from './actions';
import mixpanel from '../../../../mixpanel';
import { updateAccountInfoState } from '../../../../components/Layout/reducers/session';

const ReferralRewardsBanner = ({ firstBox }) => {
  const dispatch = useDispatch();
  const { accountInfo } = useSelector(state => state.session);
  const { rewardsInfo = {} } = useSelector(state => state.settings.data);
  const { amount, currency, maxRewards } = rewardsInfo?.currencies?.[accountInfo?.currency] ?? rewardsInfo;
  const [showRewardsBar, setShowRewardsBar] = useState(localStorage.getItem('showRewardsInfoBar'));

  useEffect(() => {
    const init = async () => {
      const receivedReward = await firstTaskRewardCompleted();
      dispatch(updateAccountInfoState({
        receivedReward,
      }));
    };

    if (accountInfo && accountInfo?.referredBy && accountInfo?.receivedReward === undefined) {
      init();
    }
  }, [accountInfo]);

  const closeInfoBar = () => {
    localStorage.removeItem('showRewardsInfoBar');
    setShowRewardsBar(null);
  };

  const playedBefore = (
    accountInfo?.leaderboard?.singles?.eventsParticipation > 0
    || accountInfo?.leaderboard?.doubles?.eventsParticipation > 0
    || accountInfo?.leaderboard?.singles?.friendlyMatches?.ranked > 0
    || accountInfo?.leaderboard?.doubles?.friendlyMatches?.ranked > 0
    || accountInfo?.leaderboard?.singles?.friendlyMatches?.unranked > 0
    || accountInfo?.leaderboard?.doubles?.friendlyMatches?.unranked > 0
  );
  const isEligibleForFirstReward = !(
    !playedBefore
    && accountInfo?.referredBy
    && accountInfo?.receivedReward === false
  );

  // don't display any banner if api repsonse isn't loaded
  if (accountInfo?.receivedReward === undefined && accountInfo?.referredBy) return false;

  return (
    <>
      {showRewardsBar && (
        <CongratsBar>
          <Paragraph>
            <Trans ns="accounts" i18nKey="referral.congrats">
              Congrats! You just received <strong>{{ amount }} {{ currency }}</strong> in
              your <Link to="/my-account/wallet">Virtual Wallet</Link> for
              successfully completing your 1st task on Sportya.
            </Trans>
          </Paragraph>
          <img src={LoadImage('close-icon.svg')} alt="" onClick={closeInfoBar} />
        </CongratsBar>
      )}
      <Container
        rewardsBar={showRewardsBar}
        receivedReward={isEligibleForFirstReward}
        firstBox={firstBox && !showRewardsBar}
      >
        <Content>
          {isEligibleForFirstReward && (
            <>
              <H4>
                <Trans ns="accounts" i18nKey="referral.inviteFriends">
                  Invite Your Friends & Get Rewarded
                </Trans>
              </H4>
              <Paragraph className="mb20">
                <Trans ns="accounts" i18nKey="referral.inviteFriendsDescription">
                  Get {{ amount }} {{ currency }} for each of the first {{ maxRewards }} players
                  who join Sportya with your referral code!
                </Trans>
              </Paragraph>
              <Button onClick={() => mixpanel.track(
                'Click Invite your Friends from the banner',
                { sp_source: 'Player Dashboard' },
              )}
              >
                <Link to="/invite-friends">
                  <Trans ns="accounts" i18nKey="referral.inviteYourFriends">
                    Invite your Friends
                  </Trans>
                </Link>
              </Button>
            </>
          )}

          {!isEligibleForFirstReward && (
            <>
              <H4>
                <Trans ns="accounts" i18nKey="referral.dashboardBannerTitle">
                  You&apos;re one step closer to your reward
                </Trans>
              </H4>
              <Paragraph large className="mb20">
                <Trans ns="accounts" i18nKey="referral.dashboardBannerSubtitle">
                  Get <strong>{{ amount }} {{ currency }}</strong> as soon as you complete ONE of the
                  following actions for the 1st time:
                </Trans>
              </Paragraph>
              <ul className="actions mt30">
                <li>
                  <Link to="/events">
                    <img src={LoadImage('mgm/item1.png')} alt="" />
                    <Trans ns="accounts" i18nKey="referral.item11">
                      Get your First Validated Result in a Sportya Tournament
                    </Trans>
                    <img src={LoadImage('right-arrow.svg')} alt="" className="ml10" />
                  </Link>
                </li>
                <li>
                  <Link to="/my-account/wallet">
                    <img src={LoadImage('mgm/item2.png')} alt="" />
                    <Trans ns="accounts" i18nKey="referral.item22">
                      Top-Up your Virtual Wallet with any amount
                    </Trans>
                    <img src={LoadImage('right-arrow.svg')} alt="" className="ml10" />
                  </Link>
                </li>
                <li>
                  <Link to="/plans">
                    <img src={LoadImage('mgm/item3.png')} alt="" />
                    <Trans ns="accounts" i18nKey="referral.item3">
                      Purchase a Premium Account with Virtual Wallet or Credit Card
                    </Trans>
                    <img src={LoadImage('right-arrow.svg')} alt="" className="ml10" />
                  </Link>
                </li>
                <li>
                  <Link to="/friendly-matches/start">
                    <img src={LoadImage('mgm/item4.png')} alt="" />
                    <Trans ns="accounts" i18nKey="referral.item4">
                      Participate in a Friendly Match with a validated score for the 1st time.
                    </Trans>
                    <img src={LoadImage('right-arrow.svg')} alt="" className="ml10" />
                  </Link>
                </li>
              </ul>
              <Paragraph smaller className="mt20 mb0">
                <Trans ns="accounts" i18nKey="referral.findOutMore">
                  Find out more about
                  <TermsLink to="/invite-friends">Sportya Rewards</TermsLink>
                  or check <TermsLink to="/terms-conditions" target="_blank">Terms and Conditions</TermsLink>
                </Trans>
              </Paragraph>
            </>
          )}
        </Content>
        <img src={LoadImage('coins-img.png')} alt="" className="coins" />
      </Container>
    </>
  );
};

export default ReferralRewardsBanner;
