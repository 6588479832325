import React from 'react';
import { Trans } from 'react-i18next';

import { Contain, P } from '../../../../components/Collection';

const SortBy = ({
  options,
  setOptions,
  filters,
  className,
}) => {
  const isStartDateSelected = (
    (options?.sortBy === 'startDate:asc' || !options?.sortBy)
    && (options?.sortBy !== 'createdAt:desc' || options?.sortBy !== 'closest')
  );
  const isNewestSelected = options?.sortBy === 'createdAt:desc';
  const isClosestSelected = options?.sortBy === 'closest';
  const hasRadius = filters?.radius;

  const handleOptions = value => {
    const newOptions = {
      ...options,
    };

    newOptions.sortBy = value;
    setOptions(newOptions);
  };

  return (
    <Contain align="center" {...className && { className }}>
      <P small margin="0 20px 0 0" color="grey2">
        <Trans ns="events" i18nKey="sortBy">Sort by</Trans>
      </P>
      <Contain>
        <P
          bold={isStartDateSelected}
          margin="0 15px 0 0"
          cursor="pointer"
          onClick={() => {
            handleOptions('startDate:asc');
          }}
        >
          <Trans ns="events" i18nKey="startDate">Start Date</Trans>
        </P>
        <P
          bold={isNewestSelected}
          margin="0 15px 0 0"
          cursor="pointer"
          onClick={() => {
            handleOptions('createdAt:desc');
          }}
        >
          <Trans ns="events" i18nKey="newest">Newest</Trans>
        </P>
        {hasRadius && (
          <P
            bold={isClosestSelected}
            cursor="pointer"
            margin="0"
            onClick={() => {
              handleOptions('closest');
            }}
          >
            <Trans ns="events" i18nKey="closest">Closest</Trans>
          </P>
        )}
      </Contain>
    </Contain>
  );
};

export default SortBy;
