import styled from '@emotion/styled';
import { P } from '../../../../../../components/Collection';
import { Initials } from '../../../../../Events/JoinCompetition/components/Search/styles';

export const PlayerOptionContainer = styled.div(({
  playerInputPlaceholder,
  isMe,
  displayLevelError,
  bottomMargin,
  isPlaceholder,
  gameLevelSet,
}) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  height: playerInputPlaceholder ? '50px' : '58px',
  userSelect: 'none',
  cursor: 'pointer',
  transition: 'all .2s ease-out',
  overflow: 'hidden',

  ...bottomMargin && {
    margin: '0 0 10px 0',
  },

  ...!playerInputPlaceholder ? {
    padding: '20px',
    '&:hover': {
      background: '#EAF6F6',
    },
  } : {
    border: isPlaceholder ? 'unset' : `1px solid ${displayLevelError ? '#F42B03' : (isMe ? '#EAF6F6' : '#78ADAB')}`,
    borderRadius: '4px',
    padding: '0 10px',
    '&:not(:last-of-type)': {
      margin: '0 0 10px 0',
    },
  },

  ...isPlaceholder && {
    width: '100%',
    padding: 'unset',
  },

  ...!gameLevelSet && {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
}));

export const PlayerOptionAvatar = styled.img({
  height: '38px',
  width: '38px',
  objectFit: 'cover',
  objectPosition: 'top',
  margin: '0 8px 0 0',
  borderRadius: '50%',
});

export const PlayerOptionMetaData = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

export const PlayerOptionUsername = styled(P)({
  margin: 'unset',
  lineHeight: '21px',
});

export const Ellipsis = styled.span({
  width: '100%',
  display: 'inline',
  maxWidth: '260px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});

export const PlayerOptionLevel = styled(P)({
  margin: 'unset',
  lineHeight: '16px',
});

export const PlayerOptionID = styled.span({
  fontWeight: 400,
  color: '#78ADAB',
});

export const SearchOptionInitialsAvatar = styled(Initials)({
  height: '38px',
  width: '38px',
  margin: '0 8px 0 0',
});
