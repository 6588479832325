const theme = {
  breakpoints: {
    minMobileMdWidth: '360px',
    minTabletWidth: '768px',
    minDesktopSmallWidth: '1024px',
    minDesktopLargeWidth: '1366px',
    minDesktopWideWidth: '1600px',
    minDesktopExtraWideWidth: '1921px',
    maxMobileSmWidth: '359.98px',
    maxMobileWidth: '767.98px',
    maxTabletWidth: '1023.98px',
    maxDesktopSmallWidth: '1365.98px',
    maxDesktopLargeWidth: '1599.98px',
  },
  functions: {
    toEm: (px) => `${px / 14}rem`,

    /*
      Media queries
     */
    mediaQueryMin: (width, content) => `@media only screen and (min-width: ${width}) {${content}}`,
    mediaQueryMax: (width, content) => `@media only screen and (max-width: ${width}) {${content}}`,
    mediaQueryBetween: (minWidth, maxWidth, content) => (
      `@media only screen and (min-width: ${minWidth}) and (max-width: ${maxWidth}) {${content}}`
    ),

    // min media queries
    minTablet: (content) => theme.functions.mediaQueryMin(theme.breakpoints.minTabletWidth, content),
    minDesktopSmall: (content) => theme.functions.mediaQueryMin(theme.breakpoints.minDesktopSmallWidth, content),
    minDesktopLarge: (content) => theme.functions.mediaQueryMin(theme.breakpoints.minDesktopLargeWidth, content),
    minDesktopWide: (content) => theme.functions.mediaQueryMin(theme.breakpoints.minDesktopWideWidth, content),
    minDesktopExtraWide: (content) => (
      theme.functions.mediaQueryMin(theme.breakpoints.minDesktopExtraWideWidth, content)
    ),

    // max media queries
    maxMobile: (content) => theme.functions.mediaQueryMax(theme.breakpoints.maxMobileWidth, content),
    maxTablet: (content) => theme.functions.mediaQueryMax(theme.breakpoints.maxTabletWidth, content),
    maxDesktopSmall: (content) => theme.functions.mediaQueryMax(theme.breakpoints.maxDesktopSmallWidth, content),
    maxDesktopLarge: (content) => theme.functions.mediaQueryMax(theme.breakpoints.maxDesktopLargeWidth, content),

    // target device media queries
    onlyTablet: (content) => theme.functions.mediaQueryBetween(
      theme.breakpoints.minTabletWidth,
      theme.breakpoints.maxTabletWidth,
      content,
    ),
    onlyTabletDesktopSmall: (content) => theme.functions.mediaQueryBetween(
      theme.breakpoints.minTabletWidth,
      theme.breakpoints.maxDesktopSmallWidth,
      content,
    ),
    onlyDesktopSmall: (content) => theme.functions.mediaQueryBetween(
      theme.breakpoints.minDesktopSmallWidth,
      theme.breakpoints.maxDesktopSmallWidth,
      content,
    ),
    onlyDesktopLarge: (content) => theme.functions.mediaQueryBetween(
      theme.breakpoints.minDesktopLargeWidth,
      theme.breakpoints.maxDesktopLargeWidth,
      content,
    ),
  },
  default: {
    colors: {
      primary: '#10242C',
      secondary: '#7cc9c6',
      third: '#F42B03',
      fourth: '#898b8a',
      yellow: '#FFE071',
      yellowDarker: '#FFA800',
      darkGreen: '#112B36',
      red: '#ef4f4f',
      white: '#fff',
      black: '#10242C',
      grey: '#cccccc',
      grey2: '#AAAAAA',
      summer: '#FFF8E1',
      disabledBlue: '#C1E6E5',
      btnDisabled: '#C1E6E5',
      darkTurquoise: '#78ADAB',
      lightGrey: '#F7F7F7',
      lightGrey2: '#EDEDED',
      lightGrey3: '#f8f8f8',
      borderGrey: '#f2f2f2',
      darkRed: '#F42B03',
      firstServe: '#E9E9E9',
      silver: '#B0B0B0',
      gold: '#FFCC18',
      golden: '#F4CA15',
      platinum: '#5854FF',
      purple: '#CB5DFF',
      special: '#CB5DFF',
      champions: '#CB5DFF',
      league: '#39D377',
      lightBlue1: '#78ADAB',
      lightBlue2: '#EAF6F6', // This is not lightBlue, it's actually light turqoise
      turquoise: '#10242C',
      mediumTurquoise: '#7CC9C6',
      lightTurquoise: '#C1E6E5',
      lightGreen: '#A6FFA4',
      darkYellow: '#FFDC98',
      lightRed: '#FFCCCF',
      lightBlue: '#EAF6F6', // This is not lightBlue, it's actually light turqoise
      secondaryTurquoise: '#EDFFFB',
      secondaryGrey: '#B0B0B0',
      warn: '#FFD540',
      success: '#6CFF8D',
      successLight: '#8FFFA7',
      error: '#F42B03',
      errorLight: '#FF8298',
      errorLight2: '#FFF1F4',
      pink: '#CB5DFF',
      lime: '#A6FFA4',
      lime2: '#00DB30',
      orange: '#FFDC98',
      silver2: '#E9E9E9',
      darkGrey: '#4E4E4E',
      secondaryRed: '#FFCCCF',
      darkCyan: '#003145',
      darkCyan2: '#012C34',
      lightCyan: '#51FDED',
      sports: {
        tennisBg: '#DEF1FF',
        tennisBorder: '#BFE4FF',
        tennisText: '#0097D4',
        padelBg: '#E4FFE8',
        padelBorder: '#A1FFAF',
        padelText: '#008C15',
      },
    },
    container: {
      maxWidth: '1234px',
      headerBoxed: '1300px',
    },
  },
};

export default theme;
