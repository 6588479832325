import React, { useContext, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
// import weather from 'openweather-apis';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import LoadImage from '../../components/common/LoadImage';
import { store as socketStore } from '../../components/Socket/store';

import { MaintenanceBar } from '../Events/styles';

import {
  Alert,
  Paragraph,
} from '../../components';

import {
  CompleteProfileStatusBar,
  Guides,
  UpcomingCompetitions,
  ReferralRewardsBanner,
  FindAnEventBanner,
  FriendlyMatches,
} from './components';

import Stats from '../PlayerProfile/components/Stats/components/Stats';
import Rankings from '../PlayerProfile/components/Stats/components/Ranking';
import FriendlyMatchStats from '../PlayerProfile/components/Stats/components/FriendlyMatchStats';
// import FeaturedEvents from '../Events/components/FeaturedEvents';
import GuideBanner from '../ForPlayers/components/getting-started-banner';

import {
  Container,
  OnMobile,
  OnDesktop,
  Weather,
  GreetingContainer,
  Greeting,
  DashboardContent,
  MainContent,
  SidebarContent,
} from './styles';
import InviteFriends from './components/InviteFriends';
import { fetchInvitedUsers } from '../Referral/reducers';

const dayTime = [
  'neutralGreeting',
  'goodMorning',
  'goodAfternoon',
  'goodEvening',
][parseInt((new Date().getHours() / 24) * 4, 10)];

const Dashboard = () => {
  const { accountInfo, isProfileCompleted, currentWeather } = useSelector(state => state.session);
  const { data: upcomingCompetitions } = useSelector(state => state.dashboard?.competitions);
  const { data: friendlyMatchesSummary } = useSelector(state => state.friendlyMatches?.summary);
  const { rewardsInfo = {} } = useSelector(state => state.settings.data);
  const { data: invitedUsers, status: invitedUsersStatus } = useSelector(state => state.invitedUsers);
  const { addToast } = useToasts();
  const { t } = useTranslation('dashboard');
  const globalState = useContext(socketStore);
  const { state: { socket: { socketClientId, socket } } } = globalState;
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (invitedUsersStatus === 'idle') {
      dispatch(fetchInvitedUsers());
    }
  }, [invitedUsersStatus]);

  useEffect(() => {
    // if club, redirect to club profile
    if (accountInfo?.role !== 'player') {
      return history.push('/my-account');
    }

    // if (accountInfo.location && !currentWeather) {
    //   weather.setLang('en');
    //   weather.setCoordinate(accountInfo.location.coords?.lat, accountInfo.location.coords?.lng);
    //   weather.setAPPID(process.env.REACT_APP_OPENWEATHERMAP_KEY);
    //
    //   weather.getSmartJSON((err, smart) => {
    //     dispatch(setCurrentWeather(smart));
    //   });
    // }
  }, [accountInfo]);

  useEffect(() => {
    const listenForResponse = async ({ success, message }) => {
      await addToast(t(message), {
        appearance: success ? 'success' : 'error',
        autoDismiss: true,
      });
    };

    if (socket) {
      socket.removeAllListeners('accounts-response');
      socket.on('accounts-response', listenForResponse);

      return () => socket.removeAllListeners('accounts-response');
    }
  }, [socketClientId]);

  const display = {
    now: new Date().getFullYear(),
    userBirthday: new Date(accountInfo?.birthDate).getFullYear(),
    minAge: 14,
    get minAgeBanner() {
      // eslint-disable-next-line react/no-this-in-sfc
      return (this.now - this.userBirthday) < this.minAge;
    },
    get progressBar() {
      return accountInfo?.role === 'player' && !isProfileCompleted;
    },
    get congratsActivation() {
      return isProfileCompleted && history.location.search.includes('congrats');
    },
  };

  const playedBefore = (
    accountInfo?.leaderboard?.singles?.eventsParticipation > 0
    || accountInfo?.leaderboard?.doubles?.eventsParticipation > 0
    || accountInfo?.leaderboard?.singles?.friendlyMatches?.ranked > 0
    || accountInfo?.leaderboard?.doubles?.friendlyMatches?.ranked > 0
    || accountInfo?.leaderboard?.singles?.friendlyMatches?.unranked > 0
    || accountInfo?.leaderboard?.doubles?.friendlyMatches?.unranked > 0
  );
  const hasFriendlyMatches = friendlyMatchesSummary?.totalMatches > 0 || friendlyMatchesSummary?.totalRequests > 0;

  const { maxRewards } = rewardsInfo?.currencies?.[accountInfo?.currency] ?? rewardsInfo;
  const maxRewardsReceived = invitedUsers.length >= maxRewards;

  return (
    <Container noPadding>
      <Helmet>
        <title>{t('pageTitle')}</title>
      </Helmet>

      {display.minAgeBanner && (
        <MaintenanceBar age>
          <img src={LoadImage('info-icon.svg')} alt="" />
          <Paragraph small>
            <Trans ns="dashboard" i18nKey="minAgeRequired">
              Starting with 2022, we are raising the minimum age required to enter Sportya events to 15 years.
              <a href="/">www.tenis10.ro</a>
            </Trans>
          </Paragraph>
        </MaintenanceBar>
      )}

      {display.progressBar && <CompleteProfileStatusBar />}

      <GreetingContainer>
        <Greeting>
          {t(dayTime)}, {accountInfo?.firstName}
        </Greeting>
        {currentWeather && accountInfo?.location && (
          <Weather>
            {t('todayWeather', {
              weatherType: currentWeather.description,
              degrees: Math.round(currentWeather.temp),
              location: accountInfo.location.name,
            })}
          </Weather>
        )}
      </GreetingContainer>

      {display.congratsActivation && (
        <Alert success className="mb20">
          <Trans ns="dashboard" i18nKey="profileCompleted">
            <strong>Your profile is complete.</strong>
            Enjoy the full{process.env.REACT_APP_SITE_NAME} experience!
          </Trans>
        </Alert>
      )}

      <DashboardContent>
        <MainContent>
          {/* Show if no upcoming & no previous event participation */}
          {upcomingCompetitions?.totalResults === 0 && !playedBefore && !hasFriendlyMatches && (<GuideBanner />)}

          {/* Show if no upcoming, no previous event participation && didn't received max allowed rewards */}
          {upcomingCompetitions?.totalResults === 0 && !hasFriendlyMatches && !maxRewardsReceived && (
            <ReferralRewardsBanner firstBox />
          )}

          {/* Upcoming Competitions */}
          <UpcomingCompetitions />

          {/* Find an Event Banner */}
          {!hasFriendlyMatches && upcomingCompetitions?.totalResults === 0 && (
            <FindAnEventBanner playedBefore={playedBefore} />
          )}

          <FriendlyMatches />

          {/* If there are Upcoming Competitions or Friendly Matches and no max rewards received */}
          {(hasFriendlyMatches || upcomingCompetitions?.totalResults > 0) && !maxRewardsReceived && (
            <ReferralRewardsBanner />
          )}

          {/* Show if no upcoming, no previous event participation && received all rewards */}
          {upcomingCompetitions?.totalResults === 0 && !hasFriendlyMatches && maxRewardsReceived && (
            <InviteFriends dashboard />
          )}

          {/* Find an Event Banner */}
          {hasFriendlyMatches && <FindAnEventBanner hasUpcomingCompetitions />}

          {/* If there are Upcoming Competitions + NO Friendly Matches */}
          {!hasFriendlyMatches && upcomingCompetitions?.totalResults > 0 && (
            <>
              {maxRewardsReceived && (<InviteFriends dashboard />)}
            </>
          )}

          {/* Find an Event Banner */}
          {!hasFriendlyMatches && upcomingCompetitions?.totalResults > 0 && (
            <FindAnEventBanner hasUpcomingCompetitions={upcomingCompetitions?.totalResults > 0} />
          )}

          {/* <FeaturedEvents dashboard amount={3} isOpened /> */}

          {/* If there are Friendly Matches and max rewards received */}
          {hasFriendlyMatches && maxRewardsReceived && (
            <InviteFriends dashboard />
          )}

          {(
            hasFriendlyMatches
            || upcomingCompetitions?.totalResults > 0
            || (upcomingCompetitions?.totalResults === 0 && playedBefore)
          ) && (
            <GuideBanner />
          )}

          <OnDesktop>
            <Guides />
          </OnDesktop>
        </MainContent>

        <SidebarContent>
          <Rankings isOnDashboard />
          <Stats isOnDashboard />
          <FriendlyMatchStats isOnDashboard />
        </SidebarContent>
      </DashboardContent>

      <OnMobile>
        <Guides />
      </OnMobile>
    </Container>
  );
};

export default Dashboard;
