export default {
  completeProfile: {
    profileDetails: 'Detalii profil',
    fillProfileDetails: 'Completează detaliile profilului',
    sportyaAvailability: 'Sportya este deschisă pentru jucătorii din România, Republica Moldova și Bulgaria. Noi locații vor fi adăugate în curând',
    year: 'An',
    month: 'Lună',
    day: 'Zi',
    gender: 'Sex',
    location: 'Locație',
    locationNotSet: '<0>Locație:</0> Setează-ți locația',
    userLocationNotSet: '<0>Locația:</0> Nu este încă setată',
    age: 'Vârstă',
    ageNotSet: '<0>Vârstă:</0> Setează-ți vârsta',
    userAgeNotSet: '<0>Vârsta:</0> Nu este încă setată',
    handNotSet: '<0>Mână Dominantă:</0> Setează-ți mâna dominantă',
    userHandNotSet: '<0>Mâna Dominantă:</0> Nu este încă setată',
    searchLocation: 'Caută o locație...',
    chooseDominantHand: 'Alege mâna dominantă',
    left: 'Stânga',
    right: 'Dreapta',
    continue: 'Continuă',
    setGameLevel: 'Setează Nivel de Joc',
    saveAndContinue: 'Salvează și continuă',
    uploadNewPhoto: 'Încarcă o fotografie nouă',
    removePhoto: 'Elimină fotografia',
    profileVisibility: 'Vizibilitatea profilului',
    pictureMaxSize: 'Fotografia de profil trebuie să aibă mai puțin de 1 MB.',
    fillProfileVisibility: 'Alege cum dorești să te vadă ceilalți jucători',
    allPlayers: 'Toți jucătorii',
    allPlayersDescription: 'Profilul tău va fi vizibil pentru toți jucătorii și vor avea opțiunea de a-ți trimite mesaje, de a te adăuga ca prieten și de a te invita la meciuri amicale.',
    allPlayersSubtitle: 'Îți recomandăm această opțiune pentru a te bucura pe deplin de experiența {{siteName}}!',
    onlyFriendsAndTheirFriends: 'Doar prietenii tăi & prietenii lor',
    onlyFriendsAndTheirFriendsDescription: 'Profilul tău va fi vizibil numai pentru jucătorii adăugați ca Prieteni și pentru prietenii lor. \n \n Toți ceilalți jucători vor vedea o versiune limitată a profilului (nume complet, ID jucător, locație, fotografie de profil) și li se va permite doar să îți trimită mesaje și să te adauge ca prieten.',
    onlyFriends: 'Doar prietenii tăi',
    onlyFriendsDescription: 'Profilul tău va fi complet vizibil numai pentru jucătorii adăugați ca Prieteni. \n \n Toți ceilalți jucători vor vedea o versiune limitată a profilului (nume complet, ID jucător, locație, fotografie de profil) și li se va permite doar să îți trimită mesaje și să te adauge ca prieten.',
    gameLevel: 'Nivelul jocului',
    gameLevel_tennis: 'Nivelul jocului de Tenis',
    gameLevel_padel: 'Nivelul jocului de Padel',
    gameLevelDescription: 'Când pășim pe teren, ne dorim să jucăm meciuri cât mai echilibrate. Din aceste considerente, autoevaluarea potențialului de joc și organizarea meciurilor / evenimentelor pe niveluri de joc au devenit baza experiențelor de joc satisfăcătoare, în Sportya.',
    gameLevelList: {
      1: 'Inițial, veți seta un nivel de joc orientativ pentru potențialul tău de joc, fie la Simplu (tenis), fie la Dublu (padel). Nivelul corespunzător pentru celălalt format de joc (Simplu/Dublu, după caz) va fi preluat automat și va coincide cu cel ales inițial. Ulterior, pe baza rezultatelor obținute, nivelurile pentru Simplu și Dublu pot fi ajustate separat.',
      2: 'Setarea nivelului inițial este un pas important și poate avea un impact major în ceea ce privește primele experiențe în evenimentele și meciurile desfășurate în Sportya. Alege-ți nivelul de joc cu grijă și încearcă să fii cât se poate de obiectiv.',
      3: 'Nivelul 4 corespunde începătorilor, în timp ce nivelul 9 se apropie cel mai mult de tenisul de performanță.',
      4: 'Nivelul de joc se adaptează și se modifică dinamic, în funcție de rezultatele înregistrate.',
    },
    chooseGameLevel: 'Alege nivelul de joc',
    chooseGameLevel_tennis: 'Alege nivelul de joc pentru tenis',
    chooseGameLevel_padel: 'Alege nivelul de joc pentru padel',
    seeVideoDemonstration: 'Vezi exemplificarea video',
    selectThisLevel: 'Alege acest nivel',
    skipForNow: 'Revino mai târziu',
    level: 'NIVEL',
    levelDescription: {
      '4_tennis': 'Jucătorul prezintă entuziasm, dorința de a învăța, cunoaștere a jocului de tenis și are capacitatea de a susține un schimb de câteva mingi. Deficiențele sale sunt viteza de joc, mișcarea întreruptă / incompletă atunci când lovește mingea iar deplasarea pe teren, în general, nu este un punct forte.',
      '4_padel': 'Tocmai a început să joace padel, învață regulile și loviturile de bază. Are dificultăți în controlul mingii, consistență și poziționare. Experiență limitată cu pereții și voleurile. Meciurile sunt <strong>mai mult pentru învățare</strong> decât pentru competiție. \n\n <strong>Echivalent (Divizii Turnee utilizate în România):</strong>\n Divizia Beginner',
      '5_tennis': 'Jucătorul înțelege și poate să se deplaseze pe teren pentru a-și crea avantaje în joc, are control al direcției cu forehand-ul și poate să susțină schimburi consistente de mingi. Deficiențele sale sunt viteza de joc încă redusă, o nesiguranță generală în mișcări care, în continuare, sunt de multe ori întrerupte / incomplete. Se observă de multe ori absența loviturilor decisive.',
      '5_padel': 'Poate menține raliuri lente cu <strong>lovituri de bază (dreapta & rever)</strong>. Începe să joace mai consecvent, dar greșește sub presiune. Are o înțelegere de bază a poziționării și a jocului de echipă. Experiență limitată cu lovituri avansate (ex. bandeja, loburi). \n\n <strong>Echivalent (Divizii Turnee utilizate în România):</strong>\n Divizia 4 (alegerea se va face în funcție de experiența de joc acumulată)',
      '6_tennis': 'Jucătorul are control al direcției atât cu forehand-ul cât și cu reverul, poate dezvolta un ritm de joc consistent și se observă, în multe situații, intenția de a construi punctul. Reverul, chiar dacă este controlabil, nu este o lovitură consistentă, al doilea serviciu are de obicei o viteză redusă și este slab plasat iar în ciuda oportunităților de joc create, în continuare se observă lipsa loviturilor decisive.',
      '6_padel': 'Control decent al loviturilor (voleuri, loburi, servicii, smash-uri simple). Folosește pereții, dar nu întotdeauna eficient. Poate susține raliuri la viteză medie și direcționa mingea. Începe să înțeleagă <strong>poziționarea strategică</strong> la fileu și pe linia de fund. \n\n <strong>Echivalent (Divizii Turnee utilizate în România):</strong>\n Divizia 4 (alegerea se va face în funcție de experiența de joc acumulată)',
      '7_tennis': 'Jucătorul poate accelera execuțiile pe forehand, are lovituri variate, alternează efectele date loviturilor și poate construi punctul pe slăbiciunile adversarului. Cu toate acestea, se observă lipsa speculării unor situații de superioritate, forțare și greșeli în punctele-cheie și un rever lipsit de agresivitate.',
      '7_padel': 'Tehnică solidă, bună <strong>înțelegere a tacticii</strong> de joc. Folosește eficient <strong>pereții, voleurile, bandeja și smash-urile</strong>. Controlează ritmul raliului și forțează erorile adversarului. \n\n <strong>Echivalent (Divizii Turnee utilizate în România):</strong>\n Divizia 3',
      '8_tennis': 'Jucătorul stăpânește liftul în regim de viteză, are un joc agresiv, folosind toată gama de lovituri și nu face cadouri adversarului. Cu toate acestea, jucătorul forțează excesiv în unele cazuri și are, în general, un rever ineficace în situații de presiune.',
      '8_padel': 'Excelent în <strong>jocul defensiv și ofensiv</strong>. Folosește spin, lovituri tăiate și unghiuri eficiente. <strong>Reacții rapide</strong> și anticipare bună a jocului adversarilor. \n\n <strong>Echivalent (Divizii Turnee utilizate în România):</strong>\n Divizia 2',
      '9_tennis': 'Jucătorul folosește serviciul ca pe o armă de temut, are lovituri variate, alternează efectele date loviturilor care de multe ori sunt imprevizibile, eficiente, decisive atât cu forehand-ul, cât și cu reverul.',
      '9_padel': 'Cel mai înalt nivel pentru amatori. Excelentă rezistență mentală, consistență și luare a deciziilor. <strong>Execuție tehnică perfectă</strong> (bandeja, vibora, smash liftat etc.). Nivel <strong>fizic și tactic de elită</strong>, similar cu cel al jucătorilor de înaltă performanță. \n\n <strong>Echivalent (Divizii Turnee utilizate în România):</strong>\n Divizia 1',
    },
    profilePicture: 'Poză de profil',
    looksGreat: 'Arată bine',
    profilePictureSubtitle: 'Fotografiile vă ajută prietenii să vă recunoască \n în {{siteName}}',
    uploadYourPhoto: 'Încarcă fotografia',
    availability: 'Disponibilitate',
    availabilityDescription: 'Găsește parteneri de joc mai ușor, stabilind disponibilitatea de a juca',
    completeProfile: 'Profil complet',
    setAvailability: 'Setează disponibilitatea',
    Mon: 'Luni',
    Tue: 'Marți',
    Wed: 'Miercuri',
    Thu: 'Joi',
    Fri: 'Vineri',
    Sat: 'Sâmbătă',
    Sun: 'Duminică',
    chooseFavoriteSport: "Alege Sportul Favorit",
    chooseFavoriteSportSubtitle: "Alege sportul pe care îl practici cel mai des. Poți adăuga alte sporturi din setările contului tău. ",
    chooseFavoriteSportSubtitle2: "Sportya este acum o comunitate multisport, odată cu adăugarea PADEL. Noi evenimente de padel, meciuri amicale, clasamente și multe altele sunt acum disponibile. \n\n <strong>Poți schimba oricând sportul din meniul principal al site-ului.</strong> \n\n Pentru a începe, selectează sportul preferat (și nivelul de joc, dacă alegi padel) pentru a determina sportul utilizat de fiecare dată când îți accesezi contul. Îți poți actualiza ulterior aceste preferințe din setările contului tău.",
  },
  logout: {
    wantToLogout: 'Deconectare?',
    logoutParagraph: 'Ești sigur că dorești să te deconectezi?',
    btnCancel: 'Anulează',
    btnLogout: 'Deconectare',
  },
  wallet: {
    seoTitle: 'Portofel Virtual | Sportya',
    virtualWallet: 'Portofel Virtual',
    walletInfo: 'Portofelul virtual Sportya este un sistem de plată care îți permite să te înregistrezi rapid la evenimente și să plătești pentru alte servicii Sportya. Îți poți încărca portofelul virtual și îl poți utiliza ulterior ca opțiune de plată. Plățile sunt validate fără întârziere. Nu stocăm datele de plată / card.',
    chooseAmountToTopUp: 'Alege suma cu care vrei să-ți încarci portofelul',
    noTransactions: 'Fără tranzacții',
    availableFunds: 'Fonduri Disponibile',
    latestTransactions: 'Ultimele Tranzacții',
    transactionId: 'ID Tranzacție',
    date: 'Dată',
    product: 'Produse',
    amount: 'Sumă',
    topUp: 'Încarcă portofel',
    transactionTypes: {
      topUp: 'Încărcare Portofel Virtual',
      topUpByAdmin: 'Încărcare Portofel Virtual (Administrativă)',
      subtractByAdmin: 'Actualizare Portofel Virtual (Administrativă)',
      eventRegistration: 'Înregistrare Eveniment: <1>{{ eventName }}</1>',
      partnerEventRegistration: 'Partener - Înregistrare Eveniment: <1>{{ eventName }}</1>',
      eventWithdrawal: 'Retragere Eveniment: <1>{{ eventName }}</1>',
      planUpdate: 'Upgrade Abonament: <1>{{ membershipName }}</1>',
      currencyNotSet: 'You must set up your location in order to use Top-Up feature.',
      balanceAdjustmentByAdmin: 'Modificare Balanță (Administrativă)',
      waitingListRefund: 'Restituire Automată Listă de Așteptare',
      courtBooking: "Booking <1>#{{ bookingId }}",
      courtBookingRefund: "Booking Refund <1>#{{ bookingId }}",
    },
    mobile: {
      id: 'ID',
      date: 'Dată',
      type: 'Tip',
      amount: 'Sumă',
    },
  },
  orders: {
    title: 'Comenzile Mele',
    subtitle: 'Istoric Comenzi',
    seoTitle: 'Comenzile Mele | Sportya',
    orderList: {
      orderID: 'ID Comandă',
      dateTime: 'Zi & Oră',
      orderType: 'Tip Comandă',
      amount: 'Sumă',
      paymentMethod: 'Metodă Plată',
      orderDetails: 'Detalii Comandă',
      viewDetails: 'Vezi Detalii',
      invoiceInfo: 'Dovadă plată',
      viewInvoice: 'Descarcă',
      invoiceNotAvailable: 'Indisponibil',
      orderSummary: {
        title: 'Detalii Comandă',
        seeEvents: 'Vezi Evenimentul',
        about: 'Înscrierea în turneu a fost validată. Din acest moment comanda nu mai poate fi modificată. Pentru întrebări suplimentare vă rugăm să ne contactați la',
        about2: 'Găsiți informații suplimentare și polița noastră de retur la',
        returnPolicy: 'Polița de Returnare',
        summary: 'Rezumat',
        bookingFee: 'Taxă Servicii Sportya',
        sprintenDonation: 'Donație Sprinten',
        coupon: 'Cupon',
        total: 'Total',
        inclTVA: 'Incl. TVA',
        clubEntryFees: ' Taxă Înscriere Club',
        howToPayEntryFee: 'Achitată direct organizatorului, în momentul prezentării la eveniment. Club organizator: ',
        data: {
          id: 'ID Comandă',
          type: 'Tip Comandă',
          date: 'Dată & Timp',
          method: 'Metodă Plată',
        },
      },
      mobile: {
        ID: 'ID',
        Date: 'Data',
        Type: 'Tip',
        Price: 'Sumă',
        Payment: 'Metodă Plată',
      },
    },
    noOrders: 'Nu există comenzi anterioare',
  },
  notifications: {
    title: 'Notificări',
    subtitle: 'Ultimele Notificări',
    seoTitle: 'Notificări | Sportya',
    seeDetails: 'Vezi Detalii',
    noNotifications: 'Nu au fost găsite notificări.',
    dropdown: {
      title: 'Notificări',
      allNotifications: 'Vezi toate notificările',
    },
    topUp: "Soldul Portofelului Virtual a fost actualizat. <0> Vezi detalii </0>.",
    gameLevelChangedByAdmin: "Nivelul de joc pentru <strong>{{ sportType }}</strong> a fost modificat de un administrator. Noul nivel este <strong>{{ gameLevelForSingles }}</strong> pentru Simplu, <strong>{{ gameLevelForDoubles }}</strong> pentru Dublu. <0> Vezi detalii </0>.",
    eventRegistrationBan: "Din cauza penalităților active pentru contul tău, nu te mai poți înregistra la evenimente. <0> Vezi detalii </0>.",
    penaltyAdministrative: "Punctele au fost scăzute din clasamentul tău de către un administrator. <0> Vezi detalii </0>",
    penaltyLateWithdrawal: "Retragerea tardivă din evenimentul <0>{{ eventName }}</0> a fost procesată. Taxa de înregistrare a fost reținută.",
    penaltyAnnouncedWithdrawal: "Retragerea din evenimentul <0>{{ eventName }}</0> a fost procesată. Taxa de înregistrare a fost returnată în <0>Virtual Wallet</0>.",
    teamWithdrawalByAdmin: "Echipa ta a fost retrasă din turneul <0>{{ eventName }}</0>. <0>Vezi detalii</0>.",
    playerWithdrawalByAdmin: "Ai fost retras din turneul <0>{{ eventName }}</0>. <0>Vezi detalii</0>",
    teamWithdrawalFromDoubles: "Partenerul tău de joc <0>{{ partnerName }}</0> a retras echipa din <0>{{ eventName }}</0>.",
    registrationByAdminOnSLForDoubles: "Înscrierea echipei tale în <0>{{ eventName }}</0> pe lista de sortare a avut loc cu succes. <0>Vezi detalii</0>.",
    registrationByAdminOnSLForSingles: "Înscrierea ta în <0>{{ eventName }}</0> pe lista de sortare a avut loc cu succes. <0>Vezi detalii</0>.",
    registrationByAdminOnWLForDoubles: "Înscrierea echipei tale în <0>{{ eventName }}</0> pe lista de așteptare a avut loc cu succes. <0>Vezi detalii</0>.",
    registrationByAdminOnWLForSingles: "Înscrierea ta în <0>{{ eventName }}</0> pe lista de așteptare a avut loc cu succes. <0>Vezi detalii</0>.",
    registrationByAdminOnMLForDoubles: "Înscrierea echipei tale în <0>{{ eventName }}</0> pe tabloul principal a avut loc cu succes. <0>Vezi detalii</0>.",
    registrationByAdminOnMLForSingles: "Înscrierea ta în <0>{{ eventName }}</0> pe tabloul principal a avut loc cu succes. <0>Vezi detalii</0>.",
    promotionToMLForDoubles: "Ca urmare a retragerilor din proba de dublu organizată în <0>{{ eventName }}</0>, echipa ta a avansat pe tabloul principal. <0>Vezi detalii</0>.",
    promotionToMLForSingles: "Ca urmare a retragerilor din proba de simplu organizată în <0>{{ eventName }}</0>, ai avansat pe tabloul principal. <0>Vezi detalii</0>.",
    eventRegistrationSLOpen: "Înscrierile pe lista de sortare pentru <0>{{ eventName }}</0> sunt deschise. <0>Înscrie-te acum</0>.",
    eventRegistrationSLClosed: "Înscrierile pe lista de sortare pentru <0>{{ eventName }}</0> s-au închis. Sortarea înscrierilor pentru tabloul principal și lista de așteptare, pe baza clasamentelor generale, este în curs de validare. <0>Vezi detalii</0>.",
    eventRegistrationMLOpen: "Înscrierile pe tabloul principal pentru <0>{{ eventName }}</0> sunt deschise. <0>Înscrie-te acum</0>.",
    eventRegistrationMLClosed: "Înscrierile pe tabloul principal pentru <0>{{ eventName }}</0> s-au închis. Înscrierile pe lista de așteptare rămân deschise până cu o zi înainte de startul evenimentului. <0>Vezi detalii</0>.",
    eventMainDrawPublished: "Tabloul principal pentru <0>{{ eventName }}</0> a fost publicat. Înscrierile pe lista de așteptare rămân deschise până cu o zi înainte de startul evenimentului. <0>Vezi detalii</0>.",
    eventStarted: "Meciurile pentru <0>{{ eventName }}</0> încep astăzi. <0>Vezi detalii</0>.",
    eventEnded: "Meciurile pentru <0>{{ eventName }}</0> s-au încheiat. Rezultatele și câștigătorii sunt în curs de actualizare. <0>Vezi detalii</0>.",
    eventClosed: "Rezultatele pentru <0>{{ eventName }}</0> au fost actualizate. <0>Vezi detalii</0>.",
    eventAnnouncementUpdates: "Actualizare turneu <0>{{ eventName }}</0>: \n",
    friendRequestReceived: "Ai primit o cerere de prietenie de la {{ partnerName }}. <0>Vezi detalii</0>.",
    friendRequestAccepted: "Ai un nou prieten pe Sportya - {{ partnerName }}. <0>Vezi Jucător</0>.",
    friendJoined: "{{ partnerName }} s-a înscris în Sportya la invitația ta. <0>Vezi Jucător</0>.",
    friendRaisedHand: "{{ partnerName }} caută un meci amical pe Sportya. <0>Invită la meci</0>.",
    invitationReceived: "{{ partnerName }} te-a invitat la un meci de {{ matchGameType }} - <strong>{{ matchFormatedId }}</strong>. <0>Vezi invitația</0>.",
    pastMatchInvite: "Confirmă participarea ta și scorul pentru meciul de {{ matchGameType }} cu {{ partnerName }} - <strong>{{ matchFormatedId }}</strong>. <0>Vezi meciul jucat</0>.",
    matchAccepted: "{{ partnerName }} a acceptat invitația ta la un meci de {{ matchGameType }} - <strong>{{ matchFormatedId }}</strong>. <0>Vezi meciul</0>.",
    matchDeclined: "{{ partnerName }} a refuzat invitația ta la un meci de {{ matchGameType }} - <strong>{{ matchFormatedId }}</strong>. <0>Vezi meciul</0>.",
    matchDenied: "{{ partnerName }} a infirmat participarea la meciul tău de {{ matchGameType }} - <strong>{{ matchFormatedId }}</strong>. <0>Vezi meciul</0>.",
    playerWithdrew: "{{ partnerName }} s-a retras din meciul tău de {{ matchGameType }} - <strong>{{ matchFormatedId }}</strong>. <0>Vezi meciul</0>.",
    addScoreReminder: "Adaugă scorul meciului tău de {{ matchGameType }} pe Sportya - <strong>{{ matchFormatedId }}</strong>. <0>Adaugă Scorul</0>.",
    upcomingMatchReminder: "Meciul tău de {{ matchGameType }} începe în 30 de minute - <strong>{{ matchFormatedId }}</strong>. <0>Vezi meciul</0>.",
    pastMatchConfirmed: "{{ partnerName }} a confirmat meciul de {{ matchGameType }} și scorul - <strong>{{ matchFormatedId }}</strong>. <0>Vezi meciul</0>.",
    scoreAdded: "{{ partnerName }} a adăugat scorul pentru meciul tău de {{ matchGameType }} - <strong>{{ matchFormatedId }}</strong>. <0>Vezi meciul</0>.",
    scoreChanged: "{{ partnerName }} a schimbat scorul pentru meciul tău de {{ matchGameType }} - <strong>{{ matchFormatedId }}</strong>. <0>Vezi meciul</0>.",
    scoreValidated: "Scorul final pentru meciul tau de {{ matchGameType }} a fost validat - <strong>{{ matchFormatedId }}</strong>. <0>Vezi meciul</0>.",
    canceledMatch: "Meciul tău de {{ matchGameType }} a fost anulat - <strong>{{ matchFormatedId }}</strong>. <0>Vezi meciul</0>.",
    updatedMatch: "Meciul tău de {{ matchGameType }} a fost actualizat - <strong>{{ matchFormatedId }}</strong>. <0>Vezi meciul</0>.",
    playerRatingReceived: "Tocmai ai primit o nouă evaluare în urma unui meci amical. <0>Vezi media de evaluare</0>.",
    newRewardReceived: "Felicitări! Ai primit o nouă recompensă. <0>Verifică Portofelul Virtual</0>",
    bookingCancellation: "Rezervarea ta la <strong>{{ clubName }}</strong> a fost anulată de către Club - <strong>{{ bookingId }}</strong>. <0>Vezi Detaliile Rezervării</0>.",
    bookingRescheduled: "Rezervarea ta la <strong>{{ clubName }}</strong> a fost reprogramată de către Club - <strong>{{ bookingId }}</strong>. <0>Vezi Detaliile Rezervării</0>.",
  },
  creditCard: 'Credit Card',
  virtualWallet: 'Portofel Virtual',
  summerMembership: 'Cont de Vară',
  premiumMembership: 'Cont Premium',
  premium: 'Cont Premium {{period}}',
  undefinedMembership: 'Cont Premium',
  transactionTypes: {
    topUp: 'Încărcare Portofel Virtual',
    topUpByAdmin: ' Încărcare Portofel Virtual (Administrativă)',
    subtractByAdmin: ' Actualizare Portofel Virtual (Administrativă)',
    eventRegistration: 'Înscriere Eveniment: <0>{{ eventName }}</0>',
    eventWithdrawal: 'Retragere Eveniment: <0>{{ eventName }}</0>',
    upgradeMembership: 'Upgrade Abonament: <1>{{ membershipName }}</1>',
    upgradeMembershipByAdmin: 'Upgrade Abonament (de Admin): <1>{{ membershipName }}</1>',
    balanceAdjustmentByAdmin: 'Balantă modificată (de Admin)',
    waitingListRefund: 'Restituire Automată Listă de Așteptare',
    currencyNotSet: 'Setează-ți locația pentru a putea utiliza Portofelul Virtual.',
    date: '<0>Dată:</0> {{ createdAt }}',
    firstTaskCompletion: "Recompensă: Acțiune eligibilă proprie",
    referralReward: "Recompensă: Acțiune jucător invitat",
    courtBooking: "Booking <1>#{{ bookingId }}",
    courtBookingRefund: "Booking Refund <1>#{{ bookingId }}",
  },
  notificationButton: 'Buton Notificări',
  referral: {
    title: "Invită-ți prietenii & Câștigă recompense",
    title2: "Creează-ți cont și Câștigă recompense!",
    subtitle: "Invită-ți prietenii pe Sportya și câștigă recompense pentru primii {{ maxRewards }} jucători* care se alătură Sportya cu codul tău de invitație! Amândoi veți primi câte <strong>{{ amount }} {{ currency }}</strong> în Portofelele Virtuale** de îndată ce <strong>una dintre următoarele acțiuni este finalizată cu succes pentru prima oară de către jucătorul invitat</strong>:",
    subtitle2: "Câștigă o recompensă imediat ce finalizezi cu succes pentru prima oară UNA dintre următoarele acțiuni:",
    item1: "Obținerea primului Rezultat Validat într-un turneu Sportya",
    item11: "Obținerea primului Rezultat Validat într-un turneu Sportya",
    item2: "Încărcarea Portofelului Virtual",
    item22: "Încărcarea Portofelului Virtual",
    item3: "Achiziționarea unui cont Premium cu Portofelul Virtual sau cardul bancar",
    item4: "Prima participare la un Meci Amical cu un scor validat",
    amountEarned: "{{ amount }} {{ currency }} Câștigați",
    rewardsCompleted: "Recompense completate {{ receivedRewards }}/{{ maxRewards }}",
    yourFriend: "Invită",
    referVia: "Invită prin",
    shareTitle: "Distribuie",
    smallText1: "*Jucătorul care invită va fi recompensat numai pentru primii {{ maxRewards }} jucători care se înscriu în Sportya cu codul de recomandare și completează cu succes una dintre acțiunile necesare pentru prima dată.",
    smallText2: "**Portofelul Virtual al jucătorului recompensat va fi creditat automat cu 5 Euro sau echivalentul în moneda Portofelului Virtual al acestuia, de îndată ce una dintre acțiunile eligibile este finalizată cu succes. Fiecare utilizator va primi recompensa o singură dată, doar pentru una dintre acțiunile eligibile.",
    smallText3: "*Suma corespunzătoare valorii recompensei va fi creditată în soldul tău în moneda Portofelului Virtual din Sportya.",
    smallText4: "**Doar una dintre acțiunile eligibile va fi recompensată atunci când este finalizată pentru prima oară.",
    smallText5: "Vă rugăm să verificați <1>Termenii și condițiile</1> pentru a afla mai multe despre recompensele Sportya",
    inviteFriends: "Invită-ți prietenii & Câștigă recompense",
    inviteFriendsDescription: "Câștigă câte <strong>{{ amount }} {{ currency }}</strong> pentru fiecare dintre primii {{ maxRewards }} jucători care se alătură Sportya cu codul tău de invitație!",
    inviteYourFriends: "Invită-ți Prietenii",
    invite: {
      playTogether: 'Invită-ți prietenii și jucați împreună',
      friends: 'Invită-ți Prietenii',
    },
    shareToMedia: {
      email: {
        title: 'Hai să jucăm tenis în Sportya',
        body: `Salutare! %0D%0A
Folosesc Sportya, platforma sportivă competitivă pentru jucătorii amatori de tenis și te invit să mi te alături și tu! %0D%0A
În Sportya, poți cunoaște alți jucători, puteți juca meciuri amicale împreună sau participa la turnee, și puteți concura pentru primele locuri în clasamentele naționale și internaționale! %0D%0A
Este o modalitate grozavă de a rămâne activ și de ne distra împreună. %0D%0A
%0D%0A
Înscrie-te acum pe Sportya folosind linkul meu unic de invitație și câștigă recompense! %0D%0A
{{inviteLink}}`,
      },
      facebook: `Join me on Sportya, the competitive sports platform for amateur tennis players!\r\n
We can connect with other players, play friendly matches together, or join tournaments and compete for the top spots in national and international rankings!\r\n
Use my unique invitation link: {{inviteLink}}`,
      twitter: 'Vino alături de mine în Sportya, platforma sportivă competitivă pentru jucătorii de tenis amatori!%0aCreeză-ți cont pentru a cunoaște alți jucători alături de care poți participa la meciuri amicale și turnee naționale și internaționale!%0a',
    },
    invitedBy: 'Ai fost invitat de <1>{{referee}}</1>.',
    joinSportya: 'Creează-ți cont și Câștigă recompense! <1>Află mai multe</1>.',
    share: 'Împărtășește-ți dragostea pentru tenis! Invită-ți prietenii în Sportya și jucați împreună meciuri amicale sau concurați pentru primele locuri în clasamentele naționale și internaționale!',
    copyAndShare: 'Copiază și distribuie linkul tău de invitație',
    willBeFriend: 'Prietenii pe care îi inviți și care se înscriu pe Sportya vor fi adăugați automat la lista ta de prieteni.',
    copy: 'Copiază',
    dashboardBannerTitle: "Ești cu un pas mai aproape de recompensă",
    dashboardBannerSubtitle: "Câștigă <1>{{ amount }} {{ currency }}</1> imediat ce finalizezi cu succes pentru prima oară UNA dintre următoarele acțiuni:",
    congrats: 'Felicitări! Tocmai ai primit <1>{{ amount }} {{ currency }}</1> în <3>Portofelul Virtual</3> pentru finalizarea cu succes a primei acțiuni pe Sportya.',
    findOutMore: 'Aflați mai multe despre <1>recompensele Sportya</1> sau verificați <3>Termenii și condițiile</3>',
  },
  ratePlayer: 'Acordă o notă',
  changeRating: 'Schimbă nota acordată',
  receivedRating: '{{ total }} notă primită',
  receivedRatings: '{{ total }} note primite',
  ratePlayerInfo: 'Poți evalua alți jucători numai după ce ai participat la cel puțin un meci amical cu ei.<br/>Media de evaluare a jucătorului este calculată pe baza tuturor notelor primite de la alți jucători și va fi<br/>afișată public pe profilul jucătorului respectiv doar după ce au fost primite minimum 3 note.',
  noRatings: 'Nicio evaluare',
  rate: 'Salvează Evaluarea',
  removeRating: 'Retrage Evaluarea',
  ownRatingInfo: 'Media ta de evaluare va deveni vizibilă tuturor jucătorilor doar după ce vei primi minimum 3 evaluări de la jucătorii cu care ai interacționat.',
  skipRating: 'Evaluez mai târziu',
  howWouldRatePlayer: 'Acordă o notă',
  howWouldRateTitlePartner: 'Acordă o notă Partenerului',
  howWouldRateTitleOpponent: 'Acordă o notă Oponentului 1',
  howWouldRateTitleOpponentPartner: 'Acordă o notă Oponentului 2',
  thankYouForRating: 'Îți mulțumim pentru evaluare!',
};
