import React, { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { H1 } from '../../components/Collection';
import LoadImage from '../../components/common/LoadImage';
import mixpanel from '../../mixpanel';

import {
  Container,
  ReferralContainer,
  URLContainer,
  InvitedPeopleContainer,
  Avatar,
  AvatarContainer,
  SocialMedia,
  CopyButton,
  InviteURL,
  InnerSection,
  TermsLink,
} from './style';
import { Paragraph } from '../../components';
import { fetchInvitedUsers } from './reducers';
import ProfileCard from '../../components/ProfileCard';

export const encode = ({
  firstName,
  lastName,
  userId,
  id,
}) => {
  let leftIds = '';
  let rightIds = '';
  const [fNameInitials] = firstName.toLowerCase();
  const [lNameInitials] = lastName.toLowerCase();

  const nameInitials = `${fNameInitials}${lNameInitials}`;
  const userIdLength = String(userId).length;
  const longIdLength = 8;

  if (userIdLength < longIdLength) {
    const sliceNumber = (longIdLength - userIdLength) / 2;
    leftIds = id.slice(0, Math.floor(sliceNumber));
    rightIds = id.slice(-Math.ceil(sliceNumber));
  }
  const longId = leftIds + userId + rightIds;
  const value = `${nameInitials}${userIdLength}${longId}`;

  const encodedString = [];
  const urlEncryption = new Map([
    ['0', 'w'],
    ['1', 'q'],
    ['2', 'b'],
    ['3', 'm'],
    ['4', 'u'],
    ['5', 'n'],
    ['6', 'o'],
    ['7', 'i'],
    ['8', 'c'],
    ['9', 'z'],
  ]);

  [...value.toString()].forEach(character => {
    encodedString.push(character.replace(character, char => urlEncryption.get(char) ?? char));
  });

  const halfLength = Math.ceil(encodedString?.length / 2);
  const left = encodedString.slice(0, halfLength);
  const right = encodedString.slice(halfLength, encodedString?.length);
  const encrypted = [...left, ...right].join('');

  return encrypted;
};

export const copyText = (inviteURLholder) => {
  const URL = inviteURLholder?.current;
  URL.select();
  URL.setSelectionRange(0, 99999);
  return navigator.clipboard.writeText(URL.value);
};

const Referral = () => {
  const { accountInfo } = useSelector(state => state.session);
  const { rewardsInfo = {} } = useSelector(state => state.settings.data);
  const { data: invitedUsers } = useSelector(state => state.invitedUsers);
  const { t } = useTranslation('accounts');
  const dispatch = useDispatch();
  const inviteURL = `${process.env.REACT_APP_WEB_URL}/join/${encode(accountInfo)}`;
  const inviteCodeRef = useRef(null);
  const inviteURLholder = useRef(null);

  if (accountInfo?.isImpersonate) {
    return <Redirect to="/dashboard" />;
  }

  useEffect(() => {
    dispatch(fetchInvitedUsers());
  }, []);

  const facebookText = t('referral.shareToMedia.facebook', { inviteLink: inviteURL });
  let facebookHref = `https://www.facebook.com/dialog/share?href=${inviteURL}`;
  facebookHref += `&app_id=429689722395295&display=popup&description=${facebookText}`;
  const twitterText = t('referral.shareToMedia.twitter', { inviteLink: inviteURL });
  const emailBody = t('referral.shareToMedia.email.body', { inviteLink: inviteURL });

  const { currency, maxRewards = 10 } = rewardsInfo?.currencies?.[accountInfo?.currency] ?? rewardsInfo;

  const amountEarned = (invitedUsers || []).reduce((total, item) => total + item?.amount, 0);
  const receivedRewards = invitedUsers?.length ?? 0;
  const rewardsCountDiff = receivedRewards >= maxRewards ? 0 : maxRewards - receivedRewards;
  const results = [...invitedUsers, ...new Array(rewardsCountDiff).fill({})];

  return (
    <Container>
      <Helmet>
        <title>{t('referral.title')}</title>
      </Helmet>
      <div className="text-center">
        <img src={LoadImage("invite-friends-icon.png")} alt="" />
        <H1 className="heading">
          <Trans ns="accounts" i18nKey="referral.title">
            Invite Your Friends & Get Rewarded
          </Trans>
        </H1>
        <Paragraph className="mt20 mb40 heading">
          <Trans ns="accounts" i18nKey="referral.subtitle">
            Invite your friends to Sportya and get rewarded for the first {{ maxRewards }} players who join
            Sportya with your referral code! You will both
            get <strong>{{ amount: rewardsInfo?.amount }} {{ currency: rewardsInfo?.currency }}</strong> in your
            Virtual Wallets as soon as they <strong>complete one of the following actions for the 1st time</strong>:
          </Trans>
        </Paragraph>

        <ul className="actions">
          <li>
            <img src={LoadImage('mgm/item1.png')} alt="" />
            <Trans ns="accounts" i18nKey="referral.item1">
              Get their First Validated Result in a Sportya tournament
            </Trans>
          </li>
          <li>
            <img src={LoadImage('mgm/item2.png')} alt="" />
            <Trans ns="accounts" i18nKey="referral.item2">
              Top-Up their Virtual Wallet with any amount
            </Trans>
          </li>
          <li>
            <img src={LoadImage('mgm/item3.png')} alt="" />
            <Trans ns="accounts" i18nKey="referral.item3">
              Purchase a Premium account with Virtual Wallet or Credit Card
            </Trans>
          </li>
        </ul>
      </div>

      <InvitedPeopleContainer>
        <Paragraph className="badge" bold>
          <Trans ns="accounts" i18nKey="referral.amountEarned">
            {{ amount: amountEarned }}
            {{ currency }}
            Earned
          </Trans>
        </Paragraph>
        <Paragraph>
          <Trans ns="accounts" i18nKey="referral.rewardsCompleted">
            Rewards Completed
            {{ receivedRewards }}
            {{ maxRewards }}
          </Trans>
        </Paragraph>
        <ul>
          {results.map(({ userInfo }, index) => (
            <li key={index}>
              <div>
                {userInfo && (<ProfileCard user={userInfo} layout="inviteFriends" />)}
                {!userInfo && (
                  <>
                    <AvatarContainer>
                      <Avatar src={LoadImage('mgm/avatar-placeholder.png')} alt="" />
                    </AvatarContainer>
                    <Paragraph small className="placeholder-text">
                      <Trans ns="accounts" i18nKey="referral.yourFriend">Your friend</Trans>
                    </Paragraph>
                  </>
                )}
              </div>
            </li>
          ))}
        </ul>
      </InvitedPeopleContainer>

      <ReferralContainer>
        <Paragraph bold large className="mb10">
          <Trans ns="accounts" i18nKey="referral.referVia">Refer via</Trans>
        </Paragraph>

        <URLContainer>
          <InnerSection className="bd-bottom">
            <Paragraph>
              <Trans ns="accounts" i18nKey="referral.code">Code</Trans>
            </Paragraph>
            <div className="d-flex">
              <InviteURL
                value={encode(accountInfo)}
                disabled
                ref={inviteCodeRef}
              />
              <CopyButton
                onClick={() => {
                  copyText(inviteCodeRef);
                  mixpanel.track('Copy invitation Code (click on Copy button for the code)');
                }}
              >
                <img src={LoadImage('copy-red.svg')} alt="Copy referral link" />
              </CopyButton>
            </div>
          </InnerSection>

          <InnerSection>
            <Paragraph>
              <Trans ns="accounts" i18nKey="referral.link">Link</Trans>
            </Paragraph>
            <div className="d-flex">
              <InviteURL
                value={`${process.env.REACT_APP_WEB_URL}/join/${encode(accountInfo)}`}
                disabled
                ref={inviteURLholder}
              />
              <CopyButton
                onClick={() => {
                  copyText(inviteURLholder);
                  mixpanel.track('Copy Invitation URL (click in Copy button)');
                }}
              >
                <img src={LoadImage('copy-red.svg')} alt="Copy referral link" />
              </CopyButton>
            </div>
          </InnerSection>
        </URLContainer>

        <div className="mt30">
          <Paragraph large bold>
            <Trans ns="accounts" i18nKey="referral.shareTitle">Share</Trans>
          </Paragraph>
          <SocialMedia
            onClick={() => mixpanel.track('Share invitation via Facebook (click in Facebook button)')}
            href={facebookHref}
            target="_blank"
          >
            <img src={LoadImage('social-media/facebook.svg')} alt="Share to Facebook" />
          </SocialMedia>
          <SocialMedia
            onClick={() => mixpanel.track('Share invitation via Twitter (click on Twitter button)')}
            href={`https://twitter.com/share?url=${inviteURL}&text=${twitterText}&hashtag=Sportya`}
            target="_blank"
          >
            <img src={LoadImage('social-media/twitter.svg')} alt="Share to Twitter" />
          </SocialMedia>
          <SocialMedia
            onClick={() => mixpanel.track('Share invitation via Email (click on Email button)')}
            href={`mailto:?to=&body=${emailBody}&subject=${t('referral.shareToMedia.email.title')}`}
            target="_blank"
          >
            <img src={LoadImage('social-media/email.svg')} alt="Share through Email" />
          </SocialMedia>
        </div>
      </ReferralContainer>

      <div className="text-center">
        <Paragraph small>
          <Trans ns="accounts" i18nKey="referral.smallText1">
            *The inviting players will be rewarded only for the first {{ maxRewards }} players who join Sportya using
            their referral codes and successfully complete one of the required actions for the first time.
          </Trans>
        </Paragraph>

        <Paragraph small>
          <Trans ns="accounts" i18nKey="referral.smallText2">
            **The Virtual Wallet of the rewarded player will be automatically credited with 5 Euros or the
            equivalent value in the currency of the respective Virtual Wallet, as soon as one of the required
            actions is successfully completed.
          </Trans>
        </Paragraph>

        <Paragraph smaller className="mt40">
          <Trans ns="accounts" i18nKey="referral.smallText5">
            Please check the <TermsLink to="/terms-conditions" target="_blank">Terms and Conditions</TermsLink>
            to find out more about Sportya rewards
          </Trans>
        </Paragraph>
      </div>
    </Container>
  );
};

export default Referral;
