import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import dateFormat from 'dateformat';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import mixpanel from '../../mixpanel';

import {
  Container,
  ListHeader,
  EventsContainer,
  Events,
  ContainFeatured,
  EventsOptions,
  ListFormat,
  GroupedEvents,
  RefPosition,
  PaginationWrapper,
  FeaturedEventsContainer,
} from './styles';

import FeaturedEvents from './components/FeaturedEvents';
import {
  setActiveFilters,
  fetchEvents,
  resetEventsLocations,
} from './reducers';
import EventFilters from './components/EventFilters';
// eslint-disable-next-line import/namespace
import SortBy from './components/SortBy';
import ContentLoader from '../../components/ContentLoader';
import Event from '../../components/Event';
import Pagination from '../../components/Pagination';
import LoadImage from '../../components/common/LoadImage';

import AdvancedFilters from './components/EventFilters/AdvancedFilters';
import FilterButton from './components/FilterButton';

import Modal from '../../components/Modal/Modal';
import useModal from '../../components/Modal/useModal';

import {
  P,
  Alert,
  Contain,
} from '../../components/Collection';

import NoResultsBanner from './components/NoResultsBanner';
import DuracellAndPringles from '../../components/Campaign/DuracellAndPringles';

const ListEvents = ({ isBanned, masterSport }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('events');

  const router = useLocation();
  const {
    location,
    radius,
    coordinates,
    endedEvents,
    gameType,
  } = router?.state || {};

  const {
    data: events,
    status,
    sportType,
  } = useSelector((state) => state.events?.list);
  const { data: activeFilters } = useSelector(
    (state) => state.events?.activeFilters,
  );
  const { isLoggedIn } = useSelector((state) => state.session);
  const [listFormatRows, setListFormatRows] = useState(true);
  const [filters, setFilters] = useState(
    router?.state
      ? {
        location,
        radius,
        gameType,
        coordinates,
        endedEvents,
      }
      : !isEmpty(activeFilters)
        ? JSON.parse(JSON.stringify(activeFilters))
        : {},
  );

  const [formFilters, setFormFilters] = useState({ ...filters });
  const [currentPage, setCurrentPage] = useState(
    activeFilters.page ? activeFilters.page - 1 : 0,
  );
  const [displayFeatured, setDisplayFeatured] = useState(false);
  const hasResults = !isEmpty(events?.results) && status !== 'loading';
  const isLoading = status === 'loading';
  const totalActiveFilters = Object.keys(filters)
    .filter(key => ['category', 'gender', 'minAge' || 'maxAge', 'playMode', ('availableEntries' || 'endedEvents')]
      .includes(key)).length;

  const initialOptions = {
    page: activeFilters.page ? activeFilters.page : 1,
    limit: 20,
  };

  const [options, setOptions] = useState(initialOptions);
  const [readableFilters, setReadableFilters] = useState();
  const [sortByDistance, setSortByDistance] = useState(false);
  const { isShowing, toggle } = useModal();

  useEffect(() => {
    if (Cookies.get('listFormat')) {
      const content = Cookies.get('listFormat');
      setListFormatRows(content === 'row');
    }

    mixpanel.track('Visit Find an Event', {
      sp_find_an_event_logged_in: isLoggedIn,
    });
  }, []);

  useEffect(() => {
    if (
      status === 'idle'
      || (sportType && sportType !== masterSport && status === 'succeeded')
    ) {
      dispatch(fetchEvents({ filters, options }));
      dispatch(resetEventsLocations());
    }
  }, [status, dispatch, masterSport, sportType]);

  useEffect(() => {
    if (status === 'succeeded') {
      if (filters.sortBy) {
        options.sortBy = filters.sortBy;
      }
      dispatch(fetchEvents({ filters, options }));
      dispatch(resetEventsLocations());
    }

    if (filters.location) {
      setReadableFilters(`${filters.location.replace('null, ', '')}`);
    } else {
      setReadableFilters(null);
    }
  }, [filters, options]);

  const dispatchFilters = (data) => {
    if (!isEmpty(data)) {
      // eslint-disable-next-line no-param-reassign
      delete data.limit;
      // eslint-disable-next-line no-param-reassign
      delete data.role;
      // eslint-disable-next-line no-param-reassign
      delete data.page;
    }

    dispatch(setActiveFilters(data));
  };

  const handleListFormat = () => {
    setListFormatRows(!listFormatRows);

    if (!listFormatRows) {
      Cookies.set('listFormat', 'row', {
        expires: 30,
        path: '/',
      });
    } else {
      Cookies.set('listFormat', 'grid', {
        expires: 30,
        path: '/',
      });
    }
  };

  const handleFilters = (name, value) => {
    const predefinedFilters = ['availableEntries', 'endedEvents'];
    const isEndedEvents = name === 'endedEvents';
    const isAvailableEntries = name === 'availableEntries';
    const isAllActive = name === 'allActive';
    let newFilters = {
      ...filters,
    };

    predefinedFilters.forEach((item) => {
      if (newFilters[item]) {
        delete newFilters[item];
        delete filters[item];
      }
    });

    if (predefinedFilters.includes(name)) {
      newFilters = {
        ...filters,
        [name]: value,
      };
    }

    delete newFilters.allEvents;

    if (isEndedEvents) {
      setOptions({ ...options, sortBy: 'createdAt:desc' });
    }

    if (isAvailableEntries || isAllActive) {
      setOptions({ ...options, sortBy: 'startDate:asc' });
    }

    handlePagination({ selected: 0 });
    setFilters(newFilters);
    dispatchFilters(newFilters);
  };

  function handleModalFilters(data) {
    const modalFilters = {};

    Object.keys(data).forEach((item) => {
      if (['minAge', 'maxAge'].includes(item)) {
        if (data[item]) {
          modalFilters[item] = data[item];
        } else {
          delete filters[item];
        }
      } else if (item === "eventsType") {
        // reset event types
        ['availableEntries', 'allActive', 'endedEvents'].forEach((v) => {
          if (filters[v]) {
            delete filters[v];
          }
        });

        if (data[item] === "endedEvents") {
          setOptions({ ...options, sortBy: 'createdAt:desc' });
        } else {
          setOptions({ ...options, sortBy: 'startDate:asc' });
        }

        if (data[item] !== "allActive") {
          modalFilters[data[item]] = true;
        } else {
          delete filters[data[item]];
        }
      } else if (!isEmpty(data[item])) {
        modalFilters[item] = data[item];
      } else {
        delete filters[item];
      }
    });

    const newFilters = {
      ...filters,
      ...modalFilters,
    };

    handlePagination({ selected: 0 });
    setFilters(newFilters);
    dispatchFilters(newFilters);
    triggerScroll();
  }

  const triggerScroll = () => {
    listRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const listRef = useRef(null);

  const handlePagination = (data) => {
    const page = data.selected + 1;

    if (currentPage !== data.selected) {
      setOptions({
        ...options,
        page,
      });
      setCurrentPage(data.selected);
    }

    dispatch(setActiveFilters({ ...filters, page }));
  };

  const eventsFound = readableFilters
    ? events?.totalResults === 1
      ? 'eventFound'
      : 'eventsFound'
    : events?.totalResults === 1
      ? 'eventFoundNc'
      : 'eventsFoundNc';

  const sortEvents = () => {
    const eventsSorted = {};

    events?.results?.forEach((event) => {
      const date = dateFormat(event?.startDate, 'mmmm yyyy');
      if (!eventsSorted[date]) {
        eventsSorted[date] = [];
      }
      eventsSorted[date].push(event);
    });
    return eventsSorted;
  };

  const sortByNewest = options.sortBy === 'createdAt:desc';
  const sortByClosest = options.sortBy === 'closest';

  const isSelected = (country) => filters?.location === `null, ${country}`;

  return (
    <>
      <Helmet>
        <title>{t('seoTitle')}</title>
      </Helmet>

      {/* Filters */}
      <Container className="filters" grid={!listFormatRows}>
        <Contain width="100%" justify="space-between">
          <EventFilters
            {...{
              filters,
              setFilters,
              formFilters,
              setFormFilters,
              handlePagination,
              triggerScroll,
              isBanned,
            }}
          />
          <Contain
            direction="row"
            margin="0 0 0 0"
            gap={5}
            className="cursor-pointer filters-btn desktop"
            align="center"
            onClick={() => toggle(!isShowing)}
          >
            <img src={LoadImage('filter.svg')} alt="" width={20} />
            <P bold margin="0 0 0 10px">
              <Trans ns="events" i18nKey="filters">Filters</Trans>
            </P>
            {totalActiveFilters > 0 && (
              <span className="total-filters">
                {totalActiveFilters}
              </span>
            )}
          </Contain>
        </Contain>
      </Container>

      {/* Events found & sorting */}
      <Container grid={!listFormatRows}>
        <ListHeader grid={!listFormatRows}>
          <RefPosition ref={listRef} />
          <P size={24} margin="0 0 5px 0">
            <Trans
              ns="events"
              i18nKey={eventsFound}
              count={events?.totalResults || 0}
              context={masterSport}
            >
              <strong>
                {{ totalResults: events?.totalResults || 0 }} events
              </strong>
              {{ location: readableFilters }}
            </Trans>
          </P>
          <SortBy
            className="desktop"
            {...{
              options,
              filters,
              setOptions,
              setSortByDistance,
              sortByDistance,
            }}
          />
          <Contain onClick={() => toggle(!isShowing)} className="mobile" gap={10}>
            <img src={LoadImage('filter.svg')} alt="All" width={20} />
            {totalActiveFilters > 0 && (
              <span className="total-filters">
                {totalActiveFilters}
              </span>
            )}
          </Contain>
        </ListHeader>
      </Container>

      <Container grid={!listFormatRows} className="event-options-container">
        <div className="desktop">
          {listFormatRows && hasResults && (
            <P large className="eventsDate">
              {Object.keys(sortEvents()).pop()}
            </P>
          )}
        </div>
        <EventsOptions grid={!listFormatRows}>
          {!listFormatRows && (
            <Contain
              direction="column"
              margin="0 auto 0 0"
              className="desktop"
            >
              <P size={24} margin="0 0 5px 0">
                <Trans
                  ns="events"
                  i18nKey={eventsFound}
                  count={events?.totalResults || 0}
                  context={masterSport}
                >
                  <strong>
                    {{ totalResults: events?.totalResults || 0 }} events
                  </strong>
                  {{ location: readableFilters }}
                </Trans>
              </P>

              <SortBy
                {...{
                  options,
                  filters,
                  setOptions,
                  setSortByDistance,
                  sortByDistance,
                }}
              />
            </Contain>
          )}
          <Contain overflowX="auto" className="desktop">
            <FilterButton
              active={
                !filters?.availableEntries
                    && !filters?.endedEvents
                    && !filters.allEvents
              }
              onClick={() => handleFilters('allActive', true)}
              margin="0 0 0 auto"
            >
              <Trans ns="events" i18nKey="allActive">
                All Active
              </Trans>
            </FilterButton>
            <FilterButton
              active={filters?.availableEntries}
              onClick={() => handleFilters('availableEntries', true)}
            >
              <Trans ns="events" i18nKey="availableEntries">
                Available Entries
              </Trans>
            </FilterButton>
            <FilterButton
              active={filters?.endedEvents}
              onClick={() => handleFilters('endedEvents', true)}
            >
              <Trans ns="events" i18nKey="showEndedEvents">
                Ended Events
              </Trans>
            </FilterButton>
          </Contain>

          {/* Featured Events */}
          <Contain direction="column" className={`mobile ${displayFeatured ? '' : 'mb20'}`}>
            <FeaturedEventsContainer grid={!listFormatRows}>
              <Contain
                minWidth="220px"
                padding="0 20px"
                background="#EAF6F6"
                height="40px"
                border="none"
                bordeRadius="2px"
                boxShadow="none"
                justify="center"
                align="center"
                borderRadius="4px"
                className="w100 text-center cursor-pointer"
                onClick={() => setDisplayFeatured(!displayFeatured)}
              >
                <P small bold margin="0 10px 0 0">
                  <Trans
                    ns="events"
                    i18nKey={!displayFeatured ? 'showFeaturedEvents' : 'hideFeaturedEvents'}
                  >
                    Show Featured Events
                  </Trans>
                </P>
                <img
                  src={LoadImage(`${!displayFeatured ? 'expand.svg' : 'collapse.svg'}`)}
                  alt="Show Featured Events"
                />
              </Contain>
            </FeaturedEventsContainer>

            <ContainFeatured displayFeatured={displayFeatured}>
              <FeaturedEvents isOpened={displayFeatured} />
            </ContainFeatured>
          </Contain>

          <Contain>
            <SortBy
              className="mobile sorting"
              {...{
                options,
                filters,
                setOptions,
                setSortByDistance,
                sortByDistance,
              }}
            />
            <Contain
              height="60px"
              justify="end"
              align="center"
              margin="0 0 0 20px"
              className="listFormat"
            >
              <ListFormat
                margin="0 15px 0 0"
                onClick={handleListFormat}
                {...(listFormatRows && { active: true })}
              >
                <img
                  src={LoadImage('rows-icon.svg')}
                  alt="Display events as rows"
                />
              </ListFormat>
              <ListFormat
                onClick={handleListFormat}
                {...(!listFormatRows && { active: true })}
              >
                <img
                  src={LoadImage('cells-icon.svg')}
                  alt="Display events as grid"
                />
              </ListFormat>
            </Contain>
          </Contain>
        </EventsOptions>
      </Container>

      {/* Featured Events */}
      <Contain direction="column" margin="0 0 20px 0" hiddenOnMobile width="100%">
        <FeaturedEventsContainer grid={!listFormatRows}>
          <Contain
            minWidth="220px"
            padding="0 20px"
            background="#EAF6F6"
            height="40px"
            border="none"
            bordeRadius="2px"
            boxShadow="none"
            justify="center"
            align="center"
            borderRadius="4px"
            className="w100 text-center cursor-pointer"
            onClick={() => setDisplayFeatured(!displayFeatured)}
          >
            <P small bold margin="0 10px 0 0">
              <Trans
                ns="events"
                i18nKey={!displayFeatured ? 'showFeaturedEvents' : 'hideFeaturedEvents'}
              >
                Show Featured Events
              </Trans>
            </P>
            <img
              src={LoadImage(`${!displayFeatured ? 'expand.svg' : 'collapse.svg'}`)}
              alt="Show Featured Events"
            />
          </Contain>
        </FeaturedEventsContainer>

        <ContainFeatured displayFeatured={displayFeatured} className="mt10">
          <FeaturedEvents isOpened={displayFeatured} />
        </ContainFeatured>
      </Contain>

      <Container grid={!listFormatRows} className="mb40 flex-column">
        <EventsContainer>
          <Events grid={!listFormatRows && hasResults && !isLoading}>
            {/* loader */}
            {isLoading && listFormatRows && (
              <ContentLoader
                title={false}
                type="eventsList"
                items={options.limit}
              />
            )}

            {/* result grouped by month */}
            {hasResults
                && !sortByNewest
                && !sortByClosest
                && listFormatRows
                && Object.keys(sortEvents()).map((month, key) => (
                  <GroupedEvents key={key} className="groupedEvents">
                    <P large className="eventsDate">{month}</P>
                    {sortEvents()[month]?.map((event, id) => (
                      <>
                        <Event key={id} event={event} grid={!listFormatRows} />
                        {key === 0 && id === 1 && (
                          <DuracellAndPringles key={id} section="events" />
                        )}
                      </>
                    ))}
                  </GroupedEvents>
                ))}

            {/* if sort by newest */}
            {hasResults
                && !(!sortByNewest && !sortByClosest && listFormatRows)
                && events?.results?.map((event, id) => (
                  <Event key={id} event={event} grid={!listFormatRows} />
                ))}

            {/* no results */}
            {!hasResults && !isLoading && (
              <>
                <Alert persist margin="0 auto" background="#C1E6E5">
                  <P bold>
                    <Trans ns="events" i18nKey="noEventsFound">
                      No events found.
                    </Trans>
                  </P>
                </Alert>
                {isSelected(filters?.location?.split(' ')[1]) && (
                  <NoResultsBanner />
                )}
              </>
            )}
          </Events>
        </EventsContainer>

        {/* Advanced Filters Modal */}
        <Modal
          type2
          noborder
          nobackground
          isShowing={isShowing}
          hide={toggle}
          header
          noMarginFormGroup
          title={t('filters')}
          nooverflow
        >
          <AdvancedFilters
            {...{
              handleModalFilters,
              toggle,
              setFilters,
              filters,
              handlePagination,
              dispatchFilters,
              masterSport,
            }}
          />
        </Modal>

        {/* Pagination */}
        <PaginationWrapper onClick={() => triggerScroll()}>
          <Pagination
            pageCount={events?.totalPages || 0}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePagination}
            forcePage={currentPage}
          />
        </PaginationWrapper>
      </Container>
    </>
  );
};

export default ListEvents;
