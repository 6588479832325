import React from 'react';
import { isEmpty } from 'lodash';
import dateFormat from 'dateformat';
import { Trans, useTranslation } from 'react-i18next';
import 'react-tabs/style/react-tabs.css';

import LoadImage from '../common/LoadImage';

import Labels from './components/labels';

import {
  EventData,
  EventContainer,
  Thumbnail,
  Details,
  Competitions,
  Badge,
  List,
  AboutEvent,
  RadiusIcon,
  GridContainer,
  EventName,
  GoToEvent,
  BadgeImage,
} from './styles';

import eventCurrentPhase from '../common/eventCurrentPhase';

import {
  Contain,
  P,
  Button,
  Link,
} from '../Collection';

import TextWithPopup from './components/textWithPopup';

const Event = ({ event, grid, followedEvent }) => {
  const { t: commonT } = useTranslation('common');
  const eventPhase = eventCurrentPhase(event?.phases);
  const eventPhaseStatus = eventPhase?.status;

  const singlesCompetitions = event?.competitions
    ?.filter((competition) => competition.gameType === 'singles').sort((a, b) => a.level - b.level);
  const doublesCompetitions = event?.competitions
    ?.filter((competition) => competition.gameType === 'doubles').sort((a, b) => a.level - b.level);

  // event cover image
  let coverImage = null;
  const { cover = null } = event?.club?.defaultEventImages || {};
  if (cover) coverImage = `clubs/${event?.club?.clubId}/${event?.category}/${cover}`;
  if (event?.image?.cover) coverImage = `events/${event?.eventId}/${event?.image?.cover}`;

  const eventDate = new Date(event?.endDate);
  const eventYear = eventDate.getFullYear();

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const distance = event?.distance / 1000;

  const badgeBgs = {
    firstServe: '#E9E9E9',
    silver: '#B0B0B0',
    gold: '#FFCC18',
    platinum: '#5854FF',
    special: '#CB5DFF',
    champions: '#CB5DFF',
    league: '#39D377',
  };

  const badgeColor = {
    firstServe: 'darkGrey',
  };

  return !grid ? (
    <EventContainer>
      <Link to={`/events/${event._id}`} className="d-flex">
        <Thumbnail
          src={LoadImage(coverImage, true, 'thumbnail', event?.category, event?.sportType)}
          alt="Event Thumbnail"
        />
      </Link>
      <Details>
        <Contain
          margin="0 0 10px 0"
          maxWidth="350px"
          width="100%"
          align="center"
          justify="space-between"
        >
          <Contain align="center">
            {event?.category && (
              <Badge bg={badgeBgs?.[event?.category]}>
                <BadgeImage src={LoadImage(`${event?.category}-category-icon.svg`)} alt={event?.category} />
                <P xSmall bold color={badgeColor?.[event?.category] || 'white'}>
                  <Trans ns="events" i18nKey={`filtersList.${event?.category}`}>
                    {event?.category}
                  </Trans>
                </P>
              </Badge>
            )}
            {eventPhaseStatus
              && (
                <P xSmall bold>
                  <Trans ns="events" i18nKey={eventPhaseStatus} />
                </P>
              )}
          </Contain>

          <P xSmall>
            <time dateTime={event?.startDate}>
              {dateFormat(event?.startDate, 'd')}
              {' '}
              {commonT(dateFormat(event?.startDate, 'mmm'))}
              {' '}
              -
              {' '}
              {dateFormat(event?.endDate, 'd')}
              {' '}
              {commonT(dateFormat(event?.endDate, 'mmm'))}
              {eventYear !== currentYear ? `, ${dateFormat(event?.endDate, 'yyyy')}` : ''}
            </time>
          </P>
        </Contain>
        <EventName to={`/events/${event._id}`} width="fit-content">{event?.name}</EventName>
        <AboutEvent>
          {event?.distance > 0 ? (
            <EventData xSmall className="radius">
              <RadiusIcon src={LoadImage('radius-marker-icon.svg')} />
              {' '}
              {(distance * 1.2).toFixed(2)}
              {' '}
              km
            </EventData>
          ) : event?.distance === 0 && (
            <EventData xSmall className="radius">
              <RadiusIcon src={LoadImage('radius-marker-icon.svg')} />
            </EventData>
          )}
          {!isEmpty(event?.club) && event?.club?.clubName !== null ? (
            <>
              {event?.club?.clubName === 'TBA' ? (
                <TextWithPopup text="TBA" popUpText={commonT('soonToBeAnnounced')} />
              ) : <EventData xSmall>{event?.club?.clubName}</EventData>}

              {event?.club?.location?.country && (
                <EventData xSmall>
                  {event?.club?.location?.city}
                  ,
                  {' '}
                  {event?.club?.location?.country}
                </EventData>
              )}

            </>
          ) : (
            <>
              {event?.club?.location?.country && (
                <EventData xSmall>
                  {event?.club?.location?.city}
                  ,
                  {' '}
                  {event?.club?.location?.country}
                </EventData>
              )}

            </>
          )}
        </AboutEvent>
        <Competitions>
          {!isEmpty(singlesCompetitions) && (
            <Contain direction="column" margin="0 30px 20px 0">
              <P xSmall bold margin="0 85px 10px 0"><Trans ns="events" i18nKey="singles">Singles</Trans></P>
              <List>
                {singlesCompetitions?.map((competition, key) => (
                  <Labels key={key} {...{ event, competition, eventPhaseStatus }} followedEvent={followedEvent} />
                ))}
              </List>
            </Contain>
          )}
          {!isEmpty(doublesCompetitions) && (
            <Contain direction="column">
              <P xSmall bold margin="0 0 10px 0"><Trans ns="events" i18nKey="doubles">Doubles</Trans></P>
              <List>
                {doublesCompetitions?.map((competition, key) => (
                  <Labels
                    key={key}
                    {...{ event, competition, eventPhaseStatus }}
                    double
                    followedEvent={followedEvent}
                  />
                ))}
              </List>
            </Contain>
          )}
          {isEmpty(singlesCompetitions) && isEmpty(doublesCompetitions)
            && (
              <P bold margin="35px 0 0 0">
                <Trans ns="events" i18nKey="competitionsUnavailable">
                  Competitions for this event will be announced soon.
                </Trans>
              </P>
            )}
        </Competitions>
        <GoToEvent outline>
          <Link to={`/events/${event._id}`}>
            <P bold padding="0 0 0 20px">
              <Trans ns="events" i18nKey="goToEvent2">Go to Event</Trans>
            </P>
            <img src={LoadImage('arrow/right-button-arrow.svg')} style={{ marginRight: '30px' }} alt="View Event" />
          </Link>
        </GoToEvent>
      </Details>
      <Button
        small
        outline
        width="100%"
        minHeight="40px"
        margin="auto 0 0 0"
        className="mobile"
      >
        <Link to={`/events/${event._id}`}>
          <Trans ns="events" i18nKey="goToEvent2">Go to Event</Trans>
        </Link>
      </Button>
    </EventContainer>
  ) : (
    <GridContainer>
      <Contain
        direction="column"
        width="347px"
        padding="25px 20px 35px"
        height="100%"
        background="#fff"
        borderRadius="10px"
        border="1px solid #EAF6F6"
        className="box"
      >
        <Contain
          margin="0 0 10px 0"
          width="100%"
          align="center"
          justify="space-between"
        >
          <Contain align="center">
            {event?.category && (
              <Badge bg={badgeBgs?.[event?.category]}>
                <BadgeImage src={LoadImage(`${event?.category}-category-icon.svg`)} alt={event?.category} />
                <P xSmall bold color="white">
                  <Trans ns="events" i18nKey={`filtersList.${event?.category}`}>
                    {event?.category}
                  </Trans>
                </P>
              </Badge>
            )}
            {eventPhaseStatus
              && (
                <P xSmall bold>
                  <Trans ns="events" i18nKey={eventPhaseStatus} />
                </P>
              )}
          </Contain>
          <P xSmall>
            <time dateTime={event?.startDate}>
              {dateFormat(event?.startDate, 'd')}
              {' '}
              {commonT(dateFormat(event?.startDate, 'mmm'))}
              {' '}
              -
              {' '}
              {dateFormat(event?.endDate, 'd')}
              {' '}
              {commonT(dateFormat(event?.endDate, 'mmm'))}
              {eventYear !== currentYear ? `, ${dateFormat(event?.endDate, 'yyyy')}` : ''}
            </time>
          </P>
        </Contain>
        <P large bold margin="0 0 10px 0">{event?.name}</P>
        <AboutEvent margin="0 0 20px 0">
          {event?.distance > 0 ? (
            <EventData xSmall className="radius">
              <RadiusIcon src={LoadImage('radius-marker-icon.svg')} />
              {' '}
              {(distance * 1.2).toFixed(2)}
              {' '}
              km
            </EventData>
          ) : event?.distance === 0 && (
            <EventData xSmall className="radius">
              <RadiusIcon src={LoadImage('radius-marker-icon.svg')} />
            </EventData>
          )}
          {!isEmpty(event?.club) && event?.club?.clubName !== null ? (
            <>
              <EventData xSmall>{event?.club?.clubName}</EventData>
              <EventData xSmall>
                {event?.club?.location?.city}
                ,
                {' '}
                {event?.club?.location?.country}
              </EventData>
            </>
          ) : (
            <EventData xSmall>
              {event?.club?.location?.city}
              ,
              {' '}
              {event?.club?.location?.country}
            </EventData>
          )}
        </AboutEvent>
        <Competitions
          direction="column"
          margin="auto 0 0 0"
          isGrid={grid}
        >
          {!isEmpty(singlesCompetitions) && (
            <Contain direction="column">
              <P xSmall bold margin="15px 0 10px 0"><Trans ns="events" i18nKey="singles">Singles</Trans></P>
              <List>
                {singlesCompetitions?.map((competition, key) => (
                  <Labels key={key} {...{ event, competition, eventPhaseStatus }} followedEvent={followedEvent} />
                ))}
              </List>
            </Contain>
          )}
          {!isEmpty(doublesCompetitions) && (
            <Contain direction="column">
              <P xSmall bold margin="15px 0 10px 0"><Trans ns="events" i18nKey="doubles">Doubles</Trans></P>
              <List>
                {doublesCompetitions?.map((competition, key) => (
                  <Labels
                    key={key}
                    {...{ event, competition, eventPhaseStatus }}
                    double
                    followedEvent={followedEvent}
                  />
                ))}
              </List>
            </Contain>
          )}
        </Competitions>
        <Button
          small
          outline
          width="100%"
          margin="15px 0 0 0"
          minHeight="40px"
        >
          <Link to={`/events/${event._id}`}>
            <Trans ns="events" i18nKey="goToEvent2">Go to Event</Trans>
          </Link>
        </Button>
      </Contain>
    </GridContainer>
  );
};

export default Event;
