import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Link as LinkWrapper } from 'react-router-dom';
import i18n from 'i18next';
import { Trans } from 'react-i18next';

export const Contain = styled.div(props => ({
  display: props.display ? props.display : 'flex',
  ...props.position && { position: props.position },
  ...props.top && { top: props.top },
  ...props.bottom && { bottom: props.bottom },
  ...props.right && { right: props.right },
  ...props.left && { left: props.left },
  ...props.direction && { flexDirection: props.direction },
  ...props.align && { alignItems: props.align },
  ...props.justify && { justifyContent: props.justify },
  ...props.width && { width: props.width },
  ...props.fontWeight && { fontWeight: props.fontWeight },
  ...props.color && { color: props.color },
  ...props.maxWidth && { maxWidth: props.maxWidth },
  ...props.minWidth && { minWidth: props.minWidth },
  ...props.minHeight && { minHeight: props.minHeight },
  ...props.maxHeight && { maxHeight: props.maxHeight },
  ...props.height && { height: props.height },
  ...props.padding && { padding: props.padding },
  ...props.paddingBottom && { paddingBottom: props.paddingBottom },
  ...props.paddingTop && { paddingTop: props.paddingTop },
  ...props.margin && { margin: props.margin },
  ...props.border && { border: props.border },
  ...props.borderRadius && { borderRadius: props.borderRadius },
  ...props.borderBottom && { borderBottom: props.borderBottom },
  ...props.borderTop && { borderTop: props.borderTop },
  ...props.background && { background: props.background },
  ...props.overflow && { overflow: props.overflow },
  ...props.overflowX && { overflowX: props.overflowX },
  ...props.cursor && { cursor: props.cursor },
  ...props.zIndex && { zIndex: props.zIndex },
  ...props.flexFlow && { flexFlow: props.flexFlow },
  ...props.textAlign && { textAlign: props.textAlign },
  ...props.modalContain && { padding: '0px 53px 60px 53px' },
  ...props.buttonContain && { padding: '20px 0 0 0' },
  ...props.userSelect && { userSelect: props.userSelect },
  ...props.flexWrap && { flexWrap: props.flexWrap },
  ...props.gap && { gap: props.gap },
  ...props.pointerEvent && { pointerEvent: props.pointerEvent },
  ...props.inputWidth && {
    width: '550px',
    '@media (max-width: 1040px)': {
      width: 'auto',
      maxWidth: '550px',
    },
  },
  ...props.hasTooltip && {
    '&:hover': {
      '> #tooltip': {
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
  ...props.paddingLeft && {
    '@media (max-width: 1040px)': {
      paddingLeft: 'unset',
    },
  },
  ...props.hiddenOnMobile && {
    display: 'flex',
    '@media (max-width: 880px)': {
      display: 'none',
    },
  },
}));

const ButtonContainer = styled.button(props => ({
  display: props.display || 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  ...props.position && { position: props.position },
  ...props.position && { bottom: 0 },
  ...props.position && { right: 0 },

  ...props.maxWidth && { maxWidth: props.maxWidth },
  ...props.direction && { flexDirection: props.direction },
  ...props.minWidth && { minWidth: props.minWidth },
  ...props.width && { width: props.width },
  ...props.minHeight && { minHeight: props.minHeight },
  ...props.margin && { margin: props.margin },
  ...props.padding && { padding: props.padding },

  height: props.height ? props.height
    : props.xSmall ? '30px'
      : props.small ? '40px'
        : props.large ? '60px' : '50px',

  fontFamily: 'DM Sans',

  fontSize:
    props.xSmall ? props.theme.functions.toEm(12)
      : props.small ? props.theme.functions.toEm(14)
        : props.large ? props.theme.functions.toEm(18) : props.theme.functions.toEm(16),

  lineHeight:
    props.xSmall ? '15px'
      : props.small ? '18px'
        : props.large ? '24px' : '12px',

  fontWeight: '700',

  background: props.background ? props.background : (props.outline || props.noBorder) && !props.primary
    ? '#fff'
    : props.theme.colors.primary,

  color: props.color ? props.color : (props.outline || props.noBorder || props.ongoing) && !props.primary
    ? props.theme.colors.primary
    : '#fff',

  border: props.border ? props.border : 0,
  borderRadius: props.borderRadius ? props.borderRadius : '4px',

  userSelect: 'none',
  cursor: 'pointer',

  ...!props.noBorder && {
    boxShadow: `inset 0 0 0 2px ${props.boxShadow ? props.boxShadow : props.theme.colors?.black}`,
  },

  a: {
    display: 'flex',
    height: props.height ? props.height : '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    color: props.color ? props.color : (props.outline || props.noBorder || props.ongoing) && !props.primary
      ? props.theme.colors.primary
      : '#fff',
    textDecoration: 'none',
  },

  ...!props.noImgMargin && {
    img: {
      margin: '0 10px',
    },
  },

  transition: 'all 0.3s ease-out',

  '&:hover': {
    ...!props.noBorder && {
      boxShadow: `inset 0 0 0 ${(props.outline || props.premium)
        ? '4px' : '3px'} ${props.whiteBoxShadow
          ? '#fff' : props.outline
            ? props.theme.colors.primary
            : props.premium
              ? props.premium
              : props.theme.colors.secondary}`,
      a: {
        transition: 'all 0.3s ease-out',
        color: props.premium
          ? props.premium
          : props.outline
            ? props.theme.colors.primary
            : props.theme.colors.secondary,
      },
    },
  },

  ...props.ongoing && {
    border: '2px solid #EAF6F6',
    background: '#fff',
    boxShadow: 'none !important',
    cursor: 'not-allowed',
  },

  ...props.disabled && {
    background: 'rgb(193, 230, 229) none repeat scroll 0% 0%',
    boxShadow: 'none !important',
    cursor: 'not-allowed',
    color: 'rgb(255, 255, 255)',
  },
}));

export const Button = ({ children, ...props }) => (
  <ButtonContainer {...props}>
    {children}
  </ButtonContainer>
);

const PContainer = styled.p(props => ({
  color: props.color
    ? props.theme.colors?.[props.color]
    : props.customColor
      ? props.customColor
      : props.theme.colors.primary,

  ...props.margin && { margin: props.margin },
  ...props.position && { position: props.position },
  ...props.top && { top: props.top },
  ...props.right && { right: props.right },
  ...props.left && { left: props.left },
  ...props.width && { width: props.width },
  ...props.maxWidth && { maxWidth: props.maxWidth },
  ...props.minWidth && { minWidth: props.minWidth },
  ...props.cursor && { cursor: props.cursor },
  ...props.opacity && { opacity: props.opacity },
  ...props.textAlign && { textAlign: props.textAlign },
  ...props.cursor && { cursor: props.cursor },
  ...props.background && { background: props.background },
  ...props.border && { border: props.border },
  ...props.borderRadius && { borderRadius: props.borderRadius },
  ...props.padding && { padding: props.padding },
  ...props.textDecoration && { textDecoration: `${props.textDecoration} !important` },
  ...props.display && { display: props.display },
  ...props.align && { alignItems: props.align },

  fontFamily: 'DM Sans',

  fontSize:
    props.size ? props.theme?.functions?.toEm(props.size)
      : props.xxSmall ? props.theme?.functions?.toEm(10)
        : props.xSmall ? props.theme?.functions?.toEm(12)
          : props.small ? props.theme?.functions?.toEm(14)
            : props.medium ? props.theme?.functions?.toEm(18)
              : props.large ? props.theme?.functions?.toEm(20) : props.theme?.functions?.toEm(16),

  lineHeight:
    props.lineHeight ? props.lineHeight
      : props.xSmall ? '15px'
        : props.small ? '18px'
          : props.medium ? '23px'
            : props.large ? '26px' : '21px',

  fontWeight: props.bold ? '700' : '400',

  a: {
    width: '100%',
    height: '100%',
    color: props.color ? props.theme.colors?.[props.color] : props.theme.colors.primary,
    textDecoration: props.underlineLinks ? 'underline' : 'none',
  },
}));

export const P = ({ children, ...props }) => (
  <PContainer {...props}>
    {children}
  </PContainer>
);

const LabelContainer = styled.label(props => ({
  fontFamily: 'DM Sans',
  fontSize: props.theme?.functions?.toEm(10),
  lineHeight: '13px',
  color: props.color ? props.theme.colors?.[props.color] : props.theme.colors.primary,
  ...props.margin && { margin: props.margin },
}));

export const Label = ({ children, ...props }) => (
  <LabelContainer {...props}>
    {children}
  </LabelContainer>
);

const H1Container = styled.h1(props => ({
  ...props.textAlign && { textAlign: props.textAlign },
  ...props.margin && { margin: props.margin },
  color: props.color ? props.theme.colors?.[props.color] : props.theme.colors.primary,
  fontFamily: 'DM Sans',
  fontSize: props.theme?.functions?.toEm(48),
  lineHeight: '62px',
  fontWeight: '700',
  '@media (max-width: 1024px)': {
    fontSize: props.fontSize ? props.fontSize : props.theme?.functions?.toEm(38),
    lineHeight: props.lineHeight ? props.lineHeight : '45px',
  },
  '@media (max-width: 700px)': {
    fontSize: props.fontSize ? props.fontSize : props.theme?.functions?.toEm(28),
    ...props.lineHeight ? { lineHeight: props.lineHeight } : '',
  },
}));

export const H1 = ({ children, ...props }) => (
  <H1Container {...props}>
    {children}
  </H1Container>
);

const H2Container = styled.h2(props => ({
  ...props.textAlign && { textAlign: props.textAlign },
  ...props.margin && { margin: props.margin },
  color: props.color ? props.theme.colors?.[props.color] : props.theme.colors.primary,
  fontFamily: 'DM Sans',
  fontSize: props.fontSize ? props.fontSize : props.theme?.functions?.toEm(38),
  lineHeight: '49px',
  fontWeight: '700',
  '@media (max-width: 1024px)': {
    fontSize: props.fontSize ? props.fontSize : props.theme?.functions?.toEm(30),
    ...props.lineHeight ? { lineHeight: props.lineHeight } : '',
  },
  '@media (max-width: 390px)': {
    fontSize: props.fontSize ? props.fontSize : props.theme?.functions?.toEm(25),
    ...props.lineHeight ? { lineHeight: props.lineHeight } : '',
  },
}));

export const H2 = ({ children, ...props }) => (
  <H2Container {...props}>
    {children}
  </H2Container>
);

const H3Container = styled.h3(({
  theme, textAlign, margin, color, fontSize, lineHeight,
}) => ({
  ...textAlign && { textAlign },
  ...margin && { margin },
  color: color ? theme.colors?.[color] : theme.colors.primary,
  fontFamily: 'DM Sans',
  fontSize: fontSize ? theme?.functions?.toEm(fontSize) : theme?.functions?.toEm(28),
  lineHeight: lineHeight || '36px',
  fontWeight: '700',
  '@media (max-width: 1024px)': {
    fontSize: theme?.functions?.toEm(28),
  },
}));

export const H3 = ({ children, ...props }) => (
  <H3Container {...props}>
    {children}
  </H3Container>
);

const H4Container = styled.h4(props => ({
  ...props.textAlign && { textAlign: props.textAlign },
  ...props.margin && { margin: props.margin },
  ...props.maxWidth && { maxWidth: props.maxWidth },
  color: props.color ? props.theme.colors?.[props.color] : props.theme.colors.primary,
  fontFamily: 'DM Sans',
  fontSize: props.theme?.functions?.toEm(24),
  lineHeight: '31px',
  fontWeight: '700',
  '@media (max-width: 1024px)': {
    fontSize: props.theme?.functions?.toEm(24),
  },
  '@media (max-width: 700px)': {
    ...props.fontSize ? { fontSize: props.fontSize } : '',
    ...props.lineHeight ? { lineHeight: props.lineHeight } : '',
  },
}));

export const H4 = ({ children, ...props }) => (
  <H4Container {...props}>
    {children}
  </H4Container>
);

const H5Container = styled.h5(props => ({
  ...props.textAlign && { textAlign: props.textAlign },
  ...props.margin && { margin: props.margin },
  ...props.maxWidth && { maxWidth: props.maxWidth },
  color: props.color ? props.theme.colors?.[props.color] : props.theme.colors.primary,
  fontFamily: 'DM Sans',
  fontSize: props.theme?.functions?.toEm(20),
  lineHeight: '31px',
  fontWeight: '700',
  '@media (max-width: 1024px)': {
    fontSize: props.theme?.functions?.toEm(20),
  },
  '@media (max-width: 700px)': {
    ...props.fontSize ? { fontSize: props.fontSize } : '',
    ...props.lineHeight ? { lineHeight: props.lineHeight } : '',
  },
}));

export const H5 = ({ children, ...props }) => (
  <H5Container {...props}>
    {children}
  </H5Container>
);

const LinkContainer = styled(LinkWrapper)(props => ({
  ...props.margin && { margin: props.margin },
  ...props.width && {
    display: 'block',
    width: props.width,
  },

  ...props.inlineBlock && {
    display: 'inline-block',
    maxWidth: props.maxWidth,
  },

  ...props.textDecoration && {
    textDecoration: `${props.textDecoration} !important`,
    p: {
      textDecoration: `${props.textDecoration} !important`,
    },
  },
}));

const ExternalLinkContainer = styled.a(props => ({
  ...props.margin && { margin: props.margin },
  ...props.width && {
    display: 'block',
    width: props.width,
  },

  ...props.inlineBlock && {
    display: 'inline-block',
    maxWidth: props.maxWidth,
  },

  ...props.textDecoration && {
    textDecoration: `${props.textDecoration} !important`,
    p: {
      textDecoration: `${props.textDecoration} !important`,
    },
  },
}));

export const Link = ({
  children, to, external, ...props
}) => {
  const baseUrl = i18n.language === 'en' ? '' : `/${i18n.language}`;
  return external ? (
    <ExternalLinkContainer href={to} target="_blank" rel="noreferrer" {...props}>
      {children}
    </ExternalLinkContainer>
  ) : (
    <LinkContainer to={baseUrl + to} {...props}>
      {children}
    </LinkContainer>
  );
};

const AlertContainer = styled.div(props => ({
  display: 'flex',
  padding: props.theme.functions?.toEm(20),
  borderRadius: props.theme.functions?.toEm(10),
  background: props.background ? props.background : props.warn ? props.theme.colors?.warn : props.theme.colors?.success,
  ...props.margin && { margin: props.margin },

  a: {
    cursor: 'pointer',
    fontWeight: '700',
    textDecoration: 'none',
    marginLeft: 'auto',
  },
}));

export const Alert = ({ children, persist, ...props }) => {
  const [hide, setHide] = useState(false);

  return !hide && (
    <AlertContainer {...props}>
      {children}
      {!persist && (
        <P bold cursor="pointer" margin="0 0 0 auto" onClick={() => setHide(true)}>
          <Trans ns="common" i18nKey="dismiss">Dismiss</Trans>
        </P>
      )}
    </AlertContainer>
  );
};

export const Input = styled.input({
  position: 'relative',

  display: 'flex',
  alignItems: 'center',

  width: '100%',
  height: '50px',
  border: '1px solid #78ADAB',
  borderRadius: '4px',
  padding: '0 15px',
  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '18px',
  color: '#10242C',

  ':focus': {
    outline: 'none',
    outlineWidth: 0,
  },
  '::placeholder': {
    textOverflow: 'ellipsis',
    opacity: '100%',
    color: '#10242C',
  },
  '&:hover': {
    border: '1px solid hsl(0, 0%, 70%)',
  },
});
