import styled from '@emotion/styled';

export const JoinTitleStyle = styled.div`
  margin-bottom: ${({ theme }) => theme.functions?.toEm(80)};
  width: 100%;

  .wrapp_join {
    max-width: ${({ theme }) => theme.functions?.toEm(1234)};
    margin: 0 auto;
    ${'' /* font-size: 10px; */}

    @media screen and (max-width: 1200px) {
      font-size: 12px;
    }

    @media screen and (max-width: 768px) {
      font-size: 11px;
      .matchInfo {
        margin-top: 7px !important;
        flex-direction: column;
      }

      .compInfo {
        flex-wrap: wrap;
      }
    }
  }

  .joinTitle {
    display: flex;
    align-items: center;
    padding: 0 0 23px 0;

    ${(props) => props.theme.functions?.mediaQueryMax(
  '920px',
        `
        padding: 0 0 23px 40px;
      `,
)}
    ${(props) => props.theme.functions?.mediaQueryMax(
  '483px',
        `
        padding: 10px 20px;
        justify-content: start;
      `,
)}
        h1 {
      font-size: ${({ theme }) => theme.functions?.toEm(46)};
      line-height: 62px;

      @media screen and (max-width: 480px) {
        font-size: 28px;
        line-height: 40px;
      }
    }

    .imgwrapper {
      width: ${({ theme }) => theme.functions?.toEm(46)};
      height: ${({ theme }) => theme.functions?.toEm(40)};
    }
  }
`;

export const JoinRow = styled.div`
    .event-restrictions {
        list-style-type: none;
        display: inline;

        li {
            display: inline-flex;
            align-items: baseline;

            &:after {
                content: ",";
                margin-right: 5px;
            }

            &:first-of-type:after,
            &:last-of-type:after {
                display: none;
            }

            &:first-of-type {
                margin-right: 5px;
            }
        }
    }
}

.compInfo {
    margin-top: 6px !important;

    p {
        margin: 0;
    }

    .text {
        font-size: ${({ theme }) => theme.functions?.toEm(18)};
        line-height: 26px;
    }

    .dot {
        margin-left: ${({ theme }) => theme.functions?.toEm(10)};
        margin-right: ${({ theme }) => theme.functions?.toEm(10)};
    }
}

.wrappShopSummary {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1049px) {
        flex-direction: column;
        align-items: center;
        .right {
            max-width: ${({ theme }) => theme.functions?.toEm(900)} !important;
            margin-top: 30px;
            width: 100%;
            padding: 40px;

            .participationFeeWrapp {
                margin: 0 auto;
                max-width: 100%;
                width: 93%;
            }
        }
    }

    @media screen and (max-width: 479px) {
        padding: 0 20px;
    }


    .left {
        max-width: ${({ theme }) => theme.functions?.toEm(760)};
        width: 100%;
        padding: ${({ theme }) => theme?.functions?.toEm(18)};
        padding-bottom: ${({ theme }) => theme?.functions?.toEm(60)};
        border-radius: ${({ theme }) => theme?.functions?.toEm(10)};
        background: #FFFFFF;
        filter: ${({ featured }) => (featured ? 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.04))' : 'none')};
        border: 1px solid #EAF6F6;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.02);

        @media screen and (max-width: 1049px) {
            max-width: ${({ theme }) => theme.functions?.toEm(900)};
            .freePlayerPackage {
                max-width: 100%;
                width: 100%;
            }
        }

        @media screen and (max-width: 480px) {
            padding-bottom: 20px;
        }

        h2 {
            font-size: ${({ theme }) => theme?.functions?.toEm(28)};
            line-height: 36px;
        }

        .compInfo {
            display: flex;
            align-items: center;
            margin-top: 1px;
        }

        .matchInfo {
            margin-top: ${({ theme }) => theme.functions?.toEm(34)};
            display: flex;
            justify-content: space-between;

            p {
                margin: 0;
            }
        }
    }

    .right {
        max-width: ${({ theme }) => theme.functions?.toEm(444)};
        width: 100%;

        @media screen and (max-width: 480px) {
            padding: 0;
        }

        .premiumSale {
            margin-top: 9px;
        }

        .penaltyTitle {
            justify-content: space-between;
        }
    }

    ul.event-info {
        list-style: none;
        padding-left: 0;
        margin: 10px 0;

        li {
            font-size: ${({ theme }) => theme?.functions?.toEm(18)};
            display: inline-block;
            margin-right: 25px;
            text-transform: capitalize;
            margin-bottom: 3px;

            &:after {
                content: "•";
                position: absolute;
                padding: 0 10px;
            }

            &:last-child:after {
                display: none;
            }

            ul {
                display: inline-block;
                padding-left: 0;

                li {
                    font-size: inherit;
                    display: inline-block;
                    margin-right: 15px;
                    text-transform: capitalize;

                    &:after {
                        content: ", ";
                        position: absolute;
                        padding: 0 5px;
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    &:last-child:after {
                        display: none;
                    }
                }
            }
        }
    }
}`;

export const FreePlayerPackage = styled.div`
  .freePlayerPackage {
    max-width: ${({ theme }) => theme.functions?.toEm(660)};
    width: 100%;
    padding: ${({ theme }) => theme.functions?.toEm(20)}
      ${({ theme }) => theme.functions?.toEm(30)};
    border-radius: ${({ theme }) => theme?.functions?.toEm(10)};
    margin-top: ${({ theme }) => theme?.functions?.toEm(40)};
    background: rgba(255, 255, 255, 1);
    filter: ${({ featured }) => (featured ? 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.04))' : 'none')};
    border: 1px solid ${({ theme }) => theme?.colors?.lightBlue2};
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.02);

    .PlayerPackageTitle {
      margin-bottom: 13px;
    }

    p {
      margin: 0;
    }

    ul {
      padding-inline-start: 20px;
      margin: 10px 0 0 0;

      li {
        margin-top: 10px;

        p {
          font-size: ${({ theme }) => theme.functions?.toEm(16)};
        }
      }

      margin-bottom: 10px;
    }

    h3 {
      font-weight: normal;
      font-size: ${({ theme }) => theme.functions?.toEm(16)};
      margin-top: 14.5px;
    }

    .sizeWrapp {
      display: flex;
      align-items: center;
      margin-top: 10px;

      .sizeSelect {
        width: 80px;
        margin-left: 7px;
        border: 1px solid ${({ theme }) => theme?.colors?.primary};
        border-radius: 4px;

        span {
          display: none;
        }
      }
    }
  }
`;

export const EventShop = styled.div`
  width: ${({ theme }) => theme.functions?.toEm(660)};
  @media screen and (max-width: 1049px) {
    width: 100%;
  }

  h2 {
    margin-top: 43px;
  }
`;

export const Summary = styled.div`
  background-color: ${({ theme }) => theme?.colors?.lightBlue};
  width: 100%;
  border-radius: 10px;
  padding: ${({ theme }) => theme.functions?.toEm(30)};
  position: relative;

  > div:first-of-type {
    margin-bottom: 100px;
  }

  h2 {
    font-size: ${({ theme }) => theme.functions?.toEm(28)};
    line-height: 36px;
    margin-bottom: 25px;
  }

  div:not(:first-of-type, :last-of-type) {
    margin-top: 14px;
    display: flex;
    justify-content: space-between;

    p {
      margin: 0;

      span {
        color: ${({ theme }) => theme?.colors?.error};
        line-height: 15.63px;
        font-size: ${({ theme }) => theme.functions?.toEm(12)};
        cursor: pointer;
      }
    }
  }

  hr {
    border: 1px solid ${({ theme }) => theme?.colors?.disabledBlue};
    margin: ${({ theme }) => theme.functions?.toEm(22)} 0
      ${({ theme }) => theme.functions?.toEm(20)};
  }

  .shoppingBasket {
    height: auto;

    p {
      margin: 0;
    }

    hr {
      border: 1px solid ${({ theme }) => theme?.colors?.disabledBlue};
      margin-top: 26px;
      margin-bottom: 0px;
    }
  }

  .totalWrapp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px !important;
    margin-bottom: 116px !important;

    height: 43px;

    h2,
    h3,
    p {
      margin: 0;
      padding: 0;
    }

    div {
      display: flex;
      flex-direction: column;
      height: 43px;
      margin: 0px;
      justify-content: space-between;

      .addCoupon {
        text-decoration: underline;
        text-align: right;
        cursor: pointer;
      }
    }
  }

  .participationFeeWrapp {
    height: 104px;
    max-width: 420px;
    position: absolute;
    left: 12px;
    right: 12px;
    background: #ffffff;
    border-radius: 10px;
    padding: 20px 18px;
    margin-top: 30px;
    bottom: 12px;

    img {
      position: absolute;
      top: -18px;
      padding: 10px;
      background-color: ${({ theme }) => theme?.colors?.lightBlue2};
      border-radius: 50%;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
    }

    div {
      display: flex;
      justify-content: space-between;
    }
  }
`;

export const PayMethod = styled.div`
  .payMethod {
    border: 1px solid ${({ theme }) => theme?.colors?.lightBlue2};
    margin-top: 38px;
    border-radius: 10px;
    padding: 19px 30px 40px;

    @media screen and (max-width: 1049px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;

      .availableBalance {
        max-width: 360px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 14px 0 25px !important;
      }

      .rules {
        p {
          max-width: 360px;
        }
      }
    }
     {
      /*style dropdown for pm */
    }

    .selectedPlaceholder {
      @media screen and (max-width: 1200px) {
        max-width: 384px;
        width: 100%;
      }
    }

    .select-method {
      max-width: 384px;
      width: 100%;
      height: 50px;
      margin-top: 20px;

      .css-yk16xz-control {
        width: 384px;
        border: 2px solid ${({ theme }) => theme?.colors?.primary};
        border-radius: 4px;
        height: 50px;

        span {
          display: none;
        }

        @media screen and (max-width: 1200px) {
          max-width: 384px;
          width: 100%;
        }
      }

      .css-1pahdxg-control {
        border: 2px solid ${({ theme }) => theme?.colors?.primary};
        border-radius: 4px;
        height: 50px;

        span {
          display: none;
        }
      }
    }

    .availableBalance {
      display: flex;
      justify-content: space-between;
      margin: 14px 0 39px;

      .insufficientFunds {
        color: ${({ theme }) => theme?.colors?.error};

        span {
          margin-right: 10px;
        }
      }
    }

    .topUp {
      @media screen and (max-width: 1049px) {
        max-width: 384px;
        width: 100%;
      }

      p {
        text-align: center;
      }

      div {
        margin-top: 12px;
        display: flex;
        justify-content: space-between;

        button {
          width: 121px;
          height: 40px;
          margin-bottom: 0;
          border-radius: 4px;
          margin: 0;
          display: flex;
          border: 2px solid ${({ theme }) => theme?.colors?.primary};
          align-items: center;
          justify-content: center;
          font-weight: 700;
          font-size: ${({ theme }) => theme.functions?.toEm(14)};
          line-height: 18.23px;
          background-color: ${({ theme }) => theme?.colors?.white};
          color: ${({ theme }) => theme?.colors?.black};

          img {
            filter: invert(1) sepia(1) saturate(5) hue-rotate(175deg);
            width: 9.33px;
            height: 6.67px;
            object-fix: contain;
            margin-right: 10px;
          }
        }

        .selected {
          background-color: #10242c !important;
          color: ${({ theme }) => theme?.colors?.white} !important;
        }
      }
    }

    .payBtnWrapp {
      max-width: 384px;
      width: 100%;
      margin: 20px 0 28px 0;

      .css-17xznwu {
        position: static;
      }
    }

    .rules {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      p {
        text-align: center;
        margin: 0;
      }
    }

    .billing-data-selector-container {
        width: 100%;

        @media screen and (max-width: 479px) {
            > p.d-flex {
                flex-direction: column;
            }
        }
    }
  }

  .discount {
    color: ${({ theme }) => theme?.colors?.turquoise};
    display: flex;
    justify-content: center;
    margin-top: 20px;

    img {
      margin-right: 7px;
    }

    span {
      margin-left: 8px;
      font-weight: normal;
      font-size: ${({ theme }) => theme.functions?.toEm(12)};
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
`;

export const DiscountModalStyle = styled.div`
  padding: 25px 45px 35px 25px;

  h2 {
    font-size: ${({ theme }) => theme.functions?.toEm(28)};
    line-height: 36px;
    margin-bottom: 19px;
  }

  .discountContentWrapp {
    min-height: 182px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      margin: 0;
    }

    span {
      font-weight: bold;
    }
  }
`;
