export default {
  title: 'Játékos keresése',
  seoTitle: 'Játékosok | Sportya',
  playerProfileSeoTitle: '{{firstName}} {{lastName}} - Játékosok | Sportya',
  allPlayers_tennis: 'All Tennis Players',
  allPlayers_padel: 'All Padel Players',
  singleLevel: 'Egyéni szint',
  singleLevel_tennis: 'Egyéni szint',
  singleLevel_padel: 'Egyéni szint',
  doubleLevel: 'Páros szint',
  doubleLevel_tennis: 'Páros szint',
  doubleLevel_padel: 'Páros szint',
  playerHand: 'Játszó kéz',
  availabilityFriendly: 'Rendelkezésre állás barátságos mérkőzésekre',
  availabilityFriendlyNotSet: 'Rendelkezésre állás barátságos mérkőzésekre még nincs beállítva',
  activity: 'Tevékenység',
  titles: 'Címek',
  leaderboards: 'Ranglistahelyezések',
  levelNotSet: 'Szint nincs beállítva',
  singles: 'Egyéni',
  doubles: 'Páros',
  points: 'Pontok',
  stats: 'Statisztikák',
  pickYear: 'Év',
  allActivities: 'Minden tevékenység',
  level: 'Szint',
  round: 'Kör',
  oponent: 'Ellenfél',
  eloRating: 'ELO Ranglistahelyezés',
  leaderboard: 'Ranglistahelyezés',
  score: 'Eredmény',
  wL: 'Gy/V',
  winStatusShorthand: 'Gy',
  loseStatusShorthand: 'V',
  woStatusShorthand: 'Nincs',
  eloDescription: 'Ranglistahelyezés torna előtt: ',
  champion: 'Bajnok',
  finalist: 'Döntős',
  week: 'Hét',
  calculationDate: 'Számítás dátuma',
  rank: 'Helyezés',
  move: 'Változás',
  bestResults: 'Legjobb eredmények',
  pointsSummary: 'Pontösszefoglaló',
  playerRank: 'Játékos helyezése',
  totalPoints: 'Összes pontszám',
  competitionPoints: 'Versenyen szerzett pontok',
  friendlyMatchPoints: 'Barátságos mérkőzésen szerzett pontok',
  basePoints: 'Alappontok',
  penaltyPoints: 'Büntetőpontok',
  playerFound: '<0>{{ totalResults }} játékost</0> találtam a következő helyen: <2>{{ location }}</2>',
  playersFound: '<0>{{ totalResults }} játékosok</0> találtam a következő helyen: <2>{{ location }}</2>',
  playerFoundNc: '<0>{{ totalResults }} játékost</0> találtam',
  playersFoundNc: '<0>{{ totalResults }} játékosok</0> találtam',
  changePhoto: 'Fotó módosítása',
  setYourGameLevel: 'Játékszint beállítása',
  gameLevel: 'Játékszint ',
  setYourAvailability: 'Rendelkezésre állás beállítása',
  availabilityForFriendlyMatches: 'Rendelkezésre állás barátságos mérkőzésekre',
  sendMessage: 'Send Message',
  myEvents: 'Eseményeim',
  mySettings: 'Beállításaim',
  newestPlayers_tennis: 'Legújabb játékosok',
  newestPlayers_padel: 'Legújabb játékosok',
  player: 'Játékos',
  friendlyMatches: 'Barátságos mérkőzések',
  registrationDate: 'Regisztráció dátuma',
  findAPlayer_tennis: 'Játékos keresése',
  findAPlayer_padel: 'Játékos keresése',
  joined: 'Csatlakozott',
  upgradedAt: 'Szint módosítva a következőre:',
  best7results: 'Legjobb 10 eredmény',
};
