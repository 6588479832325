import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { useToasts } from 'react-toast-notifications';
import { Form, Formik } from 'formik';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';

import { useHistory } from 'react-router-dom';
import {
  Alert,
  H1,
  H2,
  H3,
  H4,
  Paragraph,
} from '../../../components';
import Product from './components/Product';
import Modal from '../../../components/Modal';
import Pagination from '../../../components/Pagination';
import LoadImage from '../../../components/common/LoadImage';
import ContentLoader from '../../../components/ContentLoader';
import { store as socketStore } from '../../../components/Socket/store';
import FormButton from '../../../components/Form/Button';
import Radio from '../../../components/Form/RadioCheck';
import LoadingSpinner from '../../../components/LoadingSpinner';
import Button from '../../../components/Button';
// import getCountryIsoCode from "../../../components/common/getCountryIsoCode";

import {
  Container, VirtualWallet, WalletTransactions, ModalStyle, StripeModal, PriceWrap,
} from './styles';

import { fetchTransactions } from './reducers';
import { authorizePayment } from './actions';
import BillingDataSelector from '../../../components/BillingDataSelector';

const Wallet = () => {
  const history = useHistory();
  const { accountInfo } = useSelector(state => state.session);
  const { data, status } = useSelector(state => state.dashboard?.transactions);
  const { data: { topUpOptions } } = useSelector(state => state.settings);
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { t: commonT } = useTranslation('common');
  const { t } = useTranslation('accounts');

  const globalState = useContext(socketStore);
  const { state: { socket: { socketClientId, socket } } } = globalState;

  const [modal, setModal] = useState(false);
  const [redirectModal, setRedirectModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [billingAs, setBillingAs] = useState();

  const availableTopUpOptions = topUpOptions
    ?.filter(option => Object.keys(option.amount).includes(accountInfo?.currency))
    .map(({ id, amount }) => ({
      id,
      amount: amount[accountInfo?.currency],
      currency: accountInfo?.currency,
    }));

  // const { vat } = getCountryIsoCode(accountInfo?.location?.country);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchTransactions());
    }
  }, [status, dispatch]);

  useEffect(() => {
    const listenForResponse = async ({ success, data: responseData }) => {
      if (success) {
        window.location.replace(responseData.url);
      }

      setRedirectModal(false);
      addToast(commonT('COULD_NOT_AUTHORIZE_PAYMENT'), {
        appearance: 'error',
        autoDismiss: true,
      });
    };

    if (socket) {
      socket.removeAllListeners(
        'payments-response',
      );
      socket.on(
        'payments-response',
        listenForResponse,
      );

      return () => {
        socket.removeAllListeners(
          'payments-response',
        );
      };
    }
  }, [socketClientId]);

  const modalHandler = () => {
    setModal(!modal);
  };

  const handlePageClick = (e) => {
    const page = e.selected + 1;

    if (currentPage !== e.selected) {
      dispatch(fetchTransactions({ page }));
      setCurrentPage(e.selected);
    }
  };

  const formSchema = Yup.object().shape({
    amount: Yup.number().required(commonT('required')),
  });

  const handleSubmit = async ({ amount, method, paymentType }, { setSubmitting }) => {
    setModal(false);
    setRedirectModal(true);

    const [{ id }] = availableTopUpOptions?.filter(option => option.amount === Number(amount));
    const payload = {
      productId: id,
      method,
      paymentType,
      products: [],
      billingAs,
    };
    const product = {
      id,
      name: commonT('virtualWalletTopUp', { amount, currency: accountInfo?.currency }),
      price: Number(amount),
      quantity: 1,
      type: method,
    };
    payload.products.push(product);

    await authorizePayment({
      payload,
      addToast,
      setSubmitting,
      socketClientId,
      history,
      t: commonT,
    });
  };

  const validBillingData = (
    (billingAs === 'company'
      && !isEmpty(accountInfo?.billingData?.[billingAs]?.address)
      && !isEmpty(accountInfo?.billingData?.[billingAs]?.cui)
      && !isEmpty(accountInfo?.billingData?.[billingAs]?.companyName)
    ) || (billingAs === 'individual'
      && !isEmpty(accountInfo?.billingData?.[billingAs]?.fullName)
      && !isEmpty(accountInfo?.billingData?.[billingAs]?.address)
    )
  );

  return (
    <Container>
      <Helmet>
        <title>{t('wallet.seoTitle')}</title>
      </Helmet>
      {!accountInfo?.currency
        && (
          <Alert warn>
            <Trans ns="accounts" i18nKey="wallet.currencyNotSet">
              You must set up your location in order to use Top-Up feature.
            </Trans>
          </Alert>
        )}
      <VirtualWallet
        availableFunds={accountInfo?.walletBalance > 0}
        unavailableFunds={accountInfo?.walletBalance === 0}
        negativeFunds={accountInfo?.walletBalance < 0}
      >
        <div className="walletWrapp">
          <div className="walletHeader">
            <H1 className="walletTitle"><Trans ns="accounts" i18nKey="wallet.virtualWallet">Virtual Wallet</Trans></H1>
            <Paragraph bold small>
              <Trans ns="accounts" i18nKey="wallet.availableFunds">Available Funds</Trans>
            </Paragraph>
            <H1 className="walletSum">
              {accountInfo?.walletBalance.toFixed(2) || 0}
              {' '}
              {accountInfo?.currency || 'RON'}
            </H1>
            <Button
              className="topUpBtn"
              outline
              large
              fullwidth
              onClick={modalHandler}
              disabled={!accountInfo?.currency}
            >
              <span>
                <img
                  src={LoadImage(
                    accountInfo?.walletBalance > 0
                      ? 'wallet.svg' : (accountInfo?.walletBalance === 0
                        ? 'walletUnavailable.svg' : 'walletNegative.svg'),
                  )}
                  alt=""
                />
                <Trans ns="accounts" i18nKey="wallet.topUp">Top-Up</Trans>
              </span>
            </Button>
          </div>

          <Paragraph medium className="walletInfo">
            <Trans ns="accounts" i18nKey="wallet.walletInfo">
              The Sportya Virtual Wallet is a payment system that allows you to quickly register for events and
              pay for other Sportya services. You can top up your wallet and later use it as a payment option,
              throughout Sportya. Payments are validated without delay. We do not store your payment / card data.
            </Trans>
          </Paragraph>

          <WalletTransactions>
            {status === 'loading' ? (
              <ContentLoader title type="transactions" items={5} />
            ) : (
              <>
                {!isEmpty(data?.results) && status !== 'loading' ? (
                  <>
                    <H3><Trans ns="accounts" i18nKey="wallet.latestTransactions">Latest Transactions</Trans></H3>
                    <div className="walletTransactions">
                      <div className="transactionId">
                        <Paragraph smaller>
                          <Trans ns="accounts" i18nKey="wallet.transactionId">Transaction ID</Trans>
                        </Paragraph>
                      </div>
                      <div className="transactionDate">
                        <Paragraph smaller><Trans ns="accounts" i18nKey="wallet.date">Date</Trans></Paragraph>
                      </div>
                      <div className="transactionProduct">
                        <Paragraph smaller><Trans ns="accounts" i18nKey="wallet.product">Product</Trans></Paragraph>
                      </div>
                      <div className="transactionAmount">
                        <Paragraph smaller><Trans ns="accounts" i18nKey="wallet.amount">Amount</Trans></Paragraph>
                      </div>
                    </div>

                    <div className="transactionsWrapp">
                      {data?.results.map((transaction, idx) => (
                        <Product
                          key={idx}
                          transaction={transaction}
                        />
                      ))}
                    </div>
                  </>
                ) : (
                  <div className="noTransactionsWrapp">
                    <img src={LoadImage('file-icon.svg')} alt="data-icon" />
                    <H4><Trans ns="accounts" i18nKey="wallet.noTransactions">No Transactions</Trans></H4>
                  </div>
                )}
              </>
            )}
          </WalletTransactions>

          {modal && (
            <Modal isOpen={modal} onRequestClose={modalHandler}>
              <ModalStyle>
                <H2><Trans ns="accounts" i18nKey="wallet.topUp">Top-Up</Trans></H2>
                <Paragraph className="subTitle">
                  <Trans ns="accounts" i18nKey="wallet.chooseAmountToTopUp">Choose the amount you want to top-up</Trans>
                </Paragraph>

                <Formik
                  initialValues={{
                    method: 'topUp',
                    paymentType: 'creditCard',
                    amount: '',
                  }}
                  validationSchema={formSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    values, isSubmitting, isValid, dirty,
                  }) => (
                    <Form>
                      {availableTopUpOptions?.map((option, key) => (
                        <PriceWrap key={key} checked={Number(values.amount) === option.amount}>
                          <Radio
                            key={key}
                            name="amount"
                            label={`${option.amount} ${accountInfo?.currency}`}
                            value={option.amount}
                            checkmark
                            icon={LoadImage('checkmark-arrow.svg')}
                          />
                        </PriceWrap>
                      ))}

                      {values?.amount && (
                        <BillingDataSelector billingAs={billingAs} setBillingAs={setBillingAs} />
                      )}

                      <FormButton
                        type="submit"
                        large
                        fullwidth
                        disabled={isSubmitting || !(isValid && dirty) || !validBillingData}
                      >
                        <Trans ns="accounts" i18nKey="wallet.confirmAndPay">Confirm &amp; Pay</Trans>
                      </FormButton>
                    </Form>
                  )}
                </Formik>
                <div className="stripe-logo">
                  <img src={LoadImage('powered-by-stripe.svg')} alt="Stripe" />
                </div>
              </ModalStyle>
            </Modal>
          )}

          {redirectModal && (
            <Modal isOpen={redirectModal} onRequestClose={modalHandler}>
              <StripeModal>
                <img src={LoadImage('stripe-logo.svg')} alt="Stripe" />
                <Paragraph>
                  <Trans ns="accounts" i18nKey="wallet.stripeRedirect">Redirecting to Stripe...</Trans>
                </Paragraph>
                <LoadingSpinner className="loader" />
              </StripeModal>
            </Modal>
          )}
        </div>
      </VirtualWallet>
      {data?.totalPages > 1
        && (
          <Pagination
            pageCount={data?.totalPages || 0}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            initialPage={currentPage}
          />
        )}
    </Container>
  );
};

export default Wallet;
