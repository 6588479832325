import styled from '@emotion/styled';
import { Link } from '../../../Collection';

export const Container = styled.footer(props => ({
  display: 'flex',
  height: '100%',
  margin: 'auto 0 0 0',
  padding: props.LoggedIn ? '75px 40px 25px' : '83px 0 65px',
  ...props.blur && { filter: 'blur(30px)' },
  ...props.transparent ? {
    p: {
      color: '#fff !important',
    },
    background: 'unset',
    border: 0,
  } : props.LoggedIn ? {
    p: {
      color: '#10242C !important',
    },
    borderTop: '1px solid #EAF6F6',
    background: '#fff',
  } : {
    p: {
      color: '#fff !important',
    },
    alignItems: 'center',
    justifyContent: 'center',
    background: '#10242C',
  },

  a: {
    textDecoration: 'none !important',
  },

  '@media (max-width: 840px)': {
    padding: '23px 0 65px',
    ...props.LoggedIn && {
      padding: '23px 0 25px',
    },
  },
}));

export const Content = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  ...!props.LoggedIn && {
    maxWidth: '1234px',
  },
  width: '100%',
  '@media (max-width: 1250px)': {
    margin: '0 20px',
  },
}));

export const MediaContainer = styled.div({
  display: 'flex',
  a: {
    display: 'flex',
    marginRight: '32px',
    '&:last-of-type': {
      margin: 'unset',
    },
    '@media (max-width: 600px)': {
      margin: '10px 0',
      '&:last-of-type': {
        margin: '10px 0',
      },
    },
  },
});

export const MediaLink = styled(Link)({
  display: 'flex',
});

export const MediaIcon = styled.img(props => ({
  width: '19px',
  height: '19px',
  ...props.LoggedIn && !props.white && {
    filter: 'brightness(0%)',
  },
}));

export const MenuContainer = styled.div(props => ({
  display: 'flex',
  width: props.LoggedIn ? '60%' : 'max-content',
  justifyContent: 'space-between',
  '.menu': {
    marginRight: '68px',
    '&:last-of-type': {
      margin: 'unset',
    },
    a: {
      marginBottom: props.LoggedIn ? '20px' : '30px',
      '&:last-of-type': {
        margin: 'unset',
      },
    },
  },
  '@media (max-width: 1100px)': {
    marginTop: '20px',
    flexDirection: 'column',
    '.menu': {
      textAlign: 'center',
      margin: '5px auto',
      '&:last-of-type': {
        margin: '10px auto',
      },
      a: {
        marginBottom: '20px',
        '&:last-of-type': {
          marginBottom: '0px',
        },
      },
    },
  },
  ...props.LoggedIn && {
    '@media (max-width: 1350px)': {
      width: 'max-content',
      marginBottom: '30px',
      '.menu': {
        marginRight: '30px',
        '&:last-of-type': {
          margin: 'unset',
        },
      },
      '@media (max-width: 1100px)': {
        marginTop: '20px',
        flexDirection: 'column',
        '.menu': {
          textAlign: 'center',
          margin: '10px auto',
          '&:last-of-type': {
            margin: '10px auto',
          },
          a: {
            marginBottom: '20px',
            '&:last-of-type': {
              marginBottom: '0px',
            },
          },
        },
      },
    },
  },
}));

export const Second = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '@media (max-width: 840px)': {
    flexDirection: 'column',
    '> div': {
      flexDirection: 'column',
      a: {
        margin: '10px auto',
      },
    },
  },
});

export const OSBanner = styled.div({
  display: 'flex',
  height: '120px',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 0 60px 0',
  '@media (max-width: 900px)': {
    flexDirection: 'column',
  },
});

export const CallToAction = styled.div({
  display: 'flex',
  height: '175px',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '10px',
  border: '1px solid #153A39',
  margin: '143px 0 108px',
  h2: {
    color: '#fff !important',
  },
  '@media (max-width: 1100px)': {
    margin: '40px 10px 20px',
    flexDirection: 'column',
    h2: {
      textAlign: 'center',
    },
    button: {
      margin: '10px 0',
    },
  },
});

export const MPGLogo = styled.img({
  width: '160px',
  height: '68px',
  '@media (max-width: 840px)': {
    marginBottom: '20px',
  },
});

export const Capsule = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '37px',
  padding: '0 20px',
  background: '#0C1F26',
  borderRadius: '30px',
  transition: 'all .5s ease-out',
  '&:hover': {
    background: '#19323CCC',
  },
});

export const Third = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '@media (max-width: 840px)': {
    flexDirection: 'column',
    p: {
      order: 2,
      margin: '15px 0 15px',
    },
  },
});

export const Policies = styled.div(props => ({
  display: 'flex',
  alignItems: 'center',
  a: {
    marginRight: '40px',
    '&:last-of-type': {
      marginRight: '25px',
    },
  },
  '.form-group': {
    margin: 'unset',
    width: '146px',
    height: '34px',
  },
  '.language-selector': {
    ...props.LoggedIn && {
      // margin: '15px 0 5px 0',
    },
    '[class*="-container"]': {
      width: '146px',
      height: '34px',
    },
    '[class*="-ValueContainer"]': {
      height: '18px',
      marginBottom: '4px',
    },
    '[class*="-control"]': {
      padding: '0 12px',
      background: '#fff0',
      border: `2px solid ${props.LoggedIn && !props.white ? '#000' : '#F5F5F514'}`,
      borderRadius: '4px',
      height: '34px',
    },
    '[class*="-singleValue"]': {
      color: props.LoggedIn && !props.white ? '#000' : '#fff',
    },
    '[class*="-placeholder"]': {
      fontFamily: 'DM Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '18px',
      color: props.LoggedIn && !props.white ? '#000' : '#fff',
    },
    '[class*="-indicatorContainer"]': {
      padding: '0 5px 2px',
      svg: {
        path: {
          fill: props.LoggedIn && !props.white ? '#000' : '#fff',
        },
      },
    },
    '[class*="menu"]': {
      top: 'auto',
      bottom: 35,
      color: props.LoggedIn ? '#000' : '#fff',
      background: props.LoggedIn ? '#fff' : props.theme.colors?.darkCyan,
      border: `1px solid ${props.LoggedIn ? '#000' : '#263840'}`,
    },
  },
  '@media (max-width: 840px)': {
    flexDirection: 'column',
    a: {
      margin: 'unset',
      '&:last-of-type': {
        margin: 'unset',
      },
    },
  },
}));

export const First = styled.div(props => ({
  display: 'flex',
  justifyContent: 'space-between',

  ...props.LoggedIn && {
    width: '100%',
    margin: '0 0 20px 0',
    '.media > div': {
      marginBottom: '15px',
    },
  },

  '@media (max-width: 1100px)': {
    alignItems: 'center',
    flexDirection: 'column',
    margin: '0 auto',
    '.media': {
      textAlign: 'center',
    },
    div: {
      justifyContent: 'center',
    },
    '@media (max-width: 600px)': {
      div: {
        flexDirection: 'column',
        alignItems: 'center',

        '> p': {
          display: 'none',
        },

        '.contact': {
          marginTop: '20px',
          p: {
            margin: 'unset',
            '&:first-of-type': {
              marginBottom: '10px',
            },
          },
        },
      },
    },
  },
}));
