import { Trans, useTranslation } from 'react-i18next';
import dateFormat from 'dateformat';
import { isEmpty } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useSelector } from 'react-redux';

import { bookCourt } from '../../../actions';

import { ModalContainer } from '../bookCourtModal/styles';

import Modal from '../../../../../components/Modal';
import LoadImage from '../../../../../components/common/LoadImage';
import Paragraph from '../../../../../components/Paragraph';
import Button from '../../../../../components/Button';
import LinkWrapper from '../../../../../components/Link';
import LoadingSpinner from '../../../../../components/LoadingSpinner';
import UserAvatar from '../../../../../components/UserAvatar';
import { store as socketStore } from '../../../../../components/Socket/store';
import BillingDataSelector from '../../../../../components/BillingDataSelector';

const checkoutModal = ({
  payload,
  clubInfo,
  courtInfo,
  setCheckout,
  onRequestClose,
  pricing,
}) => {
  const { accountInfo } = useSelector((state) => state?.session);
  const { t } = useTranslation(['clubs', 'common']);
  const { addToast } = useToasts();
  const globalState = useContext(socketStore);
  const { state: { socket: { socket } = {} } = {} } = globalState;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [billingAs, setBillingAs] = useState();

  const {
    player = {},
    playerPartner = {},
    opponent = {},
    opponentPartner = {},
  } = payload.friendlyMatch;

  useEffect(() => {
    const listenForResponse = async ({ success, message }) => {
      addToast(t(message), {
        appearance: success ? 'success' : 'error',
        autoDismiss: true,
        maxOpened: 1,
      });
      setIsSubmitting(false);
    };

    if (socket) {
      socket.removeAllListeners('clubs-response');
      socket.on('clubs-response', listenForResponse);
      return () => socket.removeAllListeners('clubs-response');
    }
  }, [socket]);

  useEffect(() => {
    const listenForResponse = async ({ success, message, data }) => {
      if (success) {
        if (data.url) {
          window.location.replace(data.url);
        }
      } else {
        addToast(t(message), {
          appearance: 'error',
          autoDismiss: true,
        });
        setIsSubmitting(false);
      }
    };

    if (socket) {
      socket.removeAllListeners('payments-response');
      socket.on('payments-response', listenForResponse);
      return () => socket.removeAllListeners('payments-response');
    }
  }, [socket]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    Object.assign(payload, { billingAs });
    await bookCourt({
      payload,
      addToast,
      t,
      setIsSubmitting,
    });
  };

  const validBillingData = (
    (billingAs === 'company'
      && !isEmpty(accountInfo?.billingData?.[billingAs]?.address)
      && !isEmpty(accountInfo?.billingData?.[billingAs]?.cui)
      && !isEmpty(accountInfo?.billingData?.[billingAs]?.companyName)
    ) || (billingAs === 'individual'
      && !isEmpty(accountInfo?.billingData?.[billingAs]?.fullName)
      && !isEmpty(accountInfo?.billingData?.[billingAs]?.address)
    )
  );

  return (
    <Modal
      isOpen
      onRequestClose={!isSubmitting ? onRequestClose : () => {}}
      style={{
        maxWidth: 500,
      }}
      showCloseIcon={!isSubmitting}
      mobileFullDisplay
    >
      <ModalContainer>
        <div className="modal-header">
          <span onClick={() => setCheckout(false)} className="cursor-pointer">
            <img src={LoadImage('arrow-left.svg')} alt="" />
          </span>
        </div>
        <div className="modal-content">
          <Paragraph medium bold>
            <Trans ns="clubs" i18nKey="checkout">
              Checkout
            </Trans>
          </Paragraph>
          <Paragraph className="mb20">
            {clubInfo.clubName} - {clubInfo?.location?.name}
          </Paragraph>

          <ul className="overview">
            <li>
              <Paragraph>
                <Trans ns="clubs" i18nKey="start">
                  Start
                </Trans>
              </Paragraph>
              <Paragraph>
                {dateFormat(
                  `${payload.startDate} ${payload.startHour}`,
                  'dd mmmm (ddd), HH:MM',
                )}
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <Trans ns="clubs" i18nKey="duration">
                  Duration
                </Trans>
              </Paragraph>
              <Paragraph>
                <Trans ns="clubs" i18nKey="durationMin">
                  {{ duration: payload.duration }} min
                </Trans>
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <Trans ns="clubs" i18nKey="court">
                  Court
                </Trans>
              </Paragraph>
              <Paragraph>{courtInfo.name}</Paragraph>
            </li>
            <li>
              <Paragraph>
                <Trans ns="clubs" i18nKey="sportType">Sport Type</Trans>
              </Paragraph>
              <Paragraph>
                <Trans ns="common" i18nKey={`sports.${courtInfo.sportType}`}>{courtInfo.sportType}</Trans>
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <Trans ns="clubs" i18nKey="surface">
                  Surface
                </Trans>
              </Paragraph>
              <Paragraph>
                <Trans ns="clubs" i18nKey={`surfaceType.${courtInfo.surface}`}>
                  {courtInfo.surface}
                </Trans>
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <Trans ns="clubs" i18nKey="courtType">
                  Court Type
                </Trans>
              </Paragraph>
              <Paragraph>
                <Trans ns="clubs" i18nKey={`courtTypes.${courtInfo.type}`}>
                  {courtInfo.type}
                </Trans>
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <Trans ns="clubs" i18nKey="gameType">
                  Game Type
                </Trans>
              </Paragraph>
              <Paragraph>
                <Trans ns="clubs" i18nKey={`courtSizes.${courtInfo.size}`}>
                  {courtInfo.size}
                </Trans>
                {!isEmpty(payload.friendlyMatch) && (
                  <span>
                    ,{' '}
                    <Trans ns="clubs" i18nKey="friendlyMatch">
                      Friendly Match
                    </Trans>
                  </span>
                )}
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <Trans ns="clubs" i18nKey="selfService">
                  Self-Service
                </Trans>
              </Paragraph>
              <Paragraph>
                <Trans
                  ns="common"
                  i18nKey={clubInfo?.bookingSettings?.selfService ? 'yes' : 'no'}
                >
                  {clubInfo?.bookingSettings?.selfService ? 'Yes' : 'No'}
                </Trans>
              </Paragraph>
            </li>
            {courtInfo.hasLighting && (
              <li>
                <Paragraph>
                  <Trans ns="clubs" i18nKey="additionalInfo">
                    Additional Info
                  </Trans>
                </Paragraph>
                <Paragraph className="text-capitalize">
                  <Trans ns="common" i18nKey="withLighting">
                    With Lighting
                  </Trans>
                </Paragraph>
              </li>
            )}
            {!isEmpty(payload.friendlyMatch) && (
              <>
                <li>
                  <Paragraph>
                    <Trans ns="clubs" i18nKey="friendlyMatch">
                      Friendly Match
                    </Trans>
                  </Paragraph>
                </li>
                <li className="match-players">
                  <ul>
                    <li>
                      <UserAvatar user={player} size={38} />
                    </li>
                    {!isEmpty(playerPartner) && (
                      <li>
                        <UserAvatar user={playerPartner} size={38} />
                      </li>
                    )}
                    <li>
                      <UserAvatar user={opponent} size={38} />
                    </li>
                    {!isEmpty(opponentPartner) && (
                      <li>
                        <UserAvatar user={opponentPartner} size={38} />
                      </li>
                    )}
                  </ul>
                  <Paragraph
                    className="link"
                    onClick={() => setCheckout(false)}
                  >
                    <Trans ns="clubs" i18nKey="changePlayer">
                      Change Player
                    </Trans>
                  </Paragraph>
                </li>
              </>
            )}
            <li>
              <Paragraph>
                <Trans ns="clubs" i18nKey="totalToBePaid">
                  Total to be paid (incl. VAT)
                </Trans>
              </Paragraph>
              <Paragraph bold>
                {pricing.bookingDuration[payload.duration].price}{' '}
                {clubInfo?.currency}
              </Paragraph>
            </li>
            <li className="flex-column">
              <BillingDataSelector billingAs={billingAs} setBillingAs={setBillingAs} />
            </li>
          </ul>
        </div>

        <div className="modal-footer mt10 text-center">
          <Button
            fullwidth
            onClick={handleSubmit}
            disabled={isEmpty(payload) || isSubmitting || !validBillingData}
          >
            {isSubmitting && <LoadingSpinner className="white-dots" />}
            {!isSubmitting && (
              <span>
                <Trans ns="clubs" i18nKey="payWithCard">
                  Pay with Credit Card
                </Trans>
              </span>
            )}
          </Button>

          <div className="mt10">
            <img src={LoadImage('powered-by-stripe.svg')} alt="Stripe" />
          </div>

          <Paragraph smaller className="mt10">
            <Trans ns="clubs" i18nKey="agreeBookingTos">
              By continuing the booking, I confirm that I agree with the
              <LinkWrapper
                to="/terms-conditions"
                target="_blank"
                className="text-underline"
              >
                Terms and Conditions
              </LinkWrapper>{' '}
              and
              <LinkWrapper
                to="/return-policy"
                target="_blank"
                className="text-underline"
              >
                Return Policy
              </LinkWrapper>
              .
            </Trans>
          </Paragraph>
        </div>
      </ModalContainer>
    </Modal>
  );
};

export default checkoutModal;
