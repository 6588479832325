import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Range } from 'react-range';

import P from '../../../../components/Paragraph';
import Checkbox from '../../../../components/Form/VanillaCheckbox';
import CheckboxHandler from '../../../../components/CheckboxHandler';
import Radio from '../../../../components/Form/VanillaRadio';
import Button from '../../../../components/Form/Button';

const AdvancedFilters = ({
  handleModalFilters,
  toggle,
  filters,
  setFilters,
  handlePagination,
  dispatchFilters,
}) => {
  const { t } = useTranslation(['events', 'clubs']);
  const { t: commonT } = useTranslation('common');
  const {
    data: {
      playModes,
      genders,
      categories,
    },
  } = useSelector(state => state.settings);

  const [ageRadioState, setAgeRadioState] = useState(filters.minAge ? 'custom' : 'all');
  const [rangeSliderValues, setRangeSliderValues] = useState([filters.minAge ?? 18, filters.maxAge ?? 65]);
  const [form, setForm] = useState({});

  const ageSelectors = [
    { value: 'all', label: commonT('allAges') },
    { value: 'custom', label: commonT('customAge') },
  ];

  useEffect(() => {
    if (ageRadioState === 'custom') {
      const [minAge, maxAge] = rangeSliderValues;
      Object.assign(form, {
        minAge,
        maxAge,
      });
    }
  }, [rangeSliderValues]);

  const resetModalFilters = () => {
    const newFilters = {
      ...filters,
    };

    delete newFilters.gameType;
    delete newFilters.levelSingles;
    delete newFilters.levelDoubles;
    delete newFilters.eventStatus;
    delete newFilters.gender;
    delete newFilters.minAge;
    delete newFilters.maxAge;
    delete newFilters.playMode;
    delete newFilters.category;
    delete newFilters.endedEvents;
    delete newFilters.availableEntries;

    handlePagination({ selected: 0 });
    setFilters(newFilters);
    dispatchFilters(newFilters);
    toggle();
  };

  const handleChangeAge = (event) => {
    setAgeRadioState(event.target.value);
    switch (event.target.value) {
    case 'all':
      delete form.minAge;
      delete form.maxAge;
      // eslint-disable-next-line no-param-reassign
      delete filters.minAge;
      // eslint-disable-next-line no-param-reassign
      delete filters.maxAge;
      setAgeRadioState(event.target.value);
      break;

    case 'custom':
      setAgeRadioState(event.target.value);
      break;
    default:
    }
  };

  return (
    <>
      <div className="form-content">
        {/* {!isEmpty(gameTypes) &&
          <div className="form-group">
            <P large bold className="mb20">
              {t('gameTypeText')}
            </P>
            <div className="row">
              {gameTypes.map((selector, key) => (
                <div key={key} className="col-sm-4">
                  <Checkbox
                    name='gameType'
                    handler={() => setForm(CheckboxHandler('gameType', selector.key, form, filters, true))}
                    {...(filters?.gameType?.split(',').includes(selector.key)) && {defaultChecked: true}}
                    checked={form['gameType']?.split(',').includes(selector.key)}
                    label={t(`${selector.key`})}
                  />
              </div>
              ))}
            </div>
          </div>
          } */}
        <div className="hidden-desktop">
          <P large bold className="mb20 mt30">
            {t('events', { ns: 'clubs' })}
          </P>
          <div className="row mb20 bd-bottom">
            <div className="col-sm-6">
              <Radio
                name="eventsType"
                onChange={() => {
                  setForm(CheckboxHandler('eventsType', "allActive", form, filters, true));
                }}
                {...(!(filters.availableEntries || filters.endedEvents) || filters?.allActive) && {
                  defaultChecked: true,
                }}
                label={t('allActive')}
              />
            </div>
            <div className="col-sm-6">
              <Radio
                name="eventsType"
                onChange={() => {
                  setForm(CheckboxHandler('eventsType', "availableEntries", form, filters, true));
                }}
                {...(filters?.availableEntries) && { defaultChecked: true }}
                label={t('availableEntries')}
              />
            </div>
            <div className="col-sm-6">
              <Radio
                name="eventsType"
                onChange={() => setForm(CheckboxHandler('eventsType', "endedEvents", form, filters, true))}
                {...(filters?.endedEvents) && { defaultChecked: true }}
                label={t('showEndedEvents')}
              />
            </div>
          </div>
        </div>

        {!isEmpty(categories)
          ? (
            <>
              <P large bold className="mb20 mt30">
                {commonT('allCategories')}
              </P>
              <div className="row mb20 bd-bottom">
                <div className="col-sm-6">
                  <Radio
                    name="category"
                    onChange={() => setForm(CheckboxHandler('category', 'all', form, filters, true))}
                    {...(!filters?.category) && { defaultChecked: true }}
                    label={commonT('all')}
                  />
                </div>
                {categories.map((selector, id) => (
                  <div key={id} className="col-sm-6">
                    <Radio
                      name="category"
                      onChange={() => setForm(CheckboxHandler('category', selector.key, form, filters, true))}
                      {...(filters?.category?.includes(selector.key)) && { defaultChecked: true }}
                      label={commonT(selector.key)}
                    />
                  </div>
                ))}
              </div>

              {/* <P smaller bold className="mb20">
              {t('doubles')}
            </P>
            <div className="row">
              {levels.map((selector, id) => (
                <div key={id} className="col-sm-4">
                  <Checkbox
                    name='levelDoubles'
                    handler={() => setForm(CheckboxHandler('levelDoubles', selector.key, form, filters))}
                    {...(filters?.levelDoubles?.includes(selector.key)) && {defaultChecked: true}}
                    label={`${commonT('level')} ${selector.key}`}
                  />
                </div>
              ))}
            </div> */}
            </>
          )
          : ''}

        {/* {!isEmpty(eventPhases) &&
          <div className="form-group">
            <P large bold className="mb20">
              {t('eventStatus')}
            </P>
            <div className="row">
              {eventPhases.map((selector, key) => (
                <div key={key} className="col-sm-4">
                  <Checkbox
                    name={`eventStatus`}
                    handler={() => setForm(CheckboxHandler('eventStatus', selector.key, form, filters))}
                    checked={filters?.eventStatus?.split(',').includes(selector.key)}
                    label={t(`${selector.key`})}
                  />
                </div>
              ))}
            </div>
          </div>
          } */}

        {!isEmpty(genders)
          && (
            <div className="form-group bd-bottom">
              <P large bold className="mb20 mt30">
                {t('gender')}
              </P>
              <div className="row">
                {genders.map((selector, key) => (
                /* Workaround temporar pentru eliminarea 'mixt' */
                  selector.key !== 'mixt' && (
                    <div key={key} className="col-sm-6">
                      <Checkbox
                        handler={() => setForm(CheckboxHandler('gender', selector.key, form, filters, true))}
                        {...(filters?.gender?.split(',').includes(selector.key)) && { defaultChecked: true }}
                        checked={form.gender?.split(',').includes(selector.key)}
                        label={commonT(`only${selector.key}`)}
                      />
                    </div>
                  )
                ))}
              </div>
            </div>
          )}

        <P large bold className="mb20 mt30">
          {t('age')}
        </P>
        <div className="row bd-bottom">
          {ageSelectors.map((selector, key) => (
            <div key={key} className="col-sm-6">
              <Radio
                name="age"
                label={t(selector.label)}
                value={selector.value}
                checked={ageRadioState === selector.value}
                onChange={(event) => handleChangeAge(event)}
              />
            </div>
          ))}
        </div>

        {ageRadioState === 'custom' && (
          <div className="form-group">
            <Range
              step={1}
              min={10}
              max={100}
              values={rangeSliderValues}
              onChange={values => setRangeSliderValues(values)}
              renderTrack={({ props, children }) => (
                <div
                  onMouseDown={props.onMouseDown}
                  onTouchStart={props.onTouchStart}
                  style={{
                    ...props.style,
                    height: '36px',
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <div
                    ref={props.ref}
                    style={{
                      height: '4px',
                      width: '100%',
                      borderRadius: '4px',
                      background: '#ccc',
                      alignSelf: 'center',
                    }}
                  >
                    {children}
                  </div>
                </div>
              )}
              renderThumb={({ index, props }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: '21px',
                    width: '21px',
                    borderRadius: '50%',
                    backgroundColor: '#000',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    outline: 0,
                  }}
                >
                  <div
                    style={{
                      position: 'absolute',
                      top: '-28px',
                      color: '#000',
                      fontWeight: '500',
                      fontSize: '14px',
                      padding: '5px',
                      backgroundColor: 'transparent',
                    }}
                  >
                    {rangeSliderValues[index].toFixed(0)}
                  </div>
                </div>
              )}
            />
          </div>
        )}

        {!isEmpty(playModes)
          && (
            <div className="form-group">
              <P large bold className="mb20 mt30">
                {t('playMode')}
              </P>
              <div className="row">
                {playModes.map((selector, key) => (
                  <div key={key} className="col-sm-6">
                    <Checkbox
                      name={`playMode[${selector.key}]`}
                      handler={() => setForm(CheckboxHandler('playMode', selector.key, form, filters, true))}
                      checked={form.playMode?.split(',').includes(selector.key)}
                      {...(filters?.playMode?.split(',').includes(selector.key)) && { defaultChecked: true }}
                      label={t(selector.key)}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
      </div>
      <div className="form-footer">
        <span
          className="reset-to-default"
          onClick={resetModalFilters}
        >
          {t('clearAll')}
        </span>
        <Button
          onClick={() => {
            handleModalFilters(form);
            toggle();
          }}
          black
        >
          {t('apply')}
        </Button>
      </div>
    </>
  );
};

export default AdvancedFilters;
