import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import H3 from '../../../../components/H3';
import { BlockedPlayers as BlockedPlayersContainer } from './styles';
import { Contain, P } from '../../../../components/Collection';
import { store as socketStore } from '../../../../components/Socket/store';
import { getAccountInfo } from '../../../../components/Layout/reducers/session';
import BlockedPlayerRow from './components/BlockedPlayerRow';

const BlockedPlayers = () => {
  const { addToast } = useToasts();

  const dispatch = useDispatch();
  const { accountInfo: { userId, blockedPlayers } } = useSelector(state => state.session);
  const { t: commonT } = useTranslation('common');
  const globalState = useContext(socketStore);
  const { state: { socket: { socketClientId, socket } } } = globalState;
  const [blockedPlayersFiltered, setBlockedPlayersFiltered] = useState([]);

  useEffect(() => {
    setBlockedPlayersFiltered(blockedPlayers.filter(({ blockedUserId }) => blockedUserId !== userId));
  }, [blockedPlayers]);

  useEffect(() => {
    const listenForResponse = async ({ success, message }) => {
      await addToast(commonT(message), {
        appearance: success ? 'success' : 'error',
        autoDismiss: true,
      });

      if (success) {
        dispatch(getAccountInfo());
      }
    };

    if (socket) {
      socket.removeAllListeners('friends-response');
      socket.on('friends-response', listenForResponse);

      return () => socket.removeAllListeners('friends-response');
    }
  }, [socketClientId]);

  return (
    <BlockedPlayersContainer>
      <H3>
        <Trans ns="settings" i18nKey="profileSettings.blockedPlayers.title">Blocked Players</Trans>
      </H3>
      <Contain direction="column" maxWidth="445px">
        <P small margin="5px 0 0 0">
          <Trans ns="settings" i18nKey="profileSettings.blockedPlayers.blockImplications">
            Once you block someone, that player can no longer invite you to matches,
            start a conversation with you, or add you as a friend.
          </Trans>
        </P>
        <P small margin="0 0 5px 0">
          <Trans ns="settings" i18nKey="profileSettings.blockedPlayers.blockNote">
            Note: Does not include matches in competitions you both participate in.
          </Trans>
        </P>
        <P small>
          <Trans ns="settings" i18nKey="profileSettings.blockedPlayers.howToBlock">
            You can block players by clicking Block Player from their profile options.
          </Trans>
        </P>

        {blockedPlayersFiltered.length > 0 && (
          <Contain margin="30px 0 0 0" direction="column">
            {(blockedPlayersFiltered || []).map((player, idx) => (
              <BlockedPlayerRow player={player} socketClientId={socketClientId} key={idx} />
            ))}
          </Contain>
        )}
      </Contain>
    </BlockedPlayersContainer>
  );
};

export default BlockedPlayers;
