import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';

import { P } from '../../../../../../components/Collection';
import LoadImage from '../../../../../../components/common/LoadImage';

import {
  Ellipsis,
  PlayerOptionAvatar,
  PlayerOptionContainer,
  PlayerOptionID,
  PlayerOptionLevel,
  PlayerOptionMetaData,
  PlayerOptionUsername,
  SearchOptionInitialsAvatar,
} from './styles';

import { MatchFormClearResults } from '../../styles';
import { FriendsIcon } from '../../../../../Friends/components/FriendCard/styles';

const MatchFormSearchOption = ({
  isMe,
  player,
  handler,
  hasError,
  isDoubles,
  bottomMargin,
  isPlaceholder,
  playerInputPlaceholder,
  invitationStatus,
}) => {
  const { accountInfo } = useSelector(state => state.session);
  const { t: playerT } = useTranslation('player');
  const { t: conversationsT } = useTranslation('conversations');

  const {
    firstName,
    lastName,
    userId,
    gameLevel,
    profilePicture,
  } = player || {};

  const gameType = !isDoubles ? 'singles' : 'doubles';

  const nameInitials = () => {
    const first = firstName?.charAt(0) !== undefined ? firstName?.charAt(0).toUpperCase() : '';
    const last = lastName?.charAt(0) !== undefined ? lastName?.charAt(0).toUpperCase() : '';
    return `${first} ${last}`;
  };

  const avatarType = profilePicture ? 'picture' : 'initials';
  const avatar = {
    picture: <PlayerOptionAvatar src={LoadImage(`accounts/${userId}/${profilePicture}`, true)} alt={nameInitials()} />,
    initials:
  <SearchOptionInitialsAvatar>
    <P xSmall bold>{nameInitials()}</P>
  </SearchOptionInitialsAvatar>,
  };

  const formatedPlayerBirthdate = player?.birthDate ? new Date(player?.birthDate) : new Date();
  const birthdateDay = formatedPlayerBirthdate?.getUTCDate();
  const birthdateMonth = formatedPlayerBirthdate?.getUTCMonth() + 1;
  const birthdateYear = formatedPlayerBirthdate?.getUTCFullYear();

  const level = isMe ? accountInfo?.gameLevel?.[gameType] : gameLevel?.[gameType];

  return (
    <PlayerOptionContainer
      isPlaceholder={isPlaceholder}
      bottomMargin={bottomMargin}
      displayLevelError={hasError}
      {...!playerInputPlaceholder && {
        onClick: () => {
          if (level) {
            handler({
              ...player,
              birthDate: `${birthdateYear}-${birthdateMonth}-${birthdateDay}`,
            });
          }
        },
      }}
      playerInputPlaceholder={playerInputPlaceholder}
      isMe={isMe}
      gameLevelSet={level}
    >
      {avatar[avatarType]}
      <PlayerOptionMetaData>
        <PlayerOptionUsername bold width="max-content">
          {accountInfo?.friendsIds?.includes(player.userId) && (
            <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
          )}
          <Ellipsis>{isMe ? conversationsT('you') : `${firstName} ${lastName}`}</Ellipsis>
          {' '}
          <PlayerOptionID>
            #
            {userId}
          </PlayerOptionID>
        </PlayerOptionUsername>
        <PlayerOptionLevel xSmall>
          {playerT('gameLevel')}: {level || playerT('levelNotSet')}
          {invitationStatus && (
            <span className={`player-match-status player-match-status__${invitationStatus}`}>
              <img src={LoadImage(`friendly-matches/acceptance/${invitationStatus}-icon.svg`)} alt="" className="ml5" />
              <Trans ns="friendlyMatches" i18nKey={invitationStatus}>{invitationStatus}</Trans>
            </span>
          )}
        </PlayerOptionLevel>
      </PlayerOptionMetaData>
      {playerInputPlaceholder && !isPlaceholder && !isMe && (
        <MatchFormClearResults onClick={() => handler(false)}>
          <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
            {/* eslint-disable-next-line max-len */}
            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
          </svg>
        </MatchFormClearResults>
      )}
    </PlayerOptionContainer>
  );
};

export default MatchFormSearchOption;
