import styled from '@emotion/styled';
import { H1, Link, P } from '../../components/Collection';
import { Container } from '../../components';
import LoadImage from '../../components/common/LoadImage';
import { DeclineButton } from './Requests/styles';

export const PageContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 0 60px 0',
  width: '100%',

  '@media (max-width: 1200px)': {
    padding: '20px 10px 60px 10px',
  },
});

export const QuickGuideButton = styled.div({
  display: 'flex',
  fontWeight: '700',
  fontSize: '14px',
  lineHeight: '18px',
  color: '#F2BD00',
  cursor: 'pointer',

  '@media all and (min-width: 768px)': {
    marginLeft: 20,
  },
});

export const HeaderButtonsContainer = styled.div({
  display: 'flex',
  '@media (max-width: 560px)': {
    flexDirection: 'column',
  },
});

export const TitleAndRaiseHandContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  h1: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  '@media (max-width: 880px)': {
    flexDirection: 'column',
    alignItems: 'baseline',
    h1: {
      flexDirection: 'column-reverse',
      alignItems: 'baseline',
    },
  },

  '@media (max-width: 479px)': {
    flexDirection: 'column',
  },
});

export const TabsHeader = styled.div({
  display: 'flex',
  margin: '20px 0',
  overflow: 'auto',
  '@media (max-width: 420px)': {
    margin: '10px 0',
    justifyContent: 'center',
  },
});

export const TabContainer = styled.div({
  cursor: 'pointer',
  userSelect: 'none',
  display: 'flex',

  '@media (max-width: 479px)': {
    width: '100%',
  },
});

export const TabIcon = styled.img({
  margin: '0 8px 0 0',
  '@media (max-width: 1100px)': {
    margin: 'unset',
  },
});

export const FullWidth = styled.span({
  position: 'relative',
  width: 'max-content',
  fontWeight: 600,
  '> span': {
    fontWeight: 400,
  },
});

export const Tab = styled(Link)(({ active, noMargin }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  background: active ? '#10242C' : '#EAF6F6',

  borderRadius: '6px',

  padding: '0 20px',
  width: 'max-content',
  height: '43px',
  fontWeight: 600,

  textDecoration: 'none',
  color: active ? '#fff' : '#10242C',

  transition: 'background 0.2s ease-in-out',
  minWidth: '80px',

  '> span': {
    margin: '0 0 0 5px',
  },

  ...active && {
    '> img': {
      filter: 'brightness(0) invert(1)',
    },
  },

  ':hover': {
    textDecoration: 'none',
    ...!active && {
      background: '#7cc9c666',
    },
  },

  '.new-requests': {
    background: '#F42B03',
    position: 'absolute',
    width: 8,
    height: 8,
    padding: '4px 3px',
    borderRadius: '50%',
    right: '6px',
    top: '19px',
    '@media (max-width: 650px)': {
      top: 7,
      right: '5px',
      transform: 'translateX(-50%)',
    },
  },

  '&:not(last-of-type)': {
    margin: '0 20px 0 0',
  },

  '@media (max-width: 650px)': {
    flexDirection: 'column',
    alignItems: 'center',
    height: 'unset',
    padding: '10px',
    '&:not(last-of-type)': {
      margin: '0 10px 0 0',
    },

    ...noMargin && {
      margin: 'unset !important',
    },
  },

  '@media (max-width: 479px)': {
    width: '100%',
  },

  '@media (max-width: 381px)': {
    padding: '10px 8px',
  },

  '@media (max-width: 371px)': {
    padding: '10px 6px',
  },
}));

export const TabContent = styled.div({});

export const ButtonIcon = styled.img(({ isHeaderButton }) => ({
  ...isHeaderButton && {
    width: '20px',
    margin: '0 10px 0 0',
  },
}));

export const ViewMore = styled.div({
  display: 'flex',
  alignItems: 'center',
  height: '30px',
  padding: '0 20px',
  margin: '21px auto 0 auto',
  width: 'max-content',
  cursor: 'pointer',
  userSelect: 'none',
});

export const ViewMoreArrow = styled.img({
  margin: '0 0 0 10px',
});

export const InfoIcon = styled.img(({ width, height }) => ({
  ...width && { width },
  ...height && { height },
}));

export const InfoContainer = styled.span({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  margin: '0 0 0 10px',
  cursor: 'pointer',
  '&:hover > span': {
    display: 'block',
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
  },
});

export const Popup = styled.span({
  position: 'absolute',
  display: 'none',
  padding: '5px 10px',
  borderRadius: '4px',
  top: '-40px',
  left: '50%',
  transform: 'translateX(-50%)',
  background: 'rgb(15, 36, 44)',
  zIndex: 1,
  color: '#fff',
  whiteSpace: 'unset',
  minWidth: 300,

  '@media (max-width: 479px)': {
    left: 'auto',
    right: 0,
    transform: 'unset',
    width: 'calc(100vw - 30px)',
  },
});

export const ButtonsFirstPart = styled.div({
  display: 'flex',
  gap: 10,
  margin: '0 10px 0 0',

  '.only-mobile': {
    width: '100%',
  },

  '@media (min-width: 1200px)': {
    '.only-mobile': {
      display: 'none',
    },
  },

  '@media (max-width: 1199px)': {
    display: 'none',
  },

  '@media (max-width: 991px)': {
    margin: 'unset',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    width: '100%',

    '.position-relative': {
      width: '100%',
    },
  },
});

export const ButtonsSecondPart = styled.div({
  display: 'flex',
  gap: 10,

  '@media (max-width: 991px)': {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    gap: 10,

    '.initiate-conversation': {
      display: 'block',
    },
  },
});

export const ButtonsContainer = styled.div(({ status }) => ({
  display: 'flex',

  '@media (max-width: 991px)': {
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: 20,
    margin: 'unset',
    width: status ? 'auto' : '100%',
  },
}));

export const PlaceholderContainer = styled.div({
  background: '#8FFFA7',
  borderRadius: '10px',
  padding: '30px 64px 30px 30px',
  margin: '10px 0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
});

export const PlaceholderDestination = styled.span({
  fontWeight: '700',
  textDecoration: 'underline',
});

export const ClosePlaceholder = styled.img({
  width: '25px',
  height: '25px',
  position: 'absolute',
  right: '34px',
  cursor: 'pointer',
});

export const MobileOverlay = styled.div({
  display: 'none',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: '#1C2B2AB2',
  zIndex: 1,

  '@media (max-width: 700px)': {
    display: 'unset',
  },
});

export const LandingHeader = styled.section({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  position: 'relative',
  height: '100%',
  minHeight: '650px',
  // padding: '180px 0 0 420px',
  backgroundColor: 'linear-gradient(180deg, #003640 0%, #000 55.65%)',
  background: `url(${LoadImage('friendly-matches/landing/bg.png')}) top / cover no-repeat`,
  margin: '0 0 60px 0',

  '@media (max-width: 1330px)': {
    padding: '180px 200px',
  },

  '@media (max-width: 1000px)': {
    padding: '180px 100px',
  },

  '@media (max-width: 700px)': {
    backgroundPosition: 'right',
    padding: '20px',
    minHeight: 'unset',
    maxHeight: '350px',
  },
});

export const HeaderMeta = styled.div({
  margin: '0 0 0 -300px',
  width: 'fit-content',

  '@media (max-width: 1200px)': {
    margin: 'unset',
  },
});

export const HeaderTitle = styled(H1)(({ theme: { colors } }) => ({
  color: colors.lightCyan,
  maxWidth: '720px',
  position: 'relative',
  margin: '0 0 20px 0',
  fontSize: '74px',
  lineHeight: '78px',
  zIndex: 2,

  '@media (max-width: 700px)': {
    lineHeight: '35px',
  },
}));

export const HeaderDescription = styled(P)(({ theme: { colors } }) => ({
  position: 'relative',
  color: colors.lightCyan,
  zIndex: 2,
  maxWidth: '550px',
}));

export const LandingPageContainer = styled(Container)({
  overflow: 'hidden',
});

export const LandingSection = styled.section({
  display: 'flex',
  alignItems: 'center',
  maxHeight: '650px',

  '&:nth-of-type(even)': {
    padding: '0 0 0 75px',
  },

  '> img': {
    maxWidth: '650px',
  },

  '@media (max-width: 1600px)': {
    width: '100%',
    '> img': {
      maxWidth: '550px',
    },
  },

  '@media (max-width: 900px)': {
    flexDirection: 'column',
    margin: '0 0 100px 0 !important',
    padding: 'unset !important',
    '> img': {
      margin: 'unset !important',
      order: 1,
    },

    '&:last-of-type': {
      margin: '0 0 0 0 !important',
    },
  },

  '@media (max-width: 700px)': {
    backgroundPosition: 'right',
    minHeight: 'unset',
    '> img': {
      maxWidth: 'unset',
      width: '100%',
    },
  },
});

export const SectionDescription = styled.div(() => ({
  maxWidth: '543px',

  '@media (max-width: 900px)': {
    padding: 'unset',
    order: 2,
  },
}));

export const ActionContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '150px auto 160px auto',
  width: 'fit-content',
  textAlign: 'center',

  '@media (max-width: 700px)': {
    margin: '70px auto 70px auto',
  },
});

export const JoinNow = styled(DeclineButton)(({ theme: { colors } }) => ({
  color: colors.white,
  height: '60px',
  width: 'fit-content',
  margin: '20px 0 0 0',
  padding: '0 40px',
}));

export const ComingSoonBadge = styled.div(({ theme: { colors } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'fit-content',
  padding: '0 20px',
  height: '38px',
  background: colors.lightBlue,
  borderRadius: '90px',

  margin: '0 0 10px 0',
}));
