import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import PubSub from 'pubsub-js';
import { xor } from 'lodash';

import { get } from '../../components/common/http';

const removePlayer = (a, b) => (
  (a?.partnerId ? (a?.partnerId === b?.userId || a?.userId === b?.userId) : (a?.userId === b?.userId))
);

// Actions
export const fetchEvents = createAsyncThunk('events/fetchEvents', async ({ filters, options }, { getState }) => {
  const sportType = getState()?.session?.masterSport;
  const filterParams = new URLSearchParams({ sportType, ...filters, ...options }).toString();
  const { data: { data } } = await get(`/events?${filterParams}`);

  return { data, sportType };
});

export const fetchEventDetails = createAsyncThunk('events/fetchEventDetails', async (id) => {
  const { data: { data } } = await get(`/events/${id}`);

  return data;
});

export const fetchCompetitionMatches = createAsyncThunk('competition/fetchCompetitionMatches', async (id) => {
  try {
    const { data: { data } } = await get(`/matches/${id}`);

    return data;
  } catch ({ response }) {
    PubSub.publish('api-error-handler', {
      message: response?.data?.message,
      hasNotification: false,
    });
  }
});

export const fetchCompetitionMainDraw = createAsyncThunk('competition/fetchMainDraw', async ({ id, mlNumber }) => {
  try {
    const { data: { data } } = await get(`/matches/${id}/main-draw?mlNumber=${mlNumber}`);

    return data;
  } catch ({ response }) {
    PubSub.publish('api-error-handler', {
      message: response?.data?.message,
      hasNotification: false,
    });
  }
});

// eslint-disable-next-line max-len
export const fetchEventsLocations = createAsyncThunk('events/fetchEventsLocations', async ({ filters }, { getState }) => {
  const sportType = getState()?.session?.masterSport;
  const filterParams = new URLSearchParams({ sportType, ...filters }).toString();
  const { data: { data } } = await get(`/events/locations?${filterParams}`);
  return data;
});

export const fetchTotalEventsInCountry = createAsyncThunk('competition/fetchTotalEventsInCountry', async (country) => {
  const { data: { data } } = await get(`/events?eventStatus=openingSoon,registrationsOpen&location=null,+${country}`);
  return data;
});

// Slice
const competitionMatches = createSlice({
  name: 'competitionMatches',
  initialState: {
    data: [],
    status: 'idle',
    error: null,
  },
  reducers: {
  },
  extraReducers: {
    [fetchCompetitionMatches.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchCompetitionMatches.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchCompetitionMatches.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

const competitionMainDraw = createSlice({
  name: 'competitionMainDraw',
  initialState: {
    data: [],
    status: 'idle',
    error: null,
  },
  reducers: {
  },
  extraReducers: {
    [fetchCompetitionMainDraw.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchCompetitionMainDraw.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchCompetitionMainDraw.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

const events = createSlice({
  name: 'events',
  initialState: {
    data: [],
    eventsInYourCountry: null,
    status: 'idle',
    error: null,
    sportType: null,
  },
  extraReducers: {
    [fetchEvents.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchEvents.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload?.data;
      state.sportType = action.payload?.sportType;
    },
    [fetchTotalEventsInCountry.fulfilled]: (state, action) => {
      state.eventsInYourCountry = action.payload.results.length;
    },
    [fetchEvents.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

const activeFilters = createSlice({
  name: 'activeFilters',
  initialState: {
    data: {},
  },
  reducers: {
    setActiveFilters: (state, action) => {
      state.data = { ...action.payload };
    },
  },
});

const eventDetails = createSlice({
  name: 'eventDetails',
  initialState: {
    data: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    updateFollowers(state, action) {
      state.data.followers = xor(state.data.followers, [action.payload]);
    },
    setEventInfo(state, action) {
      state.data = action.payload;
    },
    sortLFP(state, action) {
      const { playerUserId, blockedPlayers } = action.payload || {};
      const event = state.data;
      (event?.competitions)?.map((comp, key) => {
        const newLFP = [
          ...(comp?.lookingForPartners)?.filter(({ userId }) => userId === playerUserId),
          ...(comp?.lookingForPartners)?.filter(({ userId }) => userId !== playerUserId),
        ]?.filter(player => !(event.competitions[key].mainList)?.some(mlPlayer => removePlayer(mlPlayer, player))
            && !(event.competitions[key].waitingList)?.some(wlPlayer => removePlayer(wlPlayer, player))
            && !(blockedPlayers)?.some(blockedPlayer => blockedPlayer === player?.userId));
        event.competitions[key].lookingForPartners = newLFP;

        return event;
      });
    },
    setLFP(state, action) {
      const event = state.data;
      const { id, list } = action.payload || {};
      const index = (event?.competitions)?.findIndex((comp => comp?._id === id));

      event.competitions[index].lookingForPartners = list;
    },
  },
  extraReducers: {
    [fetchEventDetails.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchEventDetails.fulfilled]: (state, action) => {
      const { payload } = action;
      (payload?.competitions)?.map((comp, key) => {
        const newLFP = (comp?.lookingForPartners || [])
          .filter(player => !(payload.competitions[key]?.mainList)?.some(mlPlayer => removePlayer(mlPlayer, player))
            && !(payload.competitions[key]?.waitingList)?.some(wlPlayer => removePlayer(wlPlayer, player)));

        payload.competitions[key].lookingForPartners = newLFP;

        return payload;
      });

      state.status = 'succeeded';
      state.data = payload;
    },
    [fetchEventDetails.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

const eventsLocations = createSlice({
  name: 'locations',
  initialState: {
    data: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    resetEventsLocations: (state) => {
      state.data = [];
      state.status = 'idle';
    },
  },
  extraReducers: {
    [fetchEventsLocations.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchEventsLocations.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchEventsLocations.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export const {
  updateFollowers, setEventInfo, sortLFP, setLFP,
} = eventDetails.actions;
export const { setActiveFilters } = activeFilters.actions;
export const { resetEventsLocations } = eventsLocations.actions;

const reducer = combineReducers({
  list: events.reducer,
  info: eventDetails.reducer,
  matches: competitionMatches.reducer,
  mainDraw: competitionMainDraw.reducer,
  locations: eventsLocations.reducer,
  activeFilters: activeFilters.reducer,
});

export default reducer;
