import styled from '@emotion/styled';
import { P } from '../../components/Collection';
import ComponentContainer from '../../components/Container';

export const Container = styled(ComponentContainer)({});

export const TabsContainer = styled.div({
  display: 'flex',
  width: '100%',
  margin: '10px 0 0 0',
  '@media (max-width: 800px)': {
    overflow: 'auto',
    padding: '0 0 10px 0',
  },
});

export const TabContainer = styled.div(({ active }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '50px',
  width: '236px',
  borderBottom: '2px solid #EAF6F6',
  padding: '0 0 1px 0',
  cursor: 'pointer',
  userSelect: 'none',

  '&:not(:last-of-type)': {
    margin: '0 15px 0 0',
  },

  '&:hover': {
    borderBottom: '3px solid #10242C',
    '> p': {
      color: '#10242C',
    },
  },

  ...active && {
    borderBottom: '3px solid #10242C',
  },

  '@media (max-width: 800px)': {
    minWidth: '236px',
  },

  '@media (max-width: 479px)': {
    minWidth: '120px',
  },
}));

export const Tab = styled(P)(({ active }) => ({
  color: active ? '#10242C' : '#78ADAB',
  pointerEvents: 'none',
}));
