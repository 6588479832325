export default {
  title: 'Caută un Jucător',
  singleLevel: 'Nivel Simplu',
  singleLevel_tennis: 'Nivel de Tenis Simplu',
  singleLevel_padel: 'Nivel de Padel Simplu',
  seoTitle: 'Jucători | Sportya',
  playerProfileSeoTitle: '{{firstName}} {{lastName}} - Jucători | Sportya',
  doubleLevel: 'Nivel Dublu',
  doubleLevel_tennis: 'Nivel de Tenis Dublu',
  doubleLevel_padel: 'Nivel de Padel Dublu',
  playerHand: 'Mână Dominantă',
  availabilityFriendly: 'Disponibilitatea pentru meciuri amicale',
  availabilityFriendlyNotSet: 'Disponibilitatea pentru meciuri amicale nu este încă setată',
  activity: 'Activitate',
  titles: 'Titluri',
  leaderboards: 'Clasamente',
  levelNotSet: 'Nivelul de joc nu a fost setat',
  singles: 'Simplu',
  doubles: 'Dublu',
  points: 'Puncte',
  stats: 'Statistici Sport',
  pickYear: 'An',
  allActivities: 'Activități',
  level: 'Nivel',
  round: 'Tur',
  opponent: 'Adversar',
  eloRating: 'Evaluare ELO',
  leaderboard: 'Clasare',
  score: 'Scor',
  wL: 'W/L',
  winStatusShorthand: 'W',
  loseStatusShorthand: 'L',
  woStatusShorthand: 'W.O.',
  eloDescription: 'Clasare înainte de turneu: ',
  playerFound: '<0>{{ totalResults }} jucător</0> găsit în <2>{{ location }}</2>',
  playersFound: '<0>{{ totalResults }} jucători</0> găsiți în <2>{{ location }}</2>',
  playerFoundNc: '<0>{{ totalResults }} jucător</0> găsit',
  playersFoundNc: '<0>{{ totalResults }} jucători</0> găsiți',
  champion: 'Campion',
  finalist: 'Finalist',
  week: 'Săpt.',
  calculationDate: 'Dată',
  rank: 'Rank',
  move: 'Move',
  bestResults: 'Competiții Incluse în Punctaj',
  pointsSummary: 'Rezumat Punctaj',
  playerRank: 'Clasare',
  totalPoints: 'Puncte Totale',
  competitionPoints: 'Puncte Evenimente',
  friendlyMatchPoints: 'Puncte Meciuri Amicale',
  sendMessage: 'Trimite Mesaj',
  myEvents: 'Evenimentele Mele',
  setYourGameLevel: 'Setează-ți Nivelul de Joc',
  setYourAvailability: 'Setează-ți Disponibilitatea',
  mySettings: 'Setările Mele',
  gameLevel: 'Nivel de Joc',
  basePoints: 'Puncte de Bază',
  penaltyPoints: 'Puncte de Penalizare',
  newestPlayers_tennis: 'Jucători Noi de Tenis',
  newestPlayers_padel: 'Jucători Noi de Padel',
  player: 'Jucător',
  friendlyMatches: 'Meciuri Amicale',
  registrationDate: 'Data Înregistrării',
  findAPlayer_tennis: 'Găsește un Jucător de Tenis',
  findAPlayer_padel: 'Găsește un Jucător de Padel',
  joined: 'Membru din',
  allPlayers_tennis: 'Toți Jucătorii de Tenis',
  allPlayers_padel: 'Toți Jucătorii de Padel',
  upgradedAt: 'Nivelul a fost schimbat la',
  best7results: 'Top 10 rezultate',
  changePhoto: 'Schimbă poza',
  availabilityForFriendlyMatches: 'Disponibilitate pentru meciuri amicale',
};
