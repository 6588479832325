import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import dateFormat from 'dateformat';

import { post } from '../../../../../components/common/http';
import Paragraph from '../../../../../components/Paragraph';
import Modal from '../../../../../components/Modal/Modal';
import ArrowRight from '../../../../../assets/images/arrow-right.svg';
import PlusIcon from '../../../../../assets/images/plus-icon.svg';
import Link from '../../../../../components/Link';

import { H4 } from '../../../../../components';
import { OrderStyle, SummaryModal } from './styles';
import ContentLoader from '../../../../../components/ContentLoader';

const MyOrders = ({ transaction, downloading, setDownloading }) => {
  const [modal, setModal] = useState(false);
  const { t } = useTranslation('accounts');

  const { bookingInfo } = transaction;
  let transactionType = transaction.type;

  if (transaction.status === 'refunded') {
    transactionType += 'Refund';
  }

  const handleModal = () => {
    setModal(!modal);
  };

  const downloadInvoice = async () => {
    setDownloading(transaction.paymentId);
    if (!downloading) {
      await post(`/payments/invoice/${transaction?.orderData?.invoiceId}/download`);
    }
  };

  return (
    <OrderStyle>
      <div className="order">
        <div className="id">
          <Paragraph small>
            <span className="order-id">{t('orders.orderList.mobile.ID')}:</span>{' '}
            #{transaction.paymentId}
          </Paragraph>
        </div>

        <Paragraph small className="date">
          <span className="order-date">
            {t('orders.orderList.mobile.Date')}:
          </span>{' '}
          {dateFormat(transaction.createdAt, 'd mmmm yyyy, HH:MM')}
        </Paragraph>

        <div className="type">
          <Paragraph small>
            <span className="order-type">
              {t('orders.orderList.mobile.Type')}:&nbsp;
            </span>
            <Trans
              ns="accounts"
              i18nKey={`transactionTypes.${transactionType}`}
            >
              <Link
                to={`/events/${transaction.eventId}/competitions/${transaction.competitionId}`}
              >
                {{ eventName: transaction.eventName }}
              </Link>
              <Link to={`/my-account/bookings/${bookingInfo?.id}`}>
                {{ bookingId: bookingInfo?.bookingId }}
              </Link>
              {{
                membershipName: t(
                  `${transaction.products[0]?.name?.split('-')[0]}`,
                  {
                    period: Number(
                      transaction.products[0]?.name?.replace(/[^0-9]/g, ''),
                    ),
                  },
                ),
              }}
            </Trans>
          </Paragraph>
        </div>

        <div className="amount">
          <Paragraph small>
            <span className="order-price">
              {t('orders.orderList.mobile.Price')}:&nbsp;
            </span>
            {[
              'eventRegistration',
              'upgradeMembership',
              'courtBooking',
            ].includes(transactionType) && '-'}
            {transaction.amount} {transaction.currency}
          </Paragraph>
        </div>

        <Paragraph small className="method">
          <span className="order-payment">
            {t('orders.orderList.mobile.Payment')}:
          </span>{' '}
          {t(transaction.paymentType)}
        </Paragraph>

        <div className="details text-right">
          <Paragraph small>
            <span className="order-invoice">
              {t('orders.orderList.invoiceInfo')}:&nbsp;
            </span>
            {transaction?.orderData?.invoiceId && (
              <div
                className="color-secondary cursor-pointer"
                onClick={() => downloadInvoice()}
              >
                {(!downloading
                  || (downloading && downloading !== transaction.paymentId)) && (
                  <Trans ns="accounts" i18nKey="orders.orderList.viewInvoice">
                    Download
                  </Trans>
                )}
                {downloading && downloading === transaction.paymentId && (
                  <ContentLoader absolute />
                )}
              </div>
            )}
            {!transaction?.orderData?.invoiceId && (
              <Trans
                ns="accounts"
                i18nKey="orders.orderList.invoiceNotAvailable"
              >
                Not Available
              </Trans>
            )}
          </Paragraph>
        </div>

        {/* <div className="details"> */}
        {/*  <Paragraph><a onClick={() => setModal(true)}> */}
        {/*    <Trans ns="accounts" i18nKey="orders.orderList.viewDetails">View Details</Trans> */}
        {/*  </a></Paragraph> */}
        {/* </div> */}
      </div>

      <Modal
        smallRadius
        small
        type2
        noborder
        nobackground
        hide={() => handleModal()}
        isShowing={modal}
      >
        <SummaryModal>
          <H4>
            <Trans ns="accounts" i18nKey="orders.orderList.orderSummary.title">
              Order Details
            </Trans>
          </H4>
          <div className="order-data">
            <Paragraph small>
              <Trans
                ns="accounts"
                i18nKey="orders.orderList.orderSummary.data.id"
              >
                Order ID
              </Trans>
              : 3821874
            </Paragraph>
            <Paragraph small>
              <Trans
                ns="accounts"
                i18nKey="orders.orderList.orderSummary.data.type"
              >
                Order Type
              </Trans>
              : 3821874
            </Paragraph>
            <Paragraph small>
              <Trans
                ns="accounts"
                i18nKey="orders.orderList.orderSummary.data.date"
              >
                Date &amp; Time
              </Trans>
              : 3821874
            </Paragraph>
            <Paragraph small>
              <Trans
                ns="accounts"
                i18nKey="orders.orderList.orderSummary.data.method"
              >
                Payment Method
              </Trans>
              : 3821874
            </Paragraph>
          </div>
          <Paragraph large bold>
            Sesiune de antrenament Singles &amp; Doubles
          </Paragraph>

          <div className="seeEvent cursor-pointer">
            {t('orders.orderList.orderSummary.seeEvents')}{' '}
            <img src={ArrowRight} alt="See events" />
          </div>

          <div className="order-details">
            <Paragraph small>
              {t('orders.orderList.orderSummary.about')}{' '}
              <a href="mailto:contact@sportya.net">contact@sportya.net</a>.{' '}
              {t('orders.orderList.orderSummary.about2')}{' '}
              <Link to="/return-policy">
                {t('orders.orderList.orderSummary.returnPolicy')}
              </Link>
              .
            </Paragraph>
          </div>

          <H4>
            <Trans ns="accounts" i18nKey="orders.orderSummary.summary">
              Summary
            </Trans>
          </H4>

          <div className="summary-box">
            <div className="bookingFee">
              <Paragraph>
                {t('orders.orderList.orderSummary.bookingFee')}{' '}
                <span className="BookingFee-Amount">15 RON</span>
              </Paragraph>
            </div>

            <div className="donationAmount">
              <Paragraph>
                {t('orders.orderList.orderSummary.sprintenDonation')}{' '}
                <span className="donation-Amount">10 RON</span>
              </Paragraph>
            </div>

            <div className="coupon">
              <Paragraph>
                {t('orders.orderList.orderSummary.coupon')}{' '}
                <span className="couponValue">-10 RON</span>
              </Paragraph>
            </div>
          </div>

          <div className="summary-total">
            <Paragraph bold medium>
              <span className="summary-total-value">
                {t('orders.orderList.orderSummary.total')}
              </span>
              <span className="total-value">141 RON</span>
            </Paragraph>
          </div>

          <Paragraph small>
            {t('orders.orderList.orderSummary.inclTVA')}
          </Paragraph>

          <div className="cpf">
            <div className="separator">
              <span className="sp-line" />
              <img src={PlusIcon} alt="plus" />
              <span className="sp-line" />
            </div>

            <Paragraph small bold>
              {t('orders.orderList.orderSummary.clubEntryFees')}{' '}
              <span className="cpf-value">60 RON</span>
            </Paragraph>
            <Paragraph small>
              {t('orders.orderList.orderSummary.howToPayEntryFee')} host
            </Paragraph>
          </div>
        </SummaryModal>
      </Modal>
    </OrderStyle>
  );
};

export default MyOrders;
