import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import PubSub from 'pubsub-js';
import { get } from '../../components/common/http';

export const fetchTabData = createAsyncThunk('profileTabs/fetchTabData', async ({
  userId,
  tab,
  gameType,
  filters,
  options,
  profile,
}) => {
  try {
    const endpoint = tab === 'rankings' ? 'leaderboards' : (tab === 'points' ? 'best-results' : tab);
    const filterParams = new URLSearchParams({ ...filters, gameType, ...options }).toString();

    const { data: { data } } = await get(`/accounts/${userId}/${endpoint}?${filterParams}`);

    return {
      data,
      tab,
      gameType,
      profile,
    };
  } catch ({ response: { data: { message } } }) {
    PubSub.publish('api-error-handler', {
      message,
      hasNotification: false,
    });
  }
});

// Actions
export const fetchAccountDetails = createAsyncThunk('accounts/fetchAccountDetails', async (id, { getState }) => {
  try {
    const sportType = getState()?.session?.masterSport;
    const { data: { data } } = await get(`/accounts/${id}?sportType=${sportType}`);
    return { data, sportType };
  } catch ({ response }) {
    PubSub.publish('api-error-handler', {
      message: response?.data?.message,
      hasNotification: false,
    });
  }
});

export const fetchAccountStats = createAsyncThunk('profileTabs/fetchAccountStats', async ({
  userId,
  profile,
  selectedSportType,
}, { getState }) => {
  try {
    const sportType = selectedSportType ?? getState()?.session?.masterSport;
    const { data: { data } } = await get(`/accounts/${userId}/stats?sportType=${sportType}`);

    return {
      data: { profile, data },
      sportType,
    };
  } catch ({ response }) {
    PubSub.publish('api-error-handler', {
      message: response?.data?.message,
      hasNotification: false,
    });
  }
});

const initialState = {
  leaderboard: {
    data: {},
    status: 'idle',
  },
  stats: {
    data: {},
    status: 'idle',
  },
  activity: {
    singles: {
      status: 'idle',
      levelChanges: [],
      data: [],
      activeFilters: {},
    },
    doubles: {
      status: 'idle',
      levelChanges: [],
      data: [],
      activeFilters: {},
    },
  },
  titles: {
    singles: {
      status: 'idle',
      data: [],
      activeFilters: {},
    },
    doubles: {
      status: 'idle',
      data: [],
      activeFilters: {},
    },
  },
  rankings: {
    singles: {
      status: 'idle',
      data: [],
    },
    doubles: {
      status: 'idle',
      data: [],
    },
  },
  points: {
    singles: {
      status: 'idle',
      penalties: [],
      data: [],
    },
    doubles: {
      status: 'idle',
      penalties: [],
      data: [],
    },
    fetched: [],
  },
  years: {
    singles: [],
    doubles: [],
    tab: null,
  },
};

const profileTabs = createSlice({
  name: 'profileTabs',
  initialState: {
    myProfile: initialState,
    visitedProfile: initialState,
    error: null,
    generalStatus: 'idle',
    sportType: null,
  },
  reducers: {
    setEventsWithPenalty: (state, action) => {
      const { eventId, gameType, profile } = action.payload || {};
      if (!state[profile].points.fetched.includes(eventId)) {
        state[profile].points[gameType].penalties.push(action.payload);
        state[profile].points.fetched.push(eventId);
      }
    },
    clearAllTabs: (state, action) => {
      const { profile } = action.payload || {};
      const tabs = ['activity', 'titles', 'rankings', 'points'];
      const types = ['singles', 'doubles'];

      for (let i = 0; i < tabs.length; i++) {
        for (let index = 0; index < types.length; index++) {
          state[profile][tabs[i]][types[index]].status = 'idle';
          state[profile][tabs[i]][types[index]].data = [];
          if (tabs[i] === 'activity') {
            state[profile][tabs[i]][types[index]].levelChanges = [];
            state[profile].years = {
              singles: [],
              doubles: [],
            };
          }
        }
      }
    },
    clearMyTab: (state, action) => {
      const { type } = action?.payload || {};
      const isActivityTab = (type === 'activity' || type === 'rankings');
      state.myProfile[type] = {
        status: 'idle',
        data: [],
      };

      if (isActivityTab) {
        state.myProfile.years = {
          singles: [],
          doubles: [],
        };
      }

      state.myProfile.error = null;
    },
    setActiveYears: (state, action) => {
      const {
        gameType,
        data,
        profile,
        tab,
      } = action.payload || {};
      state[profile].years[gameType] = data;
      state[profile].years.tab = tab;
    },
    setLevelChanges: (state, action) => {
      const { gameType, payload, profile } = action.payload || {};
      state[profile].activity[gameType].levelChanges = payload;
    },
    setActiveFilters: (state, action) => {
      const {
        gameType,
        filters,
        profile,
        tab,
      } = action.payload || {};
      state[profile][tab][gameType].activeFilters = filters;
    },
  },
  extraReducers: {
    [fetchTabData.pending]: (state) => {
      state.generalStatus = 'loading';
    },
    [fetchTabData.fulfilled]: (state, action) => {
      const {
        data,
        tab,
        gameType,
        profile,
      } = action.payload || {};
      if (tab && gameType) {
        state[profile][tab][gameType] = {
          ...state[profile][tab][gameType],
          data,
          status: 'succeeded',
        };
      }
      state.generalStatus = 'succeeded';
    },
    [fetchAccountStats.pending]: (state) => {
      state.generalStatus = 'loading';
    },
    [fetchAccountStats.fulfilled]: (state, action) => {
      const { profile, data } = action.payload?.data || { profile: 'myProfile' };
      if (profile) {
        state[profile].stats.data = data;
        state[profile].stats.status = 'succeeded';
        state.generalStatus = 'succeeded';
      }
      state.sportType = action?.payload?.sportType;
    },
    [fetchTabData.rejected]: (state, action) => {
      state.generalStatus = 'failed';
      state.error = action.error.message;
    },
  },
});

const accountDetails = createSlice({
  name: 'accountDetails',
  initialState: {
    data: {},
    status: 'idle',
    error: null,
    sportType: null,
  },
  reducers: {
    updateProfileInfo: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
  },
  extraReducers: {
    [fetchAccountDetails.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchAccountDetails.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload?.data;
      state.sportType = action.payload?.sportType;
    },
    [fetchAccountDetails.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export const {
  clearMyTab,
  clearAllTabs,
  setActiveYears,
  setLevelChanges,
  setEventsWithPenalty,
  setActiveFilters,
} = profileTabs.actions;

export const getEventsWithPenalties = ({ id, gameType, profile }) => async (dispatch) => {
  try {
    const { data: { data } } = await get(`/events/${id}`);
    dispatch(setEventsWithPenalty({ ...data, gameType, profile }));
  } catch (error) { /* empty */ }
};

export const getActiveYears = ({
  userId,
  gameType,
  profile,
  sportType,
  tab,
}) => async (dispatch) => {
  try {
    const path = tab === 'rankings' ? 'leaderboards' : 'accounts';
    const {
      data: { data },
    } = await get(`/${path}/${userId}/years-activity?gameType=${gameType}&sportType=${sportType}`);
    dispatch(setActiveYears({
      gameType,
      data,
      profile,
      tab,
    }));
  } catch (error) { /* empty */ }
};

export const getLevelChanges = ({
  userId, gameType, startDate, endDate, profile, sportType, year,
}) => async (dispatch) => {
  try {
    let url = `/leaderboards/${userId}/level-details?gameType=${gameType}`;
    if (year) {
      url += `&year=${year}`;
    } else {
      url += `${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}`;
      url += sportType && sportType !== "all" ? `&sportType=${sportType}` : '';
    }
    const {
      data: { data },
    } = await get(url);
    const result = data?.map(value => {
      const storedDate = value.createdAt;
      Object.assign(value, { createdAt: undefined });
      return {
        ...value,
        date: storedDate,
      };
    });
    return dispatch(setLevelChanges({ gameType, payload: result, profile }));
  } catch (error) { /* empty */ }
};

export const {
  updateProfileInfo,
} = accountDetails.actions;

const reducer = combineReducers({
  general: accountDetails.reducer,
  tabs: profileTabs.reducer,
});

export default reducer;
