export default {
  seoTitle: '{{club}} - Tennis Club - {{location}} | Sportya',
  see_all: 'See all',
  find_court: 'Find a court and play',
  view_details: 'View details',
  location: 'Location',
  about: 'About',
  events: 'Events',
  events_tennis: 'Tennis Events',
  events_padel: 'Padel Events',
  amenities: 'Amenities',
  openNow: 'Open Now',
  pickLocation: 'Pick a location',
  date: 'Date',
  today: 'Today',
  hour: 'Hour',
  anytime: 'Anytime',
  filterEvents: 'Events Status',
  all: "All",
  search: 'Search',
  seeOnMap: 'See on Map',
  title: 'Are you a Sport Club Manager or Court / Facility Administrator?',
  modalTitle: 'Edit Club Profile',
  seoTitle1: 'For Clubs | Sportya',
  info1: {
    title: 'Manage local and global tournaments for amateur players',
    desc: 'Personalized for your club needs and court availability, for maximum impact.',
  },
  info2: {
    title: 'Connect with new players',
    desc: 'Build and expand your player base with local and international players.',
  },
  info3: {
    title: 'Become a hub for amateur athletes',
    desc: 'Support level-based play and increase player engagement and loyalty.',
  },
  info4: {
    title: 'Book courts & Manage facilities',
    desc: 'Manage and book your courts and facilities. Keep players engaged, more frequently.',
  },
  form: {
    title: 'Do you want to know more?',
    desc: 'Give us some info and we’ll get back to you.',
    firstName: 'First name',
    lastName: 'Last name',
    jobTitle: 'Job Title',
    email: 'Email',
    phone: 'Phone',
    city: 'City',
    clubName: 'Club / Location name',
    sport: 'Sport',
    numberOfCourts: 'No. of courts or facilities',
    button: 'Contact Me',
    ToS: 'By contacting us, you confirm that you agree to the processing of your personal data, as described in the <1>Privacy Policy<1>.',
    validation: {
      required: 'Required field',
      invalidEmail: 'Invalid email',
      success: 'Your request has been sent. Thank you!',
      error: 'Something wrong happened',
    },
  },
  call: {
    title: 'Give us a call',
    phoneNr: '+4 031 805 4042',
  },
  contact: {
    title: 'Contact us',
    email: 'join@sportya.net',
  },
  CLUB_LEAD_CREATED: 'Your request was successfully sent!',
  CLUB_LEAD_ALREADY_EXIST: 'A club has already assigned your email address.',
  CLUB_LEAD_CREATE_ERROR: 'Could not send your request!',
  sportyaOSCTA: 'Are you a Club or Organizer?',
  sportyaOSButton: 'Go to SportyaOS',
  tennisClubsFound: '<0>{{ totalResults }} tennis club</0>',
  padelClubsFound: '<0>{{ totalResults }} padel club</0>',
  tennisClubsFound_plural: '<0>{{ totalResults }} tennis clubs</0>',
  padelClubsFound_plural: '<0>{{ totalResults }} padel clubs</0>',
  tennisClubsFound_with_location: '<0>{{ totalResults }} tennis club</0> in <2>{{ location }}</2>',
  padelClubsFound_with_location: '<0>{{ totalResults }} padel club</0> in <2>{{ location }}</2>',
  tennisClubsFound_plural_with_location: '<0>{{ totalResults }} tennis clubs</0> in <2>{{ location }}</2>',
  padelClubsFound_plural_with_location: '<0>{{ totalResults }} padel clubs</0> in <2>{{ location }}</2>',
  startingPriceFrom: "From {{ startingPrice }} {{ currency }}",
  indoorCourts: "Indoor Courts",
  noClubsFound: "No results were found for this search",
  filters: 'Filters',
  onlyAvailableCourts: "Only available courts",
  onlyAvailableSlots: "Show available slots only",
  showMap: "Show map",
  selfService: "Self-Service",
  clearAll: "Clear all",
  apply: "Apply",
  surface: "Surface",
  surfaceType: {
    clay: "Clay",
    hard: "Hard",
    artificial: "Artificial",
    grass: "Grass",
    cement: 'Cement',
  },
  courtType: "Court Type",
  courtTypes: {
    outdoor: "Outdoor",
    indoor: "Indoor",
  },
  courtSize: "Court Size",
  courtSizes: {
    singles: "Singles",
    doubles: "Doubles",
  },
  seeOtherAvailabilities: "See Other Availabilities",
  currentlyUnavailable: "Currently Unavailable",
  comingSoon: "Coming Soon",
  court: "Court",
  withLighting: "with lighting",
  checkout: "Checkout",
  addPlayersToFriendlyMatches: "Add players to Friendly Matches",
  gameType: "Game Type",
  bookingId: "Booking ID: <1>{{ bookingId }}",
  start: "Date",
  startTime: "Start Time",
  duration: "Duration",
  orderId: "Order ID",
  totalToBePaid: "Total to be paid (incl. VAT)",
  totalWithVat: "Total (incl. VAT)",
  billingData: "Billing Data",
  updateBillingData: "Update Billing Data",
  billingDataNotSet: "Billing data not set!",
  clickToUpdateBillingData: "Billing data is incomplete. Click to fill all info.",
  selectBillingInformation: "Select billing information",
  individual: "Physical person",
  company: "Company",
  durationMin: '{{ duration }} min',
  payWithCard: 'Pay with Credit Card',
  agreeBookingTos: 'By continuing the booking, I confirm that I agree with the \n <1>Terms and Conditions</1> and <4>Return Policy</4>.',
  friendlyMatch: 'Friendly Match',
  changePlayer: 'Change Player',
  additionalInfo: 'Additional Info',
  bookACourt_tennis: 'Book a Tennis Court',
  bookACourt_padel: 'Book a Padel Court',
  selfServiceCourts: 'Self-Service Courts',
  cancellationWithRefund: 'Cancellation with Refund - up to <1>{{ hours }} hours</1>',
  available: 'Available',
  notAvailable: 'Not available',
  yourBooking: 'Your booking',
  selfServiceCourtsPopupDescription: 'Players must make their courts at the <br/> end of the matches to leave them in <br/> good condition for the next matches.',
  cancellationWithRefundPopupDescription: 'Bookings at this Club can be canceled <br/> up to {{ hours }} hours before the Start Time.',
  failedToFetchData: 'Failed to fetch club data.',
  myBookingTooltip: "My booking <br /> {{ startHour }} - {{ endHour }} ({{ duration}} min)",
  totalActiveCourts: '{{ totalCourts }} Courts ({{ courtSurfaces }})',
  totalActiveCourts_tennis: '{{ totalCourts }} Tennis Courts ({{ courtSurfaces }})',
  totalActiveCourts_padel: '{{ totalCourts }} Padel Courts ({{ courtSurfaces }})',
  noAvailableCourts: 'No available courts for this interval.',
  noCreatedCourts: 'The club does not have courts / facilities in the chosen sport.',
  couldNotFetchCourtsBookings: 'Failed to fetch bookings. Try again!',
  upcomingNoBookings: 'No Upcoming Bookings',
  historyNoBookings: 'No Booking History',
  myBookings: 'My Bookings',
  upcoming: 'Upcoming',
  history: 'History',
  confirmedStatus: 'Confirmed',
  canceledStatus: 'Canceled',
  pendingStatus: 'Pending',
  cancelBooking: 'Cancel Booking',
  refunded: 'Refunded',
  lateCancellation: 'Late Cancellation',
  lateCancellationText: 'Are you sure you want to cancel this booking? \n\n According to the Club’s Cancellation Policy, the Booking will be canceled <1>WITHOUT REFUND</1>',
  earlyCancellation: 'Early Cancellation',
  earlyCancellationText: 'Are you sure you want to cancel this? \n\n According to the Club’s Cancellation Policy, the Booking will be canceled <1>WITH REFUND</1>',
  successPaymentStatus: 'Success',
  pendingPaymentStatus: 'Pending',
  refundedPaymentStatus: 'Refunded',
  minBookingTime: 'Minimum Booking Time',
  sportType: 'Sport Type',
  exclusiveOffer: 'Exclusive Offer',
  pricePerHour: '<strong>{{ pricePerHour }} {{ currency }}</strong> per hour',
  searchClubs: 'Search clubs',
};
