/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, useTheme } from '@emotion/react';
import Select, { components } from 'react-select';
import LoadImage from '../common/LoadImage';

const colourStyles = ({
  value,
  hideMenu,
  searchField,
  lowPlaceholder,
  lowInput,
  higher,
  noIndicator,
  width,
  wideMenu,
  findEvent,
  footerLangSelect,
  ellipsis,
  height,
  heightOptions,
  label,
  isMulti,
}) => ({
  container: styles => ({
    lineHeight: 1,
    cursor: 'pointer',
    marginBottom: 10,
    ...styles,
    ...width && { width },
  }),
  control: (styles, { isSelected }) => ({
    ...styles,
    ...height && { height },
    boxShadow: 'none',
    minHeight: '18px',
    marginTop: 0,
    borderRadius: '4px',
    cursor: 'pointer',
    padding: height ? '0 10px' : '4px 10px',
    border: '1px solid #78ADAB',
    ...isSelected && height && {
      padding: '0 10px',
    },
  }),
  valueContainer: styles => ({
    ...styles,
    minHeight: higher ? '40px' : '30px',
    padding: (searchField ? '10px 10px 10px 40px' : '0'),
    height: 'inherit',
  }),
  singleValue: styles => ({
    ...styles,
    ...findEvent && {
      display: 'flex',
      alignItems: 'center',
    },
    margin: 0,
    width: '100%',
    marginTop: label ? 4 : 0,
    fontWeight: 'bold',
    fontSize: '14px',
    transition: 'all 0.1s ease',
    padding: '2px 0',
    ...ellipsis && {
      display: 'block',
      position: 'unset',
      marginBottom: '-25px',
    },
    textAlign: 'left',
  }),
  multiValue: styles => ({
    ...styles,
    marginTop: label ? 12 : 0,
    marginLeft: 0,
    fontWeight: 'bold',
    fontSize: '14px',
    transition: 'all 0.1s ease',
    padding: '3px 7px',
    background: "#EAF6F6",
    borderRadius: 20,
    ...ellipsis && {
      display: 'block',
      position: 'unset',
      marginBottom: '-25px',
    },
  }),
  menu: styles => ({
    ...styles,
    fontSize: '14px',
    border: '1px solid #C1E6E5',
    borderRadius: '4px',
    boxShadow: '0px 8px 34px rgba(0, 0, 0, 0.15)',
    zIndex: 10,
    ...hideMenu && { display: 'none' },
    ...wideMenu && { width: '100%' },
  }),
  menuList: styles => ({
    ...styles,
    padding: 'unset',
    borderRadius: '4px',
    ...!footerLangSelect && {
      background: '#fff',
    },
    ...findEvent && {
      border: '1px solid #C1E6E5',
      borderRadius: '4px',
      boxShadow: '0px 8px 34px rgba(0, 0, 0, 0.15)',
    },
  }),
  input: styles => ({
    ...styles,
    margin: '0',
    fontFamily: 'DM Sans',
    marginTop: ((value || lowInput) && !isMulti) ? (searchField ? '0' : '7px') : 0,
    fontWeight: 'bold',
    fontSize: (searchField ? '18px' : '14px'),
    transition: 'all 0.1s ease',
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    background: isFocused && 'white',
    height: '40px',
    '&:hover': {
      background: '#EAF6F6',
      color: 'black',
    },
    ...isSelected && {
      background: 'white',
      color: 'black',
    },
    ...isFocused && footerLangSelect && {
      background: '#003145',
    },
    cursor: 'pointer',
    ...heightOptions && {
      height: heightOptions,
    },
  }),
  placeholder: (styles, { isDisabled }) => ({
    ...styles,
    color: '#000000',
    fontFamily: 'DM Sans',
    fontSize: (searchField ? '18px' : '14px'),
    fontWeight: (searchField ? 'lighter' : 'bold'),
    margin: (lowPlaceholder ? '7px 0 0 0' : 0),
    ...isDisabled && {
      color: 'rgb(179, 179, 179)',
    },
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none',
  }),
  indicatorsContainer: (styles, { isDisabled }) => ({
    ...styles,
    ...noIndicator && { display: 'none' },
    marginTop: 0,
    marginRight: '-10px',
    width: 'fit-content',
    height: 'inherit',
    ...isDisabled && {
      opacity: 0,
    },
  }),
});

const DropdownIndicator = props => (
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props} css={{ position: 'absolute', left: '10px' }}>
      <img src={LoadImage('search-icon_black.svg')} alt="Search" />
    </components.DropdownIndicator>
  )
);

const SelectComponent = (props) => {
  const theme = useTheme();
  const { colors, functions } = theme;
  const { errors, touched, name } = props;

  const handleChange = (value) => {
    props.onChange(props.name, value);
  };

  const getClassName = () => {
    let className = '';

    if ((props.value || props.defaultValue) || props.labelOn) {
      if (props.inputLabel) {
        className += 'align ';
      }

      if (props.isDisabled) {
        className += 'disabled ';
      }

      className += 'valid';
    }

    return className;
  };

  return (
    <div className={`form-group form-group--select ${props.class ?? ''} ${props.noAfter ? 'noAfter' : ''}`}>
      <label className={getClassName()}>
        {props.label}
      </label>

      <Select
        {...props}
        onChange={props.onChange?.name === '' ? handleChange : props.onChange}
        styles={colourStyles({ ...props })}
        components={props.searchField ? { DropdownIndicator }
          : props.deleteOnly ? { DropdownIndicator: () => null } : props.components}
      />
      {errors && errors[name] && touched && touched[name]
        ? (
          <div css={css`
        color: ${colors?.red}; 
        font-weight: 500;
        text-align: center;
        position: relative;
        top: ${functions?.toEm(-15)};
        `}
          >
            {errors[name]}
          </div>
        ) : null}
    </div>
  );
};

export default SelectComponent;
