import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import dateFormat from 'dateformat';
import { isEmpty } from 'lodash';

import {
  CourtsNumber,
  CourtsTypeContainer,
  RightSide,
} from '../../styles';
import Card from '../Card';
import { H4, P } from '../../../../../components/Collection';
import LoadImage from '../../../../../components/common/LoadImage';
import secureLink from '../../helpers/secureLinks';
import ExclusiveOffer from '../ExclusiveOffer';

const RightSideComponent = ({ clubInfo }) => {
  const { t } = useTranslation('common');
  let openDays = [];

  const getCourtSurfaces = (sport) => {
    const arr = [];
    (clubInfo?.courtsInfo?.[sport]?.courtTypes || [])
      .map((surface) => arr.push(...arr, t(surface.toLowerCase())));

    return [...new Set(arr)].join(", ");
  };

  if (!isEmpty(clubInfo?.openHours)) {
    openDays = Object.keys(clubInfo?.openHours).filter((day) => (
      clubInfo?.openHours[day]?.enabled && clubInfo?.openHours[day]?.startDate && clubInfo?.openHours[day]?.endDate
    ));
  }

  return (
    <RightSide>
      <ExclusiveOffer clubInfo={clubInfo} className="desktop-visible" />
      {openDays?.length > 0 && (
        <Card>
          <div className="schedule">
            <div>
              <div className="scheduleHeader">
                <P large bold>
                  <Trans ns="common" i18nKey="openHours">
                    Open Hours
                  </Trans>
                </P>
              </div>
              <P bold small className="mt10">
                {clubInfo?.sports.map((sport) => t(`sports.${sport}`)).join(", ")}
              </P>
              {openDays.map((day, key) => (
                <div key={key} className="timeInterval">
                  <P small>{day}</P>
                  <P small>
                    {dateFormat(clubInfo?.openHours[day]?.startDate, 'HH:MM')}
                    <span> - </span>
                    {dateFormat(clubInfo?.openHours[day]?.endDate, 'HH:MM')}
                  </P>
                </div>
              ))}
            </div>
          </div>
        </Card>
      )}

      <Card>
        <div className="contact">
          <H4>Contact</H4>
          {(clubInfo?.contactInfo?.firstName
            || clubInfo?.contactInfo?.lastName) && (
            <div className="listedItem">
              <img src={LoadImage('person-icon.svg')} alt="locationIcon" />{' '}
              <P small>
                {clubInfo?.contactInfo?.firstName}{' '}
                {clubInfo?.contactInfo?.lastName}
              </P>
            </div>
          )}

          {clubInfo?.contactInfo?.phone && (
            <div className="listedItem">
              <img src={LoadImage('phone-icon.svg')} alt="locationIcon" />{' '}
              <P small>{clubInfo?.contactInfo?.phone}</P>
            </div>
          )}

          {clubInfo?.contactInfo?.email && (
            <div className="listedItem">
              <img src={LoadImage('mail-icon.svg')} alt="locationIcon" />{' '}
              <P small>
                <a href="mailto:clubInfo?.contactInfo?.email">
                  {clubInfo?.contactInfo?.email}
                </a>
              </P>
            </div>
          )}

          {!isEmpty(clubInfo?.contactInfo?.website) && (
            <div className="listedItem">
              <img src={LoadImage('globe-icon.svg')} alt="locationIcon" />{' '}
              <P small>
                <a href={secureLink(clubInfo?.contactInfo?.website)}>
                  {clubInfo?.contactInfo?.website}
                </a>
              </P>
            </div>
          )}
        </div>
      </Card>

      {!isEmpty(clubInfo?.courtsInfo) && (
        <Card>
          <div className="contact">
            <H4 className="text-capitalize">
              <Trans ns="footer" i18nKey="courts">
                Courts
              </Trans>
            </H4>
            <CourtsTypeContainer>
              {clubInfo?.sports.map((sport) => (
                <CourtsNumber>
                  <P display="flex" align="center" small>
                    <img
                      src={LoadImage('clubs/court-type.svg')}
                      alt="Courts"
                      className="mr5"
                    />
                    <Trans ns="clubs" i18nKey="totalActiveCourts" context={sport}>
                      {{ totalCourts: clubInfo?.courtsInfo?.[sport]?.totalCourts || 0 }} Courts
                      {{ courtSurfaces: getCourtSurfaces(sport) }}
                    </Trans>
                  </P>
                </CourtsNumber>
              ))}
            </CourtsTypeContainer>
          </div>
        </Card>
      )}

      {clubInfo?.amenities?.length > 0 && (
        <Card>
          <div className="contact">
            <H4>
              <Trans ns="common" i18nKey="amenities">
                Amenities
              </Trans>
            </H4>
            {clubInfo?.amenities?.map(({ name }) => (
              <div key={name} className="listedItem">
                <img src={LoadImage(`clubs/amenities/${(name).replace(' ', '')}.svg`)} alt="" />{' '}
                <P small>
                  <Trans ns="common" i18nKey={(name).replace(' ', '')}>{name}</Trans>
                </P>
              </div>
            ))}
          </div>
        </Card>
      )}
    </RightSide>
  );
};

export default RightSideComponent;
