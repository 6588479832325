export default {
  seoTitle: 'Moje nastavení | Sportya',
  cancelRequest: 'Zrušit žádost',
  addFriend: 'Přidat přítele',
  confirmRequest: 'Přijměte žádost',
  friends: 'Přátelé',
  unfriend: 'Smazat přítele',
  profileSettings: {
    title: 'Moje nastavení',

    playerDetails: {
      title: 'Podrobnosti o úètu',
      passionatePlayer: 'Vášnivý tenista',
      accountType: 'Typ úètu',
      upgradeToPremium: 'Upgrade na Prémiový úèet',
      subscriptionValidity: 'Platnost pøedplatného',
      subscriptionState: 'Nikdy neskonèí',
      since: 'Od {{createdAt}}',
      free: 'Zdarma',
      summer: 'Letní sezónní úèet',
      premium: 'Prémiový {{period}} úèet',
      accountMembership: {
        free: 'Úèet zdarma',
        summer: 'Letní sezónní úèet',
        premium: 'Prémiový úèet',
      },
    },

    playerProfile: {
      accountType: {
        free: 'Úèet zdarma',
        summer: 'Letní sezónní úèet',
        premium: 'Prémiový úèet',
      },
    },

    changePassword: {
      title: 'Zmìnit heslo',
      oldPassword: 'Staré heslo',
      newPassword: 'Nové heslo',
      confirmNewPassword: 'Potvrdit nové heslo',
      passwordRequirement: 'Vaše nové heslo by mìlo obsahovat 8 až 16 alfanumerických znakù a nejménì jeden speciální znak.',
      pleaseEnterNewPassword: 'Zadejte své nové heslo.',
      passwordsDoNotMatch: 'Hesla se neshodují.',
      savePassword: 'Uložit heslo',
    },

    personalInformation: {
      title: 'Osobní údaje',
      firstName: 'Køestní jméno',
      lastName: 'Pøíjmení',
      year: 'Rok',
      month: 'Mìsíc',
      day: 'Den',
      location: 'Místo',
      phoneNumber: 'Telefonní èíslo',
      invalidPhoneNumber: 'Neplatné telefonní èíslo',
      clubTitle: 'Název',
    },

    profileDetails: {
      title: 'Podrobnosti o profilu',
      generalDetails: 'General Details',
      gameLevels: 'Game Levels',
      singleGameLevel: 'Herní úroveò pro dvouhru',
      doublesGameLevel: 'Herní úroveò pro ètyøhru',
      noGameLevel: 'Dosud jste si \nenastavili úroveò hry',
      buttonSetLevel: 'Zvolit herní úroveò',
      needOfChange: 'Pokud budete potøebovat zmìnit úroveò, mùžete kontaktovat tým Sportya na adrese <1>contact@sportya.net</1>.',
      dominantHand: 'Dominantní ruka',
      left: 'Levá',
      right: 'Pravá',
      shirtSize: 'Velikost trièka',
      shirtNone: 'Žádné',
      setYourGameLevel: 'Set Your Game Level',
    },

    languageCommunication: {
      title: 'Jazyk a komunikace',
      language: 'Jazyk',
      ro: 'Rumunština',
      en: 'Angliètina',
      hu: 'Maïarština',
      bg: 'Bulharština',
      email: 'E-mailem',
      newsletter: 'Newsletteru',
      emailChatConversations: 'E-mailová upozornění na chatové konverzace',
      emailFriendRequests: 'E-mailová upozornění na žádosti o přátelství',
      emailFriendlyMatches: 'E-mailová oznámení pro přátelské zápasy',
      subscribe: 'Pøihlášení k odbìru newsletteru Sportya (herní pøíležitosti v mé oblasti, nové funkce a další)',
    },

    availabilitySchedule: {
      title: 'Plán dostupnosti',
      Mon: 'Po',
      Tue: 'Út',
      Wed: 'Stø',
      Thu: 'Èt',
      Fri: 'Pá',
      Sat: 'So',
      Sun: 'Ne',
    },

    blockedPlayers: {
      title: 'Blokovaní hráči',
      blockImplications: 'Jakmile někoho zablokujete, tento hráč vás již nebude moci zvát na zápasy, zahájit s vámi konverzaci ani si vás přidat jako přítele.',
      howToBlock: 'Hráče můžete zablokovat kliknutím na Blokovat hráče v možnostech jejich profilu.',
      unblock: 'Odblokovat',
      block: 'Blokovat',
      unblockUser: 'Odblokujte {{unblockName}}',
      blockUser: 'Blokovat {{unblockName}}',
      areYouSureUnblock: 'Opravdu chcete odblokovat <1>{{unblockName}}</1> ?',
      areYouSureBlock: 'Opravdu chcete zablokovat <1>{{unblockName}}</1> ?',
      unblockInfo: 'Hráč vás bude moci zvát na zápasy, zahájit s vámi konverzaci nebo si vás přidat jako přítele.',
      blockInfo: 'Hráč vás již nebude moci zvát na zápasy, zahájit s vámi konverzaci ani si vás přidat jako přítele.',
      blockNote: 'Poznámka: Nezahrnuje zápasy v soutěžích, kterých se oba účastníte.',
      blockPlayer: 'Blokovat hráče',
      unblockPlayer: 'Odblokovat přehrávač',
      reportPlayer: 'Přehrávač zpráv',
      reportUser: 'Nahlásit {{reportName}}',
      reportInfo: 'Vyberte prosím důvod hlášení a uveďte jakékoli další informace, které našemu týmu pomohou lépe porozumět situaci. Nahlášený hráč nebude informován.',
      reportButton: 'Nahlásit',
      reportAndBlockButton: 'Nahlásit a zablokovat',
      successReportAndBlockTitle: 'Tohoto hráče jste nahlásili a zablokovali',
      successReportText: 'aši zprávu zkontrolujeme a budeme vás kontaktovat, pokud budeme potřebovat nějaké informace.',
      gotIt: 'Pochopil jsem',
      successReportTitle: 'Nahlásili jste tohoto hráče',
      youBlockedThisPlayer: 'Zablokovali jste tohoto hráče',
      manageBlockedPlayers: 'Spravujte blokované hráče',
    },

    billingData: {
      title: 'Fakturační údaje',
      companyName: 'Jméno společnosti',
      cui: 'Daňový registrační kód',
      regNumber: 'Evidenční číslo',
      fullName: 'Celé jméno',
      address: 'Název ulice, číslo, patro atd.',
      city: 'City',
      country: 'Země',
    },

    reportReasons: {
      reason: 'Důvod',
      selectTheReason: 'Vyberte důvod',
      harassment: 'Obtěžování nebo šikana',
      spam: 'Spam',
      fakeProfile: 'Falešný profil',
      playerUnderFifteen: 'Hráč mladší 15 let',
      other: 'Jiný důvod',
    },

    reportTextAreaInfo: 'Uveďte další informace (volitelné)',
  },
};
