import styled from '@emotion/styled';
import Container from '../../components/Container';

export const SportyaOSBanner = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '175px',
  background: '#EAF6F6',
  borderRadius: 10,
  width: '100%',
  gap: 10,
  '@media (max-width: 800px)': {
    flexDirection: 'column',
    height: '120px',
  },
});

export const ForClubsContainer = styled(Container)`

  [class*="-ValueContainer"] {
    overflow: visible;
  }

  ${props => props.theme.functions?.mediaQueryMax('1023px', `  
  h1
  {
    margin-top: 20px;
    text-align: center;
  }

  `)}

.row {
  display: flex;
  align-items: center;
  margin-bottom: ${ ({ theme }) => theme.functions?.toEm(40) };
  margin-left: ${ ({ theme }) => theme.functions?.toEm(-15) };
  margin-right: ${ ({ theme }) => theme.functions?.toEm(-15) };
  margin-top: ${ ({ theme }) => theme.functions?.toEm(0) };
  flex-direction: column;

    ${props => props.theme.functions?.minDesktopSmall(`
      flex-direction: row; 
      margin-top: ${ props.theme.functions?.toEm(40) };
    `)}

  > div {
    padding: 0 ${ ({ theme }) => theme.functions?.toEm(15) };
  }
}
  
  ul {
    padding-left: ${ ({ theme }) => theme.functions?.toEm(15) };
    ${props => props.theme.functions?.mediaQueryMax('1023px', `
      padding-left: ${ ({ theme }) => theme.functions?.toEm(20) };
    `)}
    
    li {
      margin-bottom: ${ ({ theme }) => theme.functions?.toEm(50) };
      padding-left: ${ ({ theme }) => theme.functions?.toEm(20) };
      
      h3 {
        margin-bottom: 0;
      }
      
      &::marker {
        content: "✓";
        font-size: ${ ({ theme }) => theme.functions?.toEm(22) };;
        font-family: system-ui;
        font-weight: bold;
      }
    }
  }
  
  .text {

    h3 {
      font-size: ${ ({ theme }) => theme.functions?.toEm(22) };
      font-weight: 700;
      margin: ${ ({ theme }) => theme.functions?.toEm(10) } 0;
    }
    
    .buttons {
      text-align: center;
      
      > div {
        margin: 0 5px;
      }
    }
  }
  
.form {
  background: #FFFFFF;
  border: 2px solid #EAF6F6;
  border-radius: 20px;
  padding: 30px 20px;
  text-align: center;
  
  ${ ({ theme }) => theme.functions?.minDesktopSmall(`
    border-radius: 30px;
    padding: 55px 65px;
  `)}
}
  
  button {
    display: inline-block;
  }
  
  
  .contact-info {
    p {
      font-size: ${ ({ theme }) => theme.functions?.toEm(18) };
    }

    .border-round-grey {
      margin-bottom: 15px;

      ${({ theme }) => theme.functions?.minDesktopSmall(`
        margin-bottom: 0;       
      `)}
    }    
  }

  .disclaimer {
    opacity: 0.5;

    > a
    {
      color: #000000;
    }
  }

  .mw-300
  {
    max-width: 300px;
  }
`;
