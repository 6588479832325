export default {
  title: 'Купете Премиум акаунт',
  subtitle: 'За да получите по-богата функционалност от платформата на Sportya и по-ниски турнирни такси, изберете един от предлаганите Премиум акаунти. <1>Премиум</1> акаунти',
  seoTitle: 'Купете Премиум акаунт | Sportya',

  benefits: {
    title: 'Включени предимства за всички акаунти',
    1: 'Личен профил, подробни резултати от мачовете, класиране и статистика',
    2: 'Национални ранглисти за аматьори във всички налични спортове',
    3: 'Отстъпка от 10% TENNIS WAREHOUSE EUROPE за всички покупки, най-големият специализиран онлайн магазин в Европа',
    4: 'Стандарт на игра за всички събития, включително нови топки (както е посочено на страницата на всеки турнир)',
    5: 'Приятелски мачове с всеки регистриран член в системата на Sportya',
    6: 'Система за лични съобщения, система за уведомяване',
    7: 'Достъп до статистики, получени от мачове, резултати от събития и сравнения за всички член на Sportya',
    8: 'Техническа поддръжка (по e-mail)',
  },

  info: {
    feeReduction: '1. Входните такси с отстъпка за Premium акаунтите зависят от типа събитие и държава и варират между 3 и 5  EUR за турнирна регистрация (изчислена автоматично). Допълнителни отстъпки се прилагат при някои събития в календара, като специалните турнири, Platinum категорията турнири и др.',
  },

  planConfirmation: {
    bestValue: 'Препоръчан',
    noExpiration: 'Без дата на валидност',
    currentPlan: 'Наличен абонамент',
    included: 'Включен',
    upgrade: 'Upgrade',
    learnMore: 'Научи повече',
    confirmFinalPayment: 'Моля потвърдете Вашето крайно плащане за <1>Премиум {{period}}</1>',
    validity: 'Валидност',
    holder: 'Титуляр',
    totalOrder: 'Общо',
    confirmPay: 'Потвърди и плати',
    availableBalance: 'Налични средства',
    redirected: 'Ще бъдете пренасочени към',
    redirecting: 'Пренасочване към Stripe...',
    redirectingClean: 'Пренасочване към',
  },

  bottomSection: {
    findMore: 'Научи повече',
    remindMe: 'Напомни ми по-късно',
  },

  plans: {
    free: {
      title: 'Безплатен акаунт',
      1: 'ПЪЛЕН достъп до приятелски мачове с приятели или други играчи',
      2: 'ЦЯЛА такса за регистрация на събития',
      3: 'Достъп до Турнири от категория Платиниум, Турнирите на Шампионите, Мастърс турнири, Международни турнири и Специални турнири',
      expDate: 'Без дата на валидност',
      price: 'Безплатно',
    },

    summer: {
      title: 'Летен акаунт',
      1: 'ПЪЛЕН достъп до приятелски мачове с приятели или други играчи',
      2: 'Достъп до Турнири от категория Платиниум, Турнирите на Шампионите, Мастърс турнири, Международни турнири и Специални турнири',
      3: 'Цена с ОТСТЪПКА за участие в турнири',
      info: 'Лятният акаунт е посветен на играч, който е много ангажиран във веригата, който иска да подобрява играта и класирането си постоянно и да участва във възможно най-много турнири през лятото, както от категория ПЛАТИНИУМ, така и Международни турнири и Специални турнири, като Турнирът на Шампионите и Мастърс Турнири. Лятният акаунт ви предлага достъп до всички удобства и предимства на онлайн платформата. Регистрацията за турнирите предполага заплащане на онлайн такса за резервиране на място в схемата, на намалена цена, в момента на регистрацията на турнира, както и на такса за участие в турнира към организатора, в деня на състезанието.',
      expDate: '6 месеца валидност',
      timePeriod: '1 Април 2021 - 31 Октомври 2021',
    },

    premium: {
        title: 'Премиум акаунт',
        1: 'ПЪЛЕН достъп до приятелски мачове с приятели или други играчи',
        2: 'Цена с ОТСТЪПКА за участие в турнири',
        3: 'Достъп до Турнири от категория Платиниум, Турнирите на Шампионите, Мастърс турнири, Международни турнири и Специални турнири',
        info: 'Премиум акаунтът е посветен на играча, който иска да участва във възможно най-много турнири през годината, както от категория ПЛАТИНИУМ, така и Международни турнири и Специални турнири, като Турнирът на Шампионите и Мастърс Турнири. Премиум акаунт ви предлага достъп до всички удобства и предимства на онлайн платформата. Регистрацията за турнирите предполага заплащане на онлайн такса за резервиране на място в схемата, на намалена цена, в момента на регистрацията на турнира, както и на такса за участие в турнира към организатора, в деня на състезанието',
        expDate: '12 месеца валидност',
        timePeriod: '1 Яануари 2021 - 31 Декември 2021',
    },

    overlay: {
      free: {
        1: 'Достъп до Турнири от категория Платиниум, Турнирите на Шампионите, Мастърс турнири, Международни турнири и Специални турнири',
        2: 'ЦЯЛА такса за регистрация на събития',
      },

      summer: {
        1: 'Достъп до Турнири от категория Платиниум, Турнирите на Шампионите, Мастърс турнири, Международни турнири и Специални турнири',
        2: 'НАМАЛЕНА такса за регистрация на събития',
      },

      premium: {
        1: 'Достъп до Турнири от категория Платиниум, Турнирите на Шампионите, Мастърс турнири, Международни турнири и Специални турнири',
        2: 'ОТСТЪПКИ за онлайн такси за регистрация + такси за участие в турнира, платени на организатора',
      },
    },
  },

  expirationDate: '{{value}} месеца валидност',
  activePlan: 'Вашият <1>Премиум</1> план е активен до <3>{{expDate}}</3>',
  extend: 'Продължи',

};
