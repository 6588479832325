import React from 'react';
import { Trans } from 'react-i18next';
import { Button, Modal } from '../../../../../components';
import LoadImage from '../../../../../components/common/LoadImage';
import { Icon, Paragraph } from './styles';

const QuickGuide = ({ isGuideVisible, setIsGuideVisible }) => (
  <Modal
    isOpen={isGuideVisible}
    onRequestClose={() => setIsGuideVisible(false)}
    style={{ width: '650px' }}
    mobileFullDisplay
    maxContentHeight
  >
    <div className="modal-header">
      <Paragraph larger bold className="text-left">
        <Trans ns="friendlyMatches" i18nKey="guide.title">Quick Guide for Friendly Matches</Trans>
      </Paragraph>
    </div>
    <div className="modal-content">
      <Paragraph className="mb30" noMarginLeft>
        <Trans ns="friendlyMatches" i18nKey="guide.intro" />
      </Paragraph>
      <Icon src={LoadImage('friendly-matches-guide/ranked.svg')} alt="Ranked Matches" />
      <Paragraph small margin="0 0 40px 0">
        <Trans ns="friendlyMatches" i18nKey="guide.ranked" />
      </Paragraph>
      <Icon src={LoadImage('friendly-matches-guide/unranked.svg')} alt="Unranked Matches" />
      <Paragraph small margin="0 0 40px 0">
        <Trans ns="friendlyMatches" i18nKey="guide.unranked" />
      </Paragraph>
      <Icon src={LoadImage('friendly-matches-guide/scores.svg')} alt="Scores" />
      <Paragraph small margin="0 0 30px 0">
        <Trans ns="friendlyMatches" i18nKey="guide.scores" />
      </Paragraph>
      <Icon src={LoadImage('friendly-matches-guide/playedMatches.svg')} alt="Played Matches" />
      <Paragraph small margin="0 0 30px 0">
        <Trans ns="friendlyMatches" i18nKey="guide.playedMatches" />
      </Paragraph>
      <Icon src={LoadImage('friendly-matches-guide/withdrawals.svg')} alt="Withdrawals" />
      <Paragraph small margin="0 0 30px 0">
        <Trans ns="friendlyMatches" i18nKey="guide.withdrawals" />
      </Paragraph>
      <Icon src={LoadImage('friendly-matches-guide/contact.svg')} alt="Contact Participants" />
      <Paragraph small margin="0 0 0 0">
        <Trans ns="friendlyMatches" i18nKey="guide.contact" />
      </Paragraph>
    </div>
    <div className="modal-footer flex-row justify-content-center mb20">
      <Button onClick={() => setIsGuideVisible(false)} modalSmallButton>
        <span><Trans ns="friendlyMatches" i18nKey="matchInviteForm.button">Got it</Trans></span>
      </Button>
    </div>
  </Modal>
);

export default QuickGuide;
