export default {
  home: 'Főoldal',
  login: 'Bejelentkezés',
  register: 'Regisztráció',
  dashboard: 'Irányítópult',
  findEvent: 'Esemény keresése',
  friendlyMatches: 'Barátságos Mérkőzések',
  bookCourt: 'Pálya foglalása',
  forPlayers: 'Játékosoknak',
  forClubs: 'Kluboknak',
  blog: 'Blog',
  help: 'Segítség',
  findAPlayer: 'Játékos keresése',
  community: 'Community',
  leaderboards: 'Ranglistahelyezések',
  lookForAnything: 'Keress bármit...',
  notifications: 'Értesítések',
  membershipType: "<0>{{t(prop.membership) || 'Ingyenes'}}</0> Fiók",
  gameLevel: 'Játékszint',
  myProfile: 'Profilom',
  membership: 'Tagság',
  comingSoon: 'Hamarosan',
  free: 'Ingyenes fiók',
  summer: 'Nyári fiók',
  premium: 'Prémium fiók',

  menu: {
    friends: 'Barátok',
    myEvents: 'Események',
    bookings: 'Fenntartások',
    mySettings: 'Beállítások',
    myOrders: 'Rendelések',
    virtualWallet: 'Virtuális pénztárca',
    plans: 'Sportya előfizetés',
    logout: 'Kilépés',
  },
};
