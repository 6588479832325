import styled from '@emotion/styled';

export const Container = styled.div(({ theme }) => ({
  backgroundColor: theme?.colors?.white,
  maxWidth: 600,
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: 20,

  h2: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    lineHeight: 1,

    img: {
      padding: 10,
      cursor: "pointer",
    },

    span: {
      fontSize: 12,
      padding: "5px 10px",
      borderRadius: 4,
      lineHeight: "16px",
      textTransform: "uppercase",

      "&.pending": {
        backgroundColor: "#FFF0BC",
      },

      "&.confirmed": {
        backgroundColor: "#EAF6F6",
      },

      "&.canceled": {
        backgroundColor: "#FFDDE3",
      },
    },
  },

  ".description": {
    marginTop: 20,
    marginBottom: 50,
    padding: 40,
    border: `1px solid ${theme?.colors?.lightBlue}`,
    borderRadius: 10,

    '@media (max-width: 650px)': {
      padding: 15,
      margin: 10,
    },
  },

  hr: {
    borderTop: "none",
    borderColor: theme?.colors?.lightBlue,
    margin: "30px 0",
  },

  ".location": {
    display: "flex",
    gap: 10,

    a: {
      fontWeight: "normal",
      fontSize: 16,
    },

    '@media (max-width: 650px)': {
      flexDirection: 'column',
    },
  },

  ".total-amount": {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",

    span: {
      textTransform: "uppercase",
      fontSize: 12,
      opacity: 0.5,
    },
  },

  ".overview": {
    listStyle: "none",
    display: "flex",
    flexDirection: "column",
    gap: 20,

    li: {
      display: "flex",
      justifyContent: "space-between",

      p: {
        margin: 0,
      },

      "&.match-players": {
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 10,
      },

      ".link": {
        color: theme?.colors?.darkTurquoise,
        textDecoration: "underline",
        cursor: "pointer",
      },

      ul: {
        listStyle: "none",
        display: "flex",
        gap: 10,
      },
    },

    ".friendly-match": {
      flexDirection: "column",
      gap: 10,

      ".friendly-match__title": {
        display: "flex",
        gap: 10,
      },

      "a:hover": {
        textDecoration: "none",
      },
    },
  },

  ".buttons": {
    marginTop: 30,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));
