export default {
  title: 'Homepage',
  seoTitle: 'Joacă la nivel local. Accede la nivel internațional. Alătură-te unei comunități globale de jucători amatori | Sportya',
  stats: {
    failedToLoad: '<0>Statistici indisponibile:</0> {{error}}',
    activePlayers: '<0>Jucători</0> <2>{{players}}</2>',
    activeEvents: '<0>Evenimente planificate {{currentYear}}</0> <2>{{events}}</2>',
    activeClubs: '<0>Cluburi</0> <2>{{clubs}}</2>',
    activeCourts: '<0>Terenuri</0> <2>{{courts}}</2>',
    activeCities: '<0>Orașe</0> <2>{{cities}}</2>',
    activeCountries:
    `<0>Țări</0> <2>Romania, Republica
     Moldova, Bulgaria,
     Bahrain</2>`,
  },

  newEvents: {
    title: 'Evenimente Recomandate',
    tennisTitle: 'Evenimente Recomandate de Tenis',
    paddlepadel: 'Evenimente Recomandate de Padel',
  },

  clubArea: {
    title: 'Ești Manager de Club sau Administrator de Terenuri?',
    title_tennis: 'Ești Manager de Club sau Administrator de Terenuri de Tenis?',
    title_padel: 'Ești Manager de Club sau Administrator de Terenuri de Padel?',

    first: {
      title: 'Gestionează evenimente locale și globale',
      desc: 'Personalizate pentru nevoile clubului tău, disponibilitățile terenurilor sau facilităților, pentru un impact maxim.',
    },
    second: {
      title: 'Devino un hub pentru sportivii amatori',
      desc: 'Sprijină jocul organizat pe niveluri de abilități. Sporește implicarea și loialitatea jucătorilor din club.',
    },
    third: {
      title: 'Conectează-te cu noi jucători',
      desc: 'Construiește și extinde-ți baza de jucători.',
    },
    fourth: {
      title: 'Închiriează terenurile și facilitățile tale',
      desc: 'Gestionează și oferă terenurile pentru închiriere către întreaga comunitate Sportya. Stimulează implicarea jucătorilor și crește frecvența de joc.',
    },
    button: 'Află mai multe',
  },

  centralPanel: {
    first: {
      title: 'Joacă mai mult. Joacă așa cum vrei tu.',
      desc: 'Evaluează-ți nivelul în tenis, padel și alte sporturi și alege cum vrei să joci, de la evenimente clasice, knockout, organizate în weekend, la evenimente cu format de grupe, evenimente organizate în timpul serii, Ligi Locale flexibile, meciuri amicale și multe altele.',
    },
    second: {
      title: 'Înscrie-te în turnee locale și internaționale',
      desc: 'Accesează un calendar de sute de evenimente Sportya, joacă în turnee locale și internaționale pentru jucători amatori, toate organizate pe bază de niveluri de joc, în diferite sporturi.',
    },
    third: {
      title: 'Caută și rezervă terenuri sau suprafețe de joc',
      desc: 'Caută și rezervă online terenuri de tenis, padel sau alte suprafețe de joc disponibile în cluburile partenere.',
    },
    buttons: {
      first: 'Află mai multe',
      second: 'Participă',
    },
  },

  slider: {
    '1stSlideText': 'Participă la turnee de amatori locale și internaționale',
    '1stSlideBtn': 'Caută un Turneu',
    '2ndSlideText': 'Găsește și Rezervă terenuri sau suprafețe de joc în cluburile partenere',
    '2ndSlideBtn': 'Caută Acum',
    '3rdSlideText': 'Găseste un partener pentru meciuri amicale în tenis, padel și alte sporturi',
    '3rdSlideBtn': 'Află mai Multe',
  },
};
