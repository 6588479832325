import styled from '@emotion/styled';
import ComponentContainer from '../../../components/Container';

export const OrderList = styled(ComponentContainer)`
    margin-top: 40px;
    border: 1px solid ${({ theme }) => theme?.colors?.lightBlue};
    padding: 30px 40px 30px 40px;
    border-radius: 10px;

    h3 {
        line-height: 36px;
        margin-bottom: 30px;
    }

    .orderHistory {
        width: 100%;
        display: flex;
        border-bottom: 1px solid #EAF6F6;
        justify-content: space-between;
        padding-bottom: 10px;

        p {
            margin: 0;
        }

        .orderId {
            max-width: 90px;
            width: 100%;
        }

        .orderDate {
            max-width: 180px;
            width: 100%;
        }

        .orderType {
            max-width: 300px;
            width: 100%;
        }

        .orderAmount {
            max-width: 80px;
            width: 100%;
        }

        .paymentMethod {
            max-width: 120px;
            width: 100%;
        }

        .orderDetails {
            max-width: 100px;
            width: 100%;
        }

    }

    .noOrdersWrapp {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 345px;

        h4 {
            margin-top: 10px;
        }
    }

    ${props => props.theme.functions?.mediaQueryMax('600px', `
        padding: 20px 15px;
        margin-top: 20px;
        margin-bottom: 20px;
        
        h3 {
            margin-bottom: 10px;
        }
        .orderId {
            display: none;
        }
        
        .orderDate {
            display: none;
        }
        
        .orderType {
            display: none;
        }
        
        .orderAmount {
            display: none;
        }
        
        .paymentMethod {
            display: none;
        }
        
        .orderDetails {
            display: none;
        }
        
        .pagination li a {
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
`)}
`;

export const Container = styled(ComponentContainer)`
`;
