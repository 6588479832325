import styled from '@emotion/styled';

export const CompetitionWrapp = styled.div`
padding: 0 0 40px 0;

.competitionInfo
{
  ${props => props.theme.functions?.mediaQueryMax('800px', `
  padding: 40px 0px;
  `)}
  
  ${props => props.theme.functions?.mediaQueryMax('1116px', `
  >div:last-of-type
  { 
    min-width: 240px;
    padding: 0 20px;
  }
  `)}
}

${props => props.theme.functions?.mediaQueryMax('1116px', `
.competitionInfo
{
  >div:last-of-type
    { 
      min-width: 240px;
      padding: 0 20px;
    }
}
`)}

  .react-tabs
  {
    padding: 20px;

      ${props => props.theme.functions?.mediaQueryMax('800px', `
        .tabsWrapp
        {
          flex-direction:column;
          padding: 10px 40px;

        ${props.theme.functions?.mediaQueryMax('450px', `
          padding: unset !important;
          `)}
        }
      `)}
  }

.CompWrapp{
  max-width: ${({ theme }) => theme.functions?.toEm(1234)};
  width: 100%;
  margin: 20px auto 0 auto;
  box-sizing: border-box;
  
  &__top {
      display: flex;
      flex-direction: column;
  }

  ${props => props.theme.functions?.mediaQueryMax('920px', `
    .title {
      margin: 20px 0;
    };

    .title > h1 {
      margin: unset;
      padding: unset;
    };
  `)}


  ${props => props.theme.functions?.mediaQueryMax('1310px', `
    padding: 0 20px;
  `)}

  ${props => props.theme.functions?.mediaQueryMax('800px', `
    > section {
      flex-direction: column;

      p {
        text-align: center;
      };
    };
  `)}

  ${props => props.theme.functions?.mediaQueryMax('800px', `
    h1 {
      margin: 10px auto;
    };
  `)}

  ${props => props.theme.functions?.mediaQueryMax('479px', `
    &__top {
      flex-direction: column-reverse;
      margin-bottom: 20px;
    };
    .title {
      margin: 0;
    }
  `)}
  
  .flexSB {
    display:flex;
    justify-content:space-between;
    align-items:center;
  };

  .tabsWrapp {
    display:flex;
    justify-content:space-between;
    align-items:center;

    ${props => props.theme.functions?.mediaQueryMax('416px', `
      flex-direction: row;
    `)}
  };

  .pdfWrapp {
    max-width: 135px;
    margin-right: 17.5px;
    margin-bottom:19px;
    cursor:pointer;
    img {
      margin-right:12.5px;
    };
  };


  .react-tabs__tab-list{  {/*  tab style */}
    border: none;
    margin-bottom:20px;

    .react-tabs__tab {
      border: none;
      padding:0px;
      h3{color: #7CC9C6; line-height:36px !important; }
    
      &:not(:first-of-type) {
        margin-left: 40px;
      };
    };
  };

  .react-tabs__tab--selected{ {/* selected tab style */}
    border:none;
    h3 {
      color:${({ theme }) => theme?.colors?.black} !important;
    };
  };

  .competitionInfo{
    display:flex;
    justify-content: space-between;
    padding: 0 40px;
    ${props => props.theme.functions?.mediaQueryMax('800px', 'align-items: center;')}
    color: ${({ theme }) => theme?.colors?.black};

    ${props => props.theme.functions?.mediaQueryMax('1310px', `
      .competition__description {
        margin-right: auto;
        ${props.theme.functions?.mediaQueryMax('1116px', 'margin-right: unset;')}
      };

      .competition__button {
        margin: 20px auto;
      };

      .competition__level {
        margin-right: 20px;
        ${props.theme.functions?.mediaQueryMin('1119px', 'margin: 0 20px 0 0;')}
        ${props.theme.functions?.mediaQueryMin('1116px', 'margin-right: unset;')}
      };
    `)}
  }

  h1{
    margin-top:10px;
    padding-bottom:20px;
  };

  .AnnouncementsWrapp{
    display:flex;
    justify-content:space-between;
    margin-top: 40px;
    margin-bottom:116px;
  };

  ${props => props.theme.functions?.mediaQueryMax('1116px', `
    h1 {
      text-align:center;
    };

    .competitionInfo {
      display:flex;
      justify-content:center;
      flex-direction:column;
      text-align:center;

      div:first-of-type{
        margin-bottom:5px;
      };
    };

    .AnnouncementsWrapp {
      flex-direction:column;
      align-items:center;
      text-align:center;
      div{
      margin-top:30px;
    };

    button {
      margin:0 auto;
    };
  };
  `)}
};
`;

export const CompetitionCard = styled.div`
  .competitionCard {
    border: 1px solid #C1E6E5;
    border-radius: 10px;

    .competitionWrap {
      padding: 40px;
    }

    .singleWrap {
      padding: 0 40px;
      margin: 0 0 20px 0;
      ${({ theme: { functions } }) => functions?.mediaQueryMax('768px', `
      padding: 0 10px;
    `)}
    }

    .pdfWrap {
      width: 100%;
      padding: 0 40px;

      ${props => props.theme.functions?.mediaQueryMax('1310px', `
       margin: 0 auto;
       `)}

      ${({ theme: { functions } }) => functions?.mediaQueryMax('768px', `
        padding: 0 10px;
       `)}
    }

    .listWrap {
      display: flex;
      padding: 0 40px;
      margin: 0 auto 20px auto;

      @media(max-width: 768px) {
        padding: 0 10px;
      }

      @media(max-width: 1312px) {
        div:nth-of-type(1) {
          margin: 0 0 20px 0;
        }
      }

      @media (min-width: 1311px) {
        div:nth-of-type(1) {
          margin: 0 10px 0 0;
        }

        div:nth-of-type(2) {
          margin: 0 0 0 10px;
        }
      }

      @media screen and (max-width: 1310px) {
        flex-direction: column;
        align-items: center;

        .showList {
          max-width: 100%;
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }
`;
