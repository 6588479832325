export default {
  home: 'Acasă',
  login: 'Autentificare',
  register: 'Înregistrare',
  dashboard: 'Dashboard',
  findEvent: 'Evenimente',
  friendlyMatches: 'Meciuri Amicale',
  bookCourt: 'Rezervă Teren',
  forPlayers: 'Pentru Jucători',
  forClubs: 'Pentru Cluburi',
  blog: 'Blog',
  help: 'Ajutor',
  findAPlayer: 'Jucători',
  community: 'Comunitate',
  leaderboards: 'Clasamente',
  lookForAnything: 'Caută orice...',
  notifications: 'Notificări',
  membershipType: "<0>{{t(accountInfo?.membership?.plan) || 'Free'}}</0> Account",
  membership: 'Abonamente',
  comingSoon: 'În Curând',
  gameLevel: 'Nivel Joc',
  myProfile: 'Profilul Meu',
  free: 'Cont Gratuit',
  summer: 'Cont de Vară',
  premium: 'Cont Premium',

  menu: {
    friends: 'Prieteni',
    myEvents: 'Evenimentele Mele',
    bookings: 'Rezervări',
    mySettings: 'Setări',
    myOrders: 'Comenzi',
    virtualWallet: 'Portofel Virtual',
    plans: 'Abonamentul Sportya',
    logout: 'Log Out',
  },
};
