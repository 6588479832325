export default {
  seoTitle: '{{club}} - Club Tenis - {{location}} | Sportya',
  see_all: 'Vezi toate',
  find_court: 'Găsește un teren și joacă',
  view_details: 'Vezi Detalii',
  location: 'Locație',
  about: 'Despre',
  events: 'Evenimente',
  events_tennis: 'Evenimente Tenis',
  events_padel: 'Evenimente Padel',
  amenities: 'Facilități',
  openNow: 'Deschis',
  pickLocation: 'Alege o locație',
  date: 'Dată',
  today: 'Azi',
  hour: 'Oră',
  anytime: 'Oricând',
  search: 'Caută',
  filterEvents: 'Stadiu Ev.',
  all: 'Toate',
  seeOnMap: 'Vezi pe Hartă',
  title: 'Ești manager de club sportiv sau administrator de teren / facilități sportive?',
  modalTitle: 'Editează Profilul Clubului',
  seoTitle1: 'Pentru Cluburi | Sportya',
  info1: {
    title: 'Gestionează turnee locale și internaționale pentru jucători amatori',
    desc: 'Personalizate pentru nevoile clubului tău și disponibilitățile terenurilor, pentru un impact maxim.',
  },
  info2: {
    title: 'Conectează-te cu noi jucători',
    desc: 'Construiește și extinde-ți baza de jucători.',
  },
  info3: {
    title: 'Devino un hub pentru pasionații sportului amator',
    desc: 'Sprijină jocul organizat pe niveluri de abilități. Sporește implicarea și loialitatea jucătorilor din club.',
  },
  info4: {
    title: 'Închiriează terenuri & Administrează facilitățile clubului',
    desc: 'Gestionează și oferă terenurile pentru închiriere către întreaga comunitate Sportya. Stimulează implicarea jucătorilor și crește frecvența de joc.',
  },
  form: {
    title: 'Vrei să afli mai multe?',
    desc: 'Lasă-ne câteva informații și revenim în scurt timp.',
    firstName: 'Prenume',
    lastName: 'Nume',
    jobTitle: 'Ocupație',
    email: 'Email',
    phone: 'Telefon',
    city: 'Oraș',
    clubName: 'Numele Clubului / Locației',
    sport: 'Sport',
    numberOfCourts: 'Nr. terenuri sau facilități',
    button: 'Vreau să fiu contactat',
    ToS: 'Prin contactarea noastră, confirmi că ești de acord cu procesarea datelor personale, așa cum este descris în <1>Declarația de Confidențialitate<1>.',
    validation: {
      required: 'Câmp necesar',
      invalidEmail: 'Email Invalid',
      success: 'Solicitarea a fost transmisă. Îți mulțumim!',
      error: 'Trimiterea a eșuat!',
    },
  },
  call: {
    title: 'Dă-ne un telefon',
    phoneNr: '+4 031 805 4042',
  },
  contact: {
    title: 'Contactează-ne',
    email: 'join@sportya.net',
  },
  CLUB_LEAD_CREATED: 'Cererea ta a fost trimisă cu succes!',
  CLUB_LEAD_ALREADY_EXIST: 'Adresa de email introdusă este deja atribuită unui club.',
  CLUB_LEAD_CREATE_ERROR: 'Nu am putut procesa cererea ta!',
  sportyaOSCTA: 'Ai cont de Club sau Organizator?',
  sportyaOSButton: 'Accesează SportyaOS',
  tennisClubsFound: '<0>{{ totalResults }} club de tenis</0>',
  padelClubsFound: '<0>{{ totalResults }} club de padel</0>',
  tennisClubsFound_plural: '<0>{{ totalResults }} cluburi de tenis</0>',
  padelClubsFound_plural: '<0>{{ totalResults }} cluburi de padel</0>',
  tennisClubsFound_with_location: '<0>{{ totalResults }} club de tenis</0> pentru <2>{{ location }}</2>',
  padelClubsFound_with_location: '<0>{{ totalResults }} club de padel</0> pentru <2>{{ location }}</2>',
  tennisClubsFound_plural_with_location: '<0>{{ totalResults }} cluburi de tenis</0> pentru <2>{{ location }}</2>',
  padelClubsFound_plural_with_location: '<0>{{ totalResults }} cluburi de padel</0> pentru <2>{{ location }}</2>',
  startingPriceFrom: "De la {{ startingPrice }} {{ currency }}",
  indoorCourts: "Terenuri Interioare",
  noClubsFound: "Nu au fost găsite rezultate pentru această căutare",
  filters: 'Filtre',
  onlyAvailableCourts: "Doar terenurile disponibile",
  onlyAvailableSlots: "Doar intervalele disponibile",
  showMap: "Arată Harta",
  selfService: "Self-Service",
  clearAll: "Resetează filtrele",
  apply: "Aplică",
  surface: "Suprafață",
  surfaceType: {
    grass: 'Iarbă',
    clay: 'Zgură',
    artificial: 'Sintetic',
    hard: 'Hard',
    cement: 'Ciment',
  },
  courtType: "Tip Teren",
  courtTypes: {
    outdoor: "Exterior",
    indoor: "Interior",
  },
  courtSize: "Dimensiune",
  courtSizes: {
    singles: "Simplu",
    doubles: "Dublu",
  },
  seeOtherAvailabilities: "Vezi alte disponibilități",
  currentlyUnavailable: "Momentan indisponibil",
  comingSoon: "În curând",
  court: "Teren",
  withLighting: "cu iluminat",
  checkout: "Confirmare",
  addPlayersToFriendlyMatches: "Adaugă jucători pentru Meci Amical",
  gameType: "Tip de joc",
  bookingId: "ID Rezervare: <1>{{ bookingId }}",
  start: "Dată",
  startTime: "Ora de începere",
  duration: "Durată",
  orderId: "ID Comandă",
  totalToBePaid: "Total de plată (incl. TVA)",
  totalWithVat: "Total (incl. TVA)",
  billingData: "Date Facturare",
  updateBillingData: "Actualizează datele de facturare",
  billingDataNotSet: "Datele de facturare nu sunt setate!",
  clickToUpdateBillingData: "Datele de facturare sunt incomplete. Click pentru a completa informațiile.",
  selectBillingInformation: "Selectați informațiile de facturare",
  individual: "Persoană fizică",
  company: "Companie",
  durationMin: '{{ duration }} min',
  payWithCard: 'Plătește cu Cardul',
  agreeBookingTos: 'Continuând rezervarea, confirm că sunt de acord cu \n <1>Termenii și Condițiile</1> și cu <4>Politica de Retur</4>.',
  friendlyMatch: 'Meci Amical',
  changePlayer: 'Schimbă jucătorul',
  additionalInfo: 'Informații suplimentare',
  bookACourt_tennis: 'Rezervă Teren de Tenis',
  bookACourt_padel: 'Rezervă Teren de Padel',
  selfServiceCourts: 'Terenuri Self-Service',
  cancellationWithRefund: 'Anulare cu Rambursare - minimum <1>{{ hours }} ore în avans</1>',
  available: 'Disponibil',
  notAvailable: 'Indisponibil',
  yourBooking: 'Rezervarea mea',
  selfServiceCourtsPopupDescription: 'La sfârșitul fiecărui meci, jucătorii trebuie să lase terenul pregătit pentru meciul următor.',
  cancellationWithRefundPopupDescription: 'Rezervările la acest Club pot fi anulate cu minimum {{ hours }} ore înainte de ora de începere',
  failedToFetchData: 'Nu s-au putut prelua datele clubului.',
  myBookingTooltip: "Rezervarea mea <br /> {{ startHour }} - {{ endHour }} ({{ duration}} min)",
  totalActiveCourts: '{{ totalCourts }} Terenuri ({{ courtSurfaces }})',
  totalActiveCourts_tennis: '{{ totalCourts }} Terenuri de Tenis ({{ courtSurfaces }})',
  totalActiveCourts_padel: '{{ totalCourts }} Terenuri de Padel ({{ courtSurfaces }})',
  noAvailableCourts: 'Nu există terenuri disponibile pentru acest interval.',
  noCreatedCourts: 'Clubul nu are terenuri / facilități pentru sportul ales.',
  couldNotFetchCourtsBookings: 'Nu s-au putut prelua rezervările. Încearcă din nou!',
  upcomingNoBookings: 'Nu există rezervări viitoare',
  historyNoBookings: 'Nu există rezervări în istoric',
  myBookings: 'Rezervările mele',
  upcoming: 'Viitoare',
  history: 'Istoric',
  confirmedStatus: 'Finalizată',
  canceledStatus: 'Anulată',
  pendingStatus: 'În așteptare',
  cancelBooking: 'Anulează rezervarea',
  refunded: 'Rambursare',
  lateCancellation: 'Anulare cu întârziere',
  lateCancellationText: 'Sigur dorești să anulezi această rezervare? \n\n Conform Politicii de anulare a clubului, rezervarea va fi anulată <1>FĂRĂ RAMBURSARE</1>',
  earlyCancellation: 'Anulare Rezervare',
  earlyCancellationText: 'Sigur dorești să anulezi această rezervare? \n\n Conform Politicii de anulare a clubului, rezervarea va fi anulată <1>CU RAMBURSARE</1>',
  successPaymentStatus: 'Succes',
  pendingPaymentStatus: 'În așteptare',
  refundedPaymentStatus: 'Rambursare',
  minBookingTime: 'Minimum Booking Time',
  sportType: 'Tip Sport',
  exclusiveOffer: 'Ofertă Exclusivă',
  pricePerHour: '<strong>{{ pricePerHour }} {{ currency }}</strong> pe oră',
  searchClubs: 'Caută cluburi',
};
