import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import dateFormat from 'dateformat';
import {
  debounce,
  isEmpty,
  omit,
  pick,
} from 'lodash';
import { DayPicker } from 'react-day-picker';

import Select from '../../../../../components/Form/Select';
import ToggleVisible from '../../../../../components/common/ToggleVisible';
import {
  Button,
  H4,
  Modal,
  Paragraph,
} from '../../../../../components';
import { mapLocations, getTimeIntervals } from './helpers';
import { fetchClubs, fetchClubsLocations, resetClubsLocations } from '../../../reducers';
import ToggleButton from '../../../../../components/Form/ToggleButton';

import {
  AdvancedFiltersContainer,
  Container,
  DatePickerContainer,
  FiltersContainer,
  MobileAdvancedFiltersContainer,
  MobileFiltersContainer,
  TogglesContainer,
} from './styles';
import LoadImage from '../../../../../components/common/LoadImage';
import AdvancedFiltersModal from '../AdvancedFiltersModal';
import { datePickerInterval, getLocaleByCountry } from "../../../helpers";
import { getEventsLocations } from "../../../actions";

const radiusOptions = [30, 50, 100, 150].map(value => ({
  value,
  label: `${value} km`,
}));

const defaultFilters = () => {
  // set time to the next Half of the hour
  const now = new Date();
  now.setMinutes((Math.ceil(now.getMinutes() / 30) * 30) % 60);
  if (now.getMinutes() === 0) {
    now.setHours(now.getHours() + 1);
  }

  let urlParamsFilters = Object.fromEntries(
    new URLSearchParams(window.location.search),
  );

  if (now > new Date(`${urlParamsFilters.date} ${urlParamsFilters.time}`)) {
    urlParamsFilters = omit(urlParamsFilters, ['date', 'time']);
  }

  return {
    date: dateFormat(now, "yyyy-mm-dd"),
    time: dateFormat(now, "HH:MM"),
    ...urlParamsFilters,
  };
};

const Filters = ({ options }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common']);
  const history = useHistory();
  const { data: locations, status: statusLocations } = useSelector(state => state.clubs?.locations);
  const { accountInfo, masterSport } = useSelector(state => state.session);
  const { sportType, status } = useSelector(state => state.clubs?.listing);
  const [activeLocations, setActiveLocations] = useState([]);
  const [activeFilters, setActiveFilters] = useState(defaultFilters());
  const [loadingCurrentLocation, setLoadingCurrentLocation] = useState(false);
  const [submitData, setSubmitData] = useState(false);
  const [advancedFiltersModal, setAdvancedFiltersModal] = useState(false);
  const [mobileFiltersModal, setMobileFiltersModal] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [fallbackLocations, setFallbackLocations] = useState(false);
  const [searchField, setSearchField] = useState('');

  const { ref, isComponentVisible, setIsComponentVisible } = ToggleVisible(false);

  const activeAdvancedFilters = Object.entries(pick(
    activeFilters,
    ['surface', 'courtType', 'courtSize', 'selfService'],
  ));

  useEffect(() => {
    setSubmitData(true);
  }, []);

  useEffect(() => {
    if (locations) {
      setActiveLocations(mapLocations(locations, t));
    }
  }, [locations]);

  useEffect(() => {
    const filters = Object.fromEntries(
      Object.entries(activeFilters).filter(([, v]) => !!v),
    );

    if (
      (!isEmpty(filters) && !isEmpty(options) && submitData)
      || (sportType && sportType !== masterSport && status === 'succeeded')
    ) {
      let updatedFilters = {
        ...omit(filters, ['date', 'time']),
        timestamp: `${filters.date} ${filters.time}`,
      };
      updatedFilters = Object.fromEntries(
        Object.entries(updatedFilters).filter(([, v]) => v !== 'currentLocation'),
      );

      // if no location, set user default location
      if (!updatedFilters.coordinates) {
        const { coords } = accountInfo?.location || {};
        if (!isEmpty(coords)) {
          updatedFilters.coordinates = [coords.lat, coords.lng].join(",");
          updatedFilters.radius = 1000;
          // updatedFilters.country = country;
        }
      }
      dispatch(fetchClubs({ filters: updatedFilters, options }));
      dispatch(resetClubsLocations());

      setSubmitData(false);

      history.push({
        pathname: history.location.pathname,
        search: `?${new URLSearchParams(filters).toString()}`,
      });
    }
  }, [submitData, sportType, masterSport, status]);

  useEffect(() => {
    if (!isEmpty(options)) {
      setSubmitData(true);
    }
  }, [options]);

  const handleLocations = () => {
    if (statusLocations === 'idle' || fallbackLocations) {
      setFallbackLocations(false);

      const filters = Object.fromEntries(Object.entries(activeFilters).filter(([, v]) => !!v));
      const updatedFilters = {
        ...omit(filters, ['date', 'time', 'location', 'coordinates', 'radius']),
        timestamp: `${filters.date} ${filters.time}`,
      };

      dispatch(fetchClubsLocations(updatedFilters));
    }
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      setLoadingCurrentLocation(true);
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          setActiveFilters({
            ...activeFilters,
            location: 'currentLocation',
            coordinates: `${coords.latitude},${coords.longitude}`,
            radius: activeFilters?.radius ?? 30,
          });
          setLoadingCurrentLocation(false);
        },
      );
    } else {
      return false;
    }
  };

  const setFormValue = (value, key) => {
    if (key === 'location' && value?.value === 'currentLocation') {
      return getCurrentLocation();
    }

    const newFilters = {
      ...activeFilters,
      [key]: value?.value ?? value,
      // set radius & coordinates based on location
      ...(key === 'location'
        && value?.coordinates) && {
        radius: activeFilters.radius ?? 30,
        coordinates: `${value?.coordinates.lat},${value?.coordinates.lng}`,
      },
      // unset radius & coordinates for empty location or location without coordinates
      ...(key === 'location' && (!value || !value.coordinates)) && {
        radius: null,
        coordinates: null,
      },
    };
    setActiveFilters(newFilters);
  };

  const getFormValue = (name) => {
    let data;

    switch (name) {
    case 'radius':
      data = radiusOptions
        .filter(({ value }) => value === Number(activeFilters[name]))
        .shift();
      break;
    case 'location':
      data = activeLocations
        .filter(({ value }) => value === activeFilters[name])
        .shift();
      break;
    case 'date':
    case 'time':
      data = {
        label: activeFilters[name],
        value: activeFilters[name],
      };
      break;
    default:
    }

    return data;
  };

  const fetchFallbackLocations = debounce(async (inputValue) => {
    setActiveLocations([]);

    getEventsLocations({
      allLocations: true,
      locationName: inputValue,
    }).then(res => {
      setActiveLocations(mapLocations(res, t));
      setLoadingLocations(false);
    });
  }, 300);

  const filters = (fromModal = false) => (
    <>
      <div className="bookings-filters-list">
        {fromModal && (<H4 className="mb10"><Trans ns="clubs" i18nKey="searchClubs">Search clubs</Trans></H4>)}

        <Select
          name="location"
          placeholder={t('allLocations')}
          options={activeLocations}
          label={t('location')}
          width="180px"
          height="50px"
          isClearable
          isSearchable
          onChange={(e) => setFormValue(e, 'location')}
          onFocus={handleLocations}
          value={getFormValue('location')}
          locationIndicator
          ellipsis
          isLoading={loadingLocations || loadingCurrentLocation || statusLocations === 'loading'}
          alignLabel
          lowInput
          noOptionsMessage={({ inputValue }) => {
            setSearchField(inputValue);

            if (
              (inputValue.length > 2 && !fallbackLocations && inputValue !== searchField)
              || (fallbackLocations && inputValue !== searchField)
            ) {
              setLoadingLocations(true);
              setFallbackLocations(true);
              fetchFallbackLocations(inputValue);
            }

            if (!locations.length) {
              t('noResultsFound');
            }
          }}
        />
        <Select
          name="radius"
          placeholder={t('proximityRadius')}
          onChange={(e) => setFormValue(e?.value, 'radius')}
          options={radiusOptions}
          label={t('radius')}
          width="180px"
          height="50px"
          isDisabled={!activeFilters.coordinates}
          value={getFormValue('radius')}
          alignLabel
          lowInput
          className="proximity-radius"
        />

        <div ref={ref} className="position-relative">
          <Select
            name="date"
            placeholder={t('date')}
            width="180px"
            height="50px"
            label={t('date')}
            isSearchable={false}
            value={getFormValue('date')}
            alignLabel
            lowInput
            onFocus={() => setIsComponentVisible(!isComponentVisible)}
            menuIsOpen={false}
            className="select-date"
          />

          {isComponentVisible && (
            <DatePickerContainer>
              <DayPicker
                mode="single"
                required
                showOutsideDays
                locale={getLocaleByCountry(accountInfo?.location?.country)}
                selected={new Date(getFormValue('date').value)}
                onSelect={(value) => {
                  setFormValue(dateFormat(value, 'yyyy-mm-dd'), 'date');
                  setIsComponentVisible(!isComponentVisible);
                }}
                {...datePickerInterval()}
              />
            </DatePickerContainer>
          )}
        </div>

        <Select
          name="time"
          placeholder={t('time')}
          onChange={({ value }) => setFormValue(value, 'time')}
          options={getTimeIntervals(activeFilters)}
          width="180px"
          height="50px"
          label={t('time')}
          isSearchable
          defaultValue={getFormValue('time')}
          alignLabel
          lowInput
          className="select-time"
        />
      </div>

      <Button
        red
        onClick={() => {
          setSubmitData(true);
          if (fromModal) {
            setMobileFiltersModal(false);
          }
        }}
        disabled={loadingCurrentLocation}
      >
        <span>
          <Trans ns="clubs" i18nKey="search">
            Search
          </Trans>
        </span>
      </Button>
    </>
  );

  return (
    <Container>
      <FiltersContainer>
        {filters()}

        <AdvancedFiltersContainer onClick={() => setAdvancedFiltersModal(true)}>
          <img src={LoadImage('filter.svg')} alt="All" />
          <Paragraph bold>
            <Trans ns="clubs" i18nKey="filters">
              Filters
            </Trans>
          </Paragraph>
          {activeAdvancedFilters?.length > 0 && (
            <span className="total-filters">
              {activeAdvancedFilters?.length}
            </span>
          )}
        </AdvancedFiltersContainer>
      </FiltersContainer>
      <TogglesContainer>
        <ToggleButton
          className="toggle"
          active={!!activeFilters?.availableOnly}
          handler={() => {
            setFormValue(!activeFilters?.availableOnly, 'availableOnly');
            setSubmitData(true);
          }}
        >
          <Trans ns="clubs" i18nKey="onlyAvailableCourts">
            Only available courts
          </Trans>
        </ToggleButton>
        {/* <ToggleButton */}
        {/*  className="toggle" */}
        {/*  active={!!activeFilters?.showMap} */}
        {/*  handler={() => setFormValue(!activeFilters?.showMap, 'showMap')} */}
        {/* > */}
        {/*  <Trans ns="clubs" i18nKey="showMap"> */}
        {/*    Show map */}
        {/*  </Trans> */}
        {/* </ToggleButton> */}
      </TogglesContainer>
      <MobileFiltersContainer onClick={() => setMobileFiltersModal(!mobileFiltersModal)}>
        <ul>
          <li>{getFormValue('location')?.label ?? t('allLocations')}</li>
          {getFormValue('radius')?.value && (<li>{getFormValue('radius')?.value} km</li>)}
          <li>{getFormValue('date')?.value ?? t('date')}</li>
          <li>{getFormValue('time')?.value ?? t('time')}</li>
        </ul>
        <div className="btn-container">
          <Button red smaller className="search-btn">
            <img src={LoadImage('search-icon_white.svg')} alt="" />
          </Button>
        </div>
      </MobileFiltersContainer>
      <MobileAdvancedFiltersContainer>
        {/* <div onClick={() => setFormValue(!activeFilters?.showMap, 'showMap')}> */}
        {/*  <img src={LoadImage('map-black.svg')} alt="All" /> */}
        {/* </div> */}
        <div onClick={() => setAdvancedFiltersModal(true)}>
          <img src={LoadImage('filter.svg')} alt="All" />
          {activeAdvancedFilters?.length > 0 && (
            <span className="total-filters">
              {activeAdvancedFilters?.length}
            </span>
          )}
        </div>
      </MobileAdvancedFiltersContainer>

      {advancedFiltersModal && (
        <AdvancedFiltersModal
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
          advancedFiltersModal={advancedFiltersModal}
          setSubmitData={setSubmitData}
          onRequestClose={() => {
            setAdvancedFiltersModal(false);
          }}
        />
      )}

      {mobileFiltersModal && (
        <Modal
          isOpen
          onRequestClose={() => setMobileFiltersModal(false)}
          className="bookings-filters"
          mobileFullDisplay
        >
          {filters(true)}
        </Modal>
      )}
    </Container>
  );
};

export default Filters;
