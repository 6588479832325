import styled from '@emotion/styled';

export const ListedPlayer = styled.div`
min-height: 43px;

a {
  text-decoration: none;
}

.reserved-paragraph
{
  display: flex;
  align-items: center;

  > img
  {
    height: 25px !important;
    width: 20px !important;
    margin: 0 2px !important;
  }
}

.wildcard
{
  display: flex;
  align-items: center;
  padding: 2px 10px;
  border: 1px solid #C1E6E5;
  border-radius: 34px;
  width: max-content;
  background: #FFFFFF;
  margin-right: 5px !important;
  position: relative;

  > img
  {
    margin-right: 5px;
    height: 12px !important;
    width: 12px !important;
  }

  &:hover > .popup
  {
    visibility: visible;
  }

  .popup
  {
    position: absolute;
    visibility: hidden;
    padding: 5px 10px;
    border-radius: 4px;
    top: -35px;
    left: 50%;
    transform: translateX(-50%);
    background: rgb(15, 36, 44);
    width: max-content;
    font-weight: 400;

    > p
    {
      color:  white;
    }
  }
}

.pending
{
  display: flex;
  align-items: center;
  padding: 2px 10px;
  border: 1px solid #C1E6E5;
  border-radius: 34px;
  width: max-content;
  background: #FFFFFF;

  > img
  {
    margin-left: 5px;
    height: 12px !important;
    width: 12px !important;
  }
}

.avatar-placeholder
{
  display: flex;
  justify-content: center;

  border: 2px solid ${({ theme }) => theme?.colors?.darkTurquoise};
  border-radius: 100%;

  align-items: center;
  height: 40px;
  width: 40px;

  >p
  {
    margin: 0 !important;
  }
}

.nr
{
  width: 20px;
  > p
  {
    margin: 0;
  }
}

.team
{
  display: flex;
  height: 130px;
  margin-bottom: 10px;
  ${props => (props.you
  ? `background: #EAF6F6;
  border-radius: 4px;
  `
  : `border-bottom: 1px solid #eff8f8;
  `)}
  ${props => () => (props.you && props.isPending ? 'min-height: 140px;' : '')}

  ${props => props.theme.functions?.mediaQueryMax('630px', `
    flex-direction: column;
    justify-content: space-evenly;
    height: 230px;
  `)}

  > .group
  {
    display: flex;

    ${props => props.theme.functions?.mediaQueryMax('630px', `
      height: 120px;
      
      &.is-wildcard {
        height: 160px;
      }
    `)}
    
    > .nr
    {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
    }
  
    .players
    {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      min-width: max-content;
  
      .player
      {
        display: flex;
        align-items: center;

        > .playerInfo
        {
          > p
          {
            margin: 0;
          }
  
          > .playerName
          {
            display: flex;
            align-items: center;
    
            > p
            {
              margin: 0;
            }
  
            > p:first-of-type
            {
              margin-right: 5px;
            }
  
            > .id
            {
              color:${({ theme }) => theme?.colors?.darkTurquoise};
              margin-bottom: -3px;
            }
          }
        }
  
        > .avatar
        {
          height: max-content;
          margin-right: 10px;
      
          > img
          {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 38px;
            height: 38px;
            border-radius: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  > .teamInfo
  {
    display: flex;
    position: relative;
    align-items: center;
    margin-left: auto;

    .pending
    {
      display: flex;
      align-items: center;
      padding: 2px 10px;
      border: 1px solid #C1E6E5;
      border-radius: 34px;
      width: max-content;
      background: #FFFFFF;
    
      > img
      {
        margin-left: 5px;
        height: 12px !important;
        width: 12px !important;
      }
    }

    ${props => props.theme.functions?.mediaQueryMax('630px', `
      margin: 0 auto;
    `)}

    > p
    {
      display: block;
      position: absolute;
      left: 80%;
      transform: translateX(-50%);
      bottom: -5px;

      ${props => props.theme.functions?.mediaQueryMax('630px', `
      position: absolute;
      left: 90%;
      bottom: -35px;
      `)}
    }

    > div
    {
      border-right: 1px solid white;
      padding-right: 20px;
      margin-right: 20px;
      text-align: right;

      > p
      {
        margin: 0;
      }
    }

    > div:last-of-type
    {
      border-right: 0;
    }
  }
}

> div:first-of-type
{
  >p:first-of-type
  {
    width: 13px;
  }
}

{/* reserved slot style & base style for a slot*/}
.reserved{
  p{
    margin:0;
  }
  display:flex;
  margin-left:20px;
  align-items:center;
  margin-top:11px;
  height: 38px;

  img{
  width:38px;
  height:38px;
  margin: 0 8px 0 8px;
  }
}

{/* available slot style */}
.available{
  p{
    color:${({ theme }) => theme?.colors?.secondary};
  }
}

{/* player style */}
 .listPlayer{
    border-radius: 4px;
    height:40px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding-left:20px;
    padding-right:20px;
    background-color: ${props => ({ theme }) => (props.you ? theme?.colors?.lightBlue : theme?.colors?.white)};
    min-height: ${props => () => (props.you ? '62px' : '38px')};
    ${props => () => (props.you && props.isPending ? 'min-height: 70px;' : '')}
    height: 100%;
    margin-top:11px;
    .playerInfo{
      display:flex;
        align-items:center;
        .avatarImg{
          display: flex;
          justify-content: center;
          align-items: center;
          width:38px;
          height:38px;
          border-radius:50px;
          object-fit: cover;
        }


          .nameWrapp{
              margin-left:8px;
              p{margin:0px;}
            div{
              display:flex;
              align-items:center;
              .id{
                margin-left:5px;
                color:${({ theme }) => theme?.colors?.darkTurquoise};
              }
            }
            .gameLevel
            {
              p{
                font-size:13px;
              }
            }
          }
    }
    .rank{
      p{margin:0}
      p:first-of-type{
        font-size: 10px;
        line-height:13px;
        margin-bottom:2px;
      }
      display:flex;
      flex-direction:column;
      align-items:flex-end;
    }
  }

`;
