export default {
  completeProfile: {
    profileDetails: 'Profile details',
    fillProfileDetails: 'Fill your profile details',
    sportyaAvailability: 'Sportya is available to players in Romania, Republic of Moldova, and Bulgaria. New countries coming soon',
    year: 'Year',
    month: 'Month',
    day: 'Day',
    gender: 'Gender',
    location: 'Location',
    locationNotSet: '<0>Location:</0> Set your location',
    userLocationNotSet: '<0>Location:</0> Not set yet',
    age: 'Age',
    ageNotSet: '<0>Age:</0> Set your age',
    userAgeNotSet: '<0>Age:</0> Not set yet',
    handNotSet: '<0>Playing Hand:</0> Set your playing hand',
    userHandNotSet: '<0>Playing Hand:</0> Not set yet',
    searchLocation: 'Search for location...',
    chooseDominantHand: 'Choose dominant hand',
    left: 'Left',
    right: 'Right',
    continue: 'Continue',
    setGameLevel: 'Set Game Level',
    saveAndContinue: 'Save & Continue',
    uploadNewPhoto: 'Upload new photo',
    removePhoto: 'Remove photo',
    profileVisibility: 'Profile visibility',
    fillProfileVisibility: 'Choose how you want players to see you',
    pictureMaxSize: 'The profile picture must be less than 1MB.',
    allPlayers: 'All players',
    allPlayersDescription: 'Your profile will be visible to all Players, and they will have the option to Send messages to you, Add you as a Friend, and Invite you to Friendly Matches.',
    allPlayersSubtitle: 'We recommend this option to fully enjoy the {{ siteName }} experience!',
    onlyFriendsAndTheirFriends: 'Only your friends & their friends',
    onlyFriendsAndTheirFriendsDescription: 'Your profile will be visible only to players added as Your Friends and to their friends. \n\n All other players will see a limited version of your profile (Full Name, Player ID, Location, Profile Photo), and will be allowed only to Send messages to you, and Add you as a friend.',
    onlyFriends: 'Only your friends',
    onlyFriendsDescription: 'Your profile will be fully visible only to players added as Your Friends. \n\n All other players will see a limited version of your profile (Full Name, Player ID, Location, Profile Photo), and will be allowed only to Send messages to you, and Add you as a friend.',
    gameLevel: 'Game level',
    gameLevel_tennis: 'Tennis Game level',
    gameLevel_padel: 'Padel Game level',
    gameLevelDescription: 'When we step onto the court, we aim to play balanced matches. For this reason, self-assessment of playing potential and organizing matches/events by skill levels have become the foundation of satisfying gaming experiences in Sportya.',
    gameLevelList: {
      1: 'Initially, you will set an indicative playing level based on your potential, either for Singles (tennis) or Doubles (padel). The corresponding level for the other format (Singles/Doubles, as applicable) will be automatically assigned and will match the initially chosen level. Later, based on your results, the levels for Singles and Doubles can be adjusted separately.',
      2: 'This is a very important step and can have a great impact in your initial experiences in Sportya events and matches. Choose your level wisely, do not overestimate or underestimate your skill. Try to be as objective as you can.',
      3: 'Level 4 corresponds to beginners, while level 9 is the closest level to professional play (usually chosen by retired professional players).',
      4: 'In Sportya, the level of play adapts and changes dynamically as you play, based on results.',
    },
    chooseGameLevel_tennis: 'Choose your tennis game level',
    chooseGameLevel_padel: 'Choose your padel game level',
    seeVideoDemonstration: 'See video demonstration',
    selectThisLevel: 'Select this level',
    skipForNow: 'Skip for now',
    level: 'Level',
    levelDescription: {
      '4_tennis': 'The player has enthusiasm, desire to learn, knowledge of the game of tennis and can keep the ball in play. The player is lacking in game speed, has interrupted / incomplete motions and court coverage, in general, is not a strong point.',
      '4_padel': 'Has just started playing padel, learning the rules and basic strokes. Struggles with ball control, consistency, and positioning. Limited experience with walls and volleys. Matches are more for learning than competition. \n\n <strong>Equivalent (Tournament Divisions in Romania):</strong>\n Beginner Division',
      '5_tennis': 'The player can use court coverage to create opportunities, is fairly consistent when hitting the ball with his forehand and can carry consistent rallies. His game speed is still lacking, he shows general insecurity in his motions, and shots are still interrupted / incomplete. The absence of decisive shots is often observed.',
      '5_padel': 'Can maintain slow rallies using basic strokes (forehand & backhand). Starts playing more consistently but makes mistakes under pressure. Has a basic understanding of positioning and teamwork. Limited experience with advanced shots (e.g., bandeja, lobs). \n\n <strong>Equivalent (Tournament Divisions in Romania):</strong>\n Division 4 (choice will depend on accumulated playing experience)',
      '6_tennis': 'The player exhibits improved shot control over the ball with both his forehand and backhand, can develop a consistent pace of play and is intentionally building his points. The backhand, even if it is executed with control, is still inconsistent and the second service is usually slow and inefficient / poorly placed. Despite the game opportunities created, there is still a lack of decisive shots in his game.',
      '6_padel': 'Decent control of strokes (volleys, lobs, serves, basic smashes). Uses walls but not always effectively. Can sustain medium-paced rallies and direct the ball. Begins to understand strategic positioning at the net and baseline. \n\n <strong>Equivalent (Tournament Divisions in Romania):</strong>\n Division 4 (choice will depend on accumulated playing experience)',
      '7_tennis': 'The player can use power, especially with his forehand, varies his strokes, controls spin and can build points by exploiting his opponent\'s weaknesses. However, there is a lack of speculation in situations of superiority, power can still be misused, mistakes in key points are still mostly present in his game and backhand is still lacking aggression.',
      '7_padel': 'Solid technique and good understanding of game tactics. Uses walls, volleys, bandeja, and smashes effectively. Controls rally pace and forces opponent errors. \n\n <strong>Equivalent (Tournament Divisions in Romania):</strong>\n Division 3',
      '8_tennis': 'The player controls spin while building speed, has an aggressive game, using a mixed range of shots and rarely makes unforced errors. However, the player lacks patience in some cases and generally has an ineffective backhand in moments of high pressure.',
      '8_padel': 'Excellent in both defensive and offensive play. Uses spin, slice shots, and effective angles. Quick reactions and good anticipation of opponents\' play. \n\n <strong>Equivalent (Tournament Divisions in Romania):</strong>\n Division 2',
      '9_tennis': 'The player uses his service as a weapon to be feared, has various shots in his arsenal, alternates spin and uses shots that are often unpredictable, effective, decisive with both the forehand and the backhand.',
      '9_padel': 'The highest level for amateur players. Excellent mental resilience, consistency, and decision-making. Perfect technical execution (bandeja, vibora, lifted smash, etc.). Elite physical and tactical level, similar to high-performance players. \n\n <strong>Equivalent (Tournament Divisions in Romania):</strong>\n Division 1',
    },
    profilePicture: 'Profile picture',
    looksGreat: 'Looks great',
    profilePictureSubtitle: 'Photos help your friends recognize you \n in {{ siteName }}',
    uploadYourPhoto: 'Upload your photo',
    availability: 'Availability',
    setAvailability: 'Set your availability',
    availabilityDescription: 'Find your game partners more easily by setting your game availability',
    completeProfile: 'Complete profile',
    Mon: 'Mon',
    Tue: 'Tue',
    Wed: 'Wed',
    Thu: 'Thu',
    Fri: 'Fri',
    Sat: 'Sat',
    Sun: 'Sun',
    chooseFavoriteSport: "Choose Your Favourite Sport",
    chooseFavoriteSportSubtitle: "This should be the main sport you play. Other sports can be added later from your account settings.",
    chooseFavoriteSportSubtitle2: "Sportya is now a multi-sport community with the addition of PADEL. New padel events, friendly matches, rankings, and more are now available. \n\n <strong>You can switch the sport anytime from the main website menu.</strong> \n\n To get started, select your favorite sport (and game level, if choosing padel) to determine the sport used each time you access your account. You can later update these preferences in your account settings.",
  },
  logout: {
    wantToLogout: 'Log out?',
    logoutParagraph: 'Are you sure you want to log out?',
    btnCancel: 'Cancel',
    btnLogout: 'Log out',
  },
  wallet: {
    seoTitle: 'Virtual Wallet | Sportya',
    walletInfo: 'The Sportya Virtual Wallet is a payment system that allows you to quickly register for events and pay for other Sportya services. You can top up your wallet and later use it as a payment option, throughout Sportya. Payments are validated without delay. We do not store your payment / card data.  ',
    chooseAmountToTopUp: 'Choose the amount you want to top-up',
    noTransactions: 'No Transactions',
    virtualWallet: 'Virtual Wallet',
    summerMembership: 'Summer Account',
    premiumMembership: 'Premium Account',
    availableFunds: 'Available Funds',
    transactionId: 'Transaction ID',
    date: 'Date',
    product: 'Product',
    amount: 'Amount',
    topUp: 'Top-Up',
    transactionTypes: {
      topUp: 'Virtual Wallet: Top-Up',
      topUpByAdmin: 'Virtual Wallet: Top-Up (by Admin)',
      subtractByAdmin: 'Virtual Wallet: Subtract (by Admin)',
      eventRegistration: 'Event Registration: <1>{{ eventName }}</1>',
      eventWithdrawal: 'Event Withdrawal: <1>{{ eventName }}</1>',
      upgradeMembership: 'Membership Upgrade: <1>{{ membershipName }}</1>',
      upgradeMembershipByAdmin: 'Membership Upgrade (By Admin): <1>{{ membershipName }}</1>',
      currencyNotSet: 'You must set up your location in order to start using your Virtual Wallet and Top-Up feature.',
      balanceAdjustmentByAdmin: 'Balance Adjustment (By Admin)',
      waitingListRefund: 'Waiting-List Automatic Refund',
      mobile: {
        id: 'ID',
        date: 'Date',
        type: 'Type',
        amount: 'Amount',
      },
      courtBooking: "Booking <1>#{{ bookingId }}",
      courtBookingRefund: "Booking Refund <1>#{{ bookingId }}",
    },
  },
  orders: {
    title: 'My Orders',
    subtitle: 'Order History',
    seoTitle: 'My Orders | Sportya',
    orderList: {
      orderID: 'Order ID',
      dateTime: 'Date & Time',
      orderType: 'Order Type',
      amount: 'Amount',
      paymentMethod: 'Payment Method',
      orderDetails: 'Order Details',
      viewDetails: 'View Details',
      invoiceInfo: 'Pmt. Proof',
      viewInvoice: 'Download',
      invoiceNotAvailable: 'Not Available',
      orderSummary: {
        title: 'Order Details',
        seeEvents: 'See Event',
        about: 'Tournament entry has been validated. From now on this order cannot be modified. For further questions please contact us at',
        about2: 'Additional information and our Return Policy is available at',
        returnPolicy: 'Return Policy',
        summary: 'Summary',
        bookingFee: 'Booking Fee',
        sprintenDonation: 'Sprinten Donation',
        coupon: 'Coupon',
        total: 'Total',
        inclTVA: 'Incl. VAT',
        clubEntryFees: 'Club Entry Fee',
        howToPayEntryFee: 'Paid on arrival to the hosting club. Hosting Club: ',
        data: {
          id: 'Order ID',
          type: 'Order Type',
          date: 'Date & Time',
          method: 'Payment Method',
        },
      },
      mobile: {
        ID: 'ID',
        Date: 'Date',
        Type: 'Type',
        Price: 'Amount',
        Payment: 'Payment Method',
      },
    },
    noOrders: 'No previous orders exist',
  },
  notifications: {
    title: 'Notifications',
    subtitle: 'Latest Notifications',
    seoTitle: 'Notifications | Sportya',
    seeDetails: 'See details',
    noNotifications: 'No notifications found.',
    dropdown: {
      title: 'Notifications',
      allNotifications: 'See All Notifications',
    },
    topUp: "The balance of your Virtual Wallet has been updated. <0>See details</0>.",
    gameLevelChangedByAdmin: "Your game level for <strong>{{ sportType }}</strong> has been modified by an admin. Your new level is <strong>{{ gameLevelForSingles }}</strong> for Singles, <strong>{{ gameLevelForDoubles }}</strong> for Doubles. <0>See details</0>.",
    eventRegistrationBan: "Due to incurred penalties for your account, you can no longer register for events. <0>See details</0>.",
    penaltyAdministrative: "Points have been subtracted from your ranking by an admin. <0>See details</0>",
    penaltyLateWithdrawal: "Your late withdrawal from event <0>{{ eventName }}</0> has been processed. Your registration fee has been retained.",
    penaltyAnnouncedWithdrawal: "Your withdrawal from event <0>{{ eventName }}</0> has been processed and your registration fee was added to your <0>Virtual Wallet</0>.",
    teamWithdrawalByAdmin: "Your team has been withdrawn from the event <0>{{ eventName }}</0>. <0>See details</0>.",
    playerWithdrawalByAdmin: "You have been withdrawn from the event <0>{{ eventName }}</0>. <0>See details</0>.",
    teamWithdrawalFromDoubles: "Your team partner <0>{{ partnerName }}</0> withdrew the team from <0>{{ eventName }}</0>.",
    registrationByAdminOnSLForDoubles: "Your team registration at <0>{{ eventName }}</0> on the sorting list was successful. <0>See details</0>.",
    registrationByAdminOnSLForSingles: "Your registration at <0>{{ eventName }}</0> on the sorting list was successful. <0>See details</0>.",
    registrationByAdminOnWLForDoubles: "Your team registration at <0>{{ eventName }}</0> on the waiting list was successful. <0>See details</0>.",
    registrationByAdminOnWLForSingles: "Your registration at <0>{{ eventName }}</0> on the waiting list was successful. <0>See details</0>.",
    registrationByAdminOnMLForDoubles: "Your team registration at <0>{{ eventName }}</0> on the main draw was successful. <0>See details</0>.",
    registrationByAdminOnMLForSingles: "Your registration at <0>{{ eventName }}</0> on the main draw was successful. <0>See details</0>.",
    promotionToMLForDoubles: "Due to withdrawals in the doubles competition at <0>{{ eventName }}</0>, your team has been promoted to the main draw. <0>See details</0>.",
    promotionToMLForSingles: "Due to withdrawals in the singles competition at <0>{{ eventName }}</0>, you have been promoted to the main draw. <0>See details</0>.",
    eventRegistrationSLOpen: "Registrations on the sorting list for <0>{{ eventName }}</0> are open. <0>Join Now</0>.",
    eventRegistrationSLClosed: "Registrations on the Sorting List for <0>{{ eventName }}</0> have been closed. The sorting for the Main List and the Waiting List, based on ranking positions, is in progress. <0>See details</0>.",
    eventRegistrationMLOpen: "Registrations on the Main List for <0>{{ eventName }}</0> are open. <0>Join Now</0>.",
    eventRegistrationMLClosed: "Registrations on the Main List for <0>{{ eventName }}</0> have been closed. Registration on the Waiting List are still open until the day before the start of the competition. <0>See details</0>.",
    eventMainDrawPublished: "Registrations on the Main List for <0>{{ eventName }}</0> have been closed. Registration on the Waiting List are still open until the day before the start of the competition. <0>See details</0>.",
    eventStarted: "Matches in <0>{{ eventName }}</0> start today. <0>See details</0>.",
    eventEnded: "Matches in <0>{{ eventName }}</0> ended. Results and winners are being updated. <0>See details</0>.",
    eventClosed: "Results for <0>{{ eventName }}</0> have been updated. <0>See details</0>.",
    eventAnnouncementUpdates: "<0>{{ eventName }}</0> tournament update: \n",
    friendRequestReceived: "You have a friend request from {{ partnerName }}. <0>See Details</0>.",
    friendRequestAccepted: "You have a new friend on Sportya - {{ partnerName }}. <0>View Player</0>.",
    friendJoined: "Your friend {{ partnerName }} joined Sportya. <0>View Player</0>.",
    friendRaisedHand: "Your friend {{ partnerName }} is looking for a Friendly Match. <0>Invite to Match</0>.",
    invitationReceived: "{{ partnerName }} invited you to a {{ matchGameType }} Match - <strong>{{ matchFormatedId }}</strong>. <0>Go to Match Requests</0>.",
    pastMatchInvite: "Confirm your participation and score for the {{ matchGameType }} Match with {{ partnerName }} - <strong>{{ matchFormatedId }}</strong>. <0>Go to Match Requests</0>.",
    matchAccepted: "{{ partnerName }} accepted your invitation to a {{ matchGameType }} match - <strong>{{ matchFormatedId }}</strong>. <0>Go to Matches</0>.",
    matchDeclined: "{{ partnerName }} declined your invitation to a {{ matchGameType }} match - <strong>{{ matchFormatedId }}</strong>. <0>Go to Matches</0>.",
    matchDenied: "{{ partnerName }} denied participation in your played {{ matchGameType }} match - <strong>{{ matchFormatedId }}</strong>. <0>Go to Matches</0>.",
    playerWithdrew: "{{ partnerName }} withdrew from your {{ matchGameType }} match - <strong>{{ matchFormatedId }}</strong>. <0>Go to Matches</0>.",
    addScoreReminder: "Add the score to your {{ matchGameType }} match - <strong>{{ matchFormatedId }}</strong>. <0>Add Score</0>.",
    upcomingMatchReminder: "Your {{ matchGameType }} Match starts in 30 minutes - <strong>{{ matchFormatedId }}</strong>. <0>Go to Matches</0>.",
    pastMatchConfirmed: "{{ partnerName }} confirmed your played {{ matchGameType }} match and score - <strong>{{ matchFormatedId }}</strong>. <0>Go to Matches</0>.",
    scoreAdded: "{{ partnerName }} added the score for your {{ matchGameType }} match - <strong>{{ matchFormatedId }}</strong>. <0>Go to Matches</0>.",
    scoreChanged: "{{ partnerName }} changed the score for your {{ matchGameType }} match - <strong>{{ matchFormatedId }}</strong>. <0>Go to Matches</0>.",
    scoreValidated: "The final score for your {{ matchGameType }} has been validated - <strong>{{ matchFormatedId }}</strong>. <0>Go to Matches</0>.",
    canceledMatch: "Your {{ matchGameType }} match has been canceled - <strong>{{ matchFormatedId }}</strong>. <0>Go to Matches</0>.",
    updatedMatch: "Your {{ matchGameType }} match has been updated - <strong>{{ matchFormatedId }}</strong>. <0>Go to Matches</0>.",
    playerRatingReceived: "You have just received a new player rating after a friendly match. <0>Show my Average Rating</0>.",
    newRewardReceived: "Congratulations! You've earned a new reward. <0>Check your Virtual Wallet</0>",
    bookingCancellation: "Your court booking at <strong>{{ clubName }}</strong> has been canceled by the Club - <strong>{{ bookingId }}</strong>. <0>View Booking Details</0>.",
    bookingRescheduled: "Your court booking at <strong>{{ clubName }}</strong> has been rescheduled by the Club - <strong>{{ bookingId }}</strong>. <0>View Booking Details</0>.",
  },
  creditCard: 'Credit Card',
  virtualWallet: 'Virtual Wallet',
  summerMembership: 'Summer Account',
  premiumMembership: 'Premium Account',
  premium: 'Premium {{period}} Account',
  undefinedMembership: 'Premium',
  transactionTypes: {
    topUp: 'Virtual Wallet: Top-Up',
    topUpByAdmin: 'Virtual Wallet: Top-Up (by Admin)',
    subtractByAdmin: 'Virtual Wallet: Subtract (by Admin)',
    eventRegistration: 'Event Registration: <0>{{ eventName }}</0>',
    eventWithdrawal: 'Event Withdrawal: <0>{{ eventName }}</0>',
    upgradeMembership: 'Membership Upgrade: <1>{{ membershipName }}</1>',
    upgradeMembershipByAdmin: 'Membership Upgrade (By Admin): <1>{{ membershipName }}</1>',
    balanceAdjustmentByAdmin: 'Balance Adjustment (By Admin)',
    waitingListRefund: 'Waiting-List Automatic Refund',
    currencyNotSet: 'You must set up your location in order to start using your Virtual Wallet and Top-Up feature.',
    date: '<0>Date:</0> {{ createdAt }}',
    firstTaskCompletion: "Reward: Own eligible action",
    referralReward: "Reward: Invited player action",
    courtBooking: "Booking <1>#{{ bookingId }}",
    courtBookingRefund: "Booking Refund <1>#{{ bookingId }}",
  },
  notificationButton: 'Notification Button',
  latestTransactions: 'Latest Transactions',
  partnerEventRegistration: 'Partner - Event Registration: <1>{{ eventName }}</1>',
  planUpdate: 'Upgrade Membership: <1>{{ membershipName }}</1>',
  referral: {
    title: "Invite Your Friends & Get Rewarded",
    title2: "Join Sportya and Get Rewarded!",
    subtitle: "Invite your friends to Sportya and get rewarded for the first {{ maxRewards }} players* who join Sportya with your referral code! You will each get <strong>{{ amount }} {{ currency }}</strong> in your Virtual Wallets** as soon as <strong>one of the following actions is successfully completed by the invited player for the 1st time</strong>:",
    subtitle2: "Get rewarded as soon as you complete ONE of the following actions for the 1st time:",
    item1: "Get their First Validated Result in a Sportya tournament",
    item11: "Get your First Validated Result in a Sportya tournament",
    item2: "Top-Up their Virtual Wallet",
    item22: "Top-Up your Virtual Wallet",
    item3: "Purchase a Premium account with Virtual Wallet or Credit Card",
    item4: "Participate in a Friendly Match with a validated score for the 1st time.",
    amountEarned: "{{ amount }} {{ currency }} Earned",
    rewardsCompleted: "Rewards Completed {{ receivedRewards }}/{{ maxRewards }}",
    yourFriend: "Invite",
    referVia: "Refer via",
    shareTitle: "Share",
    smallText1: "*The player who invites will be rewarded only for the first {{ maxRewards }} players who join Sportya using their referral code and successfully complete one of the required actions for the first time.",
    smallText2: "**The Virtual Wallet of the rewarded player will be automatically credited with 5 Euros or the equivalent value in the currency of their Virtual Wallet as soon as one of the eligible actions is successfully completed. Each user will receive the reward only once, for only one of the eligible actions.",
    smallText3: "*The equivalent amount of the reward value will be credited to your balance in the currency of your Virtual Wallet on Sportya.",
    smallText4: "**Only one of the eligible actions will be rewarded when completed for the 1st time.",
    smallText5: "Please check the <1>Terms and Conditions</1> to find out more about Sportya rewards",
    inviteFriends: "Invite Your Friends & Get Rewarded",
    inviteFriendsDescription: "Get <strong>{{ amount }} {{ currency }}</strong> for each of the first {{ maxRewards }} players who join Sportya with your referral code!",
    inviteYourFriends: "Invite your Friends",
    invite: {
      playTogether: 'Invite your friends and play together',
      friends: 'Invite your Friends',
    },
    shareToMedia: {
      email: {
        title: 'Let\'s play tennis in Sportya',
        body: `Hi there! %0D%0A
I’m using Sportya, the competitive sports platform for amateur tennis players, and I think you should join too! %0D%0A
With Sportya, you can find and connect with other players, play friendly matches together, or join tournaments and compete for the top spots in national and international rankings! %0D%0A
It’s a great way to stay active and have fun with friends. %0D%0A
%0D%0A
Join me on Sportya using my unique invitation link and get rewarded! %0D%0A
{{inviteLink}}`,
      },
      facebook: `Join me on Sportya, the competitive sports platform for amateur tennis players!\r\n
We can connect with other players, play friendly matches together, or join tournaments and compete for the top spots in national and international rankings!\r\n
Use my unique invitation link: {{inviteLink}}`,
      twitter: 'Join me on Sportya, the competitive sports platform for amateur tennis players!%0aUse my unique invitation link to signup and connect with other players to play friendly matches or compete in tournaments. Let’s play!%0a',
    },
    invitedBy: 'You\'ve been invited by <1>{{referee}}</1>.',
    joinSportya: 'Join Sportya and get rewarded! <1>Find out more</1>.',
    share: 'Share your Love for Tennis! Invite your Friends to Sportya, and play friendly matches together, or compete for the top spots in national and international rankings!',
    copyAndShare: 'Copy and share your invitation link',
    willBeFriend: 'The friends you invite and sign up to Sportya will be automatically added to your Friends list.',
    copy: 'Copy',
    dashboardBannerTitle: "You're one step closer to your reward",
    dashboardBannerSubtitle: "Get <1>{{ amount }} {{ currency }}</1> as soon as you complete ONE of the following actions for the 1st time:",
    congrats: 'Congrats! You just received <1>{{ amount }} {{ currency }}</1> in your <3>Virtual Wallet</3> for successfully completing your 1st action on Sportya.',
    findOutMore: 'Find out more about <1>Sportya Rewards</1> or check <3>Terms and Conditions</3>',
  },
  ratePlayer: 'Rate Player',
  changeRating: 'Change Rating',
  receivedRating: '{{ total }} Rating Received',
  receivedRatings: '{{ total }} Ratings Received',
  ratePlayerInfo: 'You can rate players only after participating in at least one friendly match with them.<br/>The average Player Rating is calculated based on all ratings received from other players and will be<br/>publicly displayed on the player\'s profile once a minimum of 3 ratings have been received.',
  noRatings: 'No Ratings',
  rate: 'Save Rating',
  removeRating: 'Remove Rating',
  ownRatingInfo: 'Your average Player Rating will be publicly displayed on your profile once you have received a minimum of 3 ratings from players you have interacted with.',
  skipRating: 'Skip Rating',
  howWouldRatePlayer: 'Rate Player',
  howWouldRateTitlePartner: 'Rate Partner',
  howWouldRateTitleOpponent: 'Rate Opponent 1',
  howWouldRateTitleOpponentPartner: 'Rate Opponent 2',
  thankYouForRating: 'Thank you for your rating!',
};
