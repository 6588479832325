import aes from 'crypto-js/aes';

import { post } from '../../../components/common/http';
import { getAccountInfo, setMasterSport } from '../../../components/Layout/reducers/session';
import mixpanel from '../../../mixpanel';

export const login = async ({
  values,
  addToast,
  setSubmitting,
  history,
  t,
  dispatch,
  redirect,
  isSignUp,
  defaultMasterSport,
}) => {
  let masterSport = defaultMasterSport;
  let account;
  try {
    const KEY = process.env.REACT_APP_ENC_KEY;
    const { email, password, remember } = values;
    const securedValues = {
      email,
      password: aes.encrypt(password, KEY).toString(),
      remember,
    };
    account = await post('/accounts/auth', securedValues);
    if (account?.data?.data?.favoriteSport) {
      masterSport = account?.data?.data?.favoriteSport;
    }
    await dispatch(getAccountInfo({ masterSport }));
    dispatch(setMasterSport(masterSport));
  } catch (error) {
    if (setSubmitting) {
      setSubmitting(false);
    }

    return (
      addToast
      && addToast(t(error.response?.data?.message || 'INTERNAL_ERROR'), {
        appearance: 'error',
        autoDismiss: true,
      })
    );
  }

  const { data: { data: accountInfo } = {} } = account || {};
  const isStepCompleted = (v) => (!v || (Object.values(v).every(x => !!x)));

  const profileDetailsCompleted = isStepCompleted({
    birthDate: accountInfo?.birthDate,
    gender: accountInfo?.gender,
    location: accountInfo?.location,
    dominantHand: accountInfo?.dominantHand,
    favoriteSport: accountInfo?.favoriteSport,
    'gameLevel.singles': accountInfo?.sports?.[masterSport]?.singles?.gameLevel,
    'gameLevel.doubles': accountInfo?.sports?.[masterSport]?.doubles?.gameLevel,
  });

  if (isSignUp) {
    mixpanel.people.set({
      sp_membership: 'free',
      sp_registration_method: 'Email',
      sp_profile_city: accountInfo.location?.city,
      sp_profile_country: accountInfo.location?.country,
    });
    mixpanel.track('FE Created account', {
      sp_created_account_method: 'Email',
      sp_created_account_newsletter: accountInfo.communicationPreferences?.newsletter ? 'on' : 'off',
    });
  }

  const isWelcomePage = !profileDetailsCompleted && accountInfo.role === 'player' && accountInfo.status === 'activated';
  const dashboard = redirect !== undefined
    ? `/${redirect.replace(/~(\b(?!account)\b)/g, '/')}`
    : `/${accountInfo?.language || 'en'}/dashboard`;
  return history.push(isWelcomePage ? '/dashboard/welcome' : dashboard);
};
