import styled from '@emotion/styled';

export const SoonToBeAnnounced = styled.div`
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-bottom: -20px;
    background-color: #EAF6F6;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: relative;

    h1 {
        position: absolute;
        font-size: 5vw;
        color: ${({ theme }) => theme.colors?.secondary};
        top: 7vw;
        left: 2vw;
        max-width: 35vw;
        line-height: 1.5;
        padding: 0 !important;
        margin: 0 !important;
    }

    img {
        width: 100%;
        object-fit: contain;
        display: block;
    }

    ${props => props.theme.functions?.mediaQueryMin('1350px', `
      h1 {
        max-width:420px;
        font-size: 60px;
        top:65px;
        left:35px;
      }
  `)}
}
`;
