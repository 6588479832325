/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ModalVideo from 'react-modal-video';

import 'react-modal-video/scss/modal-video.scss';

import {
  Container, FormContainerLevel, List, Item,
} from './styles';
import { getAccountInfo } from '../../../components/Layout/reducers/session';
import { store as socketStore } from '../../../components/Socket/store';
import { updateAccount } from './actions';
import mixpanel from '../../../mixpanel';

import Logout from '../../../components/Logout';
import {
  Contain, H2, P, Button,
} from '../../../components/Collection';
import LoadImage from '../../../components/common/LoadImage';
import GoBackButton from "../../../components/BackButton";
import getNextStep from './helpers';

const demonstrationVideos = {
  4: 'kp6EmPM8T6o',
  5: '9iSEzTTtvoY',
  6: 'OTjLF_jij-8',
  7: '4OsShZOTxdw',
  8: 'RIvu-6NY0y4',
  9: 'Ukvmhrv-isw',
};

const SetGameLevel = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('accounts');
  const { t: commonT } = useTranslation('common');
  const { accountInfo, profileCompletion, masterSport } = useSelector(state => state.session);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const globalState = useContext(socketStore);
  const { state: { socket: { socketClientId, socket } } } = globalState;

  const [gameLevel, setGameLevel] = useState(4);
  const [isOpen, setOpen] = useState(false);
  const [gameLevelSet, setGameLevelSet] = useState(false);

  const query = new URLSearchParams(history.location.search);
  const sportType = query.get('sportType') || masterSport;

  useEffect(() => {
    if (
      sportType
      && accountInfo?.sports?.[sportType]?.singles?.gameLevel
      && accountInfo?.sports?.[sportType]?.doubles?.gameLevel
    ) {
      history.push(`/${accountInfo?.language || 'en'}/dashboard?congrats`);
    }
  }, [sportType]);

  useEffect(() => {
    const listenForResponse = async ({ success, message }) => {
      await addToast(commonT(message), {
        appearance: success ? 'success' : 'error',
        autoDismiss: true,
      });

      if (success) {
        await dispatch(getAccountInfo({ masterSport }));
      }

      setIsSubmitting(false);
    };

    if (socket) {
      socket.removeAllListeners('accounts-response');
      socket.on('accounts-response', listenForResponse);
      return () => socket.removeAllListeners('accounts-response');
    }
  }, [socketClientId, masterSport]); // eslint-disable-line

  useEffect(() => {
    const nextStepUrl = getNextStep("gameLevel", profileCompletion);
    if (nextStepUrl && !query.get('sportType')) {
      return history.push(nextStepUrl);
    }
  }, [profileCompletion, query]);

  const formSchema = Yup.object().shape({
    gameLevel: Yup.object().shape({
      singles: Yup.number().required(commonT('required')),
      doubles: Yup.number().required(commonT('required')),
    }),
  });

  const increaseLevel = () => {
    if (gameLevel === 9 || gameLevelSet) return;
    setGameLevel(gameLevel + 1);
  };

  const decreaseLevel = () => {
    if (gameLevel === 4 || gameLevelSet) return;
    setGameLevel(gameLevel - 1);
  };

  const unlockLevel = () => {
    setGameLevelSet(false);
  };

  const disabled = {
    left: gameLevel === 4,
    right: gameLevel === 9,
  };

  const logLevelSelection = (payload, filled) => {
    mixpanel.identify(accountInfo?.mixpanelId);
    if (filled) {
      mixpanel.people.set({
        sp_game_level_single: payload.gameLevel.singles,
        sp_game_level_double: payload.gameLevel.doubles,
      });
    }
    mixpanel.track('Select game level', {
      sp_selected_level: filled ? 'completed' : 'skipped',
    });
  };

  return (
    <Container level>
      <Logout />
      <Contain className="parent" align="center">
        <Contain
          margin="0 80px 0 0"
          maxWidth="480px"
          direction="column"
          className="first"
        >
          <H2 className="mb20 d-flex align-items-center">
            <GoBackButton />
            <Trans
              ns="accounts"
              i18nKey="completeProfile.gameLevel"
              context={sportType}
            >
              Game level
            </Trans>
          </H2>
          <P className="mb20 fs16">
            <Trans ns="accounts" i18nKey="completeProfile.gameLevelDescription">
              When we step on the tennis court, we wish to play balanced
              matches. For this reason, the self-assessment of level of play and
              level-based matches and events became the norm for satisfying
              tennis experiences in Sportya.
            </Trans>
          </P>
          <List>
            <Item>
              <P small>
                <Trans ns="accounts" i18nKey="completeProfile.gameLevelList.1">
                  During the Sportya registration procedure, each player
                  estimates its potential at that moment, giving a grade, from 4
                  to 9, corresponding to a level of play. Although, in Sportya,
                  Singles and Doubles are treated separately and players have
                  different levels of play for Singles and Doubles, you will
                  initially set a level of play indicative of your Singles game
                  potential. This will automatically be your starting level of
                  play for your Doubles matches, but levels of play for Singles
                  and Doubles will / can be adjusted based on your match
                  results.
                </Trans>
              </P>
            </Item>
            <Item>
              <P small>
                <Trans ns="accounts" i18nKey="completeProfile.gameLevelList.2">
                  This is a very important step and can have a great impact in
                  your initial experiences in Sportya events and matches. Please
                  take your time, read the description and watch the video
                  examples of each level of play. Choose your level wisely, do
                  not overestimate or underestimate your skill. Try to be as
                  objective as you can.
                </Trans>
              </P>
            </Item>
            <Item>
              <P small>
                <Trans ns="accounts" i18nKey="completeProfile.gameLevelList.3">
                  Level 4 corresponds to beginners, while level 9 is the closest
                  level to professional play (usually chosen by retired
                  professional players).
                </Trans>
              </P>
            </Item>
          </List>
        </Contain>
        <Contain className="second" maxWidth="350px">
          <Formik
            enableReinitialize
            initialValues={{
              gameLevel: {
                singles: gameLevel || 4,
                doubles: gameLevel || 4,
              },
            }}
            validationSchema={formSchema}
            onSubmit={async (values) => {
              setIsSubmitting(true);

              logLevelSelection(values, true);

              await updateAccount({
                id: accountInfo.id,
                payload: {
                  setInitialGameLevel: true,
                  ...values,
                  masterSport: sportType,
                },
                addToast,
                socketClientId,
                dispatch,
                history,
                t: commonT,
              });
            }}
          >
            {({ isValid }) => (
              <Form>
                <Field
                  type="hidden"
                  name="gameLevel[singles]"
                  value={gameLevel}
                />
                <Field
                  type="hidden"
                  name="gameLevel[doubles]"
                  value={gameLevel}
                />

                <FormContainerLevel {...(gameLevelSet && { levelSet: true })}>
                  <P bold className="text-center mb30">
                    <Trans
                      ns="accounts"
                      i18nKey="completeProfile.chooseGameLevel"
                      context={sportType}
                    >
                      Choose your game level
                    </Trans>
                  </P>
                  <Contain width="100%" justify="space-between" align="center">
                    <div
                      onClick={() => (gameLevelSet ? unlockLevel() : decreaseLevel())}
                      className={`cursor-pointer ${disabled.left ? 'disabled' : ''}`}
                    >
                      <svg
                        width="11"
                        height="18"
                        viewBox="0 0 11 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {/* eslint-disable-next-line max-len */}
                        <path d="M10.5303 17.5303C10.831 17.2291 11 16.8208 11 16.3952C11 15.9695 10.831 15.5613 10.5303 15.2601L4.70497 9.43482L10.5303 3.60954C10.814 3.30506 10.9684 2.90233 10.9611 2.4862C10.9538 2.07008 10.7852 1.67305 10.4909 1.37876C10.1966 1.08447 9.79958 0.915894 9.38345 0.908552C8.96733 0.901211 8.5646 1.05567 8.26011 1.3394L1.29975 8.29975C0.998957 8.60092 0.830003 9.00917 0.830003 9.43482C0.830003 9.86048 0.998956 10.2687 1.29975 10.5699L8.2601 17.5303C8.56127 17.831 8.96952 18 9.39518 18C9.82083 18 10.2291 17.831 10.5303 17.5303Z" fill={gameLevelSet ? '#10242C' : '#ffffff'} />
                      </svg>
                    </div>
                    <Contain direction="column" align="center">
                      <P small bold>
                        {commonT('level').toUpperCase()}
                      </P>
                      <H2>{gameLevel}</H2>
                    </Contain>
                    <div
                      onClick={() => (gameLevelSet ? unlockLevel() : increaseLevel())}
                      className={`cursor-pointer ${disabled.right ? 'disabled' : ''}`}
                    >
                      <svg
                        width="11"
                        height="18"
                        viewBox="0 0 11 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {/* eslint-disable-next-line max-len */}
                        <path d="M0.469749 17.5303C0.168953 17.2291 -4.05513e-07 16.8208 -5.51894e-07 16.3952C-6.98275e-07 15.9695 0.168952 15.5613 0.469748 15.2601L6.29503 9.43482L0.469744 3.60954C0.186018 3.30506 0.031556 2.90233 0.0388979 2.4862C0.0462398 2.07008 0.214813 1.67305 0.509103 1.37876C0.803393 1.08447 1.20042 0.915894 1.61655 0.908552C2.03267 0.901211 2.4354 1.05567 2.73989 1.3394L9.70025 8.29975C10.001 8.60092 10.17 9.00917 10.17 9.43482C10.17 9.86048 10.001 10.2687 9.70025 10.5699L2.7399 17.5303C2.43873 17.831 2.03048 18 1.60482 18C1.17917 18 0.770919 17.831 0.469749 17.5303Z" fill={gameLevelSet ? '#10242C' : '#ffffff'} />
                      </svg>
                    </div>
                  </Contain>
                  <P small className="mb20">
                    <Trans
                      ns="accounts"
                      i18nKey={`completeProfile.levelDescription.${gameLevel}`}
                      context={sportType}
                    />
                  </P>
                  {sportType === "tennis" && (
                    <div
                      onClick={() => setOpen(true)}
                      className={`cursor-pointer watch-video ${!!gameLevelSet && 'selected-level'}`}
                    >
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        {/* eslint-disable-next-line max-len */}
                        <path d="M10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0ZM7.053 6.585C7.018 6.65483 6.99985 6.73189 7 6.81V13.192C7 13.2773 7.02181 13.3611 7.06335 13.4356C7.10489 13.51 7.16478 13.5726 7.23733 13.6174C7.30988 13.6622 7.39268 13.6877 7.47786 13.6915C7.56304 13.6953 7.64777 13.6772 7.724 13.639L14.106 10.449C14.1892 10.4075 14.2592 10.3437 14.3081 10.2646C14.357 10.1856 14.383 10.0945 14.383 10.0015C14.383 9.90854 14.357 9.81741 14.3081 9.73836C14.2592 9.65931 14.1892 9.59547 14.106 9.554L7.724 6.362C7.60545 6.3026 7.46815 6.29272 7.34232 6.33454C7.21648 6.37636 7.11241 6.46645 7.053 6.585Z" fill={gameLevelSet ? '#10242C' : '#ffffff'} />
                      </svg>
                      {t('completeProfile.seeVideoDemonstration')}
                    </div>
                  )}
                  {!gameLevelSet ? (
                    <Button
                      noBorder
                      width="270px"
                      onClick={() => setGameLevelSet(true)}
                      className="mt40"
                      type="button"
                    >
                      {t('completeProfile.selectThisLevel')}
                    </Button>
                  ) : (
                    <div className="text-center mt50 mb20">
                      <img src={LoadImage('checkmark-big.svg')} alt="check" />
                    </div>
                  )}
                </FormContainerLevel>

                {gameLevelSet && (
                  <Button
                    type="submit"
                    disabled={isSubmitting || !isValid}
                    className="mt40"
                    width="100%"
                  >
                    {commonT('continue')}
                  </Button>
                )}
              </Form>
            )}
          </Formik>
        </Contain>
      </Contain>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId={demonstrationVideos[gameLevel]}
        onClose={() => setOpen(false)}
      />
    </Container>
  );
};

export default SetGameLevel;
