import styled from '@emotion/styled';
import { Button, H3, P } from '../../../components/Collection';
import LoadImage from '../../../components/common/LoadImage';

export const Banner = styled.div({
  display: 'flex',
  alignItems: 'center',
  height: '130px',
  borderRadius: '10px',
  padding: '0 55px',
  background: `url(${LoadImage('friendly-matches/start-match/banner-bg.png')})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '100%',

  a: {
    textDecoration: 'none',
  },

  '@media (max-width: 1100px)': {
    flexDirection: 'column',
    textAlign: 'center',
    height: 'unset',
    padding: '20px 30px',
  },

  '@media (max-width: 590px)': {
    flexDirection: 'column',
    textAlign: 'center',
    height: 'unset',
    padding: '20px 30px',
    background: `url(${LoadImage('friendly-matches/start-match/banner-bg-mobile.png')})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
});

export const BannerText = styled(H3)({
  color: '#5FFDF7',
  maxWidth: '615px',
  margin: '0 auto 0 0',
  '@media (max-width: 1100px)': {
    margin: '0 0 20px 0',
  },
});

export const NewMatchButtonBanner = styled(Button)({
  padding: '0 30px 0 20px',
  '@media (max-width: 590px)': {
    width: '100%',
  },
});

export const NewMatchButton = styled.div(({ isHeaderButton }) => ({
  display: 'flex',
  margin: `0 0 0 ${isHeaderButton ? '20px' : '50px'}`,
  cursor: 'pointer',
  userSelect: 'none',
  ...isHeaderButton && {
    minWidth: '165px',
    width: 'max-content',
    alignItems: 'center',
  },
  '@media (max-width: 1100px)': {
    margin: '20px 0 0 0',
  },
}));

export const ButtonIcon = styled.img({});

export const CategoryTitle = styled(P)({
  display: 'flex',
  alignItems: 'center',

  '@media (max-width: 479px)': {
    justifyContent: 'space-between',
  },
});

export const InviteOrRematchButton = styled(Button)({
  padding: '0 20px 0 10px',
  height: '40px',
  minWidth: 140,
  '@media (max-width: 960px)': {
    width: '100%',
  },
});

export const InviteOrRematchButtonContainer = styled.div({
  '@media (max-width: 960px)': {
    margin: '20px 0 0 0',
    width: '100%',
  },
});

export const SearchForAPlayerBanner = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',

  margin: '40px 0 0 0',

  minHeight: '180px',
  padding: '36px',

  borderRadius: '10px',
  background: `url(${LoadImage('friendly-matches/start-match/search-player-banner-bg.png')})`,

  '@media (max-width: 479px)': {
    h3: {
      textAlign: 'center',
    },
    button: {
      width: '100%',
    },
  },
});

export const ButtonRightArrowIcon = styled.img({
  margin: '0 0 0 10px',
});

export const ButtonTopPicksIcon = styled.img({});
