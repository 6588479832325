/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, debounce } from 'lodash';

import RangeDatePicker from './RangeDatePicker';
import RangeDateSelects from './RangeDateSelects';

import Select from '../../../../components/Form/Select';
import {
  fetchEventsLocations,
  resetEventsLocations,
  setActiveFilters,
} from '../../reducers';
import { ClearSearch, Filters, MobileFiltersContainer } from './styles';
import { Button, H4, Modal } from '../../../../components';
import ToggleVisible from '../../../../components/common/ToggleVisible';
import LoadImage from '../../../../components/common/LoadImage';
import { Contain } from '../../../../components/Collection';

const currentYear = new Date().getFullYear();

const years = [];
for (let i = 2009; i <= currentYear; i++) {
  years.push({
    label: i,
    value: i,
  });
}
years.reverse();

const EventFilters = ({
  filters,
  setFilters,
  formFilters,
  setFormFilters,
  handlePagination,
  triggerScroll,
  isBanned,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('events');
  const { t: commonT } = useTranslation('common');
  const { data: locations, status } = useSelector((state) => state.events?.locations);
  const { data: settings } = useSelector((state) => state.settings);
  const [firstSelect, setFirstSelect] = useState(null);
  const [secondSelect, setSecondSelect] = useState(null);
  const [filterChange, setFilterChange] = useState({});
  const [reRender, setReRender] = useState();
  const { ref, isComponentVisible, setIsComponentVisible } = ToggleVisible(false);
  const [searchField, setSearchField] = useState('');
  const [locationsFetchedFrom, setLocationsFetchedFrom] = useState('activeEvents');
  const [loadingCurrentLocation, setLoadingCurrentLocation] = useState(false);
  const [mobileFiltersModal, setMobileFiltersModal] = useState(false);

  useEffect(() => {
    if (searchField.length > 1) {
      handleChange(searchField);
    }
  }, [searchField]);

  useEffect(() => {
    const start =
      !isEmpty(firstSelect) &&
      new Date(firstSelect?.year, firstSelect?.month, firstSelect?.day);
    const end =
      !isEmpty(secondSelect) &&
      new Date(secondSelect?.year, secondSelect?.month, secondSelect?.day);

    if (!isEmpty(firstSelect) && !isEmpty(secondSelect)) {
      if (start > end) {
        return handleFormFilters({
          ...filters,
          startDate: `${secondSelect.day}-${secondSelect.month}-${secondSelect.year}`,
          endDate: `${firstSelect.day}-${firstSelect.month}-${firstSelect.year}`,
        });
      }
      return handleFormFilters({
        ...filters,
        startDate: `${firstSelect.day}-${firstSelect.month}-${firstSelect.year}`,
        endDate: `${secondSelect.day}-${secondSelect.month}-${secondSelect.year}`,
      });
    }
  }, [firstSelect, secondSelect]);

  const debouncedSearch = debounce(async (inputValue, key) => {
    switch (key) {
      case 'activeEvents':
        setLocationsFetchedFrom('endedEvents');
        await dispatch(
          fetchEventsLocations({ filters: { endedEvents: true } })
        );
        break;
      case 'endedEvents':
      case 'allLocations':
        if (key !== 'allLocations') {
          setLocationsFetchedFrom('allLocations');
        }
        await dispatch(
          fetchEventsLocations({
            filters: { allLocations: true, locationName: inputValue },
          })
        );
        break;
      default:
    }
  }, 300);

  const handleChange = async (inputValue) => {
    dispatch(resetEventsLocations());
    debouncedSearch(inputValue, locationsFetchedFrom);
  };

  const handleLocations = () => {
    if (status === 'idle') {
      dispatch(fetchEventsLocations({ filters }));
    }
  };

  const dispatchFilters = (values) => {
    if (!isEmpty(values)) {
      delete values.limit;
      delete values.role;
      delete values.page;
    }
    dispatch(setActiveFilters(values));
  };

  const gameTypes = settings?.gameTypes ?? [];
  const levels = settings?.levels ?? [];
  const hasActiveFilters =
    Object.keys(filters).filter((key) =>
      ['location', 'radius', 'month', 'level', 'gameType'].includes(key)
    ).length > 0;

  const months = [
    {
      value: 'range',
      label: (
        <>
          <img src={LoadImage('calendar-icon.svg')} className="mr5" alt="" />
          <Trans ns="events" i18nKey="specificMonth">
            Set custom dates...
          </Trans>
        </>
      ),
    },
    { value: 1, label: commonT('january') },
    { value: 2, label: commonT('february') },
    { value: 3, label: commonT('march') },
    { value: 4, label: commonT('april') },
    { value: 5, label: commonT('may') },
    { value: 6, label: commonT('june') },
    { value: 7, label: commonT('july') },
    { value: 8, label: commonT('august') },
    { value: 9, label: commonT('september') },
    { value: 10, label: commonT('october') },
    { value: 11, label: commonT('november') },
    { value: 12, label: commonT('december') },
  ];

  const mapLocations = (array = [], skipCount = false) => {
    const newArray = [...new Set(array)];
    newArray.unshift({ value: 'currentLocation', name: 'currentLocation' });
    return newArray?.map((value) => ({
      value:
        value.city || value.country
          ? `${value.city}, ${value.country}`
          : value.value,
      label: value.city ? (
        `${commonT(value.city)}, ${commonT(value.country)} ${
          value.total && !skipCount ? `(${value.total})` : ''
        } `
      ) : value.name === 'currentLocation' ? (
        <>
          <img src={LoadImage('location-icon.svg')} className="mr5" alt="" />
          {commonT(value.name)}
        </>
      ) : (
        commonT(value.name)
      ),
      coordinates: value.coordinates,
    }));
  };

  const mapLevels = () =>
    levels?.map((values) => ({
      value: values.key,
      label: values.name,
    }));

  const mapGameTypes = () =>
    gameTypes?.map((values) => ({
      value: values.key,
      label: values.name,
    }));

  const mapProximity = () => {
    const proximity = [
      { key: '30' },
      { key: '50' },
      { key: '100' },
      { key: '150' },
    ];
    return proximity?.map((e) => ({
      value: e.key,
      label: `${e.key} km`,
    }));
  };

  const nationalCalendarOptions = () => {
    return (settings?.eligibleCountries || [])?.map((v) => ({
      value: v.name,
      label: commonT(`countriesByKey.${v.key}`),
    }));
  };

  const getCoords = (values) => {
    const { coords } = values;
    if (values) {
      setLoadingCurrentLocation(false);
      setFormFilters({
        ...filters,
        radius: '30',
        coordinates: `${coords.latitude},${coords.longitude}`,
      });
    }
  };

  const getLocation = () => {
    setLoadingCurrentLocation(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(getCoords);
      setFilterChange({ location: true });
    }
  };

  const handleFilters = (values) => {
    if (values?.month?.value === 'range') {
      // eslint-disable-next-line no-param-reassign
      delete values.month;
    }
    if (!isEmpty(values)) {
      triggerScroll();
    }
    handlePagination({ selected: 0 });
    setFilters(values);
    dispatchFilters(values);
  };

  const resetLocationsToDefault = () => {
    if (locationsFetchedFrom !== 'activeEvents') {
      setLocationsFetchedFrom('activeEvents');
      dispatch(fetchEventsLocations({ filters: { endedEvents: false } }));
    }
  };

  function handleFormFilters(values) {
    setReRender(!reRender);

    if (values?.value === 'currentLocation') {
      return getLocation();
    }

    if (values?.value === 'range') {
      setFilterChange({ month: true });
      setFormFilters({
        ...formFilters,
        month: months[0],
      });
      return setIsComponentVisible(!isComponentVisible);
    }

    if (this?.name === 'location') {
      if (values) {
        if (values?.value.split(',').includes('null')) {
          setFilterChange({ ...filterChange, location: true, radius: false });
          delete formFilters.radius;
          return setFormFilters({
            ...formFilters,
            [this.name]: values.value,
          });
        }
        setFilterChange({ ...filterChange, location: true, radius: true });
        return setFormFilters({
          ...formFilters,
          radius: '30',
          coordinates: `${values.coordinates.lat}, ${values.coordinates.lng}`,
          [this.name]: values.value,
        });
      }
    }

    if (this?.name === "nationalCalendar") {
      const location = values?.value ? `null, ${values.value}` : undefined;
      delete formFilters.location;
      delete formFilters.radius;
      delete formFilters.coordinates;

      // if active, will fetch data on change
      /*
      delete filters.location;
      delete filters.radius;
      delete filters.coordinates;
      const newFilters = {
        ...filters,
        [this.name]: values?.value,
        ...location && filters.location !== location && {
          location,
        },
      };
      handlePagination({ selected: 0 });
      setFilters(newFilters);
      */

      return setFormFilters({
        ...formFilters,
        [this.name]: values?.value,
        ...location && formFilters.location !== location && {
          location,
        },
      });
    }

    if (values?.startDate || values?.endDate) {
      delete formFilters.month;
      delete filters.month;
      return setFormFilters({
        ...formFilters,
        startDate: values.startDate,
        endDate: values.endDate,
      });
    }
    if (values?.month) {
      delete formFilters.startDate;
      delete formFilters.endDate;
      delete filters.startDate;
      delete filters.endDate;
      setFormFilters({ ...formFilters });
      return null;
    }

    if (!values || !values.value) {
      if (this) {
        setIsComponentVisible(false);
        delete filters[this.name];
        delete formFilters[this.name];
        if (this.name === 'month') {
          delete filters.startDate;
          delete formFilters.startDate;
          delete filters.endDate;
          delete formFilters.endDate;
        }

        if (this.name === 'location') {
          delete filters.location;
          delete formFilters.location;
          delete filters.coordinates;
          delete formFilters.coordinates;
          delete filters.radius;
          delete formFilters.radius;
          setFilterChange({
            ...filterChange,
            coordinates: false,
            radius: false,
          });
          resetLocationsToDefault();
        }

        setFilterChange({ ...filterChange, [this.name]: false });
      } else {
        delete filters[values.label];
        delete formFilters[values.label];
        setFilterChange({ ...filterChange, [values.label]: false });
      }
      setFormFilters({ ...formFilters });
    } else if (values) {
      setFilterChange({ ...filterChange, [this.name]: true });
      setFormFilters({
        ...formFilters,
        [this.name]: values.value,
      });
    }
  }

  const deleteCalendarFilters = () => {
    const newFilters = { ...filters };
    const newFormFilters = { ...formFilters };
    delete newFilters.startDate;
    delete newFilters.endDate;
    delete newFilters.month;
    delete newFilters.endedEvents;
    delete newFilters.allEvents;
    delete newFormFilters.startDate;
    delete newFormFilters.endDate;
    delete newFormFilters.month;
    delete newFormFilters.allEvents;
    setFormFilters(newFormFilters);
    handleFilters(newFilters);
  };

  const handleClearSearch = (fromModal = false) => {
    const newFilters = { ...filters };
    delete newFilters.startDate;
    delete newFilters.endDate;
    delete newFilters.month;
    delete newFilters.endedEvents;
    delete newFilters.location;
    delete newFilters.coordinates;
    delete newFilters.level;
    delete newFilters.gameType;
    delete newFilters.radius;
    delete newFilters.allEvents;
    delete newFilters.availableEntries;
    setFormFilters({});
    handleFilters(newFilters);
    setFilterChange({});
    setFirstSelect(null);
    setSecondSelect(null);
    resetLocationsToDefault();

    if (fromModal) {
      setMobileFiltersModal(false);
    }
  };

  const getFilters = {
    filterLocation: formFilters?.location?.split(','),
    myLocation: formFilters?.coordinates,
    get location() {
      if (this?.filterLocation) {
        if (this?.filterLocation[0] === 'null') {
          return {
            value: `null,+${this?.filterLocation[1]}`,
            label: commonT(`${this?.filterLocation[1].replace(/ /g, '')}-All`),
          };
        }
        return {
          value: `${this?.filterLocation[0]}, ${this?.filterLocation[1]}`,
          label: `${commonT(this?.filterLocation[0])}, ${commonT(
            this?.filterLocation[1]
          )}`,
        };
      }
      if (this.myLocation) {
        return {
          value: this.myLocation,
          label: commonT('currentLocation'),
        };
      }
      return false;
    },

    get nationalCalendar() {
      const country = (settings?.eligibleCountries || [])?.find(
        ({ name }) => name === formFilters.nationalCalendar);
      return formFilters?.nationalCalendar ? {
        value: formFilters.nationalCalendar,
        label: commonT(`countriesByKey.${country.key}`),
      } : false;
    },

    get month() {
      return formFilters?.month ? months[Number(formFilters.month)] : false;
    },

    get year() {
      return formFilters?.year
        ? {
            value: Number(formFilters.year),
            label: Number(formFilters.year),
          }
        : false;
    },

    get radius() {
      return formFilters.radius
        ? {
            value: Number(formFilters.radius),
            label: `${Number(formFilters.radius)} km`,
          }
        : false;
    },

    get level() {
      return formFilters?.level
        ? {
            value: Number(formFilters.level),
            label: Number(formFilters.level),
          }
        : false;
    },

    get gameType() {
      return formFilters?.gameType
        ? {
            value: formFilters.gameType,
            label: t(`${formFilters.gameType}`),
          }
        : false;
    },
  };

  function hasFilters(filter) {
    return filters[filter] || formFilters[filter];
  }

  const filtersComponent = (fromModal = false) => (
    <Filters {...{ hasActiveFilters, isBanned, fromModal }}>
      {fromModal && (
        <H4 className="mb10"><Trans ns="events" i18nKey="searchEvents">Search events</Trans></H4>
      )}

      <Select
        name="nationalCalendar"
        placeholder={commonT('nationalCalendar')}
        onChange={handleFormFilters}
        options={nationalCalendarOptions()}
        label={commonT('nationalCalendar')}
        higher
        lowInput
        width="150px"
        findEvent
        alignLabel
        isClearable
        deleteOnly
        locationIndicator
        ellipsis
        onBlur={resetLocationsToDefault}
        onFocus={nationalCalendarOptions}
        labelOn={formFilters.nationalCalendar}
        value={getFilters.nationalCalendar}
      />

      <Select
        name="location"
        placeholder={commonT('allLocations')}
        onChange={handleFormFilters}
        options={mapLocations(
          locations,
          ['endedEvents', 'allLocations'].includes(locationsFetchedFrom)
        )}
        label={commonT('location')}
        higher
        lowInput
        width="150px"
        findEvent
        alignLabel
        isClearable
        deleteOnly
        locationIndicator
        ellipsis
        onBlur={resetLocationsToDefault}
        onFocus={handleLocations}
        isLoading={status === 'loading' || loadingCurrentLocation}
        noOptionsMessage={({ inputValue }) => {
          setSearchField(inputValue);

          if (!locations.length) {
            commonT('noResultsFound');
          }
        }}
        labelOn={formFilters.location || formFilters.coordinates}
        value={getFilters.location}
      />

      <Select
        name="radius"
        placeholder={commonT('proximityRadius')}
        onChange={handleFormFilters}
        options={mapProximity()}
        label={commonT('radius')}
        isClearable
        isSearchable={false}
        lowInput
        alignLabel
        higher
        deleteOnly
        findEvent
        width="100px"
        noAfter={firstSelect && secondSelect}
        isDisabled={
          (formFilters?.location?.split(',').includes('null') ||
            filters?.location?.split(',').includes('null') ||
            !formFilters?.location) &&
          !formFilters.coordinates
        }
        labelOn={formFilters.radius}
        value={getFilters.radius}
      />
      {!firstSelect || !secondSelect ? (
        <Select
          name="month"
          placeholder={commonT('allMonths')}
          onChange={handleFormFilters}
          options={months}
          label={commonT('month')}
          isClearable
          isSearchable={false}
          lowInput
          alignLabel
          higher
          wideMenu
          findEvent
          deleteOnly
          ellipsis
          class="calendar"
          width="130px"
          labelOn={formFilters.month || formFilters.startDate}
          value={getFilters.month}
          defaultValue={getFilters.month}
        />
      ) : (
        firstSelect &&
        secondSelect && (
          <RangeDateSelects
            deleteFilters={deleteCalendarFilters}
            hasChanges={filterChange}
            handleChange={setFilterChange}
            setFirstSelect={setFirstSelect}
            setSecondSelect={setSecondSelect}
            firstSelect={firstSelect}
            secondSelect={secondSelect}
            handler={() => setIsComponentVisible(!isComponentVisible)}
          />
        )
      )}
      <Select
        name="level"
        placeholder={commonT('allLevels')}
        onChange={handleFormFilters}
        options={mapLevels()}
        label={commonT('level')}
        isClearable
        isSearchable={false}
        lowInput
        alignLabel
        higher
        deleteOnly
        findEvent
        width="130px"
        labelOn={formFilters.level}
        value={getFilters.level}
      />
      <Select
        name="gameType"
        placeholder={t('gameTypeText')}
        onChange={handleFormFilters}
        options={mapGameTypes()}
        label={t('gameTypeText')}
        isClearable
        isSearchable={false}
        lowInput
        alignLabel
        higher
        deleteOnly
        findEvent
        width="100px"
        labelOn={formFilters.gameType}
        value={getFilters.gameType}
      />
      <Contain justify="space-between" position="relative">
        <Button
          red
          onClick={() => {
            if (formFilters.startDate) {
              formFilters.allEvents = Boolean(formFilters.startDate); // show active and ended events for custom date range
            } else {
              const currentMonth = new Date().getMonth();
              const filtersMonth = Number(formFilters?.month) - 1;
              if (filtersMonth < currentMonth) {
                formFilters.endedEvents = true;
              } else {
                formFilters.endedEvents = false;
              }
            }
            if (fromModal) {
              setMobileFiltersModal(false);
            }
            handleFilters(formFilters);
          }}
        >
          <span>
            <Trans ns="clubs" i18nKey="search">
              Search
            </Trans>
          </span>
        </Button>

        {hasActiveFilters && (
          <ClearSearch xSmall onClick={() => handleClearSearch(fromModal)}>
            <Trans ns="common" i18nKey="clearResults">
              Clear Search
            </Trans>
          </ClearSearch>
        )}
      </Contain>

      {isComponentVisible ? (
        <RangeDatePicker
          deleteFilters={deleteCalendarFilters}
          handler={() => setIsComponentVisible(!isComponentVisible)}
          formFilters
          calendarRef={ref}
          pinnedFilters
          hasFilters={hasFilters}
          hasChanges={filterChange}
          handleChange={setFilterChange}
          firstSelect={firstSelect}
          setFirstSelect={setFirstSelect}
          secondSelect={secondSelect}
          setSecondSelect={setSecondSelect}
        />
      ) : (
        ''
      )}
    </Filters>
  );


  return (
    <>
      <div className="desktop">
        {filtersComponent()}
      </div>

      <MobileFiltersContainer onClick={() => setMobileFiltersModal(!mobileFiltersModal)}>
        <ul>
          <li>{getFilters?.nationalCalendar?.label ?? commonT('nationalCalendar')}</li>
          <li>{getFilters?.location?.label ?? commonT('allLocations')}</li>
          {getFilters?.radius?.value && (<li>{getFilters?.radius?.value} km</li>)}
          <li>{getFilters.month?.label ?? commonT('allMonths')}</li>
          <li>{getFilters.level?.value ?? commonT('allLevels')}</li>
          <li>{getFilters.gameType?.label ?? t('gameTypeText')}</li>
        </ul>
        <div className="btn-container">
          <Button red smaller className="search-btn">
            <img src={LoadImage('search-icon_white.svg')} alt="" />
          </Button>
        </div>
      </MobileFiltersContainer>

      {mobileFiltersModal && (
        <Modal
          isOpen
          onRequestClose={() => setMobileFiltersModal(false)}
          className="bookings-filters"
          mobileFullDisplay
        >
          {filtersComponent(true)}
        </Modal>
      )}
    </>
  );
};

export default EventFilters;
