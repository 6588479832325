import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '../../../../components/common/http';

// Actions
export const fetchFeaturedEvents = createAsyncThunk('events/fetchFeaturedEvents', async (_, { getState }) => {
  const sportType = getState()?.session?.masterSport;
  const { data: { data } } = await get(`/events?sportType=${sportType}&featured=true`);
  return { data, sportType };
});

// Slice
const slice = createSlice({
  name: 'featuredEvents',
  initialState: {
    data: [],
    status: 'idle',
    error: null,
    sportType: null,
  },
  reducers: {
  },
  extraReducers: {
    [fetchFeaturedEvents.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchFeaturedEvents.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload?.data;
      state.sportType = action.payload?.sportType;
    },
    [fetchFeaturedEvents.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});
export default slice.reducer;
