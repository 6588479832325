/* eslint-disable max-len */
import styled from '@emotion/styled';
import LoadImage from '../../components/common/LoadImage';

export const Guide = styled.div`
  position: relative;

  .modal-video-close-btn {
    cursor: pointer;
  }

  .scrollTo {
    position: absolute;
    top: -70px;
  }
`;

export const HiddenPanel = styled.div`
    position: absolute;
    width: 28vw;
    height: 100%;
    z-index: 2;

    ${props => props.theme.functions?.mediaQueryMax('1000px', `
    width: 20vw;`)}

    ${props => props.theme.functions?.mediaQueryMax('768px', `
    display: none`)}

    ${props => props.left && 'left: 0;'}
    ${props => props.right && 'right: 0;'}
`;

export const Video = styled.div`
  position: relative;
  display: flex !important;
  flex-direction: column;
  justify-content: end;
  cursor: pointer;
  
  > img {
    width: 100%;
    height: auto;
  }

  .video_button {
    display: none;
    position: absolute;
    right: 0px;
    bottom: 0px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    margin: 0 20px 20px auto;
    width: 150px;
    height: 42px;
    background: #FFFFFF;
    border-radius: 4px;
    cursor: pointer;
    transition: all .5s ease;
    > p { 
        margin-right: 9px;
    }
  }
`;

export const Hero = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    height: 100vh;
    background: url(${LoadImage('getting-started-guide/bg.png')}) no-repeat scroll center bottom / cover;
    white-space: pre-wrap;
    flex-direction: column;

    p {
        max-width: 500px;
        margin-top: 10px;
        font-size: 24px;
        line-height: 31px;

        ${props => props.theme.functions?.mediaQueryMax('650px', `
        font-size: 18px;
        line-height: 22px;`)}

        color: #AAFFFC;
    }

    ${props => props.theme.functions?.mediaQueryMax('650px', `
    padding: 10px;
    font-size: 18px;
    line-height: 22px;`)}

    .arrow {
        visibility: visible;
        opacity: 1;
        position: absolute;
        cursor: pointer;

        ${props => props.arrowHidden && `
        visibility: hidden;
        opacity: 0 !important;
        transition: visibility 0s .5s, opacity .5s linear;
        `}

        animation: bounce 2s infinite;
        color: white;
        font-size: 40px;
        text-align: center;
        bottom: 50px;
        ${props => props.theme.functions?.mediaQueryMax('650px', 'bottom: 100px;')}
    }

    @keyframes bounce {
        0%, 20%, 50%, 80%, 100% {
            transform: translateY(0);
        }
        40% {
            transform: translateY(-30px);
        }
        60% {
            transform: translateY(-15px);
        }
    }
`;

export const Content = styled.div`
    ${props => props.theme.functions?.mediaQueryMin('1391px', 'max-width: 1250px;')}
    ${props => props.theme.functions?.mediaQueryMax('1390px', 'max-width: 800px;')}
    ${props => props.theme.functions?.mediaQueryMax('840px', 'max-width: 600px;')}
    ${props => props.theme.functions?.mediaQueryMax('650px', 'max-width: 350px;')}
    width: 100%;

    > h1 {
        ${props => props.theme.functions?.mediaQueryMin('841px', `
        font-size: 100px;
        line-height: 110px;`)}
        ${props => props.theme.functions?.mediaQueryMax('840px', `
        font-size: 70px;
        line-height: 80px;`)}
        ${props => props.theme.functions?.mediaQueryMax('650px', `
        font-size: 40px;
        line-height: 50px;`)}

        color: #AAFFFC;
    }

    > h3 {
        margin-top: 20px;
        font-size: 24px;
        line-height: 31px;

        ${props => props.theme.functions?.mediaQueryMax('650px', `
        font-size: 18px;
        line-height: 22px;`)}

        color: #AAFFFC;
    }
`;

export const FirstPhase = styled.article`
    max-width: ${({ theme }) => theme.container?.maxWidth };
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${props => props.theme.functions?.mediaQueryMax('650px', `
    > div {
        margin-top: 20px;
    }`)}

    > img {
        height: 60px;
        width: 60px;
    }

    h3 {
        display: flex;
        align-items: center;
        margin-top: 130px;

        font-size: 30px;
        line-height: 39px;
        color: #AAFFFC;

        .dash {
            margin-left: 20px;
            width: 59px;
            height: 4px;
            ${props => props.theme.functions?.mediaQueryMax('650px', 'width: 30px;')}
            background: #F42B03;
            border-radius: 30px;
        }
    }

    > h1 {
        font-size: 70px;
        line-height: 91px;
        ${props => props.theme.functions?.mediaQueryMax('840px', `
        font-size: 70px;
        line-height: 80px;`)}
        ${props => props.theme.functions?.mediaQueryMax('650px', `
        font-size: 40px;
        line-height: 50px;`)}

        text-align: center;

        color: #AAFFFC;
    }

    > p {
        font-size: 22px;
        line-height: 29px;
        text-align: center;
        color: #AAFFFC;
    }

    .contain {
        align-items: center;

        > div:first-of-type {
            margin-bottom: 40px;
        }

        .two {
            ${props => props.theme.functions?.mediaQueryMax('650px', `
            display: flex;
            flex-direction: column;`)}

            > div:first-of-type {
                margin-right: 20px;
                ${props => props.theme.functions?.mediaQueryMax('650px', `
                margin-right: 0px;
                margin-bottom: 40px;`)}
            }

            > div:last-of-type {
                margin-left: 20px;
                ${props => props.theme.functions?.mediaQueryMax('650px', `
                margin-left: 0px;
                margin-bottom: 40px;`)}
            }
        }
    }
`;

export const SecondPhase = styled.article`
    position: relative;
    margin-top: 80px;
    width: 100%;
`;

export const ThirdPhase = styled.article`
    max-width: ${({ theme }) => theme.container?.maxWidth };
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    margin-top: 40px;

    > h1 {
        font-size: 70px;
        line-height: 91px;
        color: #AAFFFC;
    }

    > p {
        font-size: 22px;
        line-height: 29px;
        color: #AAFFFC;
    }

    .two {
        ${props => props.theme.functions?.mediaQueryMax('650px', `
        display: flex;
        flex-direction: column;`)}
        margin-bottom: 40px;

        > div:first-of-type {
            margin-right: 20px;
            ${props => props.theme.functions?.mediaQueryMax('650px', `
            margin-right: 0px;
            margin-bottom: 40px;`)}
        }

        > div:last-of-type {
            margin-left: 20px;
            ${props => props.theme.functions?.mediaQueryMax('650px', `
            margin-left: 0px;
            margin-bottom: 40px;`)}
        }
    }
`;

export const FourthPhase = styled.article`
    max-width: ${({ theme }) => theme.container?.maxWidth };
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid #10242C;
    border-radius: 20px;
    margin-top: 150px;
    padding: 40px;

    .coming-soon {
        margin-top: 20px;
        padding: 5px 10px;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        border: 2px solid #AAFFFC;
        border-radius: 20px;
    }

    > div {
        max-width: 600px;
        width: 100%;

        justify-content: center;
        align-items: center;

        img {
            height: 60px;
            width: 60px;
        }

        > h4 {
            font-size: 30px;
            line-height: 39px;
            text-align: center;
            color: #AAFFFC;
        }

        > h3 {
            font-size: 50px;
            line-height: 65px;
            text-align: center;
            margin: 20px 0;

            color: #AAFFFC;
        }

        > p {
            font-size: 22px;
            line-height: 29px;
            text-align: center;

            color: #AAFFFC;
        }
    }

    > div:last-of-type {
        //margin-top: 50px;
    }
`;

export const FifthPhase = styled.article`
    max-width: ${({ theme }) => theme.container?.maxWidth };
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    margin-top: 150px;

    h3 {
        display: flex;
        align-items: center;

        font-size: 30px;
        line-height: 39px;

        color: #AAFFFC;

        .dash {
            margin-left: 20px;
            width: 59px;
            ${props => props.theme.functions?.mediaQueryMax('650px', 'width: 30px;')}
            height: 4px;
            background: #F42B03;
            border-radius: 30px;
        }
    }

    .contain {
        margin-bottom: 30px;
        ${props => props.theme.functions?.mediaQueryMax('650px', `
        display: flex;
        flex-direction: column;`)}

        > div:first-of-type {
            margin-right: 30px;
            ${props => props.theme.functions?.mediaQueryMax('650px', `
            margin-right: 0px;
            margin-bottom: 20px;`)}
        }
    }

    > h1 {
        font-size: 70px;
        line-height: 91px;

        ${props => props.theme.functions?.mediaQueryMax('840px', `
        font-size: 70px;
        line-height: 80px;`)}
        ${props => props.theme.functions?.mediaQueryMax('650px', `
        font-size: 40px;
        line-height: 50px;`)}

        color: #AAFFFC;
    }

    > p {
        font-size: 22px;
        line-height: 29px;
        color: #AAFFFC;
    }
`;

export const SixthPhase = styled.article`
    max-width: ${({ theme }) => theme.container?.maxWidth };
    margin: 0 auto;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 150px;

    > h1 {
        font-size: 100px;
        line-height: 130px;

        ${props => props.theme.functions?.mediaQueryMax('840px', `
        font-size: 70px;
        line-height: 80px;`)}
        ${props => props.theme.functions?.mediaQueryMax('650px', `
        font-size: 40px;
        line-height: 50px;`)}

        text-align: center;
        color: #AAFFFC;
        margin-bottom: 40px;
        margin-top: 40px;
    }

    > p {
        font-size: 24px;
        line-height: 31px;
        text-align: center;
        color: #AAFFFC;
        margin-bottom: 40px;
    }

`;

export const ArticleBox = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 1;
    width: 100%;
    max-width: ${props => (props.smaller ? '580px' : '1200px')};
    padding: 60px;
    ${props => !props.alignLeft && `
      justify-content: center;
      align-items: center;
    `}
    ${props => props.mw33 && props.theme.functions?.mediaQueryMin('991px', `
      max-width: 33%;
    `)}

    ${props => (props.bg1
  ? 'background: linear-gradient(180deg, #00454F 0%, #002E34 100%);'
  : props.bg2
    ? 'background: linear-gradient(180deg, #003B54 0%, #001E2C 100%);'
    : props.bg3
      ? 'background: linear-gradient(180deg, #00294F 0%, #00172C 100%);'
      : props.bg4
        ? 'background: linear-gradient(5.9deg, #002D33 32.72%, #000A12 97.12%);'
        : `background: rgb(26,26,76);
    background: linear-gradient(121deg, rgba(26,26,76,1) 0%, rgba(0,39,39,1) 41%, rgba(1,51,44,1) 60%, rgba(1,104,93,1) 100%);`)}
    border-radius: 10px;

    img {
        height: 60px;
        width: 60px;
    }

    >p {
        color: white;
        font-size: 22px;
        line-height: 29px;
        ${props => !props.alignLeft && `
        text-align: center;`}

        margin-top: 20px;
    };

    > h2 {
        ${props => !props.alignLeft && `
        text-align: center;`}
        color: white;
    }
`;

export const SliderContainer = styled.div`
  margin-left: -15px;
  margin-right: -15px;

.slick-prev {
    ${props => props.theme.functions?.mediaQueryMin('992px', `
    left: 25%;
    transform: translateX(-25%);`)}

    ${props => props.theme.functions?.mediaQueryMax('991px', `
    left: 15%;
    transform: translateX(-15%);`)}

    z-index: 8;

    &:before {
      background-image: url('${LoadImage('getting-started-guide/arrow-left.svg')}');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      content: '';
      width: 60px;
      height: 60px;
      position: absolute;
      opacity: 1;
      top: -100%;
      left: -100%;
    }
}

.slick-next {
    ${props => props.theme.functions?.mediaQueryMin('992px', `
    right: 24%;
    transform: translateX(-24%);`)}

    ${props => props.theme.functions?.mediaQueryMax('991px', `
    right: 14%;
    transform: translateX(-14%);`)}
    z-index: 8;

    &:before {
      background-image: url('${LoadImage('getting-started-guide/arrow-right.svg')}');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      content: '';
      width: 60px;
      height: 60px;
      position: absolute;
      opacity: 1;
      top: -100%;
      right: -100%;
    }
}

counter-reset: number + 3;

.slick-dots {
    ${props => props.theme.functions?.mediaQueryMin('661px', `
    bottom: -60px;`)}
    ${props => props.theme.functions?.mediaQueryMax('660px', `
    bottom: -120px;`)}

    .slick-active {
        background: #10242C;
        border-radius: 4px;
    }
}
  
.slick-dots li {
    width: 90px;
    height: 40px;
    padding: 8px 14px;
    margin: 0 10px;
    counter-increment: number;
}

.slick-dots li button {
}

.slick-dots li button::before {
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    margin: 7px 20px;
    color: white;
    content: '${props => props.level} 'counter(number);
    width: max-content;
    left: -3px;
}

  .slick-slide {
    opacity: .5;
    transition: all .3s ease;
    transform: scale(.95);

    > div {    
      width: 40vw !important;
      
      @media all and (max-width: 991px) {
        width: 60vw !important;
      }

      @media all and (max-width: 767px) {
        width: 89vw !important;
      }
    }
    
    &.slick-center {
      opacity: 1;
      transform: scale(1);    
      .video_button {
        display: flex;
      }
    }
  }
`;

export const BalancedMatches = styled.article`
    max-width: ${({ theme }) => theme.container?.maxWidth };
    margin: 0 auto;

    h3 {
        display: flex;
        align-items: center;
        margin-top: 130px;

        font-size: 30px;
        line-height: 39px;
        color: #AAFFFC;

        .dash {
            margin-left: 20px;
            width: 59px;
            height: 4px;
            ${props => props.theme.functions?.mediaQueryMax('650px', 'width: 30px;')}
            background: #F42B03;
            border-radius: 30px;
        }
    }

    > h1 {
        font-size: 70px;
        line-height: 91px;
        ${props => props.theme.functions?.mediaQueryMax('840px', `
        font-size: 70px;
        line-height: 80px;`)}
        ${props => props.theme.functions?.mediaQueryMax('650px', `
        font-size: 40px;
        line-height: 50px;`)}

        color: #AAFFFC;
    }

    > p {
        font-size: 22px;
        line-height: 29px;
        color: #AAFFFC;
    }
`;
